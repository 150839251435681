<template>
  <el-scrollbar
    class="bottomScrollbar"
    style="height: 100%"
    :wrap-style="[{ 'overflow-x': 'hidden' }]"
  >
    <el-container class="container" style="min-width: 1050px">
      <el-header class="header" height="25px;" style="margin-bottom: 5px">
        <el-row
          style="
            width: 100%;
            border-radius: 5px;
            background-color: #606266;
            padding: 7px 7px 12px 7px;
          "
        >
          <el-col :span="24">
            <div style="font-weight: bold; font-size: 20px; color: #f8f8fc">
              <span>模型/产品查询</span>
            </div>
          </el-col>
        </el-row>
      </el-header>
      <el-main style="padding-right: 20px">
        <div class="box">
          <div>
            <el-divider content-position="left"
              ><span style="font-size: 16px">模型查询</span></el-divider
            >
          </div>
          <div style="padding-left: 5px; padding-right: 5px">
            <el-row>
              <el-col :span="24">
                <div style="text-align: right; margin-bottom: 15px">
                  <el-button plain @click="onModelQuery">查询</el-button>
                  <el-button
                    plain
                    @click="onModelBaseInfoReset"
                    :disabled="false"
                    >重置</el-button
                  >
                </div>
              </el-col>
            </el-row>
            <div>
              <el-form
                :model="modelBaseInfoForm"
                ref="modelBaseInfoForm"
                class="demo-ruleForm"
                label-width="80px"
              >
                <el-row :gutter="20">
                  <el-col :span="8"
                    ><div>
                      <!--<el-form-item label="模型编号" prop="id">
                        <el-select
                          v-model="modelBaseInfoForm.id"
                          filterable
                          placeholder="请选择"
                        >
                          <el-option
                            v-for="item in modelIdOptions"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                          >
                          </el-option>
                        </el-select>
                      </el-form-item>-->
                      <el-form-item label="模型编号" prop="id">
                        <el-input v-model="modelBaseInfoForm.id"></el-input>
                      </el-form-item></div
                  ></el-col>
                  <el-col :span="8"
                    ><div>
                      <el-form-item label="中文名称" prop="nameC">
                        <el-input v-model="modelBaseInfoForm.nameC"></el-input>
                      </el-form-item>
                    </div>
                  </el-col>
                  <el-col :span="8"
                    ><div>
                      <el-form-item label="英文名称" prop="nameE">
                        <el-input v-model="modelBaseInfoForm.nameE"></el-input>
                      </el-form-item>
                    </div>
                  </el-col>
                </el-row>
                <el-row :gutter="20">
                  <el-col :span="6"
                    ><div>
                      <el-form-item label="模型类别" prop="modelType">
                        <el-select
                          v-model="modelBaseInfoForm.modelType"
                          placeholder="请选择模型类别"
                        >
                          <el-option label="直连设备" value="0"></el-option>
                          <el-option label="云设备" value="1"></el-option>
                        </el-select>
                      </el-form-item></div
                  ></el-col>
                  <el-col :span="6"
                    ><div>
                      <el-form-item label="协议类型" prop="protocolType">
                        <el-select
                          v-model="modelBaseInfoForm.protocolType"
                          placeholder="请选择协议类别"
                        >
                          <el-option label="TCP" value="TCP"></el-option>
                          <el-option
                            label="HTTP/HTTP2"
                            value="HTTP/HTTP2"
                          ></el-option>
                          <el-option label="HTTPS" value="HTTPS"></el-option>
                          <el-option label="MQTT" value="MQTT"></el-option>
                        </el-select>
                      </el-form-item></div
                  ></el-col>
                  <el-col :span="6"
                    ><div>
                      <el-form-item label="可控性" prop="ctrlDevice">
                        <el-select
                          v-model="modelBaseInfoForm.ctrlDevice"
                          placeholder="请选择可控性"
                        >
                          <el-option label="传感器" value="0"></el-option>
                          <el-option label="控制器" value="1"></el-option>
                          <el-option label="一体机" value="2"></el-option>
                        </el-select>
                      </el-form-item>
                    </div>
                  </el-col>
                  <el-col :span="6"
                    ><div>
                      <el-form-item label="数据格式" prop="dataFormat">
                        <el-select
                          v-model="modelBaseInfoForm.dataFormat"
                          placeholder="请选择数据格式"
                        >
                          <el-option label="二进制码流" value="0"></el-option>
                          <el-option label="json字符串" value="1"></el-option>
                        </el-select>
                      </el-form-item>
                    </div>
                  </el-col>
                </el-row>
              </el-form>
            </div>
          </div>
          <div>
            <el-divider content-position="left"
              ><span style="font-size: 16px">模型主体信息</span></el-divider
            >
          </div>
          <div
            style="
              width: 100%;
              padding-right: 5px;
              padding-left: 5px;
              margin-bottom: 10px;
            "
          >
            <el-row>
              <el-col :span="24">
                <div style="text-align: right; margin-bottom: 15px">
                  <el-button
                    :disabled="selectedIndex === null"
                    plain
                    @click="onModelAbolish"
                    >作废</el-button
                  >
                  <el-button
                    :disabled="selectedIndex === null"
                    plain
                    @click="onModelModify"
                    >编辑</el-button
                  >
                  <el-button
                    :disabled="selectedIndex === null"
                    plain
                    @click="onModelDelete"
                    >删除</el-button
                  >
                </div>
              </el-col>
            </el-row>
            <el-table
              :data="modelQueryList"
              style="width: 100%"
              :header-row-style="{
                color: '#303133',
                'font-weight': 'bold',
              }"
              :header-cell-style="{
                background: '#eef1f6',
                padding: '5px',
              }"
              :cell-style="{ padding: '5px' }"
            >
              <el-table-column type="index" label="序号" width="60">
              </el-table-column>
              <el-table-column prop="id" label="模型编号" min-width="10%">
              </el-table-column>
              <el-table-column prop="nameC" label="中文名称" min-width="11%">
              </el-table-column>
              <el-table-column prop="nameE" label="英文名称" min-width="11%">
              </el-table-column>
              <el-table-column
                prop="modelType"
                label="属性类型"
                min-width="12%"
              >
              </el-table-column
              ><el-table-column
                prop="protocolType"
                label="协议类型"
                min-width="10%"
              >
              </el-table-column>
              <el-table-column prop="ctrlDevice" label="可控性" min-width="16%">
                <template #default="scope">
                  <el-select v-model="scope.row.ctrlDevice" disabled="true">
                    <el-option label="传感器" value="0"></el-option>
                    <el-option label="控制器" value="1"></el-option>
                    <el-option label="一体机" value="2"></el-option>
                  </el-select>
                </template>
              </el-table-column>
              <el-table-column
                prop="dataFormat"
                label="数据格式"
                min-width="18%"
              >
                <template #default="scope">
                  <el-select v-model="scope.row.dataFormat" disabled="true">
                    <el-option label="二进制码流" value="0"></el-option>
                    <el-option label="json字符串" value="1"></el-option>
                  </el-select>
                </template>
              </el-table-column>
              <el-table-column
                show-overflow-tooltip
                prop="desc"
                label="模型描述"
                min-width="12%"
              >
              </el-table-column>
              <el-table-column
                show-overflow-tooltip
                prop="release"
                label="是否发布"
                min-width="10%"
              >
              </el-table-column>
              <el-table-column label="是否选中" align="center" min-width="10%">
                <template slot-scope="scope">
                  <el-checkbox
                    @change="modelRadioSelected(scope.$index)"
                    :value="scope.$index === selectedIndex"
                  ></el-checkbox>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="paginationClass">
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="currentPage"
              :page-sizes="[5, 10, 20]"
              :page-size="pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total"
            >
            </el-pagination>
          </div>
        </div>
      </el-main>
    </el-container>
  </el-scrollbar>
</template>

<script>
export default {
  name: "productQuery",
  data() {
    return {
      total: 0,
      currentPage: 1,
      pageSize: 10,
      modelIdOptions: [
        {
          label: "1",
          value: "1",
        },
        {
          label: "2",
          value: "2",
        },
      ],
      //模型主体表单信息
      modelBaseInfoForm: {
        id: "",
        nameC: "",
        nameE: "",
        modelType: "",
        protocolType: "",
        dataFormat: "",
        ctrlDevice: "",
      },
      modelQueryList: [
        /*{
          id: "1",
          nameC: "名字",
          nameE: "name",
          modelType: "直连设备",
          protocolType: "HTTP",
          dataFormat: "二进制码流",
          desc: "描述描述描描述描述描",
          release: "是",
        },
        {
          id: "2",
          nameC: "名字",
          nameE: "name",
          modelType: "直连设备",
          protocolType: "HTTP",
          dataFormat: "二进制码流",
          desc: "描述描述描描述描述描",
          release: "否",
        },*/
      ],
      selectedIndex: null,
    };
  },
  created() {
    this.initModelIdOptions();
  },
  methods: {
    //查询已有的模型编号
    initModelIdOptions() {
      //网络请求  可以做成一个查询所有模型
      // this.$api.apiHitIotModels.modelIdListQuery().then((res) => {
      //   if (res.data.code === "0") {
      //     console.log("初始化ModelIdOptions");
      //   }
      // });
    },
    //根据模型信息进行查询
    onModelQuery() {
      if (
        this.modelBaseInfoForm.id === "" &&
        this.modelBaseInfoForm.nameC === "" &&
        this.modelBaseInfoForm.nameE === "" &&
        this.modelBaseInfoForm.modelType === "" &&
        this.modelBaseInfoForm.protocolType === "" &&
        this.modelBaseInfoForm.dataFormat === "" &&
        this.modelBaseInfoForm.ctrlDevice === ""
      ) {
        this.$alert("请输入查询所需参数！", {
          confirmButtonText: "确定",
        }).catch(() => {});
      } else {
        this.loadData();
      }
    },
    //重新模型信息表单
    onModelBaseInfoReset() {
      this.$refs["modelBaseInfoForm"].resetFields();
    },
    modelRadioSelected(index) {
      this.selectedIndex = index;
    },
    onModelModify() {
      /*if (都解决了，再看这个
        this.modelQueryList[this.selectedIndex].id ===
        this.$store.state.modelInputtingId  //查一下它的用法
      ) {
        this.$alert("模型正在录入！", {
          confirmButtonText: "确定",
        }).catch(() => {});
      } else*/
      if (this.modelQueryList[this.selectedIndex].release === "是") {
        this.$confirm("模型已经发布，是否停用后继续修改?", "提示", {
          confirmButtonText: "停用修改",
          cancelButtonText: "取消",
          type: "info",
        })
          .then(() => {
            //停用请求
            this.$api.apiHitIotModels
              .modelRelease({
                model_id: this.modelQueryList[this.selectedIndex].id,
                command: "0",
              })
              .then((res) => {
                this.$router.push({
                  name: "productModify",
                  params: {
                    modelId: this.modelQueryList[this.selectedIndex].id,
                  },
                });
              });
          })
          .catch(() => {});
      } else {
        this.$router.push({
          name: "productModify",
          params: { modelId: this.modelQueryList[this.selectedIndex].id },
        });
      }
    },
    onModelDelete() {
      this.$api.apiHitIotModels
        .modelDelete(this.modelQueryList[this.selectedIndex].id)
        .then((res) => {
          if (res.data.code === "1") {
            this.$alert("删除成功！", {
              confirmButtonText: "确定",
            }).catch(() => {});
            //同时刷新列表
            this.loadData();
          } else {
            this.$alert("删除失败！", {
              confirmButtonText: "确定",
            }).catch(() => {});
          }
        });
    },
    //模型废除
    onModelAbolish() {
      //网络请求模型废除
      this.$api.apiHitIotModels
        .modelAbolish(this.modelQueryList[this.selectedIndex].id)
        .then((res) => {
          if (res.data.code === "0") {
            this.$alert("废除成功！", {
              confirmButtonText: "确定",
            }).catch(() => {});
          } else {
            this.$alert("废除失败！", {
              confirmButtonText: "确定",
            }).catch(() => {});
          }
        });
    },
    //给产品展示列表tableData赋值
    loadData() {
      //网络请求-根据用户输入的模型主体信息来查询已有的一个或一组模型
      //分页，嘿嘿
      this.$api.apiHitIotModels
        .modelQuery(this.modelBaseInfoForm, this.pageSize, this.currentPage)
        .then((res) => {
          if (res.data.code === "1") {
            console.log("结果存入modelQueryList");
            //获取到的模型结果存储到模型列表中
            console.log(res.data.data);
            this.total = res.data.data.totalCount;
            this.modelQueryList = [];
            for (let i = 0; i < res.data.data.productList.length; i++) {
              this.modelQueryList.push({
                id: res.data.data.productList[i].pId,
                nameC: res.data.data.productList[i].pCname,
                nameE: res.data.data.productList[i].pEname,
                modelType:
                  res.data.data.productList[i].pType === "0"
                    ? "直连设备"
                    : "云设备",
                protocolType: res.data.data.productList[i].pProtocol,
                dataFormat: res.data.data.productList[i].pDataformat,
                desc: res.data.data.productList[i].pDesc,
                release:
                  res.data.data.productList[i].pPublish === "1" ? "是" : "否",
                ctrlDevice: res.data.data.productList[i].ctrlDevice,
              });
            }
          }
        });
    },

    handleSizeChange(pageSize) {
      // 每页条数切换
      this.pageSize = pageSize;
      // this.handleCurrentChange(this.currentPage);
      this.loadData();
    },
    handleCurrentChange(currentPage) {
      //页码切换
      this.currentPage = currentPage;
      // this.currentChangePage(this.tableData, currentPage);
      this.loadData();
    },
  },
};
</script>

<style scoped>
.container {
  width: 100%;
  height: 100%;
}
.header {
  width: 100%;
  margin: 15px 0 0;
  display: flex;
  justify-content: space-between;
}
.box {
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 5px 5px 15px 5px;
  margin-bottom: 10px;
  border-style: solid;
  border-color: #dcdfe6;
  border-width: 1px;
}
.el-row:last-child {
  margin-bottom: 0;
}
.el-col {
  border-radius: 4px;
}
</style>
