<template>
  <el-container class="container" direction="vertical">
    <el-card class="search_condition">
      <el-collapse accordion v-model="activeNames">
        <el-collapse-item name="1" style="margin-top: 0px; margin-bottom: 0px">
          <template slot="title">
            <div style="font-size: 16px">管控区域查询条件</div>
          </template>
          <el-form
            :label-width="formLabelWidth"
            :inline="false"
            style="margin-bottom: 0px; height: 125px"
          >
            <el-row style="margin-bottom: 0px; margin-top: 0px; height: 50px">
              <el-col
                :span="16"
                style="float: right; margin-bottom: 4px; margin-top: 1px"
              >
              </el-col>
              <el-col
                :span="8"
                style="float: right; margin-bottom: 4px; margin-top: 1px"
              >
                <el-button
                  @click="landsearch"
                  type="primary"
                  style="margin-right: 10px"
                  >查询</el-button
                >
                <el-button @click="reset(basicAreaInfo)">重置</el-button>
              </el-col>
            </el-row>
            <el-row style="margin-bottom: 0px; margin-top: 0px; height: 50px">
              <el-col :xs="4" :sm="6" :md="8" :lg="14" :xl="8">
                <el-form-item label="选择行政区">
                  <v-distpicker
                    :province="basicAreaInfo.province"
                    :city="basicAreaInfo.city"
                    :area="basicAreaInfo.area"
                    @province="onChangeProvince"
                    @city="onChangeCity"
                    @area="onChangeArea"
                  ></v-distpicker>
                </el-form-item>
              </el-col>
              <el-col :xs="4" :sm="6" :md="8" :lg="10" :xl="8">
                <el-form-item label="街道">
                  <el-input v-model="basicAreaInfo.street"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row style="margin-bottom: 0px; margin-top: 0px; height: 50px">
              <el-col :xs="4" :sm="6" :md="8" :lg="14" :xl="8">
                <el-form-item label="区域名称">
                  <el-input v-model="basicAreaInfo.areaName"></el-input>
                </el-form-item>
              </el-col>
              <el-col :xs="4" :sm="6" :md="8" :lg="10" :xl="8">
                <el-form-item label="面积">
                  <el-input v-model="basicAreaInfo.landArea"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </el-collapse-item>
      </el-collapse>
      <el-row :gutter="20" class="el-row" type="flex">
        <el-scrollbar
          style="height: 700px"
          :wrap-style="[{ 'overflow-x': 'hidden' }]"
        >
          <div style="margin: 4px">
            <el-col
              v-for="(item, index) in lands"
              :key="item.ctl_id"
              class="el-col"
              :span="12"
            >
              <el-card class="el-card" :key="index">
                <div slot="header" class="clearfix">
                  <span>{{ item.ctl_name }}</span>
                </div>
                <div>
                  <div class="text item">
                    <div class="item_tag">
                      <span>编号：</span>
                    </div>
                    <div class="item_desr">
                      <span> {{ item.ctl_id }}</span>
                    </div>
                  </div>
                  <div class="text item">
                    <div class="item_tag">
                      <span>地址：</span>
                    </div>
                    <div class="item_desr">
                      {{ item.ctl_address }}
                    </div>
                  </div>
                  <div class="text item">
                    <div class="item_tag">
                      <span>面积：</span>
                    </div>
                    <div class="item_desr">
                      <span>
                        {{ item.ctl_area }}
                      </span>
                    </div>
                  </div>
                </div>
                <div style="height: 80px; overflow-y: auto">
                  <el-col
                    v-for="(item, index) in lands[index].devs"
                    v-bind:key="item.index"
                    :span="12"
                  >
                    <div>
                      <el-tag
                        class="text"
                        :key="index"
                        style="
                          box-shadow: #909399;
                          width: 200px;
                          margin-left: 5px;
                          margin-right: 5px;
                        "
                        >{{ item.p_c_name }}: {{ item.sta_value
                        }}{{ item.sta_unit }}
                      </el-tag>
                    </div>
                  </el-col>
                </div>
                <div
                  style="
                    padding: 1px;
                    font-family: 'Microsoft YaHei';
                    font-size: small;
                    font-style: italic;
                    color: blueviolet;
                  "
                >
                  <el-button
                    type="text"
                    @click="landMonitorDetail(lands[index])"
                    >点击查看详情 >>></el-button
                  >
                </div>
              </el-card>
            </el-col>
          </div>
        </el-scrollbar>
      </el-row>
    </el-card>
    <el-pagination
      background
      @size-change="pageSizeChangel"
      @current-change="pageCurrentChangel"
      :current-page="pageforml.pageNum"
      :page-sizes="pageforml.pageSizes"
      :page-size="pageforml.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="pageforml.total"
      style="margin-bottom: 15px"
    ></el-pagination>
  </el-container>
</template>

<script>
import VDistpicker from "v-distpicker";
export default {
  components: {
    VDistpicker,
  },
  name: "landMonitor",
  data() {
    return {
      //公共变量
      activeNames: ["1"],
      highlightRows: new Set(),
      // LabelWidth: "60px",
      formLabelWidth: "120px",
      //区域信息分页
      ctrlId: 0,
      pageforml: {
        total: 10,
        pages: 6,
        pageNum: 1,
        pageSizes: [4, 2, 3, 6, 8],
        pageSize: 6,
        navigateFirstPage: 1,
        navigateLastPage: 3,
        lastPage: false,
        firstPage: false,
        hasNextPage: true,
        hasPreviousPage: true,
      },
      //传递变量--区域查询条件
      basicAreaInfo: {
        devId: "",
        province: "山东省",
        city: "威海市",
        area: "",
        street: "",
        areaName: "",
        landArea: "",
      },
      searchCondition: {
        pageNum: 1,
        pageSize: 6,
        ctl_address: "",
        ctl_name: "",
        alow: 0,
        aup: 0,
      },
      lands: [],
    };
  },
  mounted() {
    this.landsearch();
  },
  methods: {
    onChangeProvince(data) {
      this.basicAreaInfo.province = data.value;
    },
    onChangeCity(data) {
      this.basicAreaInfo.city = data.value;
    },
    onChangeArea(data) {
      this.basicAreaInfo.area = data.value;
    },
    landsearch: function () {
      this.searchCondition.pageNum = this.pageforml.pageNum;
      this.searchCondition.pageSize = this.pageforml.pageSize;

      let address = "";
      if (
        this.basicAreaInfo.province !== "省" &&
        this.basicAreaInfo.province.length !== 0
      )
        address += this.basicAreaInfo.province;
      if (
        this.basicAreaInfo.city !== "市" &&
        this.basicAreaInfo.city.length !== 0
      )
        address += "-" + this.basicAreaInfo.city;
      if (
        this.basicAreaInfo.area !== "区" &&
        this.basicAreaInfo.area.length !== 0
      )
        address += "-" + this.basicAreaInfo.area;
      if (this.basicAreaInfo.street !== "")
        address += "-" + this.basicAreaInfo.street;
      this.searchCondition.ctl_address = address;
      this.searchCondition.ctl_name = this.basicAreaInfo.areaName;
      this.searchCondition.alow = "0";
      this.searchCondition.aup = this.basicAreaInfo.landArea;
      console.log("haha0522:" + this.searchCondition.ctl_address);
      this.$api.apiHitIotLocation
        .getMonitorP(this.searchCondition)
        .then((res) => {
          console.log(res);
          if (res.data.code === 1) {
            console.log(res.data);
            this.lands = res.data.iotland;
            this.pageforml.total = res.data.total;
            this.ctrlId = this.lands[0].ctl_id;
          } else {
            this.$message({
              message: "查询失败，请联管理员",
              type: "info",
            });
          }
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
    landMonitorDetail(landInfo) {
      this.ctrlId = landInfo.ctl_id;
      if (landInfo.is_camera === "1") {
        let camerainfo = JSON.parse(landInfo.camera_info);
        landInfo.ctl_cameratoken = "appKey=" + camerainfo.appKey;
        landInfo.ctl_cameraurl = "ezopen:" + camerainfo.ezopen;
        landInfo.ctl_tokenurl = camerainfo.tokenurl;
      }
      console.log("landinfo2:" + landInfo.ctl_tokenurl);
      this.$router.push({
        path: "sLandMonitor",
        query: { landInfo: landInfo },
      });
    },
    pageSizeChangel(pageSize) {
      //区域当每页的数量/尺寸改变后，重新向后台发送信息  // 每页条数切换调用   landsearch()
      this.pageforml.pageSize = pageSize;
      this.landsearch();
    },
    pageCurrentChangel(currentPage) {
      //区域当前页面切换, //页码切换 调用landsearch()
      this.pageforml.pageNum = currentPage;
      this.landsearch();
    },
    reset(basicAreaInfo) {
      const emptyBasicAreaInfo = {
        province: "",
        city: "",
        area: "",
        street: "",
        areaName: "",
        name: "",
        landArea: "",
      };
      this.basicAreaInfo = emptyBasicAreaInfo;
    },
  },
};
</script>

<style scoped type="text/css">
.all {
  margin-top: -301px;
  word-break: break-all;
  height: 100%;
}
.mid {
  margin-top: 25%;
  height: 50%;
}
.mid_item {
  justify-content: center;
  align-items: center;
}

.item_tag {
  float: left;
  text-align: left;
  width: 20%;
}
.item_desr {
  margin-left: 5%;
  min-height: 30px;
  text-align: left;
}
.text {
  width: 100%;
  font-size: 12px;
  font-family: "Microsoft YaHei";
  color: #000000;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}
.clearfix:after {
  clear: both;
}
/*.el-card {*/
/*  min-width: 100%;*/
/*  height: 100%;*/
/*  margin-right: 10px;*/
/*}*/
.el-card:hover {
  margin-top: -5px;
  margin-bottom: 5px;
}
.el-card-w {
  height: 700px;
  border: none;
  background: none;
}
.el-card-w.is-hover-shadow:hover {
  box-shadow: none;
}
.el-row {
  margin-top: 10px;
  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
}
.el-row .el-card {
  min-width: 100%;
  height: 100%;
  margin-right: 5px;
  transition: all 0.5s;
}
.el-card /deep/ .el-card__header {
  background-color: #7ab2ff; /*#33aaff*/
}
.el-col {
  border-radius: 4px;
  align-items: stretch;
  margin-bottom: 10px;
  float: left;
}
.el-input {
  width: 100%;
}
.el-button {
  margin-left: 10px;
}

.search_condition {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.5);
  width: 95%;
  /*margin: 1px;*/
  border-radius: 1px;
  text-align: left;
  margin-bottom: 0px;
  margin-top: 0px;
}

.form {
  width: 80%;
}
.el-collapse {
  border-bottom: unset;
}

.el-table__header col[name="gutter"] {
  display: table-cell !important;
}

.el-table >>> .highlight {
  background: #ecf5ff;
}

.el-collapse-item >>> .el-collapse-item__header {
  justify-content: center;
  font-size: 18px;
  height: 35px;
  margin-bottom: -2px;
}
.search_condition
  >>> .el-collapse-item
  .el-collapse-item__header
  .el-collapse-item__arrow {
  margin: 1px;
}
</style>
