//位置信息管理
import locationTest from "../../views/hitIotLocation/AreaSelect";
import controlArea from "@/views/hitIotLocation/controlArea";
import bindDevice from "@/views/hitIotLocation/bindDevice";
import CtrLandModify from "../../views/hitIotLocation/CtrLandModify";
import landMonitor from "../../views/hitIotLocation/landMonitor";
import sLandMonitor from "../../views/hitIotLocation/sLandMonitor";
import chartMonitor from "../../views/hitIotLocation/chartMonitor";

const routerHitIotLocation = [
  {
    path: "locationTest",
    component: locationTest,
    meta: {
      title: "位置信息管理",
    },
  },
  {
    path: "controlArea",
    component: controlArea,
    meta: {
      title: "管控区域维护",
    },
  },
  {
    path: "bindDevice",
    component: bindDevice,
    meta: {
      title: "设备与区域绑定",
    },
  },
  {
    path: "CtrLandModify",
    component: CtrLandModify,
    meta: {
      title: "地块信息修改",
    },
  },
  {
    path: "landMonitor",
    component: landMonitor,
    meta: {
      title: "地块数据总览",
    },
  },
  {
    path: "sLandMonitor",
    component: sLandMonitor,
    meta: {
      title: "详细数据展示及设备控制",
    },
  },
  {
    path: "chartMonitor",
    component: chartMonitor,
    meta: {
      title: "动态数据展示",
    },
  },
];
export default routerHitIotLocation;
