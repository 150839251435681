<template>
  <el-container class="container" direction="vertical">
    <el-card class="search_condition">
      <el-collapse accordion v-model="activeNames">
        <el-collapse-item name="1">
          <template slot="title">
            <div style="font-size: 16px">设备查询条件</div>
          </template>
          <el-form
            :label-width="formLabelWidth"
            :inline="false"
            class="demo-from-inline"
          >
            <el-row>
              <el-col :span="6" style="float: right; margin-bottom: 6px">
                <el-button
                  @click="deviceSearch"
                  type="primary"
                  style="margin-right: 10px"
                  >查询</el-button
                >
                <el-button @click="reset()">重置</el-button>
              </el-col>
            </el-row>
            <el-row>
              <el-col :xs="4" :sm="6" :md="8" :lg="8" :xl="8">
                <el-form-item label="设备名称">
                  <el-input v-model="devCondition.name"></el-input>
                </el-form-item>
              </el-col>
              <el-col :xs="4" :sm="6" :md="8" :lg="8" :xl="8">
                <el-form-item label="设备序列号">
                  <el-input v-model="devCondition.sn"></el-input>
                </el-form-item>
              </el-col>
              <el-col :xs="4" :sm="6" :md="8" :lg="8" :xl="8">
                <el-form-item
                  label="设备状态"
                  ref="devStateDef"
                  :model="devStateDef"
                >
                  <el-select
                    v-model="devStateDef.devState"
                    placeholder="请选择"
                    style="width: 50%"
                  >
                    <el-option label="全部" value="-1"></el-option>
                    <el-option label="已注册" value="1"></el-option>
                    <el-option label="未注册" value="0"></el-option>
                    <el-option label="注销" value="4"></el-option>
                    <el-option label="异常" value="2"></el-option>
                    <el-option label="报废" value="3"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </el-collapse-item>
      </el-collapse>
    </el-card>
    <el-main>
      <el-scrollbar
        class="asideScrollbar"
        :wrap-style="[{ 'overflow-x': 'hidden' }]"
      >
        <div style="margin: 0 15px 0">
          <!--          这里套一层margin是为了使el-scrollbar往右边偏一点，不至于紧贴着元素-->
          <div class="service">
            <div>
              <el-table
                v-fit-columns
                :data="tableData"
                style="width: 100%"
                :header-row-style="{
                  color: '#303133',
                  'font-size': '16px',
                  'font-weight': 'bold',
                }"
                border
                :row-style="{ 'font-size': '16px' }"
                :header-cell-style="{ background: '#eef1f6' }"
              >
                <el-table-column label="选择" min-width="40%" fixed>
                  <template slot-scope="scope">
                    <el-radio
                      :label="scope.$index"
                      v-model="currentRow"
                      @change.native="getCurrentRow(scope.row)"
                      >&nbsp;
                    </el-radio>
                  </template>
                </el-table-column>
                <el-table-column prop="id" label="id" min-width="40%">
                </el-table-column>
                <el-table-column prop="name" label="设备名称">
                </el-table-column>
                <el-table-column prop="sn" label="设备序列号">
                </el-table-column>
                <el-table-column prop="token" label="访问标识">
                </el-table-column>
                <el-table-column prop="product" label="设备模型">
                </el-table-column>
                <el-table-column prop="type" label="设备类型">
                </el-table-column>
                <el-table-column prop="longitude" label="经度">
                </el-table-column>
                <el-table-column prop="latitude" label="纬度">
                </el-table-column>
                <el-table-column prop="admin" label="负责人"> </el-table-column>
                <el-table-column prop="mperiod" label="维护周期">
                </el-table-column>
                <el-table-column
                  prop="hostflag"
                  label="主机标识"
                  min-width="20%"
                >
                </el-table-column>
                <el-table-column prop="host" label="宿主机"> </el-table-column>
                <!--                <el-table-column prop="properties" label="摄像头或特殊属性"> </el-table-column>-->
                <el-table-column prop="i_properties" label="摄像头或特殊属性">
                </el-table-column>
                <el-table-column
                  fixed="right"
                  label="操作"
                  prop="operation"
                  width="220"
                  align="center"
                >
                  <template slot-scope="props">
                    <el-button
                      @click="
                        $router.push({
                          path: 'DeviceModify',
                          query: props.row,
                        })
                      "
                      size="mini"
                      style="font-size: 16px"
                      type="text"
                      >编辑</el-button
                    >
                    <el-button
                      @click="stateDevice(props.row)"
                      type="text"
                      style="font-size: 16px"
                      >注销/取消</el-button
                    >
                    <el-button
                      @click="delDevice(props.row)"
                      size="mini"
                      type="danger"
                      style="font-size: 16px"
                      >删除</el-button
                    >
                  </template>
                </el-table-column>
              </el-table>
              <br />
              <div class="paginationClass">
                <el-pagination
                  background
                  @size-change="handleSizeChange"
                  @current-change="handleCurrentChange"
                  :current-page="pageform.pageNum"
                  :page-sizes="pageform.pageSizes"
                  :page-size="pageform.pageSize"
                  layout="total, sizes, prev, pager, next, jumper"
                  :total="pageform.total"
                  style="margin-bottom: 15px"
                >
                </el-pagination>
              </div>
            </div>
          </div>
        </div>
      </el-scrollbar>
    </el-main>
  </el-container>
</template>

<script>
export default {
  name: "deviceDel",
  data() {
    return {
      activeNames: ["1"],
      formLabelWidth: "120px",
      currentRow: 0,
      devid: -1,
      pageform: {
        total: 20,
        pages: 5,
        pageNum: 1,
        pageSizes: [2, 5, 10, 15, 20],
        pageSize: 5,
        navigateFirstPage: 1,
        navigateLastPage: 3,
        lastPage: false,
        firstPage: false,
        hasNextPage: true,
        hasPreviousPage: true,
      },
      tableData: [
        {
          id: -1,
          name: "",
          sn: "",
          token: "",
          product: -1,
          type: "",
          longitude: "",
          latitude: "",
          admin: "",
          mperiod: "",
          hostflag: "",
          host: "",
          properties: "",
          i_properties: "",
        },
      ],
      devCondition: {
        pageNum: 1,
        pageSize: 5,
        id: -1,
        sn: "",
        token: "",
        name: "",
        type: "",
        longitude: "",
        latitude: "",
        region: "", //暂时不用
        mperiod: "",
        admin: "",
        online: -1,
        datelow: "",
        dateup: "",
        state: "",
        hostflag: "1",
        host: "",
        product: -1,
      },
      deviceBaseInfo: {
        id: -1, //返回值
        sn: "",
        token: "",
        product: "",
        state: "",
      },
      devStateDef: {
        devState: "-1",
      },
    };
  },
  activated() {
    if (window.name === "") {
      console.log("首次被加载！");
      window.name = "设备维护管理";
    } else this.deviceSearch();
  },
  methods: {
    deviceSearch() {
      //设备查询
      this.devCondition.pageNum = this.pageform.pageNum;
      this.devCondition.pageSize = this.pageform.pageSize;
      this.devCondition.id = -1;
      if (this.devStateDef.devState !== "-1")
        this.devCondition.state = this.devStateDef.devState;
      else this.devCondition.state = ""; //当由其他状态转换为全查时，不赋值将维持原值
      this.$api.apiHitIotLocation
        .getHost(this.devCondition)
        .then((res) => {
          console.log(res);
          if (res.data.code === "1") {
            let dataTmp = res.data;
            this.tableData = dataTmp.data;
            this.devid = this.tableData[0].id;
            this.pageform.total = dataTmp.total;
          } else {
            this.$message({
              message: "查询失败！",
              type: "info",
            });
          }
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
    stateDevice: function (row) {
      this.deviceBaseInfo.id = row.id;
      let msg = "";
      if (row.state === "4") {
        if (row.product !== null && row.product !== "") {
          this.deviceBaseInfo.state = "1,1";
          msg = row.name + "由注销状态变为已注册状态，";
        } else {
          //原来没与模型绑定
          this.deviceBaseInfo.state = "1,0";
          msg = row.name + "由注销状态变为未注册状态，";
        }
      } else {
        this.deviceBaseInfo.state = "1,4";
        msg = row.name + "变为注销状态，";
      }
      this.deviceBaseInfo.token = "0,0";
      this.deviceBaseInfo.product = "0,0";
      this.$api.apiHitIotLocation
        .updateDevice(this.deviceBaseInfo)
        .then((res) => {
          console.log(res);
          if (res.data.code === "1") {
            console.log(res);
            this.$message({
              message: msg + "保存成功！",
              type: "success",
            });
          } else {
            this.$message({
              message: msg + "更新失败！",
              type: "info",
            });
          }
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
    reset() {
      this.devCondition.sn = "";
      this.devCondition.name = "";
      this.devStateDef.devState = "-1";
    },
    getArrayIndex(arr, obj) {
      let i = arr.length;
      while (i--) {
        if (arr[i].id === obj.id) {
          return i;
        }
      }
      return -1;
    },
    handleSizeChange(pageSize) {
      // 每页条数切换
      this.pageform.pageSize = pageSize;
      this.deviceSearch();
    },
    handleCurrentChange(currentPage) {
      //页码切换
      this.pageform.pageNum = currentPage;
      this.deviceSearch();
    },
    getCurrentRow(row) {
      console.log(row); //可以给查询条件赋值
      this.devid = row.id;
    },
    delDevice(row) {
      this.$confirm("此操作将永久删除该设备, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        // const index = this.getArrayIndex(this.tableData, row);
        // this.tableData.splice(index, 1);
        // this.currentChangePage(this.tableData, this.pageform.currentPage);
        // this.total = this.tableData.length;
        console.log("rowid=" + row.id);
        this.$api.apiHitIotLocation
          .delDevice(row.id)
          .then((res) => {
            console.log(res);
            if (res.data.code === "1") {
              console.log(res);
              this.$message({
                message: "删除成功！",
                type: "success",
              });
              this.deviceSearch();
            } else {
              this.$message({
                message:
                  "删除失败！该设备绑定地块，请先解绑！或有数据不允许删除！",
                type: "info",
              });
            }
          })
          .catch((err) => {
            console.log(err.response);
          });
      });
    },
  },
  mounted() {
    // this.currentChangePage(this.tableData, this.pageform.currentPage);
    // this.total = this.tableData.length;
    // window.onresize = function () {
    //   this.fresh = false;
    //   // this.$forceUpdate();
    //   this.$nextTick(() => {
    //     this.refresh = true;
    //   });
    // };
  },
};
</script>

<style scoped>
.container {
  width: 100%;
  height: 100%;
}
.header {
  width: calc(100% - 30px);
  margin: 15px 15px 0;
  display: flex;
  justify-content: space-between;
}
.explain {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
  padding: 12px;
  border-radius: 5px;
  margin-bottom: 15px;
}
.service {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
  padding: 12px;
  border-radius: 5px;
}

.asideScrollbar {
  height: 100%;
}
.el-collapse {
  border-bottom: unset;
}
.search_condition {
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
  width: 95%;
  margin: 1px auto;
  border-radius: 2px;
  text-align: left;
}
.el-table__header col[name="gutter"] {
  display: table-cell !important;
}

.el-table >>> .highlight {
  background: #ecf5ff;
}

.el-collapse-item >>> .el-collapse-item__header {
  justify-content: center;
  font-size: 18px;
}
.search_condition
  >>> .el-collapse-item
  .el-collapse-item__header
  .el-collapse-item__arrow {
  margin: 0;
}
.search_condition >>> .el-card__body {
  height: 10%;
}
</style>
