<template>
  <el-container class="container">
    <el-header class="header">
      <div style="font-weight: bold; font-size: 20px">
        <span>国标</span>
        <span style="margin-left: 10px" @click="func_show = !func_show">
          <el-link type="primary" :underline="false"
            ><i class="el-icon-view"></i>功能介绍</el-link
          >
        </span>
      </div>
      <div>
        <el-button
          slot="reference"
          type="text"
          style="margin: 10px"
          @click="openT"
          >新增</el-button
        >
        <!--            这里的数据不能加this，否则会出问题-->
      </div>
    </el-header>
    <el-main>
      <el-scrollbar
        class="asideScrollbar"
        :wrap-style="[{ 'overflow-x': 'hidden' }]"
      >
        <div style="margin: 0 15px 0">
          <div class="explain" v-show="func_show">
            <div style="font-size: 16px; font-weight: bold">国标说明</div>
            <div style="color: #909399">
              <p>
                国家标准分类按照标准化对象，通常把标准分为技术标准、管理标准和工作标准三大类。
              </p>
              <p>
                技术标准——对标准化领域中需要协调统一的技术事项所制定的标准。包括基础标准、
                产品标准、工艺标准、检测试验方法标准，及安全、卫生、环保标准等。
              </p>
              <p>
                管理标准——对标准化领域中需要协调统一的管理事项所制定的标准。
              </p>
              <p>
                工作标准——对工作的责任、权利、范围、质量要求、程序、效果、检查方法、考核办法所制定的标准。
              </p>
            </div>
          </div>
          <div style="width: 100%">
            <div class="service">
              <el-tree
                :data="data"
                :props="defaultProps"
                @node-expand="(data) => handleNodeClick(data)"
                :expand-on-click-node="false"
              >
                <span class="custom-tree-node" slot-scope="{ node, data }">
                  <span>{{ node.label }}</span>
                  <span>
                    <el-button
                      type="text"
                      size="mini"
                      @click="() => openD(data)"
                    >
                      新增
                    </el-button>
                    <el-popover placement="top" width="160px">
                      <p>此项数据及其子数据都将被删除,</p>
                      <p>删除后将无法恢复，是否删除？</p>
                      <div style="text-align: right; margin: 0">
                        <el-button size="mini" type="text" @click="close"
                          >取消</el-button
                        >
                        <el-button
                          type="primary"
                          size="mini"
                          @click="() => remove(node, data)"
                          >确定</el-button
                        >
                      </div>
                      <el-button
                        type="text"
                        size="mini"
                        slot="reference"
                        style="margin: 10px"
                        >删除</el-button
                      >
                    </el-popover>
                  </span>
                </span>
              </el-tree>
            </div>
          </div>
        </div>
      </el-scrollbar>
    </el-main>
    <el-dialog
      title="添加设备"
      :visible.sync="dialogAddEquip"
      width="25%"
      :close-on-click-modal="false"
      @close="reset"
      v-dialogDrag
    >
      <el-form
        :model="form"
        :rules="rules"
        ref="form"
        label-width="80px"
        status-icon
      >
        <el-form-item label="类别编号" prop="c_id">
          <el-input v-model.number="form.c_id"></el-input>
        </el-form-item>
        <el-form-item label="中文名称" prop="c_c_name">
          <el-input v-model="form.c_c_name"></el-input>
        </el-form-item>
        <el-form-item label="英文名称" prop="c_e_name">
          <el-input v-model="form.c_e_name"></el-input>
        </el-form-item>
        <el-form-item style="text-align: left">
          <el-button @click="dialogAddEquip = false">取 消</el-button>
          <el-button type="primary" @click="append">立即添加</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </el-container>
</template>

<script>
export default {
  name: "nationalTable",
  data: function () {
    return {
      data: [],
      func_show: false,
      dialogAddEquip: false,
      subtree: null,
      index: false,
      defaultProps: {
        children: "children",
        label: "label",
        id: 0,
      },
      form: {
        c_id: "",
        c_c_name: "",
        c_e_name: "",
      },
      equip: {
        c_id: "0",
        f_id: "",
        c_c_name: "",
        c_e_name: "",
        c_series: -1,
        f_name: "",
      },
      rules: {
        c_id: [
          { required: true, message: "请输入类别编号", trigger: "blur" },
          { type: "number", message: "类别编号必须为数字值" },
        ],
        c_c_name: [
          { required: true, message: "请输入中文名称", trigger: "blur" },
        ],
        c_e_name: [
          { required: true, message: "请输入英文名称", trigger: "blur" },
        ],
      },
    };
  },
  mounted() {
    this.show();
  },
  methods: {
    handleNodeClick(data) {
      this.equip.c_id = data.id;
      this.$api.apiHitIotClassification.getEquipList(this.equip).then((res) => {
        if (res.data.code === 1) {
          data.children = res.data.data;
          for (let i = 0; i < res.data.length; ++i) {
            this.handleNodeClickE(data.children[i]);
          }
        }
      });
    },
    handleNodeClickE(data) {
      console.log(data);
      this.equip.c_id = data.id;
      this.$api.apiHitIotClassification.getEquipList(this.equip).then((res) => {
        if (res.data.code === 1) {
          data.children = res.data.data;
        }
      });
    },
    show: function () {
      this.equip.c_id = "0";
      this.$api.apiHitIotClassification.getEquipList(this.equip).then((res) => {
        if (res.data.code === 1) {
          console.log(res);
          this.data = res.data.data;
          console.log(this.data);
          for (let i = 0; i < this.data.length; ++i) {
            this.handleNodeClickE(this.data[i]);
          }
        } else {
          this.$message({
            message: "查询失败，请联管理员",
            type: "info",
          });
        }
      });
    },
    openD(data) {
      this.subtree = data;
      this.index = false;
      this.dialogAddEquip = true;
    },
    openT() {
      //右上角大的新增
      this.index = true;
      this.dialogAddEquip = true;
    },
    append() {
      //对话框的立即添加按钮
      if (this.form.c_id === "" || this.form.c_c_name === "")
        this.$message.error("添加失败，输入不能为空");
      else {
        if (this.index) {
          this.appendT();
        } else {
          this.appendD();
        }
      }
    },
    appendT() {
      //右上角大的新增
      this.equip.c_c_name = this.form.c_c_name;
      this.equip.c_e_name = this.form.c_e_name;
      this.equip.c_id = this.form.c_id;
      this.equip.f_id = 0;
      this.$api.apiHitIotClassification.insertEquip(this.equip).then((res) => {
        if (res.data.code === 1) {
          this.$message({
            message: "添加成功",
            type: "success",
          });
          this.show();
          this.dialogAddEquip = false;
        } else {
          this.$message.error("添加失败，类别编号或设备名已存在");
          this.dialogAddEquip = false;
        }
      });
    },
    appendD() {
      this.equip.c_c_name = this.form.c_c_name;
      this.equip.c_e_name = this.form.c_e_name;
      this.equip.c_id = this.form.c_id;
      this.equip.f_id = this.subtree.id;
      this.$api.apiHitIotClassification.insertEquip(this.equip).then((res) => {
        if (res.data.code === 1) {
          this.$message({
            message: "添加成功",
            type: "success",
          });
          this.handleNodeClick(this.subtree); //只对父节点进行刷新，这个说明，subtree对data是浅拷贝？
          this.dialogAddEquip = false;
        } else {
          this.$message.error("添加失败，类别编号已存在");
          this.dialogAddEquip = false;
        }
      });
    },
    remove(node, data) {
      //删除节点及其所有子树
      this.equip.c_id = data.id;
      this.$api.apiHitIotClassification.delEquip(this.equip).then((res) => {
        if (res.data.code === 1) {
          this.$message({
            message: "删除成功",
            type: "success",
          });
          this.show();
        } else {
          this.$message.error("添加失败，类别编号或设备名已存在");
        }
        this.close();
      });
    },
    close() {
      document.body.click();
    },
    reset() {
      this.$refs["form"].resetFields();
    },
  },
};
</script>

<style>
.el-tree > .el-tree-node:after {
  border-top: none;
}

.el-tree-node {
  position: relative;
  padding-left: 16px;
}

.el-tree-node__expand-icon.is-leaf {
  display: none;
}

.el-tree-node__children {
  padding-left: 16px;
}

.el-tree-node :last-child:before {
  height: 38px;
}

.el-tree > .el-tree-node:before {
  border-left: none;
}

.el-tree > .el-tree-node:after {
  border-top: none;
}

.el-tree-node:before {
  content: "";
  left: -4px;
  position: absolute;
  right: auto;
  border-width: 1px;
}

.el-tree-node:after {
  content: "";
  left: -4px;
  position: absolute;
  right: auto;
  border-width: 1px;
}

.el-tree-node:before {
  border-left: 1px dashed #1389bc;
  bottom: 0px;
  height: 100%;
  top: -26px;
  width: 1px;
}

.el-tree-node:after {
  border-top: 1px dashed #1389bc;
  height: 20px;
  top: 12px;
  width: 18px;
}

.el-tree .el-tree-node__expand-icon.expanded {
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
}

.el-tree .el-icon-caret-right:before {
  content: "";
  font-size: 16px;
  color: #1389bc;
  position: absolute;
  left: -20px;
  top: -8px;
}

.el-tree .el-tree-node__expand-icon.expanded.el-icon-caret-right:before {
  content: "";
  font-size: 16px;
  color: #1389bc;
  position: absolute;
  left: -20px;
  top: -8px;
}

.el-tree-node__content > .el-tree-node__expand-icon {
  padding: 0;
}
.container {
  width: 100%;
  height: 100%;
}
.header {
  width: calc(100% - 30px);
  margin: 15px 15px 0;
  display: flex;
  justify-content: space-between;
}
.explain {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
  padding: 12px;
  border-radius: 5px;
  margin-bottom: 15px;
}
.service {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
  padding: 12px;
  border-radius: 5px;
  display: block;
  overflow-y: scroll;
}
.asideScrollbar {
  height: 100%;
}
.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
}
</style>
