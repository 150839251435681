<template>
  <div>
    <bm-polygon
      v-for="(item, index) in polygons"
      :path="item.path"
      :editing="item.editing"
      :key="index"
      @ready="onReady($event)"
      @rightclick="rightClick(index, $event)"
      @dblclick="dblClick(index, $event)"
    />
    <bml-marker-clusterer averageCenter>
      <div v-for="(item, index) in polygons" :key="index">
        <bm-marker
          v-for="(marker, idex) of item.markers"
          :key="idex"
          :position="{ lng: marker.lng, lat: marker.lat }"
          @click="infoOpen(idex, marker)"
          @dragend="showposition(item, marker, $event)"
          @rightclick="modifymarker(marker.id, $event)"
          :icon="marker.icon"
          :title="marker.name"
          clicking
          :dragging="marker.dragging"
        >
        </bm-marker>
      </div>
    </bml-marker-clusterer>
    <!--    </bm-polygon>-->
    <bm-info-window
      class="custom"
      :show="infowindow.show"
      :position="{ lng: infowindow.lng, lat: infowindow.lat }"
      @close="infoClose"
      @open="infoOpened"
      auto-pan
      :close-on-click="false"
    >
      <p>
        <el-row>
          <span style="color: white"
            >{{ infowindow.name }}{{ infowindow.sn }}
          </span>
          <i
            class="el-icon-circle-close"
            style="color: white; float: right"
            @click="closeWindow"
          />
        </el-row>
        <el-row style="margin-top: 5px">
          <el-tag v-if="infowindow.state === '1'" type="success" effect="plain">
            正常工作
          </el-tag>
          <el-tag v-else type="warning" effect="plain"> 设备异常 </el-tag>
          <el-button
            type="text"
            icon="el-icon-more"
            round
            style="float: right"
            @click="
              $router.push({
                name: 'rtDeviceDetail',
                params: { id: infowindow.id },
              })
            "
          >
            详情
          </el-button>
        </el-row>
        <el-divider></el-divider>
        <el-row style="color: white">
          <label>经度{{ infowindow.lng }}</label>
        </el-row>
        <el-row style="color: white">
          <label>纬度{{ infowindow.lat }}</label>
        </el-row>
        <el-row> </el-row>
      </p>
    </bm-info-window>
  </div>
</template>
<script>
import commonMixin from "vue-baidu-map/components/base/mixins/common.js";
import BmPolygon from "./Polygon";
import BmInfoWindow from "vue-baidu-map/components/overlays/InfoWindow.vue";
import BmMarker from "vue-baidu-map/components/overlays/Marker.vue";
import { BmlMarkerClusterer } from "vue-baidu-map";
var BMapLib = require("bmaplib").BMapLib;
var oldBMap = require("bmaplib").BMap;
export default {
  name: "BmPolygonEx",
  components: {
    BmPolygon,
    BmInfoWindow,
    BmlMarkerClusterer,
    BmMarker,
  },
  mixins: [commonMixin("overlay")],
  props: {
    addable: Boolean,
    iRegion: String,
    polygonData: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      newmarker: {},
      newmarkerpolygonidx: undefined, //正在添加新设备点的地块
      hasnewmarker: 0, //0 没有正在添加设备点，1  有新的设备点但没有完成
      insertDev: false, //是否添加设备
      infowindow: {},
      show: false,
      polygons: [],
      modipolyogn: undefined,
      createpolygon: undefined,
      isActive: false,
      activePolygon: undefined,
      devparams: {
        pageNum: "1",
        pageSize: "20",
        i_id: -1, //-1表示不查询此字段
        i_sn: "",
        i_token: "",
        i_name: "",
        i_type: "",
        i_longitude: "",
        i_latitude: "",
        i_region: "",
        i_online: "",
        i_state: "",
        datelow: "",
        dateup: "",
        ctl_id: 2, //-1表示不查询此字段
        ctl_address: "",
        ctl_local: "",
        ctl_name: "",
        alow: -1, //-1表示不查询此字段
        aup: -1, //-1表示不查询此字段
      },
    };
  },
  watch: {
    polygonData(newPolygonData, oldPolygonData) {
      console.log("polygonData", newPolygonData);
      this.reload();
    },
    activePolygon(newvalue, oldvalue) {
      if (newvalue !== undefined) {
        console.log(newvalue);
        newvalue.setStrokeColor("#00ccff");
        newvalue.setFillColor("#cc3333");
        newvalue.setFillOpacity(1);
        newvalue.setStrokeWeight(5);
      }
      if (oldvalue !== undefined) {
        oldvalue.setFillColor("#00ccff");
        oldvalue.setStrokeColor("#cc3333");
        oldvalue.setFillOpacity(0.25);
        oldvalue.setStrokeWeight(2);
      }
    },
  },
  methods: {
    isInsidePolygon(point, polygon) {
      var { BMap } = this;
      if (
        !(point instanceof BMap.Point) ||
        !(polygon instanceof BMap.Polygon)
      ) {
        console.log("sdsdsadsa");
        return false;
      }
      // var polygonBounds = polygon.getBounds();
      // if (!polygonBounds.containsPoint(point)) {
      //   console.log("sdadas");
      //   return false;
      // }
      // console.log("efeffe");
      var pts = polygon.getPath();
      console.log({ pts });

      var N = pts.length;
      var boundOrVertex = true;
      var intersectCount = 0;
      var precision = 2e-10;
      var p1, p2; //neighbour bound vertices
      var p = point;

      p1 = pts[0]; //left vertex
      for (
        var i = 1;
        i <= N;
        ++i //check all rays
      ) {
        if (p.equals(p1)) {
          return boundOrVertex; //p is an vertex
        }
        p2 = pts[i % N]; //right vertex
        if (
          p.lat < Math.min(p1.lat, p2.lat) ||
          p.lat > Math.max(p1.lat, p2.lat)
        ) {
          //ray is outside of our interests
          p1 = p2;
          continue; //next ray left point
        }
        console.log({ p1 });
        console.log({ p2 });
        if (
          p.lat > Math.min(p1.lat, p2.lat) &&
          p.lat < Math.max(p1.lat, p2.lat)
        ) {
          if (p.lng <= Math.max(p1.lng, p2.lng)) {
            if (p1.lat == p2.lat && p.lng >= Math.min(p1.lng, p2.lng)) {
              return boundOrVertex;
            }

            if (p1.lng == p2.lng) {
              if (p1.lng == p.lng) {
                return boundOrVertex;
              } else {
                ++intersectCount;
              }
            } else {
              var xinters =
                ((p.lat - p1.lat) * (p2.lng - p1.lng)) / (p2.lat - p1.lat) +
                p1.lng;
              if (Math.abs(p.lng - xinters) < precision) {
                return boundOrVertex;
              }

              if (p.lng < xinters) {
                ++intersectCount;
              }
            }
          }
        } else {
          if (p.lat == p2.lat && p.lng <= p2.lng) {
            var p3 = pts[(i + 1) % N]; //next vertex
            if (
              p.lat >= Math.min(p1.lat, p3.lat) &&
              p.lat <= Math.max(p1.lat, p3.lat)
            ) {
              ++intersectCount;
            } else {
              intersectCount += 2;
            }
          }
        }
        p1 = p2; //next ray left point
      }
      console.log(intersectCount);
      if (intersectCount % 2 == 0) {
        //偶数在多边形外
        return false;
      } else {
        //奇数在多边形内
        return true;
      }
    },
    lablestr(idex) {
      let label = new this.BMap.Label(idex, {
        offset: new this.BMap.Size(-3, -5),
      });
      label.setStyle({
        background: "url()",
        color: "#fff",
        border: "none",
        fontSize: "24px",
        textAlign: "center",
        width: "50px",
        height: "50px",
        lineHeight: "50px",
      });
      return label;
    },
    deleteMarker(id) {},
    showposition(item, marker, e) {
      // this.$axios({
      //   method: "post",
      //   url: "http://10.245.150.82:6701/hitLandUpdateDev",
      //   data: {
      //     i_id: id,
      //     iLongitude: point.lng,
      //     iLatitude: point.lat,
      //     iRegion: "山东省威海市环翠区",
      //     ctl_id: "",
      //   },
      // })

      console.log(e);
      let target = e.currentTarget;
      console.log(target);
      console.log(marker.lng, marker.lat);
      let { lng, lat } = marker;
      console.log({ item });
      var pts = [];
      item.path.forEach((point, index) => {
        var pt = new oldBMap.Point(point.lng, point.lat);
        pts.push(pt);
      });
      let point = new oldBMap.Point(e.point.lng, e.point.lat);
      let polygon = new oldBMap.Polygon(pts);
      console.log({ polygon });
      console.log(polygon.getBounds());
      console.log(polygon.getBounds().getSouthWest());
      console.log(polygon.getBounds().getNorthEast());

      let result = BMapLib.GeoUtils.isPointInPolygon(point, polygon);
      if (result === true) {
        this.$api.apiHitIotLocation
          .updateDev({
            i_id: marker.id,
            iLongitude: e.point.lng,
            iLatitude: e.point.lat,
            iRegion: "山东省威海市环翠区",
            ctl_id: "",
          })
          .then((res) => {
            console.log(res.data);
            this.$message.success("更新成功");
          })
          .catch((err) => {
            console.log(err.response);
            this.$message.error("更新失败");
          });
      } else {
        this.$message.error("不要将设备安装在地块外");
        target.setPosition(new this.BMap.Point(lng, lat));
        target.disableDragging();
        console.log("daagfegegds");
      }
    },
    infoOpen(index, marker) {
      this.infowindow = {
        show: true,
        ...marker,
      };
    },
    infoClose() {
      // this.infowindow.show = false;
      console.log("closed");
    },
    infoOpened(e) {
      console.log({ e });
      console.log("opened");
      // console.log(this.infowindow);
    },
    closeWindow() {
      this.infowindow.show = false;
    },
    dblClick(index, e) {
      console.log("clickdas");
      if (this.insertDev) {
        this.$message.warning("请先结束添加设备点");
        return;
      }
      var polygonObj = e.currentTarget;
      if (this.activePolygon === polygonObj) {
        //取消选中当前地块
        // polygonObj.setStrokeColor("#cc3333");
        // polygonObj.setFillColor("#00ccff");
        // polygonObj.setFillOpacity(0.25);
        // polygon
        this.activePolygon = undefined;
      } else {
        //选中当前地块：高亮
        console.log(polygonObj);
        this.activePolygon = polygonObj;
      }
      // polygonObj.setStrokeWeight(5);
      // polygonObj.setStrokeColor("blue");
    },
    draw(path, { el, BMap, map }) {
      const { lng, lat } = path;
      const pixel = map.pointToOverlayPixel(new BMap.Point(lng, lat));
      el.style.left = pixel.x - 60 + "px";
      el.style.top = pixel.y - 20 + "px";
    },
    drawPolygon() {
      var { polygons } = this;
      polygons.push({
        editing: false,
        state: "pre_draw",
        path: [],
        data: "",
        isActive: false,
      });
      this.createpolygon = polygons.length - 1; //将正在创建的地块的下标赋值给createpolyogn
    },
    load() {
      console.log("load");
      // 由于mix调用，该函数必须存在
      const { BMap, map } = this;
      // 防止重复添加菜单
      if (map.menu === undefined) {
        var that = this;
        var { polygons } = this;

        // // 添加多边形
        // var addPolygonCallback = function () {
        //   polygons.push({
        //     editing: false,
        //     state: "pre_draw",
        //     path: [],
        //   });
        // };

        // var menu = new BMap.ContextMenu();
        // menu.addItem(new BMap.MenuItem("添加多边形", addPolygonCallback));
        // map.addContextMenu(menu);
        // // 暂存菜单
        // map.menu = menu;

        // var mapRightclick = function (e) {
        //   console.log("openlmenu");
        // };
        // map.menu.addEventListener("open", menuRightclick);

        // 点击地图
        var clickCallback = function (e) {
          console.log("click");
          if (polygons.length === 0) {
            return;
          }
          var polygon = polygons[polygons.length - 1];
          switch (polygon.state) {
            case "pre_draw":
              polygon.path.push(e.point);
              polygon.state = "draw";
              break;
            case "draw":
              polygon.path.push(e.point);
              break;
            default:
              break;
          }
        };
        // 鼠标移动
        var mousemoveCallback = function (e) {
          if (
            polygons.length > 0 &&
            polygons[polygons.length - 1].state === "draw"
          ) {
            var path = polygons[polygons.length - 1].path;
            if (path.length === 1) {
              path.push(e.point);
            } else if (path.length > 1) {
              // 调用vue的$set修改数组，可触发更新
              that.$set(path, path.length - 1, e.point);
            }
          }
        };
        map.addEventListener("click", clickCallback);
        map.addEventListener("mousemove", mousemoveCallback);
        // map.addEventListener("rightclick", mapRightclick);
      }
      polygons = [];
      if (this.polygonData.length > 0) {
        this.polygonData.forEach(function (item, index, array) {
          let markers = [];
          polygons.push({
            state: "idle",
            editing: false,
            path: item.path,
            data: item,
            isActive: false,
            markers: markers,
          });
          that.devparams.ctl_id = item.ctl_id;
          // that
          //   .$axios({
          //     method: "post",
          //     url: "http://10.245.150.82:6701/hitLandBindDevFind",
          //     data: that.devparams,
          //     config: {
          //       emulateJson: false,
          //     },
          //   })
          that.$api.apiHitIotLocation
            .getinstalledDev(that.devparams)
            .then((res) => {
              if (res.data.landBindDevV.length !== 0) {
                console.log(res.data.landBindDevV);
                that.polygons[index].markers = [];
                let markerslist = [];
                res.data.landBindDevV.forEach((item, idex) => {
                  markerslist.push({
                    name: item.i_name,
                    lng: item.i_longitude,
                    lat: item.i_latitude,
                    id: item.i_id,
                    token: item.i_token,
                    sn: item.i_sn,
                    state: item.i_state,
                    dragging: false,
                  });
                });
                that.polygons[index].markers = markerslist;
                console.log(that.polygons[index].markers);
              }
            })
            .catch((err) => {
              console.log(err.response);
            });
        });
      }
      this.polygons = polygons;
      console.log("polygons", this.polygons);
    },
    modifymarker(id, e) {
      let marker = e.currentTarget;
      console.log(id);
      // console.log(marker)
      var { BMap } = this;
      if (marker.menu === undefined) {
        console.log(
          "%c 设备点的右键菜单不存在，创建右键菜单",
          "color: #CD00FF;"
        );
        var menus = [];
        var that = this;
        menus.push({
          title: "取消安装设备",
          callback: function () {
            console.log("remove");
            that
              .$confirm("此操作将移除该设备, 是否继续?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
              })
              .then(() => {
                that.$api.apiHitIotLocation
                  .removeinstallDev({ i_id: id })
                  .then((res) => {
                    console.log(res.data);
                    that.map.removeOverlay(marker);
                    that.$message({
                      type: "success",
                      message: "删除成功!",
                    });
                  })
                  .catch((err) => {
                    console.log(err.response);
                    that.$message({
                      type: "error",
                      message: "删除失败!",
                    });
                  });
              })
              .catch(() => {
                that.$message({
                  type: "info",
                  message: "已取消删除",
                });
              });
            // that
            //   .$axios({
            //     method: "post",
            //     url: "http://10.245.150.82:6701/hitunLandBindDev",
            //     params: {
            //       i_id: id,
            //     },
            //   })
          },
        });
        menus.push({
          title: "更改安装位置",
          callback: function () {
            console.log("move");
            console.log({ marker });
            // that.map.removeOverlay(marker);
            marker.enableDragging();
            marker.removeContextMenu(marker.menu);
            marker.menu = undefined;
            // var markerdragged = function (e) {
            //   console.log(e);
            // };
            // marker.addEventListener({
            //
            // });
            // that
            //   .$axios({
            //     method: "post",
            //     url: "http://10.245.150.82:6701/hitLandUpdateDev",
            //     data: {
            //       i_id: id,
            //       iLongitude: marker.point.lng + 0.002,
            //       iLatitude: marker.point.lat + 0.002,
            //       iRegion: "山东省威海市环翠区",
            //       ctl_id: "",
            //     },
            //   })
            // that.$api.apiHitIotLocation
            //   .updateDev({
            //     i_id: id,
            //     iLongitude: marker.point.lng + 0.002,
            //     iLatitude: marker.point.lat + 0.002,
            //     iRegion: "山东省威海市环翠区",
            //     ctl_id: "",
            //   })
            //   .then((res) => {
            //     console.log(res.data);
            //   })
            //   .catch((err) => {
            //     console.log(err.response);
            //   });
          },
        });
        var menu = new BMap.ContextMenu();
        menus.forEach(function (item, index, array) {
          menu.addItem(
            new BMap.MenuItem(item.title, item.callback.bind(marker))
          );
        });
        marker.addContextMenu(menu);
        marker.menu = menu;
        // this.$nextTick(() => {
        //   marker.dispatchEvent("rightclick", e);
        // });
      }
    },
    finishaddnewDev(devinfo) {
      let allOverlay = this.map.getOverlays();
      this.map.removeOverlay(this.newmarker);
      this.newmarker = undefined;
      this.polygons[this.newmarkerpolygonidx].markers.push({
        name: devinfo.name,
        sn: devinfo.sn,
        state: devinfo.state,
        id: devinfo.id,
        lat: devinfo.latitude,
        lng: devinfo.longitude,
        token: devinfo.token,
        dragging: false,
      });
    },
    canceladdnewDev() {
      // this.newmarker=undefined
      console.log(this.newmarker);
      let allOverlay = this.map.getOverlays();
      this.map.removeOverlay(this.newmarker);
      this.newmarker = undefined;
    },
    onReady({ BMap, map }) {},
    rightClick(index, e) {
      console.log("265");
      var polygonObj = e.currentTarget;
      console.log(this.createpolygon);
      if (this.activePolygon === polygonObj || this.createpolygon === index) {
        console.log("268");
        this.modipolyogn = index;
        if (polygonObj.menu === undefined) {
          console.log("%c 菜单不存在，创建右键菜单", "color: #CD00FF;");
          var that = this;
          var { polygons, BMap } = this;
          var menus = [];
          //添加设备点函数
          var clickDevCallback = function ({ point }) {
            console.log("click");
            console.log(e);

            let newmarker = new that.BMap.Marker(point);
            if (that.hasnewmarker === 1) {
              let allOverlay = that.map.getOverlays();
              that.map.removeOverlay(that.newmarker);
            }
            that.map.addOverlay(newmarker);
            that.newmarker = newmarker;
            that.newmarkerpolygonidx = index; //将当前地块标记为正在添加标记点的地块
            that.hasnewmarker = 1;
          };
          // 变更状态函数
          var changeState = function (newState) {
            polygons[index].state = newState;
            if (polygonObj.menu !== undefined) {
              polygonObj.removeContextMenu(polygonObj.menu);
            }
            // 一旦状态变更，弹出的菜单也会变更。故将菜单置空，等显示菜单时重建
            polygonObj.menu = undefined;
          };
          switch (polygons[index].state) {
            case "idle":
              menus.push({
                title: "修改地块",
                callback: function () {
                  this.modipolyogn = index;
                  that.$emit("modifying", polygons[index]);
                  console.log("%c 修改地块", "color: #CC00FF;");
                  polygons[index].editing = true;
                  changeState("modify");
                },
              });
              menus.push({
                title: "删除地块",
                callback: function () {
                  console.log("%c 删除地块", "color: #CC00FF;");
                  if (polygons.length > index) {
                    console.log({ polygons });
                    console.log(index);
                    if (polygons[index].markers.length > 0) {
                      that.$alert("请先移除设备", "删除失败", {
                        confirmButtonText: "确定",
                      });
                    } else {
                      let form = {
                        //add,modify
                        ctl_id: polygons[index].data.ctl_id,
                        ctl_name: "",
                        ctl_address: "",
                        ctl_area: 0,
                        ctl_image: "",
                        ctl_local: "",
                      };
                      that.$api.apiHitIotLocation
                        .deletePolygon(form)
                        .then((res) => {
                          console.log(res.data);
                        })
                        .catch((err) => {
                          console.log(err.response);
                        });
                      // 删除多边形需要先触发事件，否则数据删掉就无法返回了
                      that.$emit("delete", { ...polygons[index] });
                      polygons.splice(index, 1);
                    }
                  }
                },
              });
              menus.push({
                title: "添加设备点",
                callback: function () {
                  console.log("%c 添加设备点", "color: #CC00FF;");
                  that.insertDev = true;
                  polygonObj.addEventListener("click", clickDevCallback); //点击地块 添加设备点
                  changeState("addover");
                },
              });
              break;
            case "draw":
              menus.push({
                title: "添加结束",
                callback: function () {
                  console.log("%c 添加结束", "color: #CC00FF;");
                  changeState("idle");
                  // 移除最后一个点，该点由鼠标移动生成
                  polygons[polygons.length - 1].path.pop();
                  if (polygons[polygons.length - 1].path.length < 3) {
                    polygons.pop();
                  } else {
                    that.$emit("drawover", polygons[polygons.length - 1]);
                  }
                },
              });
              break;
            case "addover":
              console.log(that.polygons[index]);
              menus.push({
                title: "添加结束",
                callback: function () {
                  console.log("%c 添加结束", "color: #CC00FF;");
                  that.$emit(
                    "adddevover",
                    that.polygons[index].data.ctl_id,
                    that.newmarker.point
                  );
                  that.insertDev = false;

                  changeState("idle");
                  // polygons[index].editing = false;
                  // that.$set(polygons[index], "path", polygonObj.getPath());
                  // that.modipolyogn = undefined;
                  // that.$emit("modifyover", that.polygons[index]);
                },
              });
              break;
            case "modify":
              menus.push({
                title: "修改结束",
                callback: function () {
                  console.log("%c 修改结束", "color: #CC00FF;");
                  changeState("idle");
                  polygons[index].editing = false;
                  that.$set(polygons[index], "path", polygonObj.getPath());
                  that.modipolyogn = undefined;
                  that.$emit("modifyover", that.polygons[index]);
                },
              });
              break;
            default:
              break;
          }

          var menu = new BMap.ContextMenu();
          console.log(menus);
          menus.forEach(function (item, index, array) {
            menu.addItem(
              new BMap.MenuItem(item.title, item.callback.bind(polygonObj))
            );
          });
          polygonObj.addContextMenu(menu);
          polygonObj.menu = menu;
          console.log(polygonObj.menu.items);
          // 添加菜单后不会立即弹出，需额外触发一次右键点击事件
          polygonObj.dispatchEvent("rightclick", e);
        }
      }
      console.log("tanchu");
    },
  },
};
</script>
<style>
.sample {
  width: auto;
  height: auto;
  /*line-height: auto;*/
  background: rgba(0, 0, 0, 0.5);
  overflow: hidden;
  box-shadow: 0 0 5px #000;
  color: #fff;
  text-align: center;
  padding: 10px;
  position: absolute;
}
.BMap_cpyCtrl {
  display: none;
}
.anchorBL {
  display: none;
}
</style>
