import Vue from "vue";
import VueRouter from "vue-router";
import mainBody from "../components/mainBody";
import empty from "../components/empty";
import routerHitIotClassification from "./module/hitIotClassification";
import routerHitIotLocation from "./module/hitIotLocation";
import routerHitIotBase from "./module/hitIotBase";
import routerHitIotHC from "./module/hitIotHC";
import routerHitIotRun from "./module/hitIotRun";
// RouterTab 内置路由
import { RouterTabRoutes } from "vue-router-tab";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: mainBody,
    children: [
      ...RouterTabRoutes,
      {
        path: "hitIotClassification", //设备分类管理
        component: empty,
        children: routerHitIotClassification,
      },
      {
        path: "hitIotLocation", //位置信息管理
        component: empty,
        children: routerHitIotLocation,
      },
      {
        path: "hitIotBase", //设备基础信息维护
        component: empty,
        children: routerHitIotBase,
      },
      {
        path: "hitIotHC", //健康检查参数维护
        component: empty,
        children: routerHitIotHC,
      },
      {
        path: "hitIotRun", //设备运行管理
        component: empty,
        children: routerHitIotRun,
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
