<template>
  <el-container class="container" direction="vertical">
    <el-card class="display">
      <div slot="header" class="clear-fix">
        <span>设备绑定</span>
      </div>
      <el-form
        :label-width="formLabelWidth"
        :inline="false"
        label-position="right"
        :model="deviceBaseInfo"
        status-icon
        :rules="deviceInfoRules"
        :ref="deviceBaseInfo"
      >
        <el-row>
          <el-col :span="12">
            <span>设备基本信息</span>
          </el-col>
          <el-col :span="8">
            <el-button-group style="float: right">
              <el-button
                style="margin-right: 10px; margin-bottom: 10px"
                @click="save"
                >保存</el-button
              >
              <el-button
                style="margin-right: 10px; margin-bottom: 10px"
                @click="goBack()"
                >返回</el-button
              >
            </el-button-group>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="设备序列号" prop="sn">
              <el-input
                type="text"
                class="input-medium"
                v-model="deviceBaseInfo.sn"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="设备名称" prop="name">
              <el-input
                class="input-medium"
                v-model="deviceBaseInfo.name"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="访问标识" prop="visitFlag">
              <el-input
                class="input-small"
                v-model="deviceBaseInfo.token"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="设备模型" prop="model">
              <el-input class="input-small" v-model="deviceBaseInfo.product">
                <el-button
                  style="padding-right: 10px"
                  slot="suffix"
                  type="text"
                  @click="devModelVisible = true"
                  >选择
                </el-button>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="设备类型" prop="type">
              <el-input class="input-small" v-model="deviceBaseInfo.type">
                <el-button
                  style="padding-right: 10px"
                  slot="suffix"
                  type="text"
                  @click="devTypeVisible = true"
                  >选择
                </el-button>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="经度" prop="longitude">
              <el-input
                class="input-small"
                v-model="deviceBaseInfo.longitude"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="纬度" prop="latitude">
              <el-input
                class="input-small"
                v-model="deviceBaseInfo.latitude"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="负责人" prop="admin">
              <el-input
                class="input-small"
                v-model="deviceBaseInfo.admin"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="维护周期" prop="period">
              <el-select v-model="deviceBaseInfo.period">
                <el-option
                  v-for="item in period"
                  :key="item.value"
                  :label="item.value"
                  :value="item.value"
                  :disabled="item.disabled"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="主机标识" prop="hostFlag">
              <el-select v-model="deviceBaseInfo.hostflag">
                <el-option
                  v-for="item in hostFlag"
                  :key="item.key"
                  :value="item.value"
                  :label="item.key"
                  :disabled="item.disabled"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="宿主机" prop="host">
              <el-input class="input-small" v-model="deviceBaseInfo.host"
                ><el-button
                  style="padding-right: 10px"
                  slot="suffix"
                  type="text"
                  @click="devVisible = true"
                  >选择
                </el-button>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-card>
    <el-card class="search_condition">
      <el-collapse accordion v-model="activeNames">
        <el-collapse-item name="1">
          <template slot="title">
            <div style="font-size: 16px">管控区域查询条件</div>
          </template>
          <el-form
            :label-width="formLabelWidth"
            :inline="false"
            class="demo-from-inline"
          >
            <el-row>
              <el-col :span="16" style="float: right; margin-bottom: 10px">
                <el-col :span="3">
                  <el-button
                    @click="landSearchb"
                    type="primary"
                    style="margin-right: 10px"
                    >未绑定查询</el-button
                  >
                </el-col>
                <el-col :span="2"
                  ><el-button @click="reset(basicAreaInfo)"
                    >重置</el-button
                  ></el-col
                >
                <el-col :span="3"
                  ><el-button @click="findBindDeviceb"
                    >绑定区域查询</el-button
                  ></el-col
                >
                <el-col :span="3">
                  <el-tooltip
                    effect="dark"
                    :disabled="!unbindBtn"
                    content="请先进行绑定设备信息查询..."
                    placement="top"
                  >
                    <div>
                      <el-button
                        style="margin-right: 10px; margin-bottom: 10px"
                        :disabled="unbindBtn"
                        @click="unDevBindCtl"
                        >解除绑定</el-button
                      >
                    </div>
                  </el-tooltip>
                </el-col>
                <el-col :span="2">
                  <el-tooltip
                    effect="dark"
                    :disabled="!bindBtn"
                    content="请先进行未绑定设备信息查询..."
                    placement="top"
                  >
                    <div>
                      <el-button
                        style="margin-right: 10px; margin-bottom: 10px"
                        @click="DevBindCtl"
                        :disabled="bindBtn"
                        >绑定</el-button
                      >
                    </div>
                  </el-tooltip>
                </el-col>
              </el-col>
            </el-row>
            <el-row style="margin-bottom: 0px">
              <el-col :xs="4" :sm="6" :md="8" :lg="14" :xl="8">
                <el-form-item label="选择行政区">
                  <v-distpicker
                    :province="basicAreaInfo.province"
                    :city="basicAreaInfo.city"
                    :area="basicAreaInfo.area"
                    @province="onChangeProvince"
                    @city="onChangeCity"
                    @area="onChangeArea"
                  ></v-distpicker>
                </el-form-item>
              </el-col>
              <el-col :xs="4" :sm="6" :md="8" :lg="10" :xl="8">
                <el-form-item label="街道">
                  <el-input v-model="basicAreaInfo.street"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row style="margin-bottom: 10px">
              <el-col :xs="4" :sm="6" :md="8" :lg="14" :xl="8">
                <el-form-item label="区域名称">
                  <el-input v-model="basicAreaInfo.areaName"></el-input>
                </el-form-item>
              </el-col>
              <el-col :xs="4" :sm="6" :md="8" :lg="10" :xl="8">
                <el-form-item label="面积">
                  <el-input v-model="basicAreaInfo.landArea"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </el-collapse-item>
      </el-collapse>
      <el-table
        v-fit-columns
        :row-key="getRowKeys"
        :data="areaTable"
        ref="singTable"
        @selection-change="handleSelectionChange"
        highlight-current-row
        border
        style="width: 100%; margin-top: 10px; margin-bottom: 5px"
      >
        <el-table-column
          fixed
          label="选择"
          type="selection"
          :reserve-selection="true"
        ></el-table-column>
        <el-table-column prop="ctl_id" label="区域id"> </el-table-column>
        <el-table-column prop="ctl_address" label="行政区域"> </el-table-column>
        <el-table-column prop="ctl_name" label="区域名称" width="80">
        </el-table-column>
        <el-table-column prop="ctl_area" label="地块面积" width="80">
        </el-table-column>
        <el-table-column prop="ctl_local" label="地块坐标" width="280">
        </el-table-column>
      </el-table>
      <el-pagination
        background
        @size-change="pageSizeChangel"
        @current-change="pageCurrentChangel"
        :current-page="pageforml.pageNum"
        :page-sizes="pageforml.pageSizes"
        :page-size="pageforml.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pageforml.total"
        style="margin-bottom: 15px"
      >
      </el-pagination>
    </el-card>
    <!--start 设备模型弹框 -->
    <el-dialog :visible.sync="devModelVisible">
      <template slot="title">
        <div style="font-size: 18px">设备模型选择</div>
      </template>
      <el-form ref="form" class="form-inline">
        <el-row>
          <el-col :xs="24" :sm="24" :md="12" :lg="16" :xl="6">
            <el-form-item label="模型名称" :label-width="formLabelWidth">
              <el-input v-model="devModel.p_cname"></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="6">
            <el-form-item>
              <el-button @click="searchProduct" size="medium">查找</el-button>
              <el-button @click="confirmProduct" type="primary" size="medium"
                >确定</el-button
              >
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-table
            v-fit-columns
            :data="modelP"
            ref="singTable"
            highlight-current-row
            border
            style="width: 100%; margin-top: 10px; margin-bottom: 5px"
          >
            <el-table-column label="选择" width="50">
              <template slot-scope="scope">
                <el-radio
                  :label="scope.$index"
                  v-model="currentRowP"
                  @change.native="getCurrentRowP(scope.row)"
                  >&nbsp;
                </el-radio>
              </template>
            </el-table-column>
            <el-table-column prop="pId" label="设备模型id"> </el-table-column>
            <el-table-column prop="pCname" label="模型名称" width="280">
            </el-table-column>
            <el-table-column prop="pEname" label="英文名称" width="280">
            </el-table-column>
            <el-table-column prop="pDataformat" label="数据格式">
            </el-table-column>
            <el-table-column prop="pDesc" label="模型描述" width="80">
            </el-table-column>
            <el-table-column prop="pType" label="模型类型" width="80">
            </el-table-column>
            <el-table-column prop="pProtocol" label="传输协议" width="280">
            </el-table-column>
            <el-table-column prop="ctrlDevice" label="控制类型" width="280">
            </el-table-column>
          </el-table>
          <el-pagination
            background
            @size-change="pageSizeChangeP"
            @current-change="pageCurrentChangeP"
            :current-page="pageformp.pageNum"
            :page-sizes="pageformp.pageSizes"
            :page-size="pageformp.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="pageformp.total"
            style="margin-bottom: 15px"
          >
          </el-pagination>
        </el-row>
      </el-form>
    </el-dialog>
    <!--end 设备模型弹框 -->
    <!--start 设备类型弹框-->
    <el-dialog :visible.sync="devTypeVisible">
      <template slot="title">
        <div style="font-size: 18px">设备类型选择</div>
      </template>
      <el-form ref="form" class="form-inline">
        <el-row>
          <el-col :xs="24" :sm="24" :md="12" :lg="16" :xl="6">
            <el-form-item label="类型名称" :label-width="formLabelWidth">
              <el-input v-model="devType.cname"></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="6">
            <el-form-item>
              <el-button @click="searchType" size="medium">查找</el-button>
              <el-button @click="confirmType" type="primary" size="medium"
                >确定</el-button
              >
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-table
            v-fit-columns
            :data="Type"
            ref="singTable"
            highlight-current-row
            border
            style="width: 100%; margin-top: 10px; margin-bottom: 5px"
          >
            <el-table-column label="选择" width="50">
              <template slot-scope="scope">
                <el-radio
                  :label="scope.$index"
                  v-model="currentRow"
                  @change.native="getCurrentRowT(scope.row)"
                  >&nbsp;
                </el-radio>
              </template>
            </el-table-column>
            <el-table-column prop="cid" label="类型编码" width="80">
            </el-table-column>
            <el-table-column prop="cname" label="中文名称"> </el-table-column>
            <el-table-column prop="ename" label="英文名称"> </el-table-column>
            <el-table-column prop="fid" label="父级编码" width="80">
            </el-table-column>
            <el-table-column prop="cseries" label="级数" width="50">
            </el-table-column>
          </el-table>
          <el-pagination
            background
            @size-change="pageSizeChangeT"
            @current-change="pageCurrentChangeT"
            :current-page="pageformt.pageNum"
            :page-sizes="pageformt.pageSizes"
            :page-size="pageformt.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="pageformt.total"
            style="margin-bottom: 15px"
          >
          </el-pagination>
        </el-row>
      </el-form>
    </el-dialog>
    <!--end 设备类型弹框 -->
    <!--start 主机弹框-->
    <el-dialog :visible.sync="devVisible">
      <template slot="title">
        <div style="font-size: 18px">主设备选择</div>
      </template>
      <el-form ref="form" class="form-inline">
        <el-row>
          <el-col :xs="24" :sm="24" :md="12" :lg="16" :xl="6">
            <el-form-item label="设备名称" :label-width="formLabelWidth">
              <el-input v-model="devCondition.name"></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="6">
            <el-form-item>
              <el-button @click="searchDevh" size="medium">查找</el-button>
              <el-button @click="confirmDevh" type="primary" size="medium"
                >确定</el-button
              >
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-table
            v-fit-columns
            :data="Devs"
            ref="singTable"
            highlight-current-row
            border
            style="width: 100%; margin-top: 10px; margin-bottom: 5px"
          >
            <el-table-column label="选择" width="50">
              <template slot-scope="scope">
                <el-radio
                  :label="scope.$index"
                  v-model="currentRowT"
                  @change.native="getCurrentRowD(scope.row)"
                  >&nbsp;
                </el-radio>
              </template>
            </el-table-column>
            <el-table-column prop="id" label="设备id"> </el-table-column>
            <el-table-column prop="sn" label="设备sn" width="280">
            </el-table-column>
            <el-table-column prop="token" label="访问标识"> </el-table-column>
            <el-table-column prop="name" label="设备名称" width="80">
            </el-table-column>
          </el-table>
          <el-pagination
            background
            @size-change="pageSizeChangeD"
            @current-change="pageCurrentChangeD"
            :current-page="pageformd.pageNum"
            :page-sizes="pageformd.pageSizes"
            :page-size="pageformd.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="pageformd.total"
            style="margin-bottom: 15px"
          >
          </el-pagination>
        </el-row>
      </el-form>
    </el-dialog>
    <!--end 主机型弹框 -->
  </el-container>
</template>
<script>
import VDistpicker from "v-distpicker";
export default {
  components: {
    VDistpicker,
  },
  name: "devicebindReg",
  data() {
    return {
      //公共变量
      activeNames: ["1"],
      highlightRows: new Set(),
      LabelWidth: "60px",
      formLabelWidth: "120px",
      modify_title: "管控区域录入",
      placeholder: {},
      currentRowP: 0, //设备模型弹框里的选择
      currentRowT: 0, //主机弹框里的选择
      currentRow: 0, //设备类型弹框里的选择
      //区域信息分页
      ctrlId: 0, //准备删除
      //区域
      ctrLand: "",
      landUnDev: {
        pageSize: 5,
        pageNum: 1,
        ctl_id: -1,
        ctl_address: "",
        ctl_local: "",
        ctl_name: "",
        ctl_image: "",
        ctl_area: 0,
        alow: 0,
        aup: 0,
        dev_id: -1,
      },
      //区域按钮使能
      bindBtn: true,
      unbindBtn: true,
      //区域分页
      pageforml: {
        total: 20,
        pages: 5,
        pageNum: 1,
        pageSizes: [2, 5, 10, 15, 20],
        pageSize: 5,
        navigateFirstPage: 1,
        navigateLastPage: 3,
        lastPage: false,
        firstPage: false,
        hasNextPage: true,
        hasPreviousPage: true,
      },
      //设备模型
      pageformp: {
        total: 20,
        pages: 5,
        pageNum: 1,
        pageSizes: [2, 5, 10, 15, 20],
        pageSize: 5,
        navigateFirstPage: 1,
        navigateLastPage: 3,
        lastPage: false,
        firstPage: false,
        hasNextPage: true,
        hasPreviousPage: true,
      },
      //设备类型
      pageformt: {
        total: 20,
        pages: 5,
        pageNum: 1,
        pageSizes: [2, 5, 10, 15, 20],
        pageSize: 5,
        navigateFirstPage: 1,
        navigateLastPage: 3,
        lastPage: false,
        firstPage: false,
        hasNextPage: true,
        hasPreviousPage: true,
      },
      //设备
      pageformd: {
        total: 20,
        pages: 5,
        pageNum: 1,
        pageSizes: [2, 5, 10, 15, 20],
        pageSize: 5,
        navigateFirstPage: 1,
        navigateLastPage: 3,
        lastPage: false,
        firstPage: false,
        hasNextPage: true,
        hasPreviousPage: true,
      },
      //传递变量--区域查询条件
      basicAreaInfo: {
        province: "山东省",
        city: "威海市",
        area: "",
        street: "",
        areaName: "",
        landArea: "",
      },
      devModel: {
        pageNum: "1",
        pageSize: "5",
        p_id: "",
        p_cname: "",
        p_dataformat: "",
        p_ename: "",
        p_type: "",
        p_protocol: "",
        ctrl_device: "",
      },
      devType: {
        pageNum: 1,
        pageSize: 5,
        cid: "",
        ename: "",
        cname: "",
        fid: "",
        cseries: -1,
      },
      //设备查询条件
      devCondition: {
        pageNum: 1,
        pageSize: 5,
        id: -1,
        sn: "",
        token: "",
        name: "",
        type: "",
        longitude: "",
        latitude: "",
        region: "", //暂时不用
        mperiod: "",
        admin: "",
        online: -1,
        datelow: "",
        dateup: "",
        state: "",
        hostflag: "1",
        host: "",
        product: -1,
      },
      Devs: [],
      DeviceInfo: {
        sn: "",
        token: "",
        name: "",
        type: "",
        product: "",
      },
      modelP: [],
      Type: [],
      areaTable: [],
      period: [
        {
          value: "一周",
          disabled: false,
        },
        {
          value: "一月",
          disabled: false,
        },
        {
          value: "一年",
          disabled: false,
        },
        {
          value: "两年",
          disabled: false,
        },
      ],
      hostFlag: [
        {
          key: "是",
          value: 1,
          disabled: false,
        },
        {
          key: "否",
          value: 0,
          disabled: false,
        },
      ],
      host: [],
      deviceBaseInfo: {
        id: -1, //返回值
        sn: "",
        token: "",
        name: "",
        type: "",
        longitude: "",
        latitude: "",
        region: "", //暂时不用
        mperiod: "一年",
        host: "0",
        hostflag: "",
        product: "",
        state: "",
        online: -1,
        date: "",
        admin: "",
      },
      deviceInfoRules: {
        sn: [{ required: false, message: "请输入设备序列号", trigger: "blur" }],
        name: [{ required: true, message: "请输入设备名称", trigger: "blur" }],
        token: [
          { required: false, message: "请输入访问标识", trigger: "blur" },
        ],
        product: [
          { required: false, message: "请选择设备模型", trigger: "blur" },
        ],
        type: [{ required: false, message: "请选择设备模型", trigger: "blur" }],
        admin: [
          { required: false, message: "请选择设备模型", trigger: "blur" },
        ],
        mperiod: [
          { required: false, message: "请选择设备模型", trigger: "blur" },
        ],
        hostFlag: [
          { required: false, message: "请选择设备模型", trigger: "blur" },
        ],
        host: [{ required: false, message: "请选择设备模型", trigger: "blur" }],
      },
      devModelVisible: false,
      devTypeVisible: false,
      devVisible: false,
    };
  },
  created() {
    this.getParams();
  },
  methods: {
    getRowKeys(row) {
      return row.ctl_id; //这里如果赋错值会影响选择
    },
    handleSelectionChange: function (val) {
      //形成
      // val是一个列表，包含所有的选择项
      let i = 0;
      this.ctrLand = "";
      for (; i < val.length; i++) {
        this.ctrLand += "#" + val[i].ctl_id;
      }
      console.log("ctrLand:" + this.ctrLand);
    },
    getParams() {
      let dev = this.$route.query; // 将数据放在当前组件的数据内
      this.deviceBaseInfo.id = dev.id;
      this.deviceBaseInfo.name = dev.name;
      this.deviceBaseInfo.sn = dev.sn;
      this.deviceBaseInfo.token = dev.token;
      this.initToken = dev.token;
      this.deviceBaseInfo.product = dev.product;
      this.initProduct = dev.product;
      this.deviceBaseInfo.type = dev.type;
      this.deviceBaseInfo.longitude = dev.longitude;
      this.deviceBaseInfo.latitude = dev.latitude;
      this.deviceBaseInfo.admin = dev.admin;
      this.deviceBaseInfo.period = dev.mperiod;
      this.deviceBaseInfo.hostflag = dev.hostflag;
      this.deviceBaseInfo.host = dev.host;
    },
    confirmProduct() {
      this.deviceBaseInfo.product = this.devModel.p_id;
      this.devModelVisible = false;
    },
    searchProduct() {
      //查找设备模型
      this.devModel.p_id = "";
      console.log(this.devModel);
      this.$api.apiHitIotLocation
        .getModelP(
          this.pageformp.pageSize,
          this.pageformp.pageNum,
          this.devModel
        )
        .then((res) => {
          console.log(res);
          if (res.data.code === "1") {
            let dataTmp = res.data;
            this.modelP = dataTmp.data.productList;
            this.devModel.p_id = this.modelP[0].pId;
            this.pageformp.total = dataTmp.data.totalCount;
            this.pageformp.pages = dataTmp.data.totalPages;
          } else {
            this.$message({
              message: "查询失败！",
              type: "info",
            });
          }
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
    searchType() {
      //查找设备类型
      this.devType.pageNum = this.pageformt.pageNum;
      this.devType.pageSize = this.pageformt.pageSize;
      this.devType.cid = "";
      this.$api.apiHitIotLocation
        .getNationalS(this.devType)
        .then((res) => {
          console.log(res);
          if (res.data.code === "1") {
            this.Type = res.data.data;
            this.devType.cid = this.Type[0].cid;
            this.pageformt.total = res.data.total;
            this.pageformt.pages = res.data.pages;
          } else {
            this.$message({
              message: "查询失败！",
              type: "info",
            });
          }
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
    confirmType() {
      this.deviceBaseInfo.type = this.devType.cid;
      this.devTypeVisible = false;
    },
    searchDevh() {
      //查找设备
      this.devCondition.pageNum = this.pageformd.pageNum;
      this.devCondition.pageSize = this.pageformd.pageSize;
      this.devCondition.id = -1;
      this.$api.apiHitIotLocation
        .getHost(this.devCondition)
        .then((res) => {
          console.log(res);
          if (res.data.code === "1") {
            let dataTmp = res.data;
            this.Devs = dataTmp.data;
            this.devCondition.id = this.Devs[0].id;
            this.devCondition.name = this.Devs[0].name;
            this.pageformd.pages = dataTmp.pages;
            this.pageformd.total = dataTmp.total;
          } else {
            this.$message({
              message: "查询失败！",
              type: "info",
            });
          }
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
    confirmDevh() {
      this.deviceBaseInfo.host =
        this.devCondition.name + "||" + this.devCondition.id;
      this.devVisible = false;
    },
    save() {
      this.deviceBaseInfo.host = this.deviceBaseInfo.host.split("||")[1];
      //在这里进行最终校验和提交表单，表单数据在this.deviceBaseInfo中
      console.log(this.deviceBaseInfo);
      this.$api.apiHitIotLocation
        .saveDev(this.deviceBaseInfo)
        .then((res) => {
          console.log(res);
          if (res.data.code === "1") {
            let dataTmp = res.data.data.token.split("#");
            console.log(dataTmp);
            this.deviceBaseInfo.id = dataTmp[1];
            this.deviceBaseInfo.token = dataTmp[0];
            this.$message({
              message: "保存成功！",
              type: "success",
            });
          } else {
            this.$message({
              message: "查询失败！",
              type: "info",
            });
          }
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
    goBack() {
      //在这里实现表单重置功能
      this.$tabs.close();
    },
    onChangeProvince(data) {
      this.basicAreaInfo.province = data.value;
    },
    onChangeCity(data) {
      this.basicAreaInfo.city = data.value;
    },
    onChangeArea(data) {
      this.basicAreaInfo.area = data.value;
    },
    getCurrentRow(row) {
      //设备选择
      console.log(row);
      this.ctrlId = row.ctl_id;
    },
    getCurrentRowP(row) {
      console.log(row);
      this.devModel.p_id = row.pId;
    },
    getCurrentRowT(row) {
      console.log(row); //可以给查询条件赋值 devType
      this.devType.cid = row.cid;
    },
    getCurrentRowD(row) {
      console.log(row); //可以给查询条件赋值
      this.devCondition.name = row.name;
      this.devCondition.id = row.id;
    },
    landSearchb: function () {
      this.bindBtn = false;
      this.unbindBtn = true;
      this.$refs.singTable.clearSelection(); //重置，清除表中上次的选择
      this.ctrLand = "";
      this.landSearch();
    },
    landSearch: function () {
      //未绑定地块查询
      this.landUnDev.pageNum = this.pageforml.pageNum;
      this.landUnDev.pageSize = this.pageforml.pageSize;
      let address = "";
      if (
        this.basicAreaInfo.province !== "省" &&
        this.basicAreaInfo.province.length !== 0
      )
        address += this.basicAreaInfo.province;
      if (
        this.basicAreaInfo.city !== "市" &&
        this.basicAreaInfo.city.length !== 0
      )
        address += "-" + this.basicAreaInfo.city;
      if (
        this.basicAreaInfo.area !== "区" &&
        this.basicAreaInfo.area.length !== 0
      )
        address += "-" + this.basicAreaInfo.area;
      if (this.basicAreaInfo.street !== "")
        address += "-" + this.basicAreaInfo.street;
      this.landUnDev.ctl_address = address;
      this.landUnDev.ctl_name = this.basicAreaInfo.areaName;
      this.landUnDev.aup = this.basicAreaInfo.landArea; //getLandsUnbindDev
      this.landUnDev.dev_id = this.deviceBaseInfo.id;
      this.$api.apiHitIotLocation
        .getLandsUnbindDev(this.landUnDev)
        .then((res) => {
          if (res.data.code == 1) {
            console.log(res.data);
            this.areaTable = res.data.iotland;
            this.pageforml.total = res.data.total;
            this.ctrlId = this.areaTable[0].ctl_id;
          } else {
            this.$message({
              message: "查询失败，请联管理员",
              type: "info",
            });
          }
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
    //区域分页
    pageSizeChangel(pageSize) {
      //区域当每页的数量/尺寸改变后，重新向后台发送信息  // 每页条数切换调用   landsearch()
      this.pageforml.pageSize = pageSize;
      this.landSearch();
    },
    pageCurrentChangel(currentPage) {
      //区域当前页面切换, //页码切换 调用landsearch()
      console.log(currentPage);
      this.pageforml.pageNum = currentPage;
      this.landSearch();
    },
    pageSizeChangeT(pageSize) {
      //设备类型当每页的数量/尺寸改变后，重新向后台发送信息  searchType// 每页条数切换调用   landsearch()
      this.pageformt.pageSize = pageSize;
      this.searchType();
    },
    pageCurrentChangeT(currentPage) {
      //设备类型当前页面切换, //页码切换 调用landsearch()
      this.pageformt.pageNum = currentPage;
      this.searchType();
    },
    pageSizeChangeP(pageSize) {
      //主机当每页的数量/尺寸改变后，重新向后台发送信息  searchType// 每页条数切换调用   landsearch()
      this.pageformp.pageSize = pageSize;
      this.searchProduct();
    },
    pageCurrentChangeP(currentPage) {
      //主机当前页面切换, //页码切换 调用landsearch()
      this.pageformp.pageNum = currentPage;
      this.searchProduct();
    },
    pageSizeChangeD(pageSize) {
      //主机当每页的数量/尺寸改变后，重新向后台发送信息  searchType// 每页条数切换调用   landsearch()
      this.pageformd.pageSize = pageSize;
      this.searchDevh();
    },
    pageCurrentChangeD(currentPage) {
      //主机当前页面切换, //页码切换 调用landsearch()
      this.pageformd.pageNum = currentPage;
      this.searchDevh();
    },
    reset() {
      const emptyBasicAreaInfo = {
        province: "",
        city: "",
        area: "",
        street: "",
        areaName: "",
        landArea: "",
      };
      this.basicAreaInfo = emptyBasicAreaInfo;
    },
    findBindDeviceb: function () {
      this.bindBtn = true;
      this.unbindBtn = false;
      this.ctrLand = ""; //重置，清除前面的选项
      this.$refs.singTable.clearSelection(); //重置，清除表中上次的选择
      this.findBindDevice();
    },
    findBindDevice: function () {
      //发现已与该设备绑定的设备
      this.error = null;
      let pageNum = this.pageforml.pageNum;
      let pageSize = this.pageforml.pageSize;
      if (this.deviceBaseInfo.id === -1) {
        this.$message({
          message: "没有录入及保留设备信息，查询失败！",
          type: "info",
        });
        return;
      }
      let devid = this.deviceBaseInfo.id;

      this.$api.apiHitIotLocation
        .getPolygoncByDevid(pageNum, pageSize, devid)
        .then((res) => {
          console.log(res);
          this.areaTable = res.data.data;
          if (res.data.total != 0) this.ctrlId = this.areaTable[0].ctl_id;
          else this.ctrlId = 0;
          this.pageforml.total = res.data.total;
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
          this.error = error.toString();
        });
    },
    DevBindCtl: function () {
      //一个设备与多个地块绑定
      //设备与地块绑定
      let devs = this.deviceBaseInfo.id;
      if (devs == null || devs == "") {
        this.$message({
          message: "未录入及保存区域信息，无法绑定设备，请先录入区域信息并保存",
          type: "failure",
        });
        return;
      }
      if (this.ctrlId == null || this.ctrlId == "") {
        this.$message({
          message: "未录入及保存区域信息，无法绑定设备，请先录入区域信息并保存",
          type: "failure",
        });
        return;
      }
      devs += this.ctrLand;

      this.$api.apiHitIotLocation
        .installDevOnLands(devs)
        .then((res) => {
          console.log(res);
          if (res.data.code === 1) {
            this.$message({
              message: "存储成功",
              type: "success",
            });
            this.findBindDeviceb();
          } else {
            this.$message({
              message: "存储失败",
              type: "info",
            });
          }
        })
        .catch((err) => {
          console.log(err.response);
        });
      console.log(devs);
    },
    unDevBindCtl: function () {
      //设备与地块解除绑定
      let devid = this.deviceBaseInfo.id;
      if (devid == null || devid == "") {
        this.$message({
          message: "未录入区域信息，无法绑定设备，请先录入区域信息并保存",
          type: "failure",
        });
        return;
      }
      let devs = devid + this.ctrLand;
      this.$api.apiHitIotLocation
        .uninstallDevOnLands(devs)
        .then((res) => {
          if (res.data.code == 1) {
            this.$message({
              message: "绑定解除成功",
              type: "success",
            });
            this.findBindDeviceb();
          } else {
            this.$message({
              message: "绑定解除失败，后台异常",
              type: "info",
            });
          }
        })
        .catch((err) => {
          console.log(err.response);
        });
      console.log(devs);
    },
  },
};
</script>
<style scoped>
.el-card {
  width: 60%;
  margin-left: 10%;
}
.el-input {
  width: 80%;
}
.display {
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  width: 95%;
  margin: 10px auto;
  border-radius: 5px;
}

.el-collapse {
  border-bottom: unset;
}
.search_condition {
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  width: 95%;
  margin: 5px auto;
  border-radius: 5px;
  text-align: left;
}
.el-table__header col[name="gutter"] {
  display: table-cell !important;
}

.el-table >>> .highlight {
  background: #ecf5ff;
}

.el-collapse-item >>> .el-collapse-item__header {
  justify-content: center;
  font-size: 18px;
}
.search_condition
  >>> .el-collapse-item
  .el-collapse-item__header
  .el-collapse-item__arrow {
  margin: 0;
}
</style>
