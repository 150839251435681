<template>
  <div>
    <div class="header">
      <div style="font-size: 18px; font-weight: bold">
        <el-divider direction="vertical"></el-divider>属性
      </div>
      <el-button round @click="dialogAddAttri = true">添加属性</el-button>
    </div>
    <el-table
      :data="attriList"
      style="width: 100%"
      :header-row-style="{
        color: '#303133',
        'font-size': '16px',
        'font-weight': 'bold',
      }"
      :row-style="{ 'font-size': '16px' }"
      :header-cell-style="{ background: '#eef1f6', padding: '5px' }"
      :cell-style="{ padding: '5px' }"
    >
      <el-table-column prop="name" label="属性名称" min-width="16%">
      </el-table-column>
      <el-table-column prop="format" label="数据类型" min-width="16%">
      </el-table-column>
      <el-table-column prop="access" label="访问方式" min-width="16%">
      </el-table-column>
      <!--<el-table-column prop="len" label="长度" min-width="16%">
      </el-table-column>-->
      <el-table-column prop="desc" label="描述" min-width="16%">
      </el-table-column>
      <el-table-column label="操作" min-width="15%">
        <template slot-scope="scope">
          <el-button
            @click="modiAttri(scope.row)"
            type="text"
            style="font-size: 16px"
            >修改</el-button
          >
          <el-divider direction="vertical"></el-divider>
          <el-button
            @click="delAttri(scope.row)"
            type="text"
            style="font-size: 16px"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <br />
    <div class="paginationClass">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[5, 10, 20]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>

    <el-dialog
      title="添加属性"
      :visible.sync="dialogAddAttri"
      width="50%"
      :close-on-click-modal="false"
      @close="reset"
      v-dialogDrag
    >
      <el-form
        :model="form"
        :rules="rules"
        ref="form"
        label-width="100px"
        class="demo-ruleForm"
        status-icon
      >
        <el-form-item label="属性名称" prop="name">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="数据类型" prop="format">
          <el-select v-model="form.format" placeholder="请选择数据类型">
            <el-option label="int(整型)" value="int(整型)"></el-option>
            <el-option label="decimal(小数)" value="decimal(小数)"></el-option>
            <el-option
              label="string(字符串)"
              value="string(字符串)"
            ></el-option>
            <el-option
              label="dateTime(日期时间)"
              value="dateTime(日期时间)"
            ></el-option>
            <el-option
              label="jsonObject(JSON结构体)"
              value="jsonObject(JSON结构体)"
            ></el-option>
            <el-option
              label="stringList(数组)"
              value="stringList(数组)"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="访问权限" prop="access">
          <el-radio-group v-model="form.access">
            <el-radio label="可读"></el-radio>
            <el-radio label="可写"></el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="长度" prop="len">
          <el-tooltip
            class="item"
            effect="dark"
            content="输入必须为整数，且范围在0到1000之间。"
            placement="right"
          >
            <el-input v-model.number="form.len"></el-input>
          </el-tooltip>
        </el-form-item>
        <el-form-item label="描述" prop="desc">
          <el-input type="textarea" v-model="form.desc"></el-input>
        </el-form-item>
        <el-form-item style="text-align: right">
          <!--          <el-button type="primary" @click="submitForm('ruleForm')">立即创建</el-button>-->
          <!--          <el-button @click="resetForm('ruleForm')">重置</el-button>-->
          <el-button @click="resetForm()">取 消</el-button>
          <el-button type="primary" @click="submitAddForm()">确 定</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <!--    虽然修改对话框与添加对话框几乎一模一样，但是表单提交函数的逻辑却不一样，这意味着共用一个组件需要考虑不同场景下一些参数的传入，-->
    <!--    这使得逻辑变的有点复杂，不利于代码的阅读和维护，所以底下还是写了一个几乎一样的dialog。-->
    <el-dialog
      title="修改属性"
      :visible.sync="dialogModiAttri"
      width="50%"
      :close-on-click-modal="false"
      @close="reset"
      v-dialogDrag
    >
      <el-form
        :model="form"
        :rules="rules"
        ref="form"
        label-width="100px"
        class="demo-ruleForm"
        status-icon
      >
        <el-form-item label="属性名称" prop="name">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="数据类型" prop="format">
          <el-select v-model="form.format" placeholder="请选择数据类型">
            <el-option label="int(整型)" value="int(整型)"></el-option>
            <el-option label="decimal(小数)" value="decimal(小数)"></el-option>
            <el-option
              label="string(字符串)"
              value="string(字符串)"
            ></el-option>
            <el-option
              label="dateTime(日期时间)"
              value="dateTime(日期时间)"
            ></el-option>
            <el-option
              label="jsonObject(JSON结构体)"
              value="jsonObject(JSON结构体)"
            ></el-option>
            <el-option
              label="stringList(数组)"
              value="stringList(数组)"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="访问权限" prop="access">
          <el-radio-group v-model="form.access">
            <el-radio label="可读"></el-radio>
            <el-radio label="可写"></el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="长度" prop="len">
          <el-tooltip
            class="item"
            effect="dark"
            content="输入必须为整数，且范围在0到1000之间。"
            placement="right"
          >
            <el-input v-model.number="form.len"></el-input>
          </el-tooltip>
        </el-form-item>
        <el-form-item label="描述" prop="desc">
          <el-input type="textarea" v-model="form.desc"></el-input>
        </el-form-item>
        <el-form-item style="text-align: right">
          <!--          <el-button type="primary" @click="submitForm('ruleForm')">立即创建</el-button>-->
          <!--          <el-button @click="resetForm('ruleForm')">重置</el-button>-->
          <el-button @click="resetForm()">取 消</el-button>
          <el-button type="primary" @click="submitModiForm()">确 定</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "modelAttribute",
  props: {
    productId: {
      type: String,
      default: "",
    },
  },
  data() {
    let checkNum = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请输入长度"));
      }
      setTimeout(() => {
        if (!Number.isInteger(value)) {
          callback(new Error("请输入整数值"));
        } else {
          if (value < 0) {
            callback(new Error("不能小于0"));
          } else if (value > 1000) {
            callback(new Error("不能大于1000"));
          } else {
            callback();
          }
        }
      }, 1000);
    };
    // 这里因为设置了1秒的延迟，所以提交和修改相较于业务添加那块会有1秒的延迟，给人一种慢了的感觉，想要提高体验，可以减小延迟时间
    return {
      flag: true,
      total: 0,
      currentPage: 1,
      pageSize: 10,
      attriData: [
        {
          id: "1",
          name: "温度",
          format: "int(整型)",
          access: "可读",
          desc: "温度",
          len: 100,
        },
      ],
      attriList: [],
      dialogAddAttri: false,
      dialogModiAttri: false,
      index: "",
      // index用来定位需要修改的属性下标
      form: {
        name: "",
        format: "",
        access: "",
        desc: "",
        len: "",
      },
      rules: {
        name: [{ required: true, message: "请输入属性名称", trigger: "blur" }],
        format: [
          { required: true, message: "请选择数据类型", trigger: "change" },
        ],
        access: [
          { required: true, message: "请选择访问权限", trigger: "change" },
        ],
        len: [
          {
            validator: checkNum,
            trigger: "blur",
          },
        ],
      },
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData() {
      const res = {};
      let param = {
        productId: this.productId,
      };
      this.$http
        .post(
          "http://39.104.14.150:8081/hit/iot/v1/product/ListProductProperties?productId=" +
            param.productId,
          param
        )
        .then((res) => {
          this.attriData = res.data.data;

          console.log(res.data.data);

          this.currentChangePage(this.attriData, this.currentPage);
          this.total = this.attriData.length;
        });
    },
    getArrayIndex(arr, obj) {
      let i = arr.length;
      while (i--) {
        if (arr[i].id === obj.id) {
          return i;
        }
      }
      return -1;
    },
    // 根据id相同获取数组下标的函数，搭配deleteService使用
    modiAttri(row) {
      this.index = this.getArrayIndex(this.attriData, row);
      this.form = this.attriData[this.index];
      this.dialogModiAttri = true;
    },
    delAttri(row) {
      this.$confirm("此操作将永久删除该属性, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        // 调用接口
        const res = {};

        let param = {
          productId: this.productId,
          id: row.id,
        };
        this.$http
          .post(
            "http://39.104.14.150:8081/hit/iot/v1/product/delAttribute",
            param
          )
          .then((res) => {
            if (res.data.code === "0") {
              this.loadData();
              this.$message({
                type: "success",
                message: "删除成功!",
              });
            }
          });
        // 调用接口结束

        // const index = this.getArrayIndex(this.attriData, row);
        // this.attriData.splice(index, 1);
        // this.currentChangePage(this.attriData, this.currentPage);
        // this.total = this.attriData.length;
        // this.$message({
        //   type: "success",
        //   message: "删除成功!",
        // });
      });
    },
    handleSizeChange(pageSize) {
      // 每页条数切换
      this.pageSize = pageSize;
      this.handleCurrentChange(this.currentPage);
    },
    handleCurrentChange(currentPage) {
      //页码切换
      this.currentPage = currentPage;
      this.currentChangePage(this.attriData, currentPage);
    },
    //分页方法（重点）
    currentChangePage(list, currentPage) {
      let from = (currentPage - 1) * this.pageSize;
      let to = currentPage * this.pageSize;
      this.attriList = [];
      for (; from < to; from++) {
        if (list[from]) {
          this.attriList.push(list[from]);
        }
      }
    },
    submitAddForm() {
      this.flag = true;
      let i;
      for (i = 0; i < this.attriData.length; ++i) {
        if (this.attriData[i].name === this.form.name) {
          this.$refs["form"].resetFields();
          this.$message({
            type: "error",
            message: "同名属性已经存在!",
          });
          this.flag = false;
          break;
        }
      }
      if (this.flag) {
        this.$refs["form"].validate((valid) => {
          if (valid) {
            // 调用接口
            const res = {};

            let param = {
              productId: this.productId,
              name: this.form.name,
              format: this.form.format,
              access: this.form.access,
              desc: this.form.desc,
              len: parseInt(this.form.len),
            };
            this.$http
              .post(
                "http://39.104.14.150:8081/hit/iot/v1/product/submitAdd",
                param
              )
              .then((res) => {
                if (res.data.code === "0") {
                  this.loadData();
                  this.$message({
                    type: "success",
                    message: "创建成功!",
                  });
                }
              });
            // 调用接口结束
            this.dialogAddAttri = false;
            // this.attriData.push({
            //   name: this.form.name,
            //   id: Math.round(Math.random() * 800 + 200).toString(),
            //   format: this.form.format,
            //   access: this.form.access,
            //   desc: this.form.desc,
            //   len: parseInt(this.form.len),
            // });
            // // 考虑到javascript的数组、对象也有深拷贝的问题存在
            // this.dialogAddAttri = false;
            // this.currentChangePage(this.attriData, this.currentPage);
            // this.total = this.attriData.length;
            // this.$message({
            //   type: "success",
            //   message: "创建成功!",
            // });
          } else {
            return false;
          }
        });
      }
    },
    submitModiForm() {
      this.flag = true;
      let i;
      for (i = 0; i < this.attriData.length; ++i) {
        if (
          this.attriData[i].name === this.form.name &&
          this.form.name !== this.attriData[this.index].name
        ) {
          this.$refs["form"].resetFields();
          this.$message({
            type: "error",
            message: "同名属性已经存在!",
          });
          this.flag = false;
          break;
        }
      }
      // 修改函数也要检查是否有同名属性（除修改前的自己以外）
      this.$refs["form"].validate((valid) => {
        if (valid) {
          // 调用接口
          const res = {};

          let param = {
            productId: this.productId,
            id: this.attriData[this.index].id,
            name: this.form.name,
            format: this.form.format,
            access: this.form.access,
            desc: this.form.desc,
            len: parseInt(this.form.len),
          };
          this.$http
            .post(
              "http://39.104.14.150:8081/hit/iot/v1/product/SubmitModiForm",
              param
            )
            .then((res) => {
              if (res.data.code === "0") {
                this.loadData();
                this.$message({
                  type: "success",
                  message: "修改成功!",
                });
              }
            });
          //调用接口结束
          this.dialogModiAttri = false;
          // this.attriData[this.index].name = this.form.name;
          // this.attriData[this.index].format = this.form.format;
          // this.attriData[this.index].desc = this.form.desc;
          // this.attriData[this.index].len = this.form.len;
          // this.attriData[this.index].access = this.form.access;
          // // 这里不能直接把this.addForm赋给this.attriData[this.index]，会出现诡异的bug
          // // 考虑到javascript的数组、对象也有深拷贝的问题存在
          // this.dialogModiAttri = false;
          // this.currentChangePage(this.attriData, this.currentPage);
          // this.$message({
          //   type: "success",
          //   message: "修改成功!",
          // });
        } else {
          return false;
        }
      });
    },
    resetForm() {
      this.dialogAddAttri = false;
      this.dialogModiAttri = false;
      console.log("close");
    },
    reset() {
      this.form = {
        name: "",
        format: "",
        access: "",
        desc: "",
        len: "",
      };
    },
  },
  mounted() {
    this.currentChangePage(this.attriData, this.currentPage);
    this.total = this.attriData.length;
  },
};
</script>

<style scoped>
.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
</style>
