var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-container',{staticClass:"container"},[_c('el-header',{staticClass:"header"},[_c('div',{staticStyle:{"font-weight":"bold","font-size":"20px"}},[_c('span',[_vm._v("设备实时状态")])])]),_c('el-main',[_c('el-scrollbar',{staticClass:"asideScrollbar",attrs:{"wrap-style":[{ 'overflow-x': 'hidden' }]}},[_c('div',{staticStyle:{"margin":"0 15px 0"}},[_c('div',{staticClass:"service"},[_c('div',[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.tableList,"header-row-style":{
                color: '#303133',
                'font-size': '16px',
                'font-weight': 'bold',
              },"row-style":{ 'font-size': '16px' },"header-cell-style":{ background: '#eef1f6' },"row-class-name":_vm.tableRowClassName}},[_c('el-table-column',{attrs:{"prop":"name","label":"设备常用名","min-width":"20%"}}),_c('el-table-column',{attrs:{"prop":"sn","label":"设备序列号","min-width":"20%"}}),_c('el-table-column',{attrs:{"prop":"online","label":"是否在线","min-width":"20%"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.online === '0')?_c('el-tag',{attrs:{"type":"warning"}},[_vm._v("不在线")]):(scope.row.online === '1')?_c('el-tag',{attrs:{"type":"success"}},[_vm._v("在线")]):(scope.row.online === '2')?_c('el-tag',{attrs:{"type":"warning"}},[_vm._v("在线不正常")]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"prop":"state","label":"工作状态","min-width":"20%"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.state === '0')?_c('el-tag',{attrs:{"type":"warning"}},[_vm._v("未注册")]):(scope.row.state === '1')?_c('el-tag',{attrs:{"type":"success"}},[_vm._v("已注册")]):(scope.row.state === '2')?_c('el-tag',{attrs:{"type":"danger"}},[_vm._v("异常")]):(scope.row.state === '3')?_c('el-tag',{attrs:{"type":"warning"}},[_vm._v("报废")]):(scope.row.state === '4')?_c('el-tag',{attrs:{"type":"warning"}},[_vm._v("已注销")]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"prop":"hostflag","label":"是否为主机","min-width":"20%"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.hostflag === '1')?_c('div',[_vm._v("是")]):_c('div',[_vm._v("否")])]}}])}),_c('el-table-column',{attrs:{"label":"操作","min-width":"20%"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-button',{staticStyle:{"font-size":"16px"},attrs:{"type":"text"},on:{"click":function($event){return _vm.$router.push({
                        name: 'rtDeviceDetail',
                        params: { id: scope.row.id },
                      })}}},[_vm._v("查看")])]}}])})],1),_c('br'),_c('div',{staticClass:"paginationClass"},[_c('el-pagination',{attrs:{"current-page":_vm.currentPage,"page-sizes":[5, 10, 20],"page-size":_vm.pageSize,"layout":"total, sizes, prev, pager, next, jumper","total":_vm.total},on:{"size-change":_vm.handleSizeChange,"current-change":_vm.handleCurrentChange}})],1)],1)])])])],1),_c('el-dialog',{attrs:{"title":"设备详情","visible":_vm.dialogTableVisible,"width":"80%"},on:{"update:visible":function($event){_vm.dialogTableVisible=$event}}},[_c('el-descriptions',{attrs:{"column":2,"label-style":{
        'font-size': '16px',
        color: '#909399',
        'font-weight': 'bold',
      }}},[_c('el-descriptions-item',{attrs:{"label":"设备id"}}),_c('el-descriptions-item',{attrs:{"label":"设备序列号"}}),_c('el-descriptions-item',{attrs:{"label":"设备访问的远程ip及端口"}}),_c('el-descriptions-item',{attrs:{"label":"访问标识"}}),_c('el-descriptions-item',{attrs:{"label":"设备常用名"}}),_c('el-descriptions-item',{attrs:{"label":"部署地址"}}),_c('el-descriptions-item',{attrs:{"label":"经度"}}),_c('el-descriptions-item',{attrs:{"label":"纬度"}}),_c('el-descriptions-item',{attrs:{"label":"安装日期"}}),_c('el-descriptions-item',{attrs:{"label":"维护周期"}}),_c('el-descriptions-item',{attrs:{"label":"主机标识"}}),_c('el-descriptions-item',{attrs:{"label":"宿主机"}}),_c('el-descriptions-item',{attrs:{"label":"绑定业务"}}),_c('el-descriptions-item',{attrs:{"label":"在线状态"}}),_c('el-descriptions-item',{attrs:{"label":"工作状态"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }