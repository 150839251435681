//健康检查参数维护
import hcTest from "../../views/hitIotHC/hcTest";

const routerHitIotHC = [
  {
    path: "hcTest",
    component: hcTest,
    meta: {
      title: "设备健康检查测试",
    },
  },
];
export default routerHitIotHC;
