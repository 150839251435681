//设备分类管理
import classificationTest from "../../views/hitIotClassification/classificationTest";
import serviceClassification from "@/views/hitIotClassification/serviceClassification";
import serviceDefine from "@/views/hitIotClassification/serviceDefine";
import nationalTable from "@/views/hitIotClassification/nationalTable";
import productEntry from "@/views/hitIotClassification/productEntry";
import productQuery from "@/views/hitIotClassification/productQuery";
import productModify from "@/views/hitIotClassification/productModify";

const routerHitIotClassification = [
  {
    path: "classificationTest",
    component: classificationTest,
    meta: {
      title: "设备分类管理测试",
    },
  },
  {
    path: "nationalTable",
    component: nationalTable,
    meta: {
      title: "国标分类",
    },
  },
  {
    path: "serviceClassification",
    component: serviceClassification,
    meta: {
      title: "业务分类",
    },
  },
  {
    path: "serviceDefine/:service",
    component: serviceDefine,
    meta: {
      title: "定义业务关联",
    },
  },
  {
    path: "productEntry",
    component: productEntry,
    name: "productEntry",
    meta: {
      title: "设备模型录入",
    },
  },
  {
    path: "productQuery",
    component: productQuery,
    name: "productQuery",
    meta: {
      title: "设备模型查询",
    },
  },
  {
    path: "productModify/:modelId",
    component: productModify,
    name: "productModify",
    meta: {
      title: "设备模型修改",
    },
  },
];
export default routerHitIotClassification;
