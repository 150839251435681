<template>
  <el-container class="container" direction="vertical">
    <el-row :gutter="20" class="el-row" type="flex">
      <el-col class="el-col" :span="12">
        <el-card class="el-card">
          <div slot="header" class="clearfix" style="height: 24px">
            <span>地块信息</span>
            <el-button
              type="button"
              icon="el-icon-circle-close"
              circle
              style="float: right; margin-top: -6px"
              @click="goBack"
            ></el-button>
          </div>
          <div>
            <div class="text item">
              <div class="item_tag">
                <span>名称：</span>
              </div>
              <div class="item_desr">
                <span> {{ land.ctl_name }}</span>
              </div>
            </div>
            <div class="text item">
              <div class="item_tag">
                <span>编号：</span>
              </div>
              <div class="item_desr">
                <span> {{ land.ctl_id }}</span>
              </div>
            </div>
            <div class="text item">
              <div class="item_tag">
                <span>地址：</span>
              </div>
              <div class="item_desr">
                {{ land.ctl_address }}
              </div>
            </div>
            <div class="text item">
              <div class="item_tag">
                <span>面积：</span>
              </div>
              <div class="item_desr">
                <span>
                  {{ land.ctl_area }}
                </span>
              </div>
            </div>
          </div>
        </el-card>
      </el-col>
      <el-col class="el-col" :span="12">
        <el-card class="el-card">
          <div slot="header" class="clearfix">
            <span>环境监控信息</span>
            <template>
              <el-radio-group v-model="RedioZ" style="float: right">
                <el-radio :label="1">24小时</el-radio>
                <el-radio :label="2">7天</el-radio>
              </el-radio-group>
            </template>
          </div>
          <div style="height: 180px; overflow-y: auto">
            <div v-for="(item, index) in devs" v-bind:key="index">
              <div v-if="item.sta_value !== 'ON' && item.sta_value !== 'OFF'">
                <el-col :span="8">
                  <div>
                    <el-tag
                      class="text"
                      :key="index"
                      style="
                        box-shadow: #909399;
                        width: 155px;
                        margin-left: 0px;
                        margin-right: 0px;
                      "
                      @click.native="staticsChart(item)"
                      >{{ item.p_c_name }}: {{ item.sta_value
                      }}{{ item.sta_unit }}
                    </el-tag>
                  </div>
                </el-col>
              </div>
            </div>
          </div>
        </el-card>
      </el-col>
    </el-row>
    <el-row :gutter="20" class="el-row" type="flex">
      <el-col class="el-col" :span="12">
        <el-card class="el-card">
          <div slot="header" class="clearfix">
            <span>监控信息变化折线图</span>
          </div>
          <div ref="envLineChart" style="height: 350px"></div>
        </el-card>
      </el-col>
      <el-col class="el-col" :span="12">
        <el-card class="el-card">
          <div slot="header" class="clearfix">
            <span>监控信息变化直方图</span>
          </div>
          <div ref="envBarChart" style="height: 350px"></div>
        </el-card>
      </el-col>
    </el-row>
  </el-container>
</template>

<script>
const echarts = require("echarts");
export default {
  name: "chartMonitor",
  data() {
    return {
      //公共变量
      activeNames: ["1"],
      RedioZ: "1",
      land: {
        ctl_name: "哈工大一号",
        ctl_id: 1,
        ctl_address: "山东省-威海市-环翠区-哈工大路1号",
        ctl_area: 25000,
      },
      xA: [],
      yA: [],
      dataList: [],
      pcname: "",
      unit: "",
      devs: [
        {
          p_c_name: "10CMPH",
          sta_value: 6.5,
          sta_unit: "",
        },
        {
          p_c_name: "20CMPH",
          sta_value: 6.3,
          sta_unit: "",
        },
        {
          p_c_name: "30CMPH",
          sta_value: 6.1,
          sta_unit: "",
        },
        {
          p_c_name: "10CM温度",
          sta_value: "20.1",
          sta_unit: "℃",
        },
        {
          p_c_name: "10CM湿度",
          sta_value: "35.7",
          sta_unit: "%RH",
        },
        {
          p_c_name: "20CM湿度",
          sta_value: "20.7",
          sta_unit: "%RH",
        },
        {
          p_c_name: "30CM温度",
          sta_value: "19.9",
          sta_unit: "℃",
        },
      ],
    };
  },
  mounted() {},
  created: function () {
    this.$nextTick(() => {
      this.disPlayLandC();
    });
  },
  methods: {
    goBack: function () {
      this.$tabs.close();
    },
    disPlayLandC: function () {
      let i = 0;
      let lS = this.$route.query;
      this.pcname = lS.devMonitor.p_c_name;
      this.unit = lS.devMonitor.sta_unit;
      this.land = lS.landInfo;
      this.devs = lS.devInfo;
      this.RedioZ = 1;
      this.$forceUpdate();
      for (let i = 0; i < 24; i++) {
        this.xA[i] = i;
      }
      this.staticsChart(lS.devMonitor);
    },
    staticsChart: function (item) {
      this.pcname = item.p_c_name;
      this.unit = item.sta_unit;
      //console.log(item);
      switch (this.RedioZ) {
        case 1:
          this.$api.apiHitIotRun
            .getMonitor24(item.device_id, item.p_c_name, 0, 0)
            .then((res) => {
              if (res.data.code === "1") {
                this.dataList = res.data.data.dataList;
                for (let i = 0; i < this.dataList.length; i++) {
                  this.yA[i] = this.dataList[i].sta_value;
                }
                this.initLineChart();
                this.initBarChart();
              } else {
                console.log("error!zxdhhh....");
              }
            });
          break;
        case 2:
          console.log("case 2 is called!");
          break;
      }
      this.$forceUpdate();
    },
    initLineChart: function () {
      //item, typeData
      let lineChart = echarts.init(this.$refs.envLineChart);
      let option = {
        title: {
          text: this.pcname + "   单位: " + this.unit,
        },
        tooltip: {},
        legend: {},
        xAxis: {
          data: this.xA,
        },
        yAxis: {},
        series: [
          {
            data: this.yA, //[530.8850574712644, 529.969696969697, 534.1442307692307, 534.5126050420168, 531.3471074380166],
            type: "line",
            smooth: true,
          },
        ],
      };
      lineChart.setOption(option);
    },
    initBarChart: function () {
      //item, typeData
      let barChart = echarts.init(this.$refs.envBarChart);
      let option = {
        title: {
          text: this.pcname + "   单位: " + this.unit,
        },
        tooltip: {},
        legend: {},
        xAxis: {
          data: this.xA, // ["A", "B", "C", "D", "E"],
        },
        yAxis: {},
        series: [
          {
            data: this.yA, // [10, 22, 28, 23, 19],
            type: "bar",
          },
        ],
      };
      barChart.setOption(option);
    },
  },
};
</script>

<style scoped type="text/css">
.all {
  margin-top: -301px;
  word-break: break-all;
  height: 100%;
}
.mid {
  margin-top: 25%;
  height: 50%;
}
.mid_item {
  justify-content: center;
  align-items: center;
}

.item_tag {
  float: left;
  text-align: left;
  width: 20%;
}
.el_tag:hover {
  margin-top: -5px;
}

.item_desr {
  margin-left: 5%;
  min-height: 30px;
  text-align: left;
}
.text {
  width: 100%;
  font-size: 12px;
  font-family: "Microsoft YaHei";
  color: #000000;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}
.clearfix:after {
  clear: both;
}

.el-row {
  margin-top: 5px;
  margin-bottom: 5px;
  display: flex;
  flex-wrap: wrap;
}
.el-row .el-card {
  min-width: 100%;
  height: 100%;
  margin-right: 0px;
  transition: all 0.5s;
}
.el-card /deep/ .el-card__header {
  background-color: #7ab2ff;
}
.el-col {
  border-radius: 4px;
  align-items: stretch;
  margin-bottom: 10px;
  float: left;
}
.el-input {
  width: 100%;
}
.el-button {
  margin-left: 10px;
}

.form {
  width: 80%;
}
.el-collapse {
  border-bottom: unset;
}

.el-table__header col[name="gutter"] {
  display: table-cell !important;
}

.el-table >>> .highlight {
  background: #ecf5ff;
}

.el-collapse-item >>> .el-collapse-item__header {
  justify-content: center;
  font-size: 18px;
  height: 35px;
  margin-bottom: -2px;
}
</style>
