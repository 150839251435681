<template>
  <el-container class="templateContainer" direction="vertical">
    <el-row>
      <custom-header></custom-header>
    </el-row>
    <el-container>
      <custom-aside></custom-aside>
      <el-container class="mainContainer" direction="vertical">
        <!--        <el-scrollbar class="mainScrollbar">-->
        <el-container class="mainView">
          <!--此处添加路由等-->
          <router-view class="mainRouter"></router-view>
        </el-container>
        <!--        </el-scrollbar>-->
        <el-row>
      <Custom-footer></Custom-footer>
    </el-row>
      </el-container>
    </el-container>

  </el-container>
</template>

<script>
import CustomAside from "./aside";
import CustomHeader from "./header";
import CustomFooter from "./footer";
export default {
  name: "mainBody",
  components: { CustomHeader, CustomAside, CustomFooter },
};
</script>

<style>
html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
}

.mainContainer {
  height: calc(100vh - 61px); /*,header61px*/
}

/*.mainScrollbar {*/
/*  height: 100%;*/
/*}*/

.mainView {
  width: 100%;
  min-height: calc(100vh - 137px); /*header61px+footer96px+?????*/
  /* 为了保证footer总是在视图底部*/
}

.mainRouter {
  width: 100%;
}
</style>
