import axiosInstanceMethods from "../../config/request";
//设备运行管理
const apiHitIotRun = {
  //获取地块监控列表http://127.0.0.1:8081/hit/iot/v1/datastatistic/listStatisticsData?deviceId=27&method=0&propKey=温度&pageSize=0&pageNum=0
  getMonitor24: (devid, pcname, pageNumc, pageSizec) =>
    axiosInstanceMethods()["iotPlatform"].request({
      method: "get",
      url: "/datastatistic/listStatisticsData",
      params: {
        deviceId: devid,
        method: 0,
        propKey: pcname,
        pageNum: pageNumc,
        pageSize: pageSizec,
      },
    }),
  //查询模型属性
  getProperties: (data) =>
    axiosInstanceMethods()["iotPlatform"].request({
      method: "post",
      url: "/product/queryProperties",
      data: data,
    }),
  //查询模型属性
  getCtlProperties: (data) =>
    axiosInstanceMethods()["iotPlatform"].request({
      method: "post",
      url: "/product/queryCtrlProperties",
      data: data,
    }),
  //保存设备属性
  saveDevPropN: (data) =>
    axiosInstanceMethods()["iotPlatform"].request({
      method: "post",
      url: "/device/adddevprop",
      data: data,
    }),
  //查询设备属性
  getDevProp: (data) =>
    axiosInstanceMethods()["iotPlatform"].request({
      method: "post",
      url: "/device/searchdevprop",
      data: data,
    }),
  //更新设备属性
  updateDevProp: (data) =>
    axiosInstanceMethods()["iotPlatform"].request({
      method: "post",
      url: "/device/updatedevprop",
      data: data,
    }),
  //删除设备属性
  deleteDevProp: (data) =>
    axiosInstanceMethods()["iotPlatform"].request({
      method: "post",
      url: "/device/deldevprop",
      data: data,
    }),
  //增加设备属性与模型属性关系
  saveDevPppp: (data) =>
    axiosInstanceMethods()["iotPlatform"].request({
      method: "post",
      url: "/device/insertdppp",
      data: data,
    }),
  //查询设备属性与模型属性关系
  getDevPppp: (data) =>
    axiosInstanceMethods()["iotPlatform"].request({
      method: "post",
      url: "/device/searchdppp",
      data: data,
    }),
  //删除设备属性与模型属性关系
  delDevPppp: (data) =>
    axiosInstanceMethods()["iotPlatform"].request({
      method: "post",
      url: "/device/deldppp",
      data: data,
    }),
  //查询地块上的可控设备
  searchDevCtl: (data) =>
    axiosInstanceMethods()["iotPlatform"].request({
      method: "post",
      url: "deviceM/searchDevCtrl",
      data: data,
    }),
  //向可控设备发送指令
  sendDevCmd: (data) =>
    axiosInstanceMethods()["iotPlatform"].request({
      method: "post",
      url: "deviceM/sendCommand",
      data: data,
    }),
  //日志接口:获取某一设备的最新操作日志和告警日志
  listLogsAndWarnings: (pageNum,pageSize,devid) =>
    axiosInstanceMethods()["iotPlatform"].request({
      method: "get",
      url: "command/listLogsAndWarnings",
      params: {
        deviceId: devid,
        pageNum: pageNum,
        pageSize: pageSize,
      },
    }),
  //设备查询
  listdevicebasicinfo:(pageNum,pageSize) =>
    axiosInstanceMethods()["iotPlatform"].request({
      method: "get",
      url: "device/listdevicebasicinfo",
      params: {
        pageNum: pageNum,
        pageSize: pageSize,
      },
    }),
  deviceMonitorInfo:(id) =>
    axiosInstanceMethods()["iotPlatform"].request({
      method: "get",
      url: "iotweb/device",
      params: {
        sta_install_id: id,
      },
    }),
  deviceStatistics:(query,pageNum,pageSize,id) =>  //this.queryInfo.query,this.queryInfo.pageNum,this.queryInfo.pageSize,id
    axiosInstanceMethods()["iotPlatform"].request({
      method: "get",
      url: "iotweb/statistics",
      params:{
        query: query,
        pageNum: pageNum,
        pageSize: pageSize,
        sta_install_id: id,
      },
    }),
};
export default apiHitIotRun;
