<template>
  <el-menu
    class="headerMenu"
    mode="horizontal"
    background-color="#304156"
    text-color="#bbb"
    active-text-color="#fff"
  >
    <el-menu-item class="logoWrap headerBtn" @click="$router.push('/')">
      <img src="../assets/logo.png" alt="IOT平台" class="logoPic" />
    </el-menu-item>
    <el-menu-item class="headerBtn" id="btnName" @click="$router.push('/')">
      IOT平台
    </el-menu-item>
    <el-menu-item class="headerBtn" id="btnAbout"> 关于 </el-menu-item>
  </el-menu>
</template>

<script>
export default {
  name: "customHeader",
  data() {
    return {};
  },
  methods: {},
};
</script>

<style scoped>
.headerMenu {
  background-color: var(--themeBgColor);
}
.logoPic {
  height: 100%;
}
.headerBtn {
  font-size: 20px;
}
#btnAbout {
  float: right;
}
</style>
