import apiHitIotClassification from "./module/hitIotClassification";
import apiHitIotLocation from "./module/hitIotLocation";
import apiHitIotBase from "./module/hitIotBase";
import apiHitIotHC from "./module/hitIotHC";
import apiHitIotRun from "./module/hitIotRun";
import apiHitIotModels from "./module/hitIotModels";
import apiVideo from "./module/video";

const API = {
  //设备分类管理
  apiHitIotClassification,
  //位置信息管理
  apiHitIotLocation,
  //设备基础信息维护
  apiHitIotBase,
  //健康检查信息维护
  apiHitIotHC,
  //设备运行管理
  apiHitIotRun,
  //设备模型
  apiHitIotModels,
  //摄像头
  apiVideo,
};
export default API;
