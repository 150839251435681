<template>
  <el-container class="container">
    <el-header class="header" height="40">
      <div style="font-weight: bold; font-size: 18px">
        <span>消息解编码定义</span>
      </div>
      <div>
        <el-button @click="dialogAddMessage = true" type="primary" round
          >新增消息</el-button
        >
      </div>
    </el-header>
    <el-main class="main">
      <div v-for="(item, idx) in data" :key="idx">
        <message
          :field-data="item.fieldData"
          :name="item.name"
          :type="item.type"
          :desc="item.desc"
          :byte-order="item.byteOrder"
          :data-name-list="dataNameList"
          :index-mess="idx"
          @del-message="delMessage"
          @modi-message-base="modiMessageBase"
          @modi-message-field="modiMessageField"
        ></message>
      </div>
    </el-main>
    <el-dialog
      :visible.sync="dialogAddMessage"
      width="80%"
      :close-on-click-modal="false"
      @close="reset"
      style="margin-top: 10px"
      v-dialogDrag
      append-to-body
    >
      <template slot="title">
        <div style="font-size: 20px; font-weight: bold">新增消息</div>
      </template>
      <el-form
        :model="form"
        :rules="rules"
        ref="form"
        label-width="100px"
        class="demo-ruleForm"
        status-icon
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="消息名称" prop="name">
              <el-input v-model="form.name"></el-input> </el-form-item
          ></el-col>
          <el-col :span="12">
            <el-form-item label="消息类型" prop="type">
              <el-select v-model="form.type" placeholder="请选择消息类型">
                <el-option label="数据上报" value="数据上报"></el-option>
                <el-option label="下发命令" value="下发命令"></el-option>
              </el-select> </el-form-item
          ></el-col>
        </el-row>
        <el-row>
          <el-col :span="12"
            ><el-form-item label="描述" prop="desc">
              <el-input
                v-model="form.desc"
                type="textarea"
              ></el-input> </el-form-item
          ></el-col>
          <el-col :span="12">
            <el-form-item label="字节序" prop="byteOrder">
              <el-select
                v-model="form.byteOrder"
                placeholder="请选择字节序类型"
              >
                <el-option label="大端模式" value="大端模式"></el-option>
                <el-option label="小端模式" value="小端模式"></el-option>
              </el-select> </el-form-item
          ></el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <addMessageField
              @sync-field="syncField"
              ref="addMessageField"
            ></addMessageField
          ></el-col>
        </el-row>
        <el-form-item style="text-align: right">
          <!--          <el-button type="primary" @click="submitForm('ruleForm')">立即创建</el-button>-->
          <!--          <el-button @click="resetForm('ruleForm')">重置</el-button>-->
          <el-button @click="resetAddForm()">取 消</el-button>
          <el-button type="primary" @click="submitAddForm()">确 定</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </el-container>
</template>

<script>
import message from "@/components/hitlotClassification/message/messageShow/message";
import addMessageField from "@/components/hitlotClassification/message/messageField/addMessageField";
export default {
  name: "messageCoding",
  //组件的全局参数，可以由在嵌入父组件的时候被赋值
  props: {
    productId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      data: [
        {
          name: "消息1",
          type: "数据上报",
          byteOrder: "大端模式",
          desc: "。。。",
          fieldData: [
            {
              checked: true,
              name: "messageId",
              desc: "地址域",
              range: {
                from: 0,
                to: 2,
              },
              len: 2,
              default: "2",
              raddixpoint: "1",
            },
          ],
        },
        {
          name: "消息2",
          type: "命令下达",
          byteOrder: "大端模式",
          desc: "。。。",
          fieldData: [
            {
              checked: true,
              name: "messageId",
              desc: "地址域",
              range: {
                from: 0,
                to: 2,
              },
              len: 2,
              default: "2",
              raddixpoint: "0",
            },
          ],
        },
        {
          name: "消息3",
          type: "指令响应上报",
          byteOrder: "大端模式",
          desc: "。。。",
          fieldData: [
            {
              checked: true,
              name: "messageId",
              desc: "地址域",
              range: {
                from: 0,
                to: 2,
              },
              len: 2,
              default: "2",
              raddixpoint: "0",
            },
            {
              checked: false,
              name: "messageId",
              desc: "地址域",
              range: {
                from: 0,
                to: 2,
              },
              len: 2,
              default: "2",
              raddixpoint: "2",
            },
            {
              checked: false,
              name: "messageId",
              desc: "地址域",
              range: {
                from: 0,
                to: 2,
              },
              len: 2,
              default: "2",
              raddixpoint: "3",
            },
          ],
        },
      ],
      dialogAddMessage: false,
      flag: true,
      // 用来判断是否重名
      form: {
        name: "",
        type: "",
        byteOrder: "",
        desc: "",
        fieldData: [],
      },
      rules: {
        name: [{ required: true, message: "请输入消息名称", trigger: "blur" }],
        type: [
          { required: true, message: "请选择消息类型", trigger: "change" },
        ],
        desc: [{ required: true, message: "请输入描述", trigger: "blur" }],
        byteOrder: [
          { required: true, message: "请选择字节序", trigger: "change" },
        ],
      },
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData() {
      //调用获取产品消息列表的api
      let param = {
        productId: this.productId,
      };
      let url =
        "http://39.104.14.150:8081/hit/iot/v1/product/listMessage?proId=";

      console.log("--------------------------");
      console.log(param.productId);
      console.log(
        "http://39.104.14.150:8081/hit/iot/v1/product/listMessage?proId=" +
          this.productId
      );

      this.$http.get(url + "" + param.productId).then((res) => {
        console.log(res.data.data);
        //先将data置空，删除默认值，使用从后台请求到的值
        this.data = [];

        //为什么要定义that = this不太明白
        let that = this;

        //请求到的数据为一个消息描述数组，所以遍历将其信息填充到data数组中
        for (let i = 0; i < res.data.data.length; i++) {
          console.log(res.data.data[i]);

          //定义一个和上面data一样结构的对象实例，然后push到数组中
          let temp = {
            name: res.data.data[i].name,
            type: res.data.data[i].type,
            byteOrder: "大端模式",
            desc: "。。。",
            fieldData: [],
          };
          for (let j = 0; j < res.data.data[i].params.length; j++) {
            temp.fieldData.push({
              checked: true,
              name: res.data.data[i].params[j].name,
              desc: res.data.data[i].params[j].name,
              range: {
                from: res.data.data[i].params[j].start / 2,
                to: res.data.data[i].params[j].end / 2,
              },
              len:
                (res.data.data[i].params[j].end -
                  res.data.data[i].params[j].start) /
                2,
              default: res.data.data[i].params[j].default_value,
              raddixpoint: res.data.data[i].params[j].raddix_point,
            });
          }

          this.data.push(temp);
        }
      });
    },
    deepClone: function deepClone(target) {
      // 定义一个变量
      let result;
      // 如果当前需要深拷贝的是一个对象的话
      if (typeof target === "object") {
        // 如果是一个数组的话
        if (Array.isArray(target)) {
          result = []; // 将result赋值为一个数组，并且执行遍历
          for (let i in target) {
            // 递归克隆数组中的每一项
            result.push(deepClone(target[i]));
          }
          // 判断如果当前的值是null的话；直接赋值为null
        } else if (target === null) {
          result = null;
          // 判断如果当前的值是一个RegExp对象的话，直接赋值
        } else if (target.constructor === RegExp) {
          result = target;
        } else {
          // 否则是普通对象，直接for in循环，递归赋值对象的所有值
          result = {};
          for (let i in target) {
            result[i] = deepClone(target[i]);
          }
        }
        // 如果不是对象的话，就是基本数据类型，那么直接赋值
      } else {
        result = target;
      }
      // 返回最终结果
      return result;
    },
    getIndexByName(arr, name) {
      let i = arr.length;
      while (i--) {
        if (arr[i].name === name) {
          return i;
        }
      }
      return -1;
    },
    reset() {
      this.$refs["form"].resetFields();
      this.form.fieldData = [];
    },
    resetAddForm() {
      this.dialogAddMessage = false;
      this.$refs["addMessageField"].tableData = [];
      this.$refs["addMessageField"].currentChangePage(
        this.$refs["addMessageField"].tableData,
        this.$refs["addMessageField"].currentPage
      );
    },
    submitAddForm() {
      this.flag = true;
      let i;
      for (i = 0; i < this.data.length; ++i) {
        if (this.data[i].name === this.form.name) {
          this.resetAddForm();
          this.$message({
            type: "error",
            message: "同名消息已经存在!",
          });
          this.flag = false;
          break;
        }
      }
      if (this.flag && this.form.fieldData.length === 0) {
        this.$message({
          type: "error",
          message: "消息字段不能为空!",
        });
        this.flag = false;
      }
      if (this.flag) {
        let messageId = false;
        for (i = 0; i < this.form.fieldData.length; ++i) {
          if (this.form.fieldData[i].name === "messageId") {
            messageId = true;
          }
        }
        if (!messageId) {
          this.$message({
            type: "error",
            message: "每个消息必须要有且只能有一个地址域字段作为唯一标识!",
          });
          this.flag = false;
        }
      }
      if (this.flag) {
        this.$refs["form"].validate((valid) => {
          if (valid) {
            this.data.push(this.deepClone(this.form));
            this.resetAddForm();
            this.$message({
              type: "success",
              message: "创建成功!",
            });
          } else {
            return false;
          }
        });
      }
    },
    delMessage(name) {
      const index = this.getIndexByName(this.data, name);
      this.data.splice(index, 1);
    },
    syncField(syncdata) {
      this.form.fieldData = this.deepClone(syncdata);
    },
    modiMessageBase(message, idx) {
      this.data[idx].name = message.name;
      this.data[idx].desc = message.desc;
      this.data[idx].type = message.type;
      this.data[idx].byteOrder = message.byteOrder;
      this.data = this.deepClone(this.data);
      // 深入Vue的响应式原理，不难发现，只改变数组的内部是无法触发响应的
    },
    modiMessageField(fieldData, idx) {
      this.data[idx].fieldData = this.deepClone(fieldData);
      this.data = this.deepClone(this.data);
      // 深入Vue的响应式原理，不难发现，只改变数组的内部是无法触发响应的
    },
  },
  computed: {
    dataNameList() {
      let result = [];
      let i = 0;
      for (; i < this.data.length; ++i) {
        result.push(this.data[i].name);
      }
      return result;
    },
  },
  components: {
    message,
    addMessageField,
  },
};
</script>

<style scoped>
.container {
  width: 100%;
  height: 100%;
}
.header {
  width: calc(100% - 20px);
  margin: 5px 10px 0;
  display: flex;
  justify-content: space-between;
}
.main {
  min-height: calc(95vh - 530px);
  margin: 0 5px;
}
</style>
