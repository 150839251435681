<template>
  <p>hcTest.vue</p>
</template>

<script>
export default {
  name: "hcTest",
};
</script>

<style scoped></style>
