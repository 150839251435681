<template>
  <router-tab restore />
</template>

<script>
export default {
  name: "customEmpty",
};
</script>

<style scoped></style>
