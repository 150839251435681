//设备分类管理
import axiosInstanceMethods from "../../config/request";
const apiHitIotClassification = {
  //获取分类列表
  getEquipList: (data) =>
    axiosInstanceMethods()["iotLocation"].request({
      method: "post",
      url: "/Equip/select",
      data: data,
    }),
  //新增数据
  insertEquip: (data) =>
    axiosInstanceMethods()["iotLocation"].request({
      method: "post",
      url: "/Equip/insert",
      data: data,
    }),
  //删除数据
  delEquip: (data) =>
    axiosInstanceMethods()["iotLocation"].request({
      method: "post",
      url: "/Equip/delete",
      data: data,
    }),
  //haiming 2022.04.02  zxd 2022.10.22
  //获得IOT平台维护的产品列表，用在deviceReg
  listAllProducts: (pageSize,pageNum) =>
    axiosInstanceMethods()["iotPlatform"].request({
      method: "get",
      url: "/product/listAllProducts",
      params: {
        pageSize: pageSize,
        pageNum: pageNum,
      },
    }),
  //获得IOT平台维护的产品列表-后端分页
  getProductList: (data) =>
    axiosInstanceMethods()["iotPlatform"].request({
      method: "get",
      url: "/product/listAllProducts",
      params: data,
    }),
  //删除某产品
  deleteProduct: (data) =>
    axiosInstanceMethods()["iotPlatform"].request({
      method: "post",
      url: "/product/deleteProduct",
      data: data,
    }),
  //判断是否存在同名产品
  existProduct: (data) =>
    axiosInstanceMethods()["iotPlatform"].request({
      method: "post",
      url: "/product/existProduct",
      data: data,
    }),
  //新增一个IoT平台产品分类
  addProduct: (data) =>
    axiosInstanceMethods()["iotPlatform"].request({
      method: "post",
      url: "/product/CreateProducts",
      data: data,
    }),
  //查询IoT平台维护的某个产品的信息
  queryProductInfo: (data) =>
    axiosInstanceMethods()["iotPlatform"].request({
      method: "post",
      url: "/product/queryProductInfo",
      params: data,
    }),
  //根据产品id查询产品对应的所有属性
  getProductProperties: (data) =>
    axiosInstanceMethods()["iotPlatform"].request({
      method: "post",
      url: "/product/ListProductProperties",
      params: data,
    }),
  // 删除产品的某一属性
  delProductProperty: (data) =>
    axiosInstanceMethods()["iotPlatform"].request({
      method: "post",
      url: "/product/delAttribute",
      data: data,
    }),
  //判断是否存在同名属性
  existProperty: (data) =>
    axiosInstanceMethods()["iotPlatform"].request({
      method: "post",
      url: "/product/existProperty",
      data: data,
    }),
  //用于添加产品的属性
  addProductProperty: (data) =>
    axiosInstanceMethods()["iotPlatform"].request({
      method: "post",
      url: "/product/submitAdd",
      data: data,
    }),
  //modelAttribute组件接口——修改属性请求 post——submitModiForm
  modifyProductProperty: (data) =>
    axiosInstanceMethods()["iotPlatform"].request({
      method: "post",
      url: "/product/SubmitModiForm",
      data: data,
    }),
  //根据产品id查询产品对应的所有指令
  getProductOrders: (data) =>
    axiosInstanceMethods()["iotPlatform"].request({
      method: "post",
      url: "/product/ListProductOrders",
      params: data,
    }),
  //删除一条命令
  deleteProductOrder: (data) =>
    axiosInstanceMethods()["iotPlatform"].request({
      method: "post",
      url: "/product/delCommand",
      data: data,
    }),
  //判断命令是否存在
  existCommand: (data) =>
    axiosInstanceMethods()["iotPlatform"].request({
      method: "post",
      url: "/product/existCommand",
      data: data,
    }),
  //添加一条命令
  addProductOrder: (data) =>
    axiosInstanceMethods()["iotPlatform"].request({
      method: "post",
      url: "/product/submitAddCommandForm",
      data: data,
    }),
  //命令修改提交
  modifyProductOrder: (data) =>
    axiosInstanceMethods()["iotPlatform"].request({
      method: "post",
      url: "/product/submitModiCommandForm",
      data: data,
    }),
  //加载指令的绑定下发参数
  loadTransmitParam: (data) =>
    axiosInstanceMethods()["iotPlatform"].request({
      method: "post",
      url: "/product/loadTransmitParam",
      data: data,
    }),
  //添加指令的绑定下发参数
  addTransmitParam: (data) =>
    axiosInstanceMethods()["iotPlatform"].request({
      method: "post",
      url: "/product/addTransmitParam",
      data: data,
    }),
  //删除指令的绑定下发参数
  deleteTransmitParam: (data) =>
    axiosInstanceMethods()["iotPlatform"].request({
      method: "post",
      url: "/product/deleteTransmitParam",
      data: data,
    }),
  //修改指令的绑定下发参数
  modifyTransmitParam: (data) =>
    axiosInstanceMethods()["iotPlatform"].request({
      method: "post",
      url: "/product/modifyTransmitParam",
      data: data,
    }),
  //判断待增加的指令下发参数是否已经存在（是否重名）
  existTransmitParam: (data) =>
    axiosInstanceMethods()["iotPlatform"].request({
      method: "post",
      url: "/product/existTransmitParam",
      data: data,
    }),
  //加载指令的绑定响应参数
  loadResponseParam: (data) =>
    axiosInstanceMethods()["iotPlatform"].request({
      method: "post",
      url: "/product/loadResponseParam",
      data: data,
    }),
  //添加指令的绑定响应参数
  addResponseParam: (data) =>
    axiosInstanceMethods()["iotPlatform"].request({
      method: "post",
      url: "/product/deleteResponseParam",
      data: data,
    }),
  //删除指令的绑定响应参数
  deleteResponseParam: (data) =>
    axiosInstanceMethods()["iotPlatform"].request({
      method: "post",
      url: "/product/deleteResponseParam",
      data: data,
    }),
  //修改指令的绑定下发参数
  modifyResponseParam: (data) =>
    axiosInstanceMethods()["iotPlatform"].request({
      method: "post",
      url: "/product/modifyResponseParam",
      data: data,
    }),
  //判断待增加的指令响应参数是否已经存在（是否重名）
  existResponseParam: (data) =>
    axiosInstanceMethods()["iotPlatform"].request({
      method: "post",
      url: "/product/existResponseParam",
      data: data,
    }),
  //列出产品的所有消息
  listMessage: (data) =>
    axiosInstanceMethods()["iotPlatform"].request({
      method: "get",
      url: "/product/listMessage",
      params: data,
    }),
  //获取消息的最后一个字段的range的to
  getRangetoOfLastField: (data) =>
    axiosInstanceMethods()["iotPlatform"].request({
      method: "post",
      url: "/product/getRangetoOfLastField",
      data: data,
    }),
  //获取消息的所有字段
  getMessageFieldList: (data) =>
    axiosInstanceMethods()["iotPlatform"].request({
      method: "get",
      url: "/product/getMessageFieldList",
      params: data,
    }),
  //删除消息字段-后端需要更新后面消息的range
  deleteMessageField: (data) =>
    axiosInstanceMethods()["iotPlatform"].request({
      method: "post",
      url: "/product/deleteMessageField",
      data: data,
    }),
  //获取用于模型映射绑定的ModelOptions
  loadModelOptions: (data) =>
    axiosInstanceMethods()["iotPlatform"].request({
      method: "get",
      url: "/product/loadModelOptions",
      params: data,
    }),
  //查询消息字段是否已经存在（是否存在同名字段）
  existMessageField: (data) =>
    axiosInstanceMethods()["iotPlatform"].request({
      method: "post",
      url: "/product/existMessageField",
      data: data,
    }),
  //创建新的消息字段
  addMessageField: (data) =>
    axiosInstanceMethods()["iotPlatform"].request({
      method: "post",
      url: "/product/addMessageField",
      data: data,
    }),
  //修改消息字段-后端需要更新后面消息的range
  modifyMessageField: (data) =>
    axiosInstanceMethods()["iotPlatform"].request({
      method: "post",
      url: "/product/modifyMessageField",
      data: data,
    }),
  //删除消息
  deleteMessage: (data) =>
    axiosInstanceMethods()["iotPlatform"].request({
      method: "post",
      url: "/product/deleteMessage",
      data: data,
    }),
  //修改消息基础信息
  modifyMessageBase: (data) =>
    axiosInstanceMethods()["iotPlatform"].request({
      method: "post",
      url: "/product/modifyMessageBase",
      data: data,
    }),
  //判断同名消息是否存在
  existMessage: (data) =>
    axiosInstanceMethods()["iotPlatform"].request({
      method: "post",
      url: "/product/existMessage",
      data: data,
    }),
  //显示产品已有的的消息-模型绑定规则
  listParamtoMessageBindingRules: (data) =>
    axiosInstanceMethods()["iotPlatform"].request({
      method: "get",
      url: "/product/listParamtoMessageBindingRules",
      params: data,
    }),
};
export default apiHitIotClassification;
