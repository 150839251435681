import axios from "axios";
import qs from "qs";
import getAxiosInstances from "./axiosInstance";

let axiosMethods = (axiosInstance) => {
  return {
    baseURL: axiosInstance.defaults.baseURL,
    get: function (url, params) {
      return new Promise((resolve, reject) => {
        axiosInstance
          .get(url, {
            params: params,
          })
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    post: function (url, params) {
      //data 可以直接用data
      return new Promise((resolve, reject) => {
        axiosInstance
          .post(url, qs.stringify(params)) //data
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    put: function (url, params) {
      return new Promise((resolve, reject) => {
        axiosInstance
          .put(url, params)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    patch: function (url, params) {
      return new Promise((resolve, reject) => {
        axiosInstance
          .put(url, params)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    deletefn: function (url, params) {
      return new Promise((resolve, reject) => {
        axiosInstance
          .delete(url, params)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    request: function (config) {
      return new Promise((resolve, reject) => {
        axiosInstance
          .request(config)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    postImage: function (url, data) {
      return new Promise((resolve, reject) => {
        axiosInstance
          .post(url, data, {
            baseURL: "",
            headers: { "Content-Type": "multipart/form-data" },
          })
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  };
};

let axiosInstanceMethods = () => {
  let axiosMethodList = {};
  for (let i in getAxiosInstances()) {
    axiosMethodList[i] = axiosMethods(getAxiosInstances()[i]);
  }
  return axiosMethodList;
};

export default axiosInstanceMethods;

/**
 * get方法，对应get请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function get(url, params) {
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        params: params,
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

/**
 * post方法，参数序列化
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function post(url, params) {
  return new Promise((resolve, reject) => {
    axios
      .post(url, qs.stringify(params))
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

/**
 * put方法，对应put请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function put(url, params) {
  return new Promise((resolve, reject) => {
    axios
      .put(url, params)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

/**
 * patch方法，对应patch请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function patch(url, params) {
  return new Promise((resolve, reject) => {
    axios
      .put(url, params)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

/**
 * deletefn,对应delete方法
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function deletefn(url, params) {
  return new Promise((resolve, reject) => {
    axios
      .delete(url, params)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

/**
 * request,对应request方法
 * @param {Object} config [{headers:Object,baseURL:String,url:String,method:String[,data:Object]}对象]
 */
export function request(config) {
  return new Promise((resolve, reject) => {
    axios
      .request(config)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

/**
 * postImage,对应postImage上传图片方法
 * @param {String} url [图床url]
 * @param {Object} data [formData图片数据]
 */
export function postImage(url, data) {
  return new Promise((resolve, reject) => {
    axios
      .post(url, data, {
        baseURL: "",
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
