const serviceList = {
  dev: {
    smartAgriculture: {
      // url: "http://119.3.65.42", //远程
      url: "http://39.104.14.150",  //现远程
      // url: "http://127.0.0.1",
      port: "8000",
      suffix: "api",
    }, //智慧农业
    iotPlatform: {
      url: "http://39.104.14.150", //远程/hit/iot/v1/product/listAllProducts
      // url: "http://127.0.0.1",
      port: "8081",
      suffix: "/hit/iot/v1",
    }, //物联平台
    iotLocation: {
      url: "http://39.104.14.150",//远程
      // url: "http://127.0.0.1",
      port: "6701",
      suffix: "", //hit/iot/v1 暂时不加，乐乐那里可能用的是没有路径的
    },
    imageUpload: {
      url: "http://10.245.150.146",
      port: "",
      suffix: "/api",
    }, //图片上传
    monitorEZVIZ: {
      url: "https://open.ys7.com",
      port: "",
      suffix: "/api",
    }, //海康威视萤石云监控摄像头,获取token
  },
  test: {
    smartAgriculture: {
      url: "http://119.3.65.42",
      port: "8000",
      suffix: "",
    }, //智慧农业
    iotPlatform: {
      url: "http://39.104.14.150",
      port: "8081",
      suffix: "",
    }, //物联平台
    imageUpload: {
      url: "http://139.104.14.150",
      port: "",
      suffix: "/api",
    }, //图片上传
  },
  production: {},
};

export default serviceList;
