<template>
  <el-container class="container" direction="vertical">
    <el-row :gutter="20" class="el-row" type="flex">
      <el-col class="el-col" :span="12">
        <el-card class="el-card">
          <div slot="header" class="clearfix" style="height: 24px">
            <span>地块信息</span>
            <el-button
              type="button"
              icon="el-icon-circle-close"
              circle
              style="float: right; margin-top: -6px"
              @click="goBack"
            ></el-button>
            <el-button
              type="button"
              icon="el-icon-refresh"
              circle
              style="float: right; margin-top: -6px"
              @click="landSearch"
            ></el-button>
          </div>
          <div>
            <div class="text item">
              <div class="item_tag">
                <span>名称：</span>
              </div>
              <div class="item_desr">
                <span> {{ land.ctl_name }}</span>
              </div>
            </div>
            <div class="text item">
              <div class="item_tag">
                <span>编号：</span>
              </div>
              <div class="item_desr">
                <span> {{ land.ctl_id }}</span>
              </div>
            </div>
            <div class="text item">
              <div class="item_tag">
                <span>地址：</span>
              </div>
              <div class="item_desr">
                {{ land.ctl_address }}
              </div>
            </div>
            <div class="text item">
              <div class="item_tag">
                <span>面积：</span>
              </div>
              <div class="item_desr">
                <span>
                  {{ land.ctl_area }}
                </span>
              </div>
            </div>
          </div>
        </el-card>
      </el-col>
      <el-col class="el-col" :span="12">
        <el-card class="el-card" :span="24">
          <div slot="header" class="clearfix">
            <span>摄像头</span>
          </div>
          <div>
            <!-- <video-box></video-box> -->
            <!--            <ezui-kit v-bind:msg="land"></ezui-kit>-->
            <div class="hello-ezuikit-js">
              <div id="video-container" style="width: 100%; height: 100%"></div>
            </div>
          </div>
        </el-card>
      </el-col>
    </el-row>
    <el-row :gutter="20" class="el-row" type="flex">
      <el-col class="el-col" :span="24">
        <el-card class="el-card">
          <div
            slot="header"
            class="clearfix"
            style="
              padding: 1px;
              font-family: 'Microsoft YaHei';
              font-size: large;
              font-style: italic;
              color: #e22e39;
              height: 15px;
            "
          >
            <span>预警信息</span>
          </div>
          <el-table
            v-fit-columns
            :show-header="false"
            :data="warnInfo"
            :fit="true"
            highlight-current-row
            ref="warningTable"
          >
            <el-table-column
              label="警告信息"
              prop="warningInfo"
            ></el-table-column>
            <el-table-column label="时间" prop="time">
              <!--              width="140px"-->
            </el-table-column>
          </el-table>
        </el-card>
      </el-col>
    </el-row>
    <el-row :gutter="20" class="el-row" type="flex">
      <el-col class="el-col" :span="24">
        <el-card class="search_condition">
          <el-collapse v-model="activeNames">
            <el-collapse-item
              name="1"
              style="margin-top: 0px; margin-bottom: 0px"
            >
              <template slot="title">
                <div style="font-size: 16px">环境监测信息</div>
              </template>
              <div v-for="(item, index) in devs" v-bind:key="index">
                <div v-if="item.sta_value !== 'ON' && item.sta_value !== 'OFF'">
                  <el-col :span="4">
                    <div>
                      <el-tag
                        class="text"
                        :disable-transitions="false"
                        :key="index"
                        style="
                          box-shadow: #909399;
                          width: 170px;
                          margin-left: 0px;
                          margin-right: 0px;
                        "
                        @click.native="landMonitorChart(item)"
                        >{{ item.p_c_name }}: {{ item.sta_value
                        }}{{ item.sta_unit }}
                      </el-tag>
                    </div>
                  </el-col>
                </div>
              </div>
            </el-collapse-item>
            <el-collapse-item
              name="2"
              style="margin-top: 0px; margin-bottom: 0px"
            >
              <template slot="title">
                <div style="font-size: 16px">可控制设备</div>
              </template>
              <div v-for="(item, index) in devsC" v-bind:key="index">
                <el-col :span="4">
                  <div>
                    <el-card style="width: 155px">
                      <p style="margin-bottom: 5px">
                        <el-switch
                          v-model="devSwitch[index]"
                          active-text="开"
                          inactive-text="关"
                          @click.native="devOpera(item, index)"
                          v-loading="cardLoading"
                        />
                      </p>
                      <el-tag
                        type="warning"
                        class="text"
                        :disable-transitions="false"
                        :key="index"
                        style="
                          box-shadow: #909399;
                          width: 145px;
                          margin-left: 0px;
                          margin-right: 0px;
                          font-family: 'Microsoft YaHei';
                          color: #454e65;
                        "
                        >{{ item.prop_name }}: {{ item.dp_state }}
                      </el-tag>
                    </el-card>
                  </div>
                </el-col>
              </div>
            </el-collapse-item>
          </el-collapse>
        </el-card>
      </el-col>
    </el-row>
  </el-container>
</template>

<script>
//import EzuiKit from "../../components/video/EZUIKitJs";
import EZUIKit from "ezuikit-js";
export default {
  name: "sLandMonitor",
  //components: { EzuiKit },
  data() {
    return {
      //公共变量
      accessToken: "",
      cardLoading: false,
      devSwitch: [],
      activeNames: ["1"],
      land: {
        ctl_name: "哈工大一号",
        ctl_id: 1,
        ctl_address: "山东省-威海市-环翠区-哈工大路1号",
        ctl_area: 25000,
        ctl_cameratoken: "",
        ctl_cameraurl: "",
        ctl_tokenurl: "",
      },
      warnInfo: [
        {
          warningInfo:
            "[空气温度传感器]威海第一基地空气温度传感器当前值为:[100.0],不在设备阈值范围,特此报警",
          time: "2022-04-22 12:18:18",
        },
        {
          warningInfo:
            "[空气湿度传感器]威海第一基地空气湿度传感器当前值为:[100.0],不在设备阈值范围,特此报警",
          time: "2022-04-20 12:18:18",
        },
      ],
      devs: [], //传感器
      devsM: [],
      devsC: [], //控制器
      devsS: {
        ctl_id: -1,
        i_id: -1,
        prop_id: -1,
        prop_name: "",
        dp_id: -1,
        dp_name: "",
        dp_state: -1,
      },
    };
  },
  watch: {
    accessToken() {
      this.setPlayer();
    },
  },
  created() {
    //this.landSearch();
    this.$nextTick(() => {
      this.disPlayLand();
    });
  },
  mounted() {},
  methods: {
    getVideoAccessToken(cameratoken, tokenurl) {
      console.log("hhahahaah......");
      this.$api.apiVideo
        .getAccessToken(cameratoken, tokenurl)
        .then((res) => {
          console.log("token", res);
          if (res.data.code === "200") {
            this.accessToken = res.data.data["accessToken"];
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    setPlayer() {
      console.group("mounted 组件挂载完毕状态===============》");
      let player = new EZUIKit.EZUIKitPlayer({
        //这是海康卫视的播放器
        autoplay: true,
        id: "video-container",
        accessToken: this.accessToken, //访问token
        url: this.land.ctl_cameraurl, //访问地址 --"ezopen://open.ys7.com/G96786970/1.hd.live",//this.msg.cameraurl,
        template: "standard", // simple - 极简版;standard-标准版;security - 安防版(预览回放);voice-语音版；
        audio: 0, // 是否默认开启声音 0 - 关闭 1 - 开启
        width: 520, //335
        height: 300, //190
      });
      console.log("player", player);
    },
    goBack: function () {
      this.$tabs.close();
    },
    devOpera: function (item, index) {
      //发送指令
      console.log("item:" + item);
      let flag = 1;
      let pname = item.prop_name;
      if (item.dp_state == 1) flag = 0;
      let msg = {
        deviceId: item.i_id,
        messageId: item.me_id,
        propname: item.prop_name,
        flag: flag,
      };
      console.log(msg);
      this.$api.apiHitIotRun
        .sendDevCmd(msg)
        .then((res) => {
          console.log(res);
          if (res.data.code === "1") {
            this.$message({
              message: "指令发送成功！",
              type: "success",
            });
          } else {
            this.$message({
              message: "指令发送失败！",
              type: "info",
            });
          }
        })
        .catch((err) => {
          console.log(err.response);
        });
      console.log("nanniang...." + this.cardLoading);
      this.cardLoading = true;
      //暂停5秒
      //接收信息
      setTimeout(() => {
        this.cardLoading = false;
        this.ctrlDevSearch();
        //回调信息在这里
      }, 5000);
      console.log("nanniang...." + this.cardLoading);
      //若失败再发信息
    },
    disPlayLand: function () {
      let i = 0;
      let lS = this.$route.query;
      this.land.ctl_id = lS.landInfo.ctl_id;
      this.land.ctl_name = lS.landInfo.ctl_name;
      this.land.ctl_address = lS.landInfo.ctl_address;
      this.land.ctl_area = lS.landInfo.ctl_area;
      this.land.ctl_cameratoken = lS.landInfo.ctl_cameratoken;
      this.land.ctl_cameraurl = lS.landInfo.ctl_cameraurl;
      this.land.ctl_tokenurl = lS.landInfo.ctl_tokenurl;
      this.devs = lS.landInfo.devs;
      if (lS.landInfo.is_camera === "1") {
        this.getVideoAccessToken(
          lS.landInfo.ctl_cameratoken,
          lS.landInfo.ctl_tokenurl
        ); //从上一页传送过来的访问方式
      }
      this.devsS.ctl_id = lS.landInfo.ctl_id;
      console.log(this.land);
      // for (i = 0; i < this.devs.length; i++) {
      //   if (this.devs[i].sta_value === "ON") {
      //     this.devSwitch[i] = true;
      //   } else if (this.devs[i].sta_value === "OFF") {
      //     this.devSwitch[i] = false;
      //   } else {
      //     this.devsM[i] = this.devs[i];
      //   }
      // }
      this.ctrlDevSearch(); //取得控制器信息
    },
    ctrlDevSearch: function () {
      this.$api.apiHitIotRun
        .searchDevCtl(this.devsS)
        .then((res) => {
          console.log(res);
          if (res.data.code === "1") {
            // console.log(res.data);
            this.devsC = res.data.data;
            console.log(this.devsC);
            // console.log(res.data.data);
            // console.log("这是地块控制器id：" + this.devsC[0].dp_state);
            let i = 0;
            for (i = 0; i < this.devsC.length; i++) {
              // console.log("这是地块id：" + this.devs[i].dp_state);
              if (this.devsC[i].dp_state === 1) this.devSwitch[i] = true;
              else if (this.devsC[i].dp_state === 0) this.devSwitch[i] = false;
            }
          } else {
            this.$message({
              message: "查询失败，请联管理员",
              type: "info",
            });
          }
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
    landSearch: function () {
      this.$api.apiHitIotLocation
        .getMonitorSP(this.land.ctl_id)
        .then((res) => {
          console.log(res);
          if (res.data.code === "1") {
            console.log(res.data);
            this.land.ctl_id = res.data.data.ctl_id;
            this.land.ctl_name = res.data.data.ctl_name;
            this.land.ctl_address = res.data.data.ctl_address;
            this.land.ctl_area = res.data.data.ctl_area;
            console.log(res.data.data.devs);
            this.devs = res.data.data.devs;
            console.log("这是地块id：" + this.devs[0].sta_value);
            let i = 0;
            for (i = 0; i < this.devs.length; i++) {
              console.log("这是地块id：" + this.devs[i].sta_value);
              if (this.devs[i].sta_value === "ON") this.devSwitch[i] = true;
              else if (this.devs[i].sta_value === "OFF")
                this.devSwitch[i] = false;
            }
          } else {
            this.$message({
              message: "查询失败，请联管理员",
              type: "info",
            });
          }
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
    landMonitorChart: function (item) {
      console.log(item);
      this.$router.push({
        path: "chartMonitor",
        query: {
          devMonitor: item,
          landInfo: this.land,
          devInfo: this.devs,
        },
      });
    },
  },
};
</script>

<style scoped type="text/css">
.all {
  margin-top: -301px;
  word-break: break-all;
  height: 100%;
}
.mid {
  margin-top: 25%;
  height: 50%;
}
.mid_item {
  justify-content: center;
  align-items: center;
}

.item_tag {
  float: left;
  text-align: left;
  width: 20%;
}
.el_tag:hover {
  margin-top: -5px;
}

.item_desr {
  margin-left: 5%;
  min-height: 30px;
  text-align: left;
}
.text {
  width: 100%;
  font-size: 12px;
  font-family: "Microsoft YaHei";
  color: #000000;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}
.clearfix:after {
  clear: both;
}

.el-row {
  margin-top: 5px;
  margin-bottom: 5px;
  display: flex;
  flex-wrap: wrap;
}
.el-row .el-card {
  min-width: 100%;
  height: 100%;
  margin-right: 0px;
  transition: all 0.5s;
}
.el-card /deep/ .el-card__header {
  background-color: #7ab2ff;
}
.el-col {
  border-radius: 4px;
  align-items: stretch;
  margin-bottom: 10px;
  float: left;
}
.el-input {
  width: 100%;
}
.el-button {
  margin-left: 10px;
}

.search_condition {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.5);
  width: 100%;
  /*margin: 1px;*/
  border-radius: 1px;
  text-align: left;
  margin-bottom: 0px;
  margin-top: 0px;
}

.form {
  width: 80%;
}
.el-collapse {
  border-bottom: unset;
}

.el-table__header col[name="gutter"] {
  display: table-cell !important;
}

.el-table >>> .highlight {
  background: #ecf5ff;
}

.el-collapse-item >>> .el-collapse-item__header {
  justify-content: center;
  font-size: 18px;
  height: 35px;
  margin-bottom: -2px;
}
.search_condition
  >>> .el-collapse-item
  .el-collapse-item__header
  .el-collapse-item__arrow {
  margin: 1px;
}
</style>
