<template>
  <el-scrollbar
    class="bottomScrollbar"
    style="height: 100%"
    :wrap-style="[{ 'overflow-x': 'hidden' }]"
  >
    <el-container class="container" style="min-width: 1000px">
      <el-header class="header" height="25px;" style="margin-bottom: 5px">
        <el-row
          style="
            width: 100%;
            border-radius: 5px;
            background-color: #606266;
            padding: 7px 7px 12px 7px;
          "
        >
          <el-col :span="12">
            <div style="font-weight: bold; font-size: 20px; color: #f8f8fc">
              <span>模型/产品修改</span>
            </div>
          </el-col>
          <el-col :span="12">
            <div style="text-align: right">
              <el-tooltip content="复制" placement="bottom" effect="light">
                <el-button
                  plain
                  icon="el-icon-document-copy"
                  circle
                  style="padding: 5px; margin-right: 5px"
                  @click="onModelCopy"
                ></el-button>
              </el-tooltip>
              <el-tooltip content="完成修改" placement="bottom" effect="light">
                <el-button
                  plain
                  icon="el-icon-check"
                  circle
                  style="padding: 5px; margin-right: 5px"
                  @click="onModelCompleted"
                ></el-button>
              </el-tooltip>
              <el-tooltip content="模型发布" placement="bottom" effect="light">
                <el-button
                  plain
                  icon="el-icon-upload"
                  circle
                  style="padding: 5px"
                  @click="onModelBaseInfoRelease"
                ></el-button>
              </el-tooltip>
            </div>
          </el-col>
        </el-row>
      </el-header>
      <el-main style="padding-right: 30px">
        <div class="block">
          <el-timeline>
            <el-timeline-item
              timestamp="模型主体信息"
              placement="top"
              :color="
                modelState.preserve
                  ? timeLineActivities[0].color
                  : timeLineActivities[1].color
              "
            >
              <div class="box">
                <div>
                  <el-divider content-position="left"
                    ><span style="font-size: 16px"
                      >模型主体信息</span
                    ></el-divider
                  >
                </div>
                <div style="padding-left: 5px; padding-right: 5px">
                  <el-row>
                    <el-col :span="12">
                      <div style="text-align: left; margin-bottom: 15px">
                        <span style="margin-right: 10px">状态:</span>
                        <el-tag
                          :type="modelState.preserve ? 'success' : 'warning'"
                          >{{
                            modelState.preserve ? "已保存" : "未保存"
                          }}</el-tag
                        >
                      </div>
                    </el-col>
                    <el-col :span="12">
                      <div style="text-align: right; margin-bottom: 15px">
                        <el-button plain @click="onModelBaseInfoPreserve"
                          >保存</el-button
                        >
                        <el-button
                          plain
                          @click="onModelBaseInfoEdit"
                          :disabled="modelState.isEditor"
                          >编辑</el-button
                        >
                        <el-button
                          plain
                          @click="onModelBaseInfoReset"
                          :disabled="!modelState.isEditor"
                          >重置</el-button
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <div>
                    <el-form
                      :model="modelBaseInfoForm"
                      :rules="modelBaseInfoRules"
                      ref="modelBaseInfoForm"
                      class="demo-ruleForm"
                      label-width="80px"
                    >
                      <el-row :gutter="20">
                        <el-col :span="8"
                          ><div>
                            <el-form-item label="模型编号" prop="id">
                              <el-input
                                v-model="modelBaseInfoForm.id"
                                :disabled="!modelState.isEditor"
                              ></el-input>
                            </el-form-item></div
                        ></el-col>
                        <el-col :span="8"
                          ><div>
                            <el-form-item label="中文名称" prop="nameC">
                              <el-input
                                v-model="modelBaseInfoForm.nameC"
                                :disabled="!modelState.isEditor"
                              ></el-input>
                            </el-form-item>
                          </div>
                        </el-col>
                        <el-col :span="8"
                          ><div>
                            <el-form-item label="英文名称" prop="nameE">
                              <el-input
                                v-model="modelBaseInfoForm.nameE"
                                :disabled="!modelState.isEditor"
                              ></el-input>
                            </el-form-item>
                          </div>
                        </el-col>
                      </el-row>
                      <el-row :gutter="20">
                        <el-col :span="8"
                          ><div>
                            <el-form-item label="模型类别" prop="modelType">
                              <el-select
                                v-model="modelBaseInfoForm.modelType"
                                :disabled="!modelState.isEditor"
                                placeholder="请选择模型类别"
                              >
                                <el-option
                                  label="直连设备"
                                  value="0"
                                ></el-option>
                                <el-option label="云设备" value="1"></el-option>
                              </el-select>
                            </el-form-item></div
                        ></el-col>
                        <el-col :span="8"
                          ><div>
                            <el-form-item label="协议类型" prop="protocolType">
                              <el-select
                                v-model="modelBaseInfoForm.protocolType"
                                :disabled="!modelState.isEditor"
                                placeholder="请选择协议类别"
                              >
                                <el-option label="TCP" value="TCP"></el-option>
                                <el-option
                                  label="HTTP/HTTP2"
                                  value="HTTP/HTTP2"
                                ></el-option>
                                <el-option
                                  label="HTTPS"
                                  value="HTTPS"
                                ></el-option>
                                <el-option
                                  label="MQTT"
                                  value="MQTT"
                                ></el-option>
                              </el-select>
                            </el-form-item></div
                        ></el-col>
                        <el-col :span="8"
                          ><div>
                            <el-form-item label="数据格式" prop="dataFormat">
                              <el-select
                                v-model="modelBaseInfoForm.dataFormat"
                                :disabled="!modelState.isEditor"
                                placeholder="请选择数据格式"
                              >
                                <el-option
                                  label="二进制码流"
                                  value="0"
                                ></el-option>
                                <el-option
                                  label="json字符串"
                                  value="1"
                                ></el-option>
                              </el-select>
                            </el-form-item>
                          </div>
                        </el-col>
                      </el-row>
                      <el-row :gutter="20">
                        <el-col :span="8"
                          ><div>
                            <el-form-item label="可控性" prop="dataFormat">
                              <el-select
                                v-model="modelBaseInfoForm.ctrlDevice"
                                :disabled="!modelState.isEditor"
                                placeholder="请选择可控性"
                              >
                                <el-option label="传感器" value="0"></el-option>
                                <el-option label="控制器" value="1"></el-option>
                                <el-option label="一体机" value="2"></el-option>
                              </el-select>
                            </el-form-item>
                          </div>
                        </el-col>
                        <el-col :span="16">
                          <el-form-item label="模型描述" prop="desc">
                            <el-input
                              v-model="modelBaseInfoForm.desc"
                              :disabled="!modelState.isEditor"
                              type="textarea"
                              :rows="1"
                            >
                            </el-input>
                          </el-form-item>
                        </el-col>
                      </el-row>
                    </el-form>
                  </div>
                </div>
              </div>
            </el-timeline-item>
            <el-timeline-item
              v-if="modelDataFormat === 'binary'"
              timestamp="编码与解码规则"
              placement="top"
              :color="
                codingRulesStatePres
                  ? timeLineActivities[0].color
                  : timeLineActivities[1].color
              "
            >
              <div class="box">
                <div>
                  <el-divider content-position="left"
                    ><span style="font-size: 16px"
                      >编码与解码规则</span
                    ></el-divider
                  >
                </div>
                <v-hover>
                  <template v-slot:default="{ hover }">
                    <div style="padding-left: 5px; padding-right: 5px">
                      <v-fade-transition>
                        <v-overlay
                          v-if="!modelState.preserve ? hover : false"
                          absolute
                          color="#DCDFE6"
                          style="
                            border-radius: 5px;
                            margin-left: 27px;
                            margin-top: 24px;
                          "
                        >
                          <v-btn>请先完成主体模块信息的录入并保存。</v-btn>
                        </v-overlay>
                      </v-fade-transition>
                      <el-row>
                        <el-col :span="12">
                          <div style="text-align: left; margin-bottom: 15px">
                            <span style="margin-right: 10px">状态:</span>
                            <el-tag
                              :type="
                                codingRulesStatePres ? 'success' : 'warning'
                              "
                              >{{
                                codeDataList.length === 0
                                  ? "空"
                                  : codingRulesStatePres
                                  ? "已保存"
                                  : "未保存"
                              }}</el-tag
                            >
                          </div>
                        </el-col>
                        <el-col :span="12">
                          <div style="text-align: right; margin-bottom: 15px">
                            <el-button
                              plain
                              @click="codeDataAdd"
                              :disabled="codeDataAddState"
                              >新增</el-button
                            >
                            <el-button plain @click="codeDataSave"
                              >保存</el-button
                            >
                            <el-button
                              plain
                              @click="codeDataReset"
                              :disabled="codingRulesStatePres"
                              >重置</el-button
                            >
                          </div>
                        </el-col>
                      </el-row>
                      <el-scrollbar
                        class="bottomScrollbar"
                        :wrap-style="[{ 'overflow-y': 'hidden' }]"
                      >
                        <div style="min-width: 1000px; margin-bottom: 10px">
                          <el-table
                            v-loading="codeDataResetLoading"
                            :data="codeDataList"
                            style="width: 100%"
                            :row-class-name="tableRowClassName"
                            :header-row-style="{
                              color: '#303133',
                              'font-weight': 'bold',
                            }"
                            :header-cell-style="{
                              background: '#eef1f6',
                              padding: '5px',
                            }"
                            :cell-style="{ padding: '5px' }"
                          >
                            <el-table-column
                              type="index"
                              label="序号"
                              width="60"
                            >
                            </el-table-column>
                            <el-table-column
                              prop="nameC"
                              label="中文名称"
                              min-width="12.5%"
                            >
                              <template slot-scope="scope">
                                <input
                                  type="text"
                                  v-model="scope.row.nameC"
                                  v-show="scope.row.iseditor"
                                  style="width: 100%"
                                />
                                <span v-show="!scope.row.iseditor">{{
                                  scope.row.nameC
                                }}</span>
                              </template>
                            </el-table-column>
                            <el-table-column
                              prop="nameE"
                              label="英文名称"
                              min-width="12.5%"
                            >
                              <template slot-scope="scope">
                                <input
                                  type="text"
                                  v-model="scope.row.nameE"
                                  v-show="scope.row.iseditor"
                                  style="width: 100%"
                                />
                                <span v-show="!scope.row.iseditor">{{
                                  scope.row.nameE
                                }}</span>
                              </template>
                            </el-table-column>
                            <el-table-column
                              prop="messageId"
                              label="消息标识"
                              min-width="12.5%"
                            >
                              <template slot-scope="scope">
                                <input
                                  type="text"
                                  v-model="scope.row.messageId"
                                  v-show="scope.row.iseditor"
                                  style="width: 100%"
                                />
                                <span v-show="!scope.row.iseditor">{{
                                  scope.row.messageId
                                }}</span>
                              </template>
                            </el-table-column>
                            <el-table-column
                              prop="start"
                              label="起始位置"
                              min-width="12.5%"
                            >
                              <template slot-scope="scope">
                                <input
                                  type="text"
                                  v-model="scope.row.start"
                                  v-show="scope.row.iseditor"
                                  style="width: 100%"
                                />
                                <span v-show="!scope.row.iseditor">{{
                                  scope.row.start
                                }}</span>
                              </template> </el-table-column
                            ><el-table-column
                              prop="end"
                              label="终止位置"
                              min-width="12.5%"
                            >
                              <template slot-scope="scope">
                                <input
                                  type="text"
                                  v-model="scope.row.end"
                                  v-show="scope.row.iseditor"
                                  style="width: 100%"
                                />
                                <span v-show="!scope.row.iseditor">{{
                                  scope.row.end
                                }}</span>
                              </template>
                            </el-table-column>
                            <el-table-column
                              prop="response"
                              label="是否响应"
                              min-width="12.5%"
                            >
                              <template slot-scope="scope">
                                <el-select
                                  v-model="scope.row.response"
                                  size="mini"
                                  :disabled="!scope.row.iseditor"
                                >
                                  <el-option value="是" label="是"></el-option>
                                  <el-option value="否" label="否"></el-option>
                                </el-select>
                              </template>
                            </el-table-column>
                            <el-table-column label="操作" min-width="12.5%">
                              <template slot-scope="scope">
                                <el-button
                                  type="text"
                                  size="mini"
                                  :disabled="scope.row.editorDisable"
                                  @click="codeDataEdit(scope.$index, scope.row)"
                                  >编辑</el-button
                                >
                                <el-button
                                  type="text"
                                  size="mini"
                                  :disabled="scope.row.delDisable"
                                  @click="codeDataDel(scope.$index, scope.row)"
                                  >删除</el-button
                                >
                              </template>
                            </el-table-column>
                            <el-table-column label="操作" min-width="12.5%">
                              <template slot-scope="scope">
                                <el-button
                                  type="text"
                                  size="mini"
                                  :disabled="scope.row.bindingDisable"
                                  @click="
                                    codeDataBinding(scope.$index, scope.row)
                                  "
                                  >绑定属性</el-button
                                >
                              </template>
                            </el-table-column>
                          </el-table>
                        </div>
                      </el-scrollbar>
                    </div>
                  </template>
                </v-hover>
              </div>
            </el-timeline-item>
            <el-timeline-item
              v-if="modelDataFormat === 'binary'"
              timestamp="模型属性信息"
              placement="top"
              :color="
                attributeStatePres
                  ? timeLineActivities[0].color
                  : timeLineActivities[1].color
              "
            >
              <div class="box">
                <div>
                  <el-divider content-position="left"
                    ><span style="font-size: 16px"
                      >模型属性信息</span
                    ></el-divider
                  >
                </div>
                <v-hover>
                  <template v-slot:default="{ hover }">
                    <div style="padding-left: 5px; padding-right: 5px">
                      <v-fade-transition>
                        <v-overlay
                          v-if="codeRuleOption == null ? hover : false"
                          absolute
                          color="#DCDFE6"
                          style="
                            border-radius: 5px;
                            margin-left: 27px;
                            margin-top: 24px;
                          "
                        >
                          <v-btn>请先完成编码与解码规则的录入并保存。</v-btn>
                        </v-overlay>
                      </v-fade-transition>
                      <el-row>
                        <el-col :span="12">
                          <div style="text-align: left; margin-bottom: 15px">
                            <span style="margin-right: 10px">状态:</span>
                            <el-tag
                              :type="attributeStatePres ? 'success' : 'warning'"
                              >{{
                                codeRuleOption === null
                                  ? "未选择绑定"
                                  : attributeDataList[codeRuleOption].length ===
                                    0
                                  ? "空"
                                  : attributeStatePres
                                  ? "已保存"
                                  : "未保存"
                              }}</el-tag
                            >
                          </div>
                        </el-col>
                        <el-col :span="12">
                          <div style="text-align: right; margin-bottom: 15px">
                            <el-button
                              plain
                              @click="attributeDataSubmit"
                              :disabled="
                                codeRuleOption !== null &&
                                attributeDataList[codeRuleOption].length === 0
                                  ? false
                                  : !attributeStatePres
                              "
                              >绑定提交</el-button
                            >
                            <el-button
                              plain
                              @click="attributeDataAdd"
                              :disabled="
                                attributeDataAddState || codeRuleOption == null
                              "
                              >新增</el-button
                            >
                            <el-button
                              plain
                              @click="attributeDataSave"
                              :disabled="codeRuleOption == null"
                              >保存</el-button
                            >
                            <el-button
                              plain
                              @click="attributeDataReset"
                              :disabled="
                                attributeStatePres || codeRuleOption == null
                              "
                              >重置</el-button
                            >
                          </div>
                        </el-col>
                      </el-row>
                      <el-scrollbar
                        class="bottomScrollbar"
                        :wrap-style="[{ 'overflow-y': 'hidden' }]"
                      >
                        <div style="min-width: 1200px; margin-bottom: 10px">
                          <el-table
                            v-loading="attributeDataResetLoading"
                            :data="attributeDataList[codeRuleOption]"
                            style="width: 100%"
                            :header-row-style="{
                              color: '#303133',
                              'font-weight': 'bold',
                            }"
                            :header-cell-style="{
                              background: '#eef1f6',
                              padding: '5px',
                            }"
                            :cell-style="{ padding: '5px' }"
                          >
                            <el-table-column
                              type="index"
                              label="序号"
                              width="60"
                            >
                            </el-table-column>
                            <el-table-column
                              prop="prop_name"
                              label="名称"
                              min-width="8%"
                            >
                              <template slot-scope="scope">
                                <input
                                  type="text"
                                  v-model="scope.row.prop_name"
                                  v-show="scope.row.iseditor"
                                  style="width: 100%"
                                />
                                <span v-show="!scope.row.iseditor">{{
                                  scope.row.prop_name
                                }}</span>
                              </template>
                            </el-table-column>
                            <el-table-column
                              prop="prop_access_mod"
                              label="访问方式"
                              min-width="8%"
                            >
                              <template slot-scope="scope">
                                <el-select
                                  v-model="scope.row.prop_access_mod"
                                  size="mini"
                                  :disabled="!scope.row.iseditor"
                                >
                                  <el-option value="0" label="只读"></el-option>
                                  <el-option value="1" label="只写"></el-option>
                                  <el-option
                                    value="2"
                                    label="可读可写"
                                  ></el-option>
                                </el-select>
                              </template>
                            </el-table-column>
                            <el-table-column
                              prop="prop_dataformat"
                              label="数据类型"
                              min-width="8%"
                            >
                              <template slot-scope="scope">
                                <el-select
                                  v-model="scope.row.prop_dataformat"
                                  size="mini"
                                  :disabled="!scope.row.iseditor"
                                >
                                  <el-option value="0" label="int"></el-option>
                                  <el-option
                                    value="1"
                                    label="decimal"
                                  ></el-option>
                                  <el-option
                                    value="2"
                                    label="string"
                                  ></el-option>
                                </el-select>
                              </template>
                            </el-table-column>
                            <el-table-column
                              prop="prop_type"
                              label="属性类型"
                              min-width="8%"
                            >
                              <template slot-scope="scope">
                                <el-select
                                  v-model="scope.row.prop_type"
                                  size="mini"
                                  :disabled="!scope.row.iseditor"
                                >
                                  <el-option
                                    value="0"
                                    label="独立属性"
                                  ></el-option>
                                  <el-option
                                    value="1"
                                    label="指令命令"
                                  ></el-option>
                                  <el-option
                                    value="2"
                                    label="指令反馈"
                                  ></el-option>
                                </el-select>
                              </template> </el-table-column
                            ><el-table-column
                              prop="prop_enum_map"
                              label="映射信息"
                              min-width="10%"
                            >
                              <template slot-scope="scope">
                                <input
                                  type="text"
                                  v-model="scope.row.prop_enum_map"
                                  v-show="scope.row.iseditor"
                                  style="width: 100%"
                                />
                                <span v-show="!scope.row.iseditor">{{
                                  scope.row.prop_enum_map
                                }}</span>
                              </template>
                            </el-table-column>
                            <el-table-column
                              prop="prop_range"
                              label="取值范围"
                              min-width="8%"
                            >
                              <template slot-scope="scope">
                                <input
                                  type="text"
                                  v-model="scope.row.prop_range"
                                  v-show="scope.row.iseditor"
                                  style="width: 100%"
                                />
                                <span v-show="!scope.row.iseditor">{{
                                  scope.row.prop_range
                                }}</span>
                              </template>
                            </el-table-column>
                            <el-table-column
                              prop="prop_unit"
                              label="属性单位"
                              min-width="8%"
                            >
                              <template slot-scope="scope">
                                <input
                                  type="text"
                                  v-model="scope.row.prop_unit"
                                  v-show="scope.row.iseditor"
                                  style="width: 100%"
                                />
                                <span v-show="!scope.row.iseditor">{{
                                  scope.row.prop_unit
                                }}</span>
                              </template>
                            </el-table-column>
                            <el-table-column
                              prop="start"
                              label="起始位置"
                              min-width="8%"
                            >
                              <template slot-scope="scope">
                                <input
                                  type="text"
                                  v-model="scope.row.start"
                                  v-show="scope.row.iseditor"
                                  style="width: 100%"
                                />
                                <span v-show="!scope.row.iseditor">{{
                                  scope.row.start
                                }}</span>
                              </template>
                            </el-table-column>
                            <el-table-column
                              prop="end"
                              label="终止位置"
                              min-width="8%"
                            >
                              <template slot-scope="scope">
                                <input
                                  type="text"
                                  v-model="scope.row.end"
                                  v-show="scope.row.iseditor"
                                  style="width: 100%"
                                />
                                <span v-show="!scope.row.iseditor">{{
                                  scope.row.end
                                }}</span>
                              </template>
                            </el-table-column>
                            <el-table-column
                              prop="radixpoint"
                              label="小数位"
                              min-width="8%"
                            >
                              <template slot-scope="scope">
                                <input
                                  type="text"
                                  v-model="scope.row.radixpoint"
                                  v-show="scope.row.iseditor"
                                  style="width: 100%"
                                />
                                <span v-show="!scope.row.iseditor">{{
                                  scope.row.radixpoint
                                }}</span>
                              </template>
                            </el-table-column>
                            <el-table-column
                              prop="default"
                              label="缺省值"
                              min-width="8%"
                            >
                              <template slot-scope="scope">
                                <input
                                  type="text"
                                  v-model="scope.row.default"
                                  v-show="scope.row.iseditor"
                                  style="width: 100%"
                                />
                                <span v-show="!scope.row.iseditor">{{
                                  scope.row.default
                                }}</span>
                              </template>
                            </el-table-column>
                            <el-table-column label="操作" min-width="10%">
                              <template slot-scope="scope">
                                <el-button
                                  type="text"
                                  size="mini"
                                  :disabled="scope.row.editorDisable"
                                  @click="
                                    attributeDataEdit(scope.$index, scope.row)
                                  "
                                  >编辑</el-button
                                >
                                <el-button
                                  type="text"
                                  size="mini"
                                  :disabled="scope.row.delDisable"
                                  @click="
                                    attributeDataDelete(scope.$index, scope.row)
                                  "
                                  >删除</el-button
                                >
                              </template>
                            </el-table-column>
                          </el-table>
                        </div>
                      </el-scrollbar>
                    </div>
                  </template>
                </v-hover>
              </div>
            </el-timeline-item>
            <el-timeline-item
              v-if="modelDataFormat === 'json'"
              timestamp="模型属性信息"
              placement="top"
              :color="
                jsonAttributeStatePres
                  ? timeLineActivities[0].color
                  : timeLineActivities[1].color
              "
            >
              <div class="box">
                <div>
                  <el-divider content-position="left"
                    ><span style="font-size: 16px"
                      >模型属性信息</span
                    ></el-divider
                  >
                </div>
                <v-hover>
                  <template v-slot:default="{ hover }">
                    <div style="padding-left: 5px; padding-right: 5px">
                      <v-fade-transition>
                        <v-overlay
                          v-if="!modelState.preserve ? hover : false"
                          absolute
                          color="#DCDFE6"
                          style="
                            border-radius: 5px;
                            margin-left: 27px;
                            margin-top: 24px;
                          "
                        >
                          <v-btn>请先完成模型主体信息的录入并保存。</v-btn>
                        </v-overlay>
                      </v-fade-transition>
                      <el-row>
                        <el-col :span="12">
                          <div style="text-align: left; margin-bottom: 15px">
                            <span style="margin-right: 10px">状态:</span>
                            <el-tag
                              :type="
                                jsonAttributeStatePres ? 'success' : 'warning'
                              "
                              >{{
                                jsonAttributeStatePres ? "已保存" : "未保存"
                              }}</el-tag
                            >
                          </div>
                        </el-col>
                        <el-col :span="12">
                          <div style="text-align: right; margin-bottom: 15px">
                            <el-button
                              plain
                              @click="jsonAttributeDataAdd"
                              :disabled="jsonAttributeDataAddState"
                              >新增</el-button
                            >
                            <el-button plain @click="jsonAttributeDataSave"
                              >保存</el-button
                            >
                            <el-button
                              plain
                              @click="jsonAttributeDataReset"
                              :disabled="jsonAttributeStatePres"
                              >重置</el-button
                            >
                          </div>
                        </el-col>
                      </el-row>
                      <el-scrollbar
                        class="bottomScrollbar"
                        :wrap-style="[{ 'overflow-y': 'hidden' }]"
                      >
                        <div style="min-width: 1200px; margin-bottom: 10px">
                          <el-table
                            v-loading="jsonAttributeDataResetLoading"
                            :data="jsonAttributeDataList"
                            style="width: 100%"
                            :header-row-style="{
                              color: '#303133',
                              'font-weight': 'bold',
                            }"
                            :header-cell-style="{
                              background: '#eef1f6',
                              padding: '5px',
                            }"
                            :cell-style="{ padding: '5px' }"
                          >
                            <el-table-column
                              type="index"
                              label="序号"
                              width="60"
                            >
                            </el-table-column>
                            <el-table-column
                              prop="prop_name"
                              label="名称"
                              min-width="8%"
                            >
                              <template slot-scope="scope">
                                <input
                                  type="text"
                                  v-model="scope.row.prop_name"
                                  v-show="scope.row.iseditor"
                                  style="width: 100%"
                                />
                                <span v-show="!scope.row.iseditor">{{
                                  scope.row.prop_name
                                }}</span>
                              </template>
                            </el-table-column>
                            <el-table-column
                              prop="prop_access_mod"
                              label="访问方式"
                              min-width="8%"
                            >
                              <template slot-scope="scope">
                                <el-select
                                  v-model="scope.row.prop_access_mod"
                                  size="mini"
                                  :disabled="!scope.row.iseditor"
                                >
                                  <el-option value="0" label="只读"></el-option>
                                  <el-option value="1" label="只写"></el-option>
                                  <el-option
                                    value="2"
                                    label="可读可写"
                                  ></el-option>
                                </el-select>
                              </template>
                            </el-table-column>
                            <el-table-column
                              prop="prop_dataformat"
                              label="数据类型"
                              min-width="8%"
                            >
                              <template slot-scope="scope">
                                <el-select
                                  v-model="scope.row.prop_dataformat"
                                  size="mini"
                                  :disabled="!scope.row.iseditor"
                                >
                                  <el-option value="0" label="int"></el-option>
                                  <el-option
                                    value="1"
                                    label="decimal"
                                  ></el-option>
                                  <el-option
                                    value="2"
                                    label="string"
                                  ></el-option>
                                </el-select>
                              </template>
                            </el-table-column>
                            <el-table-column
                              prop="prop_type"
                              label="属性类型"
                              min-width="8%"
                            >
                              <template slot-scope="scope">
                                <el-select
                                  v-model="scope.row.prop_type"
                                  size="mini"
                                  :disabled="!scope.row.iseditor"
                                >
                                  <el-option
                                    value="0"
                                    label="独立属性"
                                  ></el-option>
                                  <el-option
                                    value="1"
                                    label="指令命令"
                                  ></el-option>
                                  <el-option
                                    value="2"
                                    label="指令反馈"
                                  ></el-option>
                                </el-select>
                              </template> </el-table-column
                            ><el-table-column
                              prop="prop_enum_map"
                              label="映射信息"
                              min-width="10%"
                            >
                              <template slot-scope="scope">
                                <input
                                  type="text"
                                  v-model="scope.row.prop_enum_map"
                                  v-show="scope.row.iseditor"
                                  style="width: 100%"
                                />
                                <span v-show="!scope.row.iseditor">{{
                                  scope.row.prop_enum_map
                                }}</span>
                              </template>
                            </el-table-column>
                            <el-table-column
                              prop="prop_range"
                              label="取值范围"
                              min-width="8%"
                            >
                              <template slot-scope="scope">
                                <input
                                  type="text"
                                  v-model="scope.row.prop_range"
                                  v-show="scope.row.iseditor"
                                  style="width: 100%"
                                />
                                <span v-show="!scope.row.iseditor">{{
                                  scope.row.prop_range
                                }}</span>
                              </template>
                            </el-table-column>
                            <el-table-column
                              prop="prop_unit"
                              label="属性单位"
                              min-width="8%"
                            >
                              <template slot-scope="scope">
                                <input
                                  type="text"
                                  v-model="scope.row.prop_unit"
                                  v-show="scope.row.iseditor"
                                  style="width: 100%"
                                />
                                <span v-show="!scope.row.iseditor">{{
                                  scope.row.prop_unit
                                }}</span>
                              </template>
                            </el-table-column>
                            <el-table-column
                              prop="radixpoint"
                              label="小数位"
                              min-width="8%"
                            >
                              <template slot-scope="scope">
                                <input
                                  type="text"
                                  v-model="scope.row.radixpoint"
                                  v-show="scope.row.iseditor"
                                  style="width: 100%"
                                />
                                <span v-show="!scope.row.iseditor">{{
                                  scope.row.radixpoint
                                }}</span>
                              </template>
                            </el-table-column>
                            <el-table-column
                              prop="default"
                              label="缺省值"
                              min-width="8%"
                            >
                              <template slot-scope="scope">
                                <input
                                  type="text"
                                  v-model="scope.row.default"
                                  v-show="scope.row.iseditor"
                                  style="width: 100%"
                                />
                                <span v-show="!scope.row.iseditor">{{
                                  scope.row.default
                                }}</span>
                              </template>
                            </el-table-column>
                            <el-table-column label="操作" min-width="10%">
                              <template slot-scope="scope">
                                <el-button
                                  type="text"
                                  size="mini"
                                  :disabled="scope.row.editorDisable"
                                  @click="
                                    jsonAttributeDataEdit(
                                      scope.$index,
                                      scope.row
                                    )
                                  "
                                  >编辑</el-button
                                >
                                <el-button
                                  type="text"
                                  size="mini"
                                  :disabled="scope.row.delDisable"
                                  @click="
                                    jsonAttributeDataDelete(
                                      scope.$index,
                                      scope.row
                                    )
                                  "
                                  >删除</el-button
                                >
                              </template>
                            </el-table-column>
                          </el-table>
                        </div>
                      </el-scrollbar>
                    </div>
                  </template>
                </v-hover>
              </div>
            </el-timeline-item>
          </el-timeline>
        </div>
      </el-main>
    </el-container>
    <el-dialog
      title="请选择复制内容"
      :visible.sync="dialogModelDataCopyVisible"
    >
      <el-form :model="modelCopyForm">
        <el-form-item label="模型主体信息">
          <el-select v-model="modelCopyForm.accessToModelBaseInfo">
            <el-option :value="true" label="是"></el-option>
            <el-option :value="false" label="否"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="编码与解码规则">
          <el-cascader
            v-model="modelCopyForm.codeRulesOptions"
            :options="codeRulesOptions"
            :props="{ multiple: true }"
            :disabled="cascaderCodeRulesDisable"
            clearable
          ></el-cascader>
        </el-form-item>
        <el-form-item label="模型属性">
          <el-cascader
            v-model="modelCopyForm.AttirbuteOptions"
            :options="AttirbuteOptions"
            :props="{ multiple: true }"
            :disabled="cascaderAttirbuteDisable"
            clearable
          ></el-cascader>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogModelCopyCancel">取 消</el-button>
        <el-button type="primary" @click="dialogModelCopyOk">确 定</el-button>
      </div>
    </el-dialog>
  </el-scrollbar>
</template>

<script>
export default {
  name: "productModify",
  data() {
    return {
      //模型复制=================================================================================
      dialogModelDataCopyVisible: false,
      modelCopyForm: {
        accessToModelBaseInfo: null,
        codeRulesOptions: [],
        AttirbuteOptions: [],
      },
      codeRulesOptions: [],
      AttirbuteOptions: [],
      //级联多选框的可选项为空控制台会报错，所以设置为不可用
      cascaderCodeRulesDisable: false,
      cascaderAttirbuteDisable: false,
      //=======================================================================================
      //模型主体=================================================================================
      //记录模型主体的保存发布状态
      modelState: {
        preserve: false,
        isEditor: true,
        completed: false,
      },
      //模型主体表单提交的检查规则
      modelBaseInfoRules: {
        id: [{ required: true, message: "请输入模型编号", trigger: "blur" }],
        nameC: [
          { required: true, message: "请输入模型中文名称", trigger: "blur" },
        ],
        modelType: [
          { required: true, message: "请选择模型类别", trigger: "change" },
        ],
        protocolType: [
          { required: true, message: "请选择协议类型", trigger: "change" },
        ],
        dataFormat: [
          { required: true, message: "请选择数据格式", trigger: "change" },
        ],
      },
      //模型主体信息选json还是binary，默认binary
      modelDataFormat: "binary",
      //模型主体表单信息
      modelBaseInfoForm: {
        id: "",
        nameC: "",
        nameE: "",
        modelType: "",
        protocolType: "",
        dataFormat: "",
        desc: "",
        ctrlDevice: 0,
      },
      //========================================================================================

      //编码与解码规则=============================================================================
      //编码与解码规则表格数据
      codeDataList: [
        // {
        //   nameC: "小沈阳",
        //   nameE: "小损样~",
        //   messageId: "香格里拉",
        //   start: "0",
        //   end: "2",
        //   response: "否",
        //   iseditor: false,
        //   editorDisable: false,
        //   delDisable: false,
        //   bindingDisable: false,
        //      message_id: 真正的id
        // },
        // {
        //   nameC: "乐奇",
        //   nameE: "LeOi",
        //   messageId: "超级飞侠",
        //   start: "2",
        //   end: "4",
        //   response: "是",
        //   iseditor: false,
        //   editorDisable: false,
        //   delDisable: false,
        //   bindingDisable: false,
        // },
      ],
      //标记规则保存提交是修改还是新建
      isCodeRuleModify: false,
      //重置按钮重置表格数据有一定的延迟，所以采用加载条。但是我加上这个后就没有延迟的情况了。
      codeDataResetLoading: false,
      //记录编码解码规则的保存状态
      codingRulesStatePres: false,
      //记录编辑解码规则的新增按钮状态
      codeDataAddState: false,
      //服务保存和重置按钮的编码数据位置临时变量
      codeDataPos: {
        index: null,
        row: null,
      },
      // //模型属性信息绑定规则可选择项
      // codeRuleOptions: [],

      //模型属性匹配不成功的规则数组
      //里面存放的是对应下标的规则是否匹配成功
      codeRuleMatchFailed: [],

      //模型属性信息绑定规则选择， 对应attributeDataList的下标
      codeRuleOption: null,
      //========================================================================================

      //模型属性信息————binary====================================================================
      //记录模型属性的保存状态
      attributeStatePres: false,
      //记录模型属性的新增按钮状态
      attributeDataAddState: false,
      //模型属性信息加载条
      attributeDataResetLoading: false,
      //服务保存和重置按钮的模型属性数据位置临时变量
      attributeDataPos: {
        index: null,
        row: null,
      },
      //模型属性表格数据
      attributeDataList: [
        // [
        //   {
        //     prop_name: "属性1",
        //     prop_access_mod: "http",
        //     prop_dataformat: "json",
        //     prop_type: "啥属性",
        //     prop_enum_map: "啥玩意",
        //     prop_range: "0-2",
        //     prop_unit: "cm",
        //     start: "0",
        //     end: "2",
        //     radixpoint: "1",
        //     default: "2",
        //     iseditor: false,
        //     editorDisable: false,
        //     delDisable: false,
        //   },
        // ],
        // [
        //   {
        //     prop_name: "属性1",
        //     prop_access_mod: "http",
        //     prop_dataformat: "json",
        //     prop_type: "啥属性",
        //     prop_enum_map: "啥玩意",
        //     prop_range: "0-2",
        //     prop_unit: "cm",
        //     start: "0",
        //     end: "2",
        //     radixpoint: "1",
        //     default: "2",
        //     iseditor: false,
        //     editorDisable: false,
        //     delDisable: false,
        //   },
        // ],
      ],
      //========================================================================================

      //模型属性信息————json======================================================================
      //记录模型属性的保存状态
      jsonAttributeStatePres: false,
      //记录模型属性的新增按钮状态
      jsonAttributeDataAddState: false,
      //模型属性信息加载条
      jsonAttributeDataResetLoading: false,
      //服务保存和重置按钮的模型属性数据位置临时变量
      jsonAttributeDataPos: {
        index: null,
        row: null,
      },
      //模型属性表格数据
      jsonAttributeDataList: [],
      //========================================================================================

      //其他=====================================================================================
      //步骤/时间线的css属性
      timeLineActivities: [
        {
          color: "#409EFF",
        },
        {
          color: "#DCDFE6",
        },
      ],
      //========================================================================================
    };
  },
  created() {
    let id = this.$route.params.modelId;
    console.log(id);
    this.modelBaseInfoForm.id = id;
    this.initModelData();
  },
  methods: {
    //模型复制====================================================================================
    dialogModelCopyCancel() {
      this.modelCopyForm = {
        accessToModelBaseInfo: null,
        codeRulesOptions: [],
        AttirbuteOptions: [],
      };
      this.dialogModelDataCopyVisible = false;
    },
    dialogModelCopyOk() {
      let modelBaseInfo = {};
      let codeRulesDataList = [];
      let attributeDataList = [];
      if (this.modelCopyForm.accessToModelBaseInfo === true) {
        modelBaseInfo = {
          nameC: this.modelBaseInfoForm.nameC,
          nameE: this.modelBaseInfoForm.nameE,
          modelType: this.modelBaseInfoForm.modelType,
          protocolType: this.modelBaseInfoForm.protocolType,
          dataFormat: this.modelBaseInfoForm.dataFormat,
          desc: this.modelBaseInfoForm.desc,
        };
      }
      if (this.modelCopyForm.codeRulesOptions.length > 0) {
        for (let i = 0; i < this.modelCopyForm.codeRulesOptions.length; ++i) {
          codeRulesDataList.push(
            this.codeDataList[this.modelCopyForm.codeRulesOptions[i][0]]
          );
          attributeDataList.push([]);
          for (let j = 0; j < this.modelCopyForm.AttirbuteOptions.length; ++j) {
            if (
              this.modelCopyForm.AttirbuteOptions[j][0] ===
              this.modelCopyForm.codeRulesOptions[i][0]
            ) {
              attributeDataList[i].push(
                this.attributeDataList[
                  this.modelCopyForm.AttirbuteOptions[j][0]
                ][this.modelCopyForm.AttirbuteOptions[j][1]]
              );
            }
          }
        }
      }
      this.$store.commit("modelDataCopy", {
        modelMainBaseInfo: JSON.parse(JSON.stringify(modelBaseInfo)),
        codeDataList: JSON.parse(JSON.stringify(codeRulesDataList)),
        attributeDataList: JSON.parse(JSON.stringify(attributeDataList)),
      });
      // if (this.modelCopyForm.AttirbuteOptions.length > 0) {
      //   console.log(this.modelCopyForm.AttirbuteOptions);
      //   console.log(this.modelCopyForm.AttirbuteOptions[0]);
      //   console.log(this.modelCopyForm.AttirbuteOptions[0][1]);
      // }
      this.modelCopyForm = {
        accessToModelBaseInfo: null,
        codeRulesOptions: [],
        AttirbuteOptions: [],
      };
      this.dialogModelDataCopyVisible = false;
    },
    //===========================================================================================
    // 请求查询到的模型数据==========================================================================
    initModelData() {
      //模型修改初始数据请求
      //参数为模型编号
      this.$api.apiHitIotModels
        .onModelModifyDataQuery(this.modelBaseInfoForm.id)
        .then((res) => {
          console.log("返回数据的处理参考下面");
          //根据返回的数据，要还原录入现场的一些状态
          this.modelBaseInfoForm = {
            id: res.data.data.productList[0].pId,
            nameC: res.data.data.productList[0].pCname,
            nameE: res.data.data.productList[0].pEname,
            modelType: res.data.data.productList[0].pType,
            //考虑将protocol统一改为数值型，避免歧义
            protocolType: res.data.data.productList[0].pProtocol,
            dataFormat: res.data.data.productList[0].pDataformat,
            desc: res.data.data.productList[0].pDesc,
            ctrlDevice: res.data.data.productList[0].ctrlDevice,
          };
          this.$api.apiHitIotModels
            .onMessageModifyDataQuery(this.modelBaseInfoForm.id)
            .then((res) => {
              //请求到的消息属性列表赋值到页面中
              console.log("属性信息：" + res.data.data);
              for (let i = 0; i < res.data.data.propertiesList.length; i++) {
                //i为解编码规则的下标
                this.codeDataList.push({
                  nameC: res.data.data.propertiesList[i].meCName,
                  nameE: res.data.data.propertiesList[i].meEName,
                  messageId: res.data.data.propertiesList[i].meTag,
                  start: res.data.data.propertiesList[i].meTagStart,
                  end: res.data.data.propertiesList[i].meTagEnd,
                  response:
                    res.data.data.propertiesList[i].meIsresponse === "0"
                      ? "否"
                      : "是",
                  message_id: res.data.data.propertiesList[i].meId,
                  iseditor: false,
                  editorDisable: false,
                  delDisable: false,
                  bindingDisable: false,
                });
                //插入一个空的数组
                this.attributeDataList.push([]);
                //与此条消息相关的属性列表加入到attributeDataList[i]处，属性与消息的绑定关系通过下标确定
                for (
                  let j = 0;
                  j < res.data.data.propertiesList[i].properties.length;
                  j++
                ) {
                  this.attributeDataList[i].push({
                    prop_name:
                      res.data.data.propertiesList[i].properties[j].prop_name,
                    prop_access_mod:
                      res.data.data.propertiesList[i].properties[j]
                        .prop_access_mod,
                    prop_dataformat:
                      res.data.data.propertiesList[i].properties[j]
                        .prop_dataformat,
                    prop_type:
                      res.data.data.propertiesList[i].properties[j].prop_type,
                    prop_enum_map:
                      res.data.data.propertiesList[i].properties[j]
                        .prop_enum_map,
                    prop_range:
                      res.data.data.propertiesList[i].properties[j].prop_range,
                    prop_unit:
                      res.data.data.propertiesList[i].properties[j].prop_unit,
                    start:
                      res.data.data.propertiesList[i].properties[j].prop_start,
                    end: res.data.data.propertiesList[i].properties[j].prop_end,
                    radixpoint:
                      res.data.data.propertiesList[i].properties[j]
                        .prop_raddixpoint,
                    default:
                      res.data.data.propertiesList[i].properties[j]
                        .prop_defaultvalue,
                    iseditor: false,
                    editorDisable: false,
                    delDisable: false,
                  });
                }
                //判断是否绑定了属性
                this.codeRuleMatchFailed.push(
                  this.attributeDataList[i].length === 0 ? false : true
                );
              }
            });
          //这个一定要加
          this.modelState.preserve = true;
          this.modelState.isEditor = false;
          //编码表不为空：
          this.codingRulesStatePres = true;
          //每条规则是否匹配成功
          //this.codeRuleMatchFailed = [false, true];
        });

      /*this.modelBaseInfoForm = {
        id: "1",
        nameC: "1",
        nameE: "1",
        modelType: "直连设备",
        protocolType: "TCP",
        dataFormat: "二进制码流",
        desc: "描述描述描述",
      };

      this.modelState.preserve = true;
      this.modelState.isEditor = false;

      this.codeDataList = [
        {
          nameC: "小沈阳",
          nameE: "小损样~",
          messageId: "香格里拉",
          start: "0",
          end: "2",
          response: "否",
          iseditor: false,
          editorDisable: false,
          delDisable: false,
          bindingDisable: false,
        },
        {
          nameC: "小沈阳",
          nameE: "小损样~",
          messageId: "香格里拉",
          start: "0",
          end: "2",
          response: "否",
          iseditor: false,
          editorDisable: false,
          delDisable: false,
          bindingDisable: false,
        },
        {
          nameC: "乐奇",
          nameE: "LeOi",
          messageId: "超级飞侠",
          start: "2",
          end: "4",
          response: "是",
          iseditor: false,
          editorDisable: false,
          delDisable: false,
          bindingDisable: false,
        },
      ];
      this.codingRulesStatePres = true;
      this.attributeDataList = [
        [
          {
            prop_name: "属性1",
            prop_access_mod: "http",
            prop_dataformat: "json",
            prop_type: "啥属性",
            prop_enum_map: "啥玩意",
            prop_range: "0-2",
            prop_unit: "cm",
            start: "0",
            end: "2",
            radixpoint: "1",
            default: "2",
            iseditor: false,
            editorDisable: false,
            delDisable: false,
          },
        ],
        [
          {
            prop_name: "属性1",
            prop_access_mod: "http",
            prop_dataformat: "json",
            prop_type: "啥属性",
            prop_enum_map: "啥玩意",
            prop_range: "0-2",
            prop_unit: "cm",
            start: "0",
            end: "2",
            radixpoint: "1",
            default: "2",
            iseditor: false,
            editorDisable: false,
            delDisable: false,
          },
          {
            prop_name: "属性2",
            prop_access_mod: "http",
            prop_dataformat: "json",
            prop_type: "啥属性",
            prop_enum_map: "啥玩意",
            prop_range: "0-2",
            prop_unit: "cm",
            start: "0",
            end: "2",
            radixpoint: "1",
            default: "2",
            iseditor: false,
            editorDisable: false,
            delDisable: false,
          },
        ],
      ];*/
      //this.codeRuleMatchFailed = [false, true];
    },
    //==========================================================================================
    //模型主体====================================================================================
    //模型主体保存按钮响应事件
    onModelBaseInfoPreserve() {
      if (this.modelState.preserve) {
        this.$alert("模型主体信息已保存。", "提示", {
          confirmButtonText: "确定",
        }).catch(() => {});
      } else {
        this.$refs["modelBaseInfoForm"].validate((valid) => {
          if (valid) {
            //模型保存提交网络请求模块
            if (this.isModelBaseInfoModify === false) {
              //新增提交要做重名查询
              this.$api.apiHitIotModels
                .modelAdd(this.modelBaseInfoForm)
                .then((res) => {
                  if (res.data.code === "1") {
                    this.$alert("保存成功", "提示", {
                      confirmButtonText: "确定",
                    }).catch(() => {});
                    this.modelState.preserve = true;
                    this.modelState.isEditor = false;
                    console.log(res.data);
                    this.modelBaseInfoForm.id = res.data.data + "";
                    if (this.modelBaseInfoForm.dataFormat === "0") {
                      this.modelDataFormat = "binary";
                    } else {
                      this.modelDataFormat = "json";
                    }

                    //一边录入一边查询怎么办？标记正在录入的模型id
                    if (this.modelState.completed === false) {
                      this.$store.commit(
                        "modelInputtingIdSet",
                        this.modelBaseInfoForm.id
                      );
                    }
                  } else {
                    this.$alert("保存失败", "提示", {
                      confirmButtonText: "确定",
                    }).catch(() => {});
                  }
                });
            } else {
              this.$api.apiHitIotModels
                .modelModify(this.modelBaseInfoForm)
                .then((res) => {
                  if (res.data.code === "1") {
                    this.$alert("修改成功", "提示", {
                      confirmButtonText: "确定",
                    }).catch(() => {});
                    this.modelState.preserve = true;
                    this.modelState.isEditor = false;

                    if (this.modelBaseInfoForm.dataFormat === "0") {
                      this.modelDataFormat = "binary";
                    } else {
                      this.modelDataFormat = "json";
                    }

                    //一边录入一边查询怎么办？标记正在录入的模型id
                    if (this.modelState.completed === false) {
                      this.$store.commit(
                        "modelInputtingIdSet",
                        this.modelBaseInfoForm.id
                      );
                    }
                  } else {
                    this.$alert("修改失败", "提示", {
                      confirmButtonText: "确定",
                    }).catch(() => {});
                  }
                });
            }
          } else {
            console.log("error submit!!");
            return false;
          }
        });
      }
    },
    //模型主体编辑按钮响应事件
    onModelBaseInfoEdit() {
      this.modelState.preserve = false;
      this.modelState.isEditor = true;
    },
    //模型主体发布按钮响应事件
    onModelBaseInfoRelease() {
      if (
        this.modelState.preserve &&
        this.codingRulesStatePres &&
        this.codeRuleOption === null
      ) {
        // 网络请求部分
        this.$api.apiHitIotModels
          .modelRelease({
            model_id: this.modelBaseInfoForm.id,
            command: "1",
            //number: this.modelBaseInfoForm.id,
          })
          .then((res) => {
            if (res.data.code === "1") {
              this.$alert(
                "模型已发布!如果需要继续修改，请前往模型修改页面。",
                "提示",
                {
                  confirmButtonText: "确定",
                }
              )
                .then(() => {
                  //状态标记为完成
                  this.modelState.completed = true;
                  //置空正在录入的模型id
                  this.$store.commit("modelInputtingIdSetNull");
                  //关闭当前页面
                  //关闭当前页面
                  this.$tabs.close();
                })
                .catch(() => {});
            } else {
              this.$alert("模型发布失败！" + res.data.msg, "提示", {
                confirmButtonText: "确定",
              }).catch(() => {});
            }
          });
        //注释网络请求后的调试部分******************************
        //接口对接时删除此部分，解除上面网络请求的注释即可
        /*this.$alert(
          "模型已发布!如果需要继续修改，请前往模型修改页面。",
          "提示",
          {
            confirmButtonText: "确定",
          }
        )
          .then(() => {
            //状态标记为完成
            this.modelState.completed = true;
            //置空正在录入的模型id
            this.$store.commit("modelInputtingIdSetNull");
            //关闭当前页面
            //关闭当前页面
            this.$tabs.close();
          })
          .catch(() => {});*/
        //*************************************************
      } else {
        this.$alert("发布模型前请先完成各个模块的录入和保存！", "提示", {
          confirmButtonText: "确定",
        }).catch(() => {});
      }
    },
    //模型发布函数
    modelRelease() {
      // 网络请求部分
      // this.$api.apiHitIotModels
      //   .modelRelease({
      //     number: this.modelBaseInfoForm.id,
      //   })
      //   .then((res) => {
      //     if (res.data.code === "0") {
      //       this.$alert(
      //         "模型已发布!如果需要继续修改，请前往模型修改页面。",
      //         "提示",
      //         {
      //           confirmButtonText: "确定",
      //         }
      //       )
      //         .then(() => {
      //           //状态标记为完成
      //           this.modelState.completed = true;
      //           //关闭当前页面并跳转到查询页面
      //           this.$tabs.close({
      //             to: {
      //               name: "productQuery",
      //             },
      //           });
      //         })
      //         .catch(() => {});
      //     } else {
      //       this.$alert("模型发布失败！", "提示", {
      //         confirmButtonText: "确定",
      //       }).catch(() => {});
      //     }
      //   });
      //注释网络请求后的调试部分******************************
      //接口对接时删除此部分，解除上面网络请求的注释即可
      this.$alert("模型已发布!如果需要继续修改，请前往模型修改页面。", "提示", {
        confirmButtonText: "确定",
      })
        .then(() => {
          //状态标记为完成
          this.modelState.completed = true;
          //关闭当前页面并跳转到查询页面
          this.$tabs.close({
            to: {
              name: "productQuery",
            },
          });
        })
        .catch(() => {});
      //*************************************************
    },
    //模型完成录入
    onModelCompleted() {
      if (
        (this.modelState.preserve && this.codeDataList.length === 0) ||
        (this.modelState.preserve &&
          this.codingRulesStatePres &&
          this.codeRuleOption === null)
      ) {
        this.$alert("模型修改完成，暂时为未发布状态。", "提示", {
          confirmButtonText: "确定",
        })
          .then(() => {
            //状态标记为完成
            this.modelState.completed = true;
            //关闭当前页面并跳转到查询页面
            this.$tabs.close({
              to: {
                name: "productQuery",
              },
            });
          })
          .catch(() => {});
      } else {
        this.$alert(
          "提交模型录入完成前请先完成模型主体信息或其他模块的录入和保存！",
          "提示",
          {
            confirmButtonText: "确定",
          }
        ).catch(() => {});
      }
    },
    //模型粘贴
    onModelCopy() {
      if (
        (this.modelState.preserve && this.codeDataList.length === 0) ||
        (this.modelState.preserve &&
          this.codingRulesStatePres &&
          this.codeRuleOption === null)
      ) {
        this.codeRulesOptions = [];
        this.AttirbuteOptions = [];
        for (let i = 0; i < this.codeDataList.length; ++i) {
          this.codeRulesOptions.push({
            value: i,
            label: this.codeDataList[i].nameC,
          });
        }
        for (let i = 0; i < this.attributeDataList.length; ++i) {
          this.AttirbuteOptions.push({
            value: i,
            label: this.codeDataList[i].nameC,
            children: [],
          });
          for (let j = 0; j < this.attributeDataList[i].length; ++j) {
            this.AttirbuteOptions[i].children.push({
              value: j,
              label: this.attributeDataList[i][j].prop_name,
            });
          }
        }
        this.dialogModelDataCopyVisible = true;
      } else {
        this.$alert("复制前请先完成正在进行的模型内容的修改！", "提示", {
          confirmButtonText: "确定",
        }).catch(() => {});
      }
    },
    //模型主体重置按钮响应事件
    onModelBaseInfoReset() {
      this.modelBaseInfoForm = {
        id: "",
        nameC: "",
        nameE: "",
        modelType: "",
        protocolType: "",
        dataFormat: "",
        desc: "",
      };
    },
    //==========================================================================================

    //编码与解码规则==============================================================================
    //编码规则表格新增按钮响应事件
    codeDataAdd() {
      //是否修改标记为false
      this.isCodeRuleModify = false;
      this.codingRulesStatePres = false;
      this.codeDataList.push({
        nameC: "",
        nameE: "",
        messageId: "",
        start: "",
        end: "",
        response: "否",
        iseditor: true,
        editorDisable: true,
        delDisable: false,
        bindingDisable: true,
      });
      for (let i = 0; i < this.codeDataList.length - 1; ++i) {
        this.codeDataList[i].editorDisable = true;
        this.codeDataList[i].delDisable = true;
        this.codeDataList[i].bindingDisable = true;
      }
      this.codeDataPos.index = this.codeDataList.length - 1;
      //让新增按钮暂时失效
      this.codeDataAddState = true;
      //状态设置为未保存
      this.codingRulesStatePres = false;

      //是否匹配成功，加一个不成功
      this.codeRuleMatchFailed.push(false);
    },
    //编码规则表格重置按钮响应事件
    codeDataReset() {
      this.codeDataResetLoading = true;
      this.codeDataList[this.codeDataPos.index] = {
        nameC: "",
        nameE: "",
        messageId: "",
        start: "",
        end: "",
        response: "否",
        iseditor: true,
        editorDisable: true,
        delDisable: false,
        bindingDisable: true,
      };
      this.codeDataResetLoading = false;
    },
    //编码规则表格编辑按钮响应事件
    codeDataEdit(index, row) {
      //是否修改标记为true
      this.isCodeRuleModify = true;
      row.iseditor = true;
      for (let i = 0; i < this.codeDataList.length; ++i) {
        this.codeDataList[i].editorDisable = true;
        this.codeDataList[i].delDisable = true;
        this.codeDataList[i].bindingDisable = true;
      }
      this.codeDataList[index].delDisable = false;

      this.codeDataPos.index = index;
      this.codeDataPos.row = row;

      //让新增按钮暂时失效
      this.codeDataAddState = true;

      //状态设置为未保存
      this.codingRulesStatePres = false;

      //提交修改后主要判断是否匹配成功
    },
    //编码规则表格移除响应事件
    codeDataDel(index, row) {
      if (this.codeDataList[index].message_id === undefined) {
        console.log("message_id is null"); //一个未提交的成功的编码与解码规则，是没有message_id,不删除之，如果不想继续修改，则没办法进行下面的操作，所以，要删除
        this.codeDataList.splice(index, 1);
        //恢复每行的编辑和删除,并重新赋值id
        //注意，弹出对话框确认是异步，所以要把表格状态恢复和数据删除放在一个线程里！
        for (let i = 0; i < this.codeDataList.length; ++i) {
          this.codeDataList[i].editorDisable = false;
          this.codeDataList[i].delDisable = false;
          this.codeDataList[i].bindingDisable = false;
        }
        //让新增按钮恢复可选状态
        this.codeDataAddState = false;

        //删除后如果列表为空，则保存状态置为false
        if (this.codeDataList.length === 0) {
          this.codingRulesStatePres = false;
        } else {
          //状态设置为已保存
          this.codingRulesStatePres = true;
        }
        //attributeDataList删除一个数组
        this.attributeDataList.splice(index, 1);
        //codeRuleMatchFailed删除一个匹配标识
        this.codeRuleMatchFailed.splice(index, 1);
        return;
      }
      this.$confirm(
        "此操作将一同删去与之绑定的模型属性信息，且无法重置, 是否删除该项并保存?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          console.log(index);
          console.log(this.codeDataList[index].message_id);
          this.$api.apiHitIotModels
            .codeRuleDel(this.codeDataList[index].message_id)
            .then((res) => {
              if (res.data.code === "1") {
                this.$alert("删除成功", "提示", {
                  confirmButtonText: "确定",
                });
                this.codeDataList.splice(index, 1);
                //恢复每行的编辑和删除,并重新赋值id
                //注意，弹出对话框确认是异步，所以要把表格状态恢复和数据删除放在一个线程里！
                for (let i = 0; i < this.codeDataList.length; ++i) {
                  this.codeDataList[i].editorDisable = false;
                  this.codeDataList[i].delDisable = false;
                  this.codeDataList[i].bindingDisable = false;
                }
                //让新增按钮恢复可选状态
                this.codeDataAddState = false;

                //删除后如果列表为空，则保存状态置为false
                if (this.codeDataList.length === 0) {
                  this.codingRulesStatePres = false;
                } else {
                  //状态设置为已保存
                  this.codingRulesStatePres = true;
                }

                //attributeDataList删除一个数组
                this.attributeDataList.splice(index, 1);
                //codeRuleMatchFailed删除一个匹配标识
                this.codeRuleMatchFailed.splice(index, 1);
              } else {
                this.$alert("删除失败", "提示", {
                  confirmButtonText: "确定",
                });
              }
            });
        })
        .catch(() => {});
    },
    //编码规则保存按钮响应事件
    //存储接口返回的messageid并在提交属性规则表的时候提供messageId
    codeDataSave() {
      if (!this.codingRulesStatePres) {
        if (
          this.codeDataList[this.codeDataPos.index].nameC === "" ||
          this.codeDataList[this.codeDataPos.index].nameE === "" ||
          this.codeDataList[this.codeDataPos.index].messageId === "" ||
          this.codeDataList[this.codeDataPos.index].start === "" ||
          this.codeDataList[this.codeDataPos.index].end === ""
        ) {
          this.$alert("请完整输入数据！", "提示", {
            confirmButtonText: "确定",
          }).catch(() => {});
        } else {
          if (this.isCodeRuleModify === false) {
            //新增重名查询
            console.log(this.codeDataList[this.codeDataPos.index]);
            this.$api.apiHitIotModels
              .codeRuleAdd(
                this.codeDataList[this.codeDataPos.index],
                this.modelBaseInfoForm.id
              )
              .then((res) => {
                if (res.data.code === "1") {
                  //恢复当前行的不可编辑状态
                  this.codeDataList[this.codeDataPos.index].iseditor = false;
                  //记录后台返回的messgaeid
                  this.codeDataList[this.codeDataPos.index].message_id =
                    res.data.data + "";
                  console.log(this.codeDataList);
                  //恢复每行的编辑和删除
                  for (let i = 0; i < this.codeDataList.length; ++i) {
                    this.codeDataList[i].editorDisable = false;
                    this.codeDataList[i].delDisable = false;
                    this.codeDataList[i].bindingDisable = false;
                  }

                  //让新增按钮恢复可选状态
                  this.codeDataAddState = false;

                  //状态设置为已保存
                  this.codingRulesStatePres = true;

                  //attributeDataList增加一个数组
                  this.attributeDataList.push([]);
                  //新增规则对应属性表为空，默认匹配不成功
                } else {
                  this.$alert(res.data.msg, "提示", {
                    confirmButtonText: "确定",
                  });
                }
              });
          } else {
            //修改
            this.$api.apiHitIotModels
              .codeRuleModify(
                this.codeDataList[this.codeDataPos.index],
                this.modelBaseInfoForm.id
              )
              .then((res) => {
                if (res.data.code === "1") {
                  //匹配成功
                  this.codeRuleMatchFailed[this.codeDataPos.index] = true;
                } else {
                  //匹配失败
                  this.codeRuleMatchFailed[this.codeDataPos.index] = false;
                }
                //恢复当前行的不可编辑状态
                this.codeDataList[this.codeDataPos.index].iseditor = false;
                //恢复每行的编辑和删除
                for (let i = 0; i < this.codeDataList.length; ++i) {
                  this.codeDataList[i].editorDisable = false;
                  this.codeDataList[i].delDisable = false;
                  this.codeDataList[i].bindingDisable = false;
                }
                //让新增按钮恢复可选状态
                this.codeDataAddState = false;
                //状态设置为已保存
                this.codingRulesStatePres = true;
              });
          }
          /*//注释网络请求后的调试部分******************************
          //接口对接时删除此部分，解除上面网络请求的注释即可
          //恢复当前行的不可编辑状态
          this.codeDataList[this.codeDataPos.index].iseditor = false;

          //恢复每行的编辑和删除
          for (let i = 0; i < this.codeDataList.length; ++i) {
            this.codeDataList[i].editorDisable = false;
            this.codeDataList[i].delDisable = false;
            this.codeDataList[i].bindingDisable = false;
          }

          //让新增按钮恢复可选状态
          this.codeDataAddState = false;

          //状态设置为已保存
          this.codingRulesStatePres = true;

          //attributeDataList增加一个数组
          this.attributeDataList.push([]);
          //新增规则对应属性表为空，默认匹配不成功
          //!**************************************************/
        }
      } else {
        this.$alert("编码解码规则已保存。", "提示", {
          confirmButtonText: "确定",
        }).catch(() => {});
      }
    },
    codeDataBinding(index, row) {
      if (this.codeRuleOption === null) {
        for (let i = 0; i < this.codeDataList.length; ++i) {
          this.codeDataList[i].editorDisable = true;
          this.codeDataList[i].delDisable = true;
          this.codeDataList[i].bindingDisable = true;
        }
        this.codeDataList[index].bindingDisable = false;
        this.codeRuleOption = index;
        this.codeDataAddState = true;
      }
    },
    //用来反映提交后的编码与解码规则是否与模型属性匹配成功，匹配不成功有以下两种情况
    //1.模型属性为空匹配不成功
    //2.模型属性有但是匹配不成功
    tableRowClassName({ row, rowIndex }) {
      if (this.codeRuleMatchFailed[rowIndex]) {
        return "success-row";
      } else {
        return "warning-row";
      }
    },
    //===========================================================================================

    //模型属性信息————binary=======================================================================
    //模型属性信息行编辑操作响应函数
    attributeDataEdit(index, row) {
      row.iseditor = true;
      for (
        let i = 0;
        i < this.attributeDataList[this.codeRuleOption].length;
        ++i
      ) {
        this.attributeDataList[this.codeRuleOption][i].editorDisable = true;
        this.attributeDataList[this.codeRuleOption][i].delDisable = true;
      }
      this.attributeDataList[this.codeRuleOption][index].delDisable = false;

      this.attributeDataPos.index = index;
      this.attributeDataPos.row = row;

      //让新增按钮暂时失效
      this.attributeDataAddState = true;

      //状态设置为未保存
      this.attributeStatePres = false;
    },
    //模型属性信息行删除操作响应函数
    attributeDataDelete(index, row) {
      this.$confirm("此操作无法重置, 是否删除该项并保存?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.attributeDataList[this.codeRuleOption].splice(index, 1);
          //恢复每行的编辑和删除,并重新赋值id
          //注意，弹出对话框确认是异步，所以要把表格状态恢复和数据删除放在一个线程里！
          for (
            let i = 0;
            i < this.attributeDataList[this.codeRuleOption].length;
            ++i
          ) {
            this.attributeDataList[this.codeRuleOption][
              i
            ].editorDisable = false;
            this.attributeDataList[this.codeRuleOption][i].delDisable = false;
          }
          //让新增按钮恢复可选状态
          this.attributeDataAddState = false;
          //删除后如果列表为空，则保存状态置为false
          if (this.attributeDataList[this.codeRuleOption].length === 0) {
            this.attributeStatePres = false;
          } else {
            //状态设置为已保存
            this.attributeStatePres = true;
          }
        })
        .catch(() => {});
    },
    //模型属性信息新增按钮事件
    attributeDataAdd() {
      this.attributeStatePres = false;
      this.attributeDataList[this.codeRuleOption].push({
        prop_name: "",
        prop_access_mod: "",
        prop_dataformat: "",
        prop_type: "",
        prop_enum_map: "",
        prop_range: "",
        prop_unit: "",
        start: "",
        end: "",
        radixpoint: "",
        default: "",
        iseditor: true,
        editorDisable: true,
        delDisable: false,
      });
      for (
        let i = 0;
        i < this.attributeDataList[this.codeRuleOption].length - 1;
        ++i
      ) {
        this.attributeDataList[this.codeRuleOption][i].editorDisable = true;
        this.attributeDataList[this.codeRuleOption][i].delDisable = true;
      }
      this.attributeDataPos.index =
        this.attributeDataList[this.codeRuleOption].length - 1;
      //让新增按钮暂时失效
      this.attributeDataAddState = true;
      //状态设置为未保存
      this.attributeStatePres = false;
    },
    //模型属性信息保存按钮事件
    //包含暂存已有的规则表
    attributeDataSave() {
      if (!this.attributeStatePres) {
        if (
          this.attributeDataList[this.codeRuleOption][
            this.attributeDataPos.index
          ].prop_access_mod === "" ||
          this.attributeDataList[this.codeRuleOption][
            this.attributeDataPos.index
          ].prop_dataformat === "" ||
          this.attributeDataList[this.codeRuleOption][
            this.attributeDataPos.index
          ].prop_type === "" ||
          this.attributeDataList[this.codeRuleOption][
            this.attributeDataPos.index
          ].prop_name === "" ||
          this.attributeDataList[this.codeRuleOption][
            this.attributeDataPos.index
          ].prop_enum_map === "" ||
          this.attributeDataList[this.codeRuleOption][
            this.attributeDataPos.index
          ].prop_unit === "" ||
          this.attributeDataList[this.codeRuleOption][
            this.attributeDataPos.index
          ].start === "" ||
          this.attributeDataList[this.codeRuleOption][
            this.attributeDataPos.index
          ].end === "" ||
          this.attributeDataList[this.codeRuleOption][
            this.attributeDataPos.index
          ].prop_range === "" ||
          this.attributeDataList[this.codeRuleOption][
            this.attributeDataPos.index
          ].radixpoint === "" ||
          this.attributeDataList[this.codeRuleOption][
            this.attributeDataPos.index
          ].default === ""
        ) {
          this.$alert("保存失败，请完整输入数据！", "提示", {
            confirmButtonText: "确定",
          }).catch(() => {});
        } else {
          //调用暂存接口
          let props = [];
          for (
            let i = 0;
            i < this.attributeDataList[this.codeRuleOption].length;
            i++
          ) {
            props.push({
              prop_access_mod:
                this.attributeDataList[this.codeRuleOption][i].prop_access_mod,
              prop_dataformat:
                this.attributeDataList[this.codeRuleOption][i].prop_dataformat,
              prop_defaultvalue:
                this.attributeDataList[this.codeRuleOption][i].default,
              prop_desc:
                this.attributeDataList[this.codeRuleOption][i].prop_name,
              prop_end: this.attributeDataList[this.codeRuleOption][i].end,
              prop_enum_map:
                this.attributeDataList[this.codeRuleOption][i].prop_enum_map,
              prop_name:
                this.attributeDataList[this.codeRuleOption][i].prop_name,
              prop_raddixpoint:
                this.attributeDataList[this.codeRuleOption][i].raddixpoint,
              prop_range:
                this.attributeDataList[this.codeRuleOption][i].prop_range,
              prop_start: this.attributeDataList[this.codeRuleOption][i].start,
              prop_type:
                this.attributeDataList[this.codeRuleOption][i].prop_type,
              prop_unit:
                this.attributeDataList[this.codeRuleOption][i].prop_unit,
            });
          }
          //这里需要有message的id至少是数组的标识
          this.$api.apiHitIotModels
            .attributeBindingSubmit(
              props,
              this.modelBaseInfoForm.id,
              this.codeDataList[this.codeRuleOption].message_id,
              0
            )
            .then((res) => {
              if (res.data.code === "1") {
                this.$alert("暂存成功！", "提示", {
                  confirmButtonText: "确定",
                }).catch(() => {});
              } else {
                this.$alert("暂存失败！", "提示", {
                  confirmButtonText: "确定",
                }).catch(() => {});
              }
            });
          //恢复当前行的不可编辑状态
          this.attributeDataList[this.codeRuleOption][
            this.attributeDataPos.index
          ].iseditor = false;

          //恢复每行的编辑和删除
          for (
            let i = 0;
            i < this.attributeDataList[this.codeRuleOption].length;
            ++i
          ) {
            this.attributeDataList[this.codeRuleOption][
              i
            ].editorDisable = false;
            this.attributeDataList[this.codeRuleOption][i].delDisable = false;
          }

          //让新增按钮恢复可选状态
          this.attributeDataAddState = false;

          //状态设置为已保存
          this.attributeStatePres = true;
        }
      }
    },
    //模型属性信息重置按钮事件
    attributeDataReset() {
      this.attributeDataResetLoading = true;
      this.attributeDataList[this.codeRuleOption][this.attributeDataPos.index] =
        {
          prop_name: "",
          prop_access_mod: "",
          prop_dataformat: "",
          prop_type: "",
          prop_enum_map: "",
          prop_range: "",
          prop_unit: "",
          start: "",
          end: "",
          radixpoint: "",
          default: "",
          iseditor: true,
          editorDesable: true,
          delDisable: false,
        };
      this.attributeDataResetLoading = false;
    },
    attributeDataSubmit() {
      if (this.attributeDataList[this.codeRuleOption].length === 0) {
        this.$confirm("您的录入结果为空。是否放弃此次录入？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "继续录入",
          type: "info",
        })
          .then(() => {
            this.codeRuleOption = null;
            this.attributeStatePres = false;

            //恢复解编码规则表的状态
            this.codeDataAddState = true;
            for (let i = 0; i < this.codeDataList.length; ++i) {
              this.codeDataList[i].editorDisable = false;
              this.codeDataList[i].delDisable = false;
              this.codeDataList[i].bindingDisable = false;
            }
          })
          .catch(() => {});
      } else {
        this.$confirm("确定将属性绑定提交?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "info",
        })
          .then(() => {
            // 绑定属性提交网络请求
            // 匹配成功的话: this.codeRuleMatchFailed[this.codeRuleOption] = true来变对应规则所在的那行为绿色（原来标记为黄色）
            // this.$api.apiHitIotModels
            //   .attributeBindingSubmit(this.attributeDataList[this.codeRuleOption])
            //   .then((res) => {
            //     if (res.data.code === "0") {
            //       this.$alert("提交成功！", "提示",{
            //         confirmButtonText: "确定",
            //       }).catch(() => {});
            //       this.codeRuleMatchFailed[this.codeRuleOption] = true;
            //       this.codeRuleOption = null;
            //       this.attributeStatePres = false;
            //
            //       //恢复解编码规则表的状态
            //       this.codeDataAddState = false;
            //       for (let i = 0; i < this.codeDataList.length; ++i) {
            //         this.codeDataList[i].editorDisable = false;
            //         this.codeDataList[i].delDisable = false;
            //         this.codeDataList[i].bindingDisable = false;
            //       }
            //     } else {
            //       this.$alert("提交失败！", "提示",{
            //         confirmButtonText: "确定",
            //       }).catch(() => {});
            //     }
            //   });
            //注释网络请求后的调试部分******************************
            //接口对接时删除此部分，解除上面网络请求的注释即可
            this.$alert("提交成功！", {
              confirmButtonText: "确定",
            }).catch(() => {});
            this.codeRuleMatchFailed[this.codeRuleOption] = true;
            this.codeRuleOption = null;
            this.attributeStatePres = false;

            //恢复解编码规则表的状态
            this.codeDataAddState = false;
            for (let i = 0; i < this.codeDataList.length; ++i) {
              this.codeDataList[i].editorDisable = false;
              this.codeDataList[i].delDisable = false;
              this.codeDataList[i].bindingDisable = false;
            }
            //*************************************************
          })
          .catch(() => {});
      }
    },
    //===========================================================================================

    //模型属性信息————json=========================================================================
    jsonAttributeDataEdit(index, row) {
      row.iseditor = true;
      for (let i = 0; i < this.jsonAttributeDataList.length; ++i) {
        this.jsonAttributeDataList[i].editorDisable = true;
        this.jsonAttributeDataList[i].delDisable = true;
      }
      this.jsonAttributeDataList[index].delDisable = false;

      this.jsonAttributeDataPos.index = index;
      this.jsonAttributeDataPos.row = row;

      //让新增按钮暂时失效
      this.jsonAttributeDataAddState = true;

      //状态设置为未保存
      this.jsonAttributeStatePres = false;
    },
    //模型属性信息行删除操作响应函数
    jsonAttributeDataDelete(index, row) {
      this.$confirm("此操作无法重置, 是否删除该项并保存?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.jsonAttributeDataList.splice(index, 1);
          //恢复每行的编辑和删除,并重新赋值id
          //注意，弹出对话框确认是异步，所以要把表格状态恢复和数据删除放在一个线程里！
          for (let i = 0; i < this.jsonAttributeDataList.length; ++i) {
            this.jsonAttributeDataList[i].editorDisable = false;
            this.jsonAttributeDataList[i].delDisable = false;
          }
          //让新增按钮恢复可选状态
          this.jsonAttributeDataAddState = false;
          //删除后如果列表为空，则保存状态置为false
          if (this.jsonAttributeDataList.length === 0) {
            this.jsonAttributeStatePres = false;
          } else {
            //状态设置为已保存
            this.jsonAttributeStatePres = true;
          }
        })
        .catch(() => {});
    },
    //模型属性信息新增按钮事件
    jsonAttributeDataAdd() {
      this.jsonAttributeStatePres = false;
      this.jsonAttributeDataList.push({
        prop_name: "",
        prop_access_mod: "",
        prop_dataformat: "",
        prop_type: "",
        prop_enum_map: "",
        prop_range: "",
        prop_unit: "",
        radixpoint: "",
        default: "",
        iseditor: true,
        editorDisable: true,
        delDisable: false,
      });
      for (let i = 0; i < this.jsonAttributeDataList.length - 1; ++i) {
        this.jsonAttributeDataList[i].editorDisable = true;
        this.jsonAttributeDataList[i].delDisable = true;
      }
      this.jsonAttributeDataPos.index = this.jsonAttributeDataList.length - 1;
      //让新增按钮暂时失效
      this.jsonAttributeDataAddState = true;
      //状态设置为未保存
      this.jsonAttributeStatePres = false;
    },
    //模型属性信息保存按钮事件
    jsonAttributeDataSave() {
      if (!this.jsonAttributeStatePres) {
        if (
          this.jsonAttributeDataList[this.attributeDataPos.index]
            .prop_access_mod === "" ||
          this.jsonAttributeDataList[this.attributeDataPos.index]
            .prop_dataformat === "" ||
          this.jsonAttributeDataList[this.attributeDataPos.index].prop_type ===
            "" ||
          this.jsonAttributeDataList[this.attributeDataPos.index].prop_name ===
            "" ||
          this.jsonAttributeDataList[this.attributeDataPos.index]
            .prop_enum_map === "" ||
          this.jsonAttributeDataList[this.attributeDataPos.index].prop_unit ===
            "" ||
          this.jsonAttributeDataList[this.attributeDataPos.index].prop_range ===
            "" ||
          this.jsonAttributeDataList[this.attributeDataPos.index].radixpoint ===
            "" ||
          this.jsonAttributeDataList[this.attributeDataPos.index].default === ""
        ) {
          this.$alert("请完整输入数据！", "提示", {
            confirmButtonText: "确定",
          })
            .catch(() => {})
            .catch(() => {});
        } else {
          //恢复当前行的不可编辑状态
          this.jsonAttributeDataList[
            this.jsonAttributeDataPos.index
          ].iseditor = false;

          //恢复每行的编辑和删除
          for (let i = 0; i < this.jsonAttributeDataList.length; ++i) {
            this.jsonAttributeDataList[i].editorDisable = false;
            this.jsonAttributeDataList[i].delDisable = false;
          }

          //让新增按钮恢复可选状态
          this.jsonAttributeDataAddState = false;

          //状态设置为已保存
          this.jsonAttributeStatePres = true;
        }
      }
    },
    //模型属性信息重置按钮事件
    jsonAttributeDataReset() {
      this.jsonAttributeDataResetLoading = true;
      this.jsonAttributeDataList[this.jsonAttributeDataPos.index] = {
        prop_name: "",
        prop_access_mod: "",
        prop_dataformat: "",
        prop_type: "",
        prop_enum_map: "",
        prop_range: "",
        prop_unit: "",
        radixpoint: "",
        default: "",
        iseditor: true,
        editorDisable: true,
        delDisable: false,
      };
      this.jsonAttributeDataResetLoading = false;
    },
    //==========================================================================================
  },
  computed: {
    getModelCopyFormCodeRulesOptions() {
      return this.modelCopyForm.codeRulesOptions;
    },
    getModelCopyFormAttributeOptions() {
      return this.modelCopyForm.AttirbuteOptions;
    },
  },
  //选择复制内容的表单逻辑有些复杂，需要监听
  watch: {
    getModelCopyFormCodeRulesOptions: {
      handler(newName, oldName) {
        this.modelCopyForm.AttirbuteOptions = [];
        if (this.modelCopyForm.codeRulesOptions.length === 0) {
          this.cascaderAttirbuteDisable = true;
        } else {
          this.AttirbuteOptions = [];
          for (let i = 0; i < this.modelCopyForm.codeRulesOptions.length; ++i) {
            this.AttirbuteOptions.push({
              value:
                this.codeRulesOptions[this.modelCopyForm.codeRulesOptions[i][0]]
                  .value,
              label:
                this.codeRulesOptions[this.modelCopyForm.codeRulesOptions[i][0]]
                  .label,
              children: [],
            });
            for (
              let j = 0;
              j <
              this.attributeDataList[this.modelCopyForm.codeRulesOptions[i][0]]
                .length;
              ++j
            ) {
              this.AttirbuteOptions[i].children.push({
                value: j,
                label:
                  this.attributeDataList[
                    this.modelCopyForm.codeRulesOptions[i][0]
                  ][j].prop_name,
              });
            }
          }
          this.cascaderAttirbuteDisable = false;
        }
      },
      deep: true,
    },
  },
  //添加了一个Vue的导航离开守卫，防止用户在没有提交保存的情况下意外退出页面
  beforeRouteLeave(to, from, next) {
    if (this.modelState.completed === false) {
      this.$confirm(
        "您将在未进行模型修改完成提交或发布模型的状态下离开此页，系统已为您保存已经修改的部分。",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "info",
        }
      )
        .then(() => {
          next();
        })
        .catch(() => {});
    }
  },
};
</script>

<style scoped>
.container {
  width: 100%;
  height: 100%;
}
.header {
  width: calc(100% - 30px);
  margin: 15px 15px 0;
  display: flex;
  justify-content: space-between;
}
.box {
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 5px;
  margin-bottom: 10px;
  border-style: solid;
  border-color: #dcdfe6;
  border-width: 1px;
}
.el-row:last-child {
  margin-bottom: 0;
}
.el-col {
  border-radius: 4px;
}
.asideScrollbar {
  height: 100%;
}
.bottomScrollbar {
  width: 100%;
}
.block {
  max-width: 1600px;
}
.el-table /deep/ .success-row {
  background: #f0f9eb;
}
.el-table /deep/ .warning-row {
  background: oldlace;
}
</style>
