<template>
  <el-container class="container">
    <el-header class="header">
      <div style="font-weight: bold; font-size: 20px">
        <span>设备实时状态</span>
      </div>
    </el-header>
    <el-main>
      <el-scrollbar
        class="asideScrollbar"
        :wrap-style="[{ 'overflow-x': 'hidden' }]"
      >
        <div style="margin: 0 15px 0">
          <!--          这里套一层margin是为了使el-scrollbar往右边偏一点，不至于紧贴着元素-->
          <div class="service">
            <div>
              <el-table
                :data="tableList"
                style="width: 100%"
                :header-row-style="{
                  color: '#303133',
                  'font-size': '16px',
                  'font-weight': 'bold',
                }"
                :row-style="{ 'font-size': '16px' }"
                :header-cell-style="{ background: '#eef1f6' }"
                :row-class-name="tableRowClassName"
              >
                <el-table-column prop="name" label="设备常用名" min-width="20%">
                </el-table-column>
                <el-table-column prop="sn" label="设备序列号" min-width="20%">
                </el-table-column>
                <el-table-column prop="online" label="是否在线" min-width="20%">
                  <template slot-scope="scope">
                    <el-tag v-if="scope.row.online === '0'" type="warning"
                      >不在线</el-tag
                    >
                    <el-tag v-else-if="scope.row.online === '1'" type="success"
                      >在线</el-tag
                    >
                    <el-tag v-else-if="scope.row.online === '2'" type="warning"
                      >在线不正常</el-tag
                    >
                  </template>
                </el-table-column>
                <el-table-column prop="state" label="工作状态" min-width="20%">
                  <template slot-scope="scope">
                    <el-tag v-if="scope.row.state === '0'" type="warning"
                      >未注册</el-tag
                    >
                    <el-tag v-else-if="scope.row.state === '1'" type="success"
                      >已注册</el-tag
                    >
                    <el-tag v-else-if="scope.row.state === '2'" type="danger"
                      >异常</el-tag
                    >
                    <el-tag v-else-if="scope.row.state === '3'" type="warning"
                      >报废</el-tag
                    >
                    <el-tag v-else-if="scope.row.state === '4'" type="warning"
                      >已注销</el-tag
                    >
                  </template>
                </el-table-column>
                <el-table-column
                  prop="hostflag"
                  label="是否为主机"
                  min-width="20%"
                >
                  <template slot-scope="scope">
                    <div v-if="scope.row.hostflag === '1'">是</div>
                    <div v-else>否</div>
                  </template>
                </el-table-column>
                <el-table-column label="操作" min-width="20%">
                  <template slot-scope="scope">
                    <el-button
                      @click="
                        $router.push({
                          name: 'rtDeviceDetail',
                          params: { id: scope.row.id },
                        })
                      "
                      type="text"
                      style="font-size: 16px"
                      >查看</el-button
                    >
                  </template>
                </el-table-column>
              </el-table>
              <br />
              <div class="paginationClass">
                <el-pagination
                  @size-change="handleSizeChange"
                  @current-change="handleCurrentChange"
                  :current-page="currentPage"
                  :page-sizes="[5, 10, 20]"
                  :page-size="pageSize"
                  layout="total, sizes, prev, pager, next, jumper"
                  :total="total"
                >
                </el-pagination>
              </div>
            </div>
          </div>
        </div>
      </el-scrollbar>
    </el-main>
    <el-dialog title="设备详情" :visible.sync="dialogTableVisible" width="80%">
      <el-descriptions
        :column="2"
        :label-style="{
          'font-size': '16px',
          color: '#909399',
          'font-weight': 'bold',
        }"
      >
        <el-descriptions-item label="设备id"></el-descriptions-item>
        <el-descriptions-item label="设备序列号"></el-descriptions-item>
        <el-descriptions-item
          label="设备访问的远程ip及端口"
        ></el-descriptions-item>
        <el-descriptions-item label="访问标识"></el-descriptions-item>
        <el-descriptions-item label="设备常用名"></el-descriptions-item>
        <el-descriptions-item label="部署地址"></el-descriptions-item>
        <el-descriptions-item label="经度"></el-descriptions-item>
        <el-descriptions-item label="纬度"></el-descriptions-item>
        <el-descriptions-item label="安装日期"></el-descriptions-item>
        <el-descriptions-item label="维护周期"></el-descriptions-item>
        <el-descriptions-item label="主机标识"></el-descriptions-item>
        <el-descriptions-item label="宿主机"></el-descriptions-item>
        <el-descriptions-item label="绑定业务"></el-descriptions-item>
        <el-descriptions-item label="在线状态"></el-descriptions-item>
        <el-descriptions-item label="工作状态"></el-descriptions-item>
      </el-descriptions>
    </el-dialog>
  </el-container>
</template>

<script>
export default {
  name: "deviceDel",
  data() {
    return {
      total: 0,
      currentPage: 1,
      pageSize: 5,
      tableData: [
        {
          name: "智慧农业熵情站",
          sn: "11111",
          type: "某类型",
          admin: "王昊",
          longitude: "11.11",
          latitude: "111.11",
          region: "威海",
          mperiod: "1年",
          date: "2020年8月",
          hostflag: "1",
          online: "1",
          state: "1",
          host: "智慧农业熵情站",
          // host由后端查询外键返回
        },
        {
          name: "智慧农业熵情站",
          sn: "22222",
          type: "某类型",
          admin: "王昊",
          longitude: "11.11",
          latitude: "111.11",
          region: "威海",
          mperiod: "1年",
          date: "2020年8月",
          hostflag: "1",
          online: "2",
          state: "1",
          host: "智慧农业熵情站",
          // host由后端查询外键返回
        },
        {
          name: "智慧农业熵情站",
          sn: "333333",
          type: "某类型",
          admin: "王昊",
          longitude: "11.11",
          latitude: "111.11",
          region: "威海",
          mperiod: "1年",
          date: "2020年8月",
          hostflag: "1",
          online: "1",
          state: "3",
          host: "智慧农业熵情站",
          // host由后端查询外键返回
        },
      ],
      tableList: [],
      dialogTableVisible: false,
      checkDeviceData: {},
    };
  },

  created() {
    this.loadData();
  },
  methods: {
    //列出所有注册成功的设备简要信息
    loadData: function () {
      console.log(this.tableData);
      this.$api.apiHitIotRun
        .listdevicebasicinfo(this.currentPage, this.pageSize)
        .then((res) => {
          this.tableList = [];
          for (let i = 0; i < res.data.data.length; i++) {
            this.tableList.push({
              id: res.data.data[i].id,
              name: res.data.data[i].name,
              sn: res.data.data[i].sn,
              type: res.data.data[i].type,
              admin: res.data.data[i].admin,
              longitude: res.data.data[i].longitude,
              latitude: res.data.data[i].latitude,
              region: res.data.data[i].region,
              mperiod: res.data.data[i].mperiod,
              date: res.data.data[i].date,
              hostflag: res.data.data[i].hostflag,
              host: res.data.data[i].host,
              state: "1",
              online: "1",
            });
          }
          //this.currentChangePage(this.tableData, this.currentPage); //2023.1.5 张小东
          this.total = res.data.total;
        });
      // const url =  //张小东 2023.1.5 多余的代码
      //   "http://127.0.0.1:8081/hit/iot/v1/device/listdevicebasicinfo?pageNum="+this.currentPage+"&pageSize="+this.pageSize;
      // this.$http.get(url).then((res) => {
      //   //console.log("res:"+res.data.pages);
      //   //this.tableData = [];
      //   this.tableList=[];
      //   for (let i = 0; i < res.data.data.length; i++) {
      //     //console.log(this.tableData);
      //     // this.tableData.push({ //张小东 2023.1.5 多余的代码
      //     //   id: res.data.data[i].id,
      //     //   name: res.data.data[i].name,
      //     //   sn: res.data.data[i].sn,
      //     //   type: res.data.data[i].type,
      //     //   admin: res.data.data[i].admin,
      //     //   longitude: res.data.data[i].longitude,
      //     //   latitude: res.data.data[i].latitude,
      //     //   region: res.data.data[i].region,
      //     //   mperiod: res.data.data[i].mperiod,
      //     //   date: res.data.data[i].date,
      //     //   hostflag: res.data.data[i].hostflag,
      //     //   host: res.data.data[i].host,
      //     //   state: "1",
      //     //   online: "1",
      //     // });
      //     this.tableList.push({
      //       id: res.data.data[i].id,
      //       name: res.data.data[i].name,
      //       sn: res.data.data[i].sn,
      //       type: res.data.data[i].type,
      //       admin: res.data.data[i].admin,
      //       longitude: res.data.data[i].longitude,
      //       latitude: res.data.data[i].latitude,
      //       region: res.data.data[i].region,
      //       mperiod: res.data.data[i].mperiod,
      //       date: res.data.data[i].date,
      //       hostflag: res.data.data[i].hostflag,
      //       host: res.data.data[i].host,
      //       state: "1",
      //       online: "1",
      //     });
      //   }
      //   //this.currentChangePage(this.tableData, this.currentPage); //2023.1.5 张小东
      //   this.total = res.data.total;
      // });
    },

    getArrayIndex(arr, obj) {
      let i = arr.length;
      while (i--) {
        if (arr[i].id === obj.id) {
          return i;
        }
      }
      return -1;
    },
    handleSizeChange(pageSize) {
      // 每页条数切换
      this.pageSize = pageSize;
      this.handleCurrentChange(this.currentPage);
    },
    handleCurrentChange(currentPage) {
      //页码切换
      this.currentPage = currentPage;
      console.log("currentPage:" + this.currentPage);
      this.loadData();
      //this.currentChangePage(this.tableData, currentPage);
    },
    //分页方法（重点）这个分页方法是错的 张小东 2023.1.5
    // currentChangePage(list, currentPage) {
    //   let from = (currentPage - 1) * this.pageSize;
    //   let to = currentPage * this.pageSize;
    //   this.tableList = [];
    //   for (; from < to; from++) {
    //     if (list[from]) {
    //       this.tableList.push(list[from]);
    //     }
    //   }
    //   //this.loadData();
    // },
    tableRowClassName({ row, rowIndex }) {
      if (row.online !== "1" || row.state !== "1") {
        return "warning-row";
      }
      return "";
    },

    //跳转到设备详情页
    checkDevice(row) {
      //this.$router.push("/hitIotBase/deviceReg")
      //this.dialogTableVisible = true;
    },
  },
  mounted() {
    // this.currentChangePage(this.tableData, this.currentPage);
    // this.total = this.tableData.length;
  },
};
</script>

<style scoped>
.container {
  width: 100%;
  height: 100%;
}
.header {
  width: calc(100% - 30px);
  margin: 15px 15px 0;
  display: flex;
  justify-content: space-between;
}
.explain {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
  padding: 12px;
  border-radius: 5px;
  margin-bottom: 15px;
}
.service {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
  padding: 12px;
  border-radius: 5px;
}
.asideScrollbar {
  height: 100%;
}
/deep/.el-table .warning-row {
  background: oldlace;
}
</style>
