import axios from "axios";
import baseUrls from "./baseUrlList";

//根据服务名创建axios实例函数
function createAxiosInstance(serviceName) {
  return axios.create({
    baseURL: baseUrls[serviceName],
  });
}

//生成axios实例列表
let getAxiosInstances = () => {
  let axiosInstances = {};
  for (let i in baseUrls) {
    axiosInstances[i] = createAxiosInstance(i);
  }
  return axiosInstances;
};

export default getAxiosInstances;
