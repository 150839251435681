<template>
  <el-container class="container" direction="vertical">
    <el-card class="display">
      <div slot="header" class="clear-fix">
        <span>设备注册</span>
      </div>
      <el-form
        :label-width="formLabelWidth"
        :inline="false"
        label-position="right"
        :model="deviceBaseInfo"
        status-icon
        :rules="deviceInfoRules"
        :ref="deviceBaseInfo"
      >
        <el-row>
          <el-col :span="16">
            <span>设备基本信息</span>
          </el-col>
          <el-col :span="2">
            <el-button
              style="margin-right: 10px; margin-bottom: 10px"
              type="primary"
              @click="save"
              >保存</el-button
            >
          </el-col>
          <el-col :span="2">
            <el-tooltip
              effect="dark"
              :disabled="!modelPropB"
              content="请先新增设备属性"
              placement="top"
            >
              <div>
                <el-button
                  style="margin-right: 10px; margin-bottom: 10px"
                  :disabled="resetDev"
                  @click="resetForm(deviceBaseInfo)"
                  >重置</el-button
                >
              </div>
            </el-tooltip>
          </el-col>
          <el-col :span="3">
            <el-tooltip
              effect="dark"
              :disabled="!bindLand"
              content="请先新增设备属性"
              placement="top"
            >
              <div>
                <el-button
                  style="margin-right: 10px; margin-bottom: 10px"
                  :disabled="bindLand"
                  @click="
                    $router.push({
                      path: 'devicebindReg',
                      query: deviceBaseInfo,
                    })
                  "
                  >绑定地块</el-button
                >
              </div>
            </el-tooltip>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="设备序列号" prop="sn">
              <el-input
                type="text"
                class="input-medium"
                v-model="deviceBaseInfo.sn"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="设备名称" prop="name">
              <el-input
                class="input-medium"
                v-model="deviceBaseInfo.name"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="访问标识" prop="visitFlag">
              <el-input
                class="input-small"
                v-model="deviceBaseInfo.token"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="设备模型" prop="model">
              <el-input class="input-small" v-model="deviceBaseInfo.product">
                <el-button
                  style="padding-right: 10px"
                  slot="suffix"
                  type="text"
                  @click="devModelVisible = true"
                  >选择
                </el-button>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="设备类型" prop="type">
              <el-input class="input-small" v-model="deviceBaseInfo.type">
                <el-button
                  style="padding-right: 10px"
                  slot="suffix"
                  type="text"
                  @click="devTypeVisible = true"
                  >选择
                </el-button>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="经度" prop="longitude">
              <el-input
                class="input-small"
                v-model="deviceBaseInfo.longitude"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="纬度" prop="latitude">
              <el-input
                class="input-small"
                v-model="deviceBaseInfo.latitude"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="负责人" prop="admin">
              <el-input
                class="input-small"
                v-model="deviceBaseInfo.admin"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="维护周期" prop="period">
              <el-select v-model="deviceBaseInfo.period">
                <el-option
                  v-for="item in period"
                  :key="item.value"
                  :label="item.value"
                  :value="item.value"
                  :disabled="item.disabled"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="主机标识" prop="hostFlag">
              <el-select v-model="deviceBaseInfo.hostflag">
                <el-option
                  v-for="item in hostFlag"
                  :key="item.key"
                  :value="item.value"
                  :label="item.key"
                  :disabled="item.disabled"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="宿主机" prop="host">
              <el-input class="input-small" v-model="deviceBaseInfo.host"
                ><el-button
                  style="padding-right: 10px"
                  slot="suffix"
                  type="text"
                  @click="devVisible = true"
                  >选择
                </el-button>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24" style="">
            <el-form-item label="摄像头或特殊属性" label-width="10%">
              <el-input
                v-model="deviceBaseInfo.i_properties"
                :readonly="true"
                style="width: 87%"
              ></el-input>
              <el-button @click="dialogVisible = true">录入</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-card>
    <el-card class="search_condition">
      <el-row :gutter="20" class="el-row" type="flex">
        <el-col :span="12">
          <el-row style="height: 40px">
            <el-col :span="12">
              <span>模型属性</span>
            </el-col>
            <el-col :span="12">
              <el-button-group style="float: right">
                <el-tooltip
                  effect="dark"
                  :disabled="!modelPropB"
                  content="请先新增设备属性"
                  placement="top"
                >
                  <div>
                    <el-button
                      style="margin-right: 10px; margin-bottom: 10px"
                      :disabled="modelPropB"
                      @click="addpm"
                      type="primary"
                      >添加</el-button
                    >
                  </div>
                </el-tooltip>
              </el-button-group>
            </el-col>
          </el-row>
          <el-table
            v-fit-columns
            :data="modelProp"
            stripe
            height="480"
            ref="modelPropTable"
            highlight-current-row
            border
            style="width: 100%; margin-top: 10px; margin-bottom: 5px"
            @selection-change="selectionLineChangeHandle"
          >
            <el-table-column
              fixed
              label="选择"
              type="selection"
            ></el-table-column>
            <el-table-column
              prop="prop_id"
              header-align="left"
              align="left"
              label="属性id"
              :width="flexColumnWidth('prop_id', this.modelProp)"
            ></el-table-column>
            <el-table-column
              prop="prop_name"
              header-align="left"
              align="left"
              label="属性名称"
              :width="flexColumnWidth('prop_name', this.modelProp)"
            >
            </el-table-column>
            <el-table-column
              prop="prop_type"
              header-align="left"
              align="left"
              label="属性类型"
              :width="flexColumnWidth('prop_type', this.modelProp)"
            >
            </el-table-column>
            <el-table-column
              prop="prop_enum_map"
              header-align="left"
              align="left"
              label="映射信息"
              :width="flexColumnWidth('prop_enum_map', this.modelProp)"
            >
            </el-table-column>
            <el-table-column
              prop="prop_desc"
              header-align="center"
              align="left"
              label="属性描述"
              :width="flexColumnWidth('prop_desc', this.modelProp)"
            >
            </el-table-column>
            <el-table-column
              prop="prop_mid"
              header-align="left"
              align="left"
              label="消息id"
              :width="flexColumnWidth('prop_mid', this.modelProp)"
            >
            </el-table-column>
          </el-table>
        </el-col>
        <el-col :span="12">
          <el-row style="height: 40px">
            <el-col :span="16">
              <span>设备属性</span>
            </el-col>
            <el-col :span="8">
              <el-button-group>
                <el-col :span="12">
                  <el-tooltip
                    effect="dark"
                    :disabled="!devPropB"
                    content="请先保存设备注册信息"
                    placement="top"
                  >
                    <div>
                      <el-button
                        style="margin-right: 10px; margin-bottom: 10px"
                        :disabled="devPropB"
                        @click="inputDevProp"
                        type="primary"
                        >新增</el-button
                      >
                    </div>
                  </el-tooltip>
                </el-col>
              </el-button-group>
            </el-col>
          </el-row>
          <el-row>
            <el-table
              :data="devProp"
              stripe
              ref="devPropTable"
              :row-class-name="tableRowClassName"
              highlight-current-row
              border
              height="235px"
              style="width: 100%; margin-top: 10px; margin-bottom: 5px"
              @selection-change="handleSelectionChange"
              @row-click="clickDevProp"
            >
              <el-table-column label="选择" width="50" fixed>
                <template slot-scope="scope">
                  <el-radio
                    :label="scope.$index"
                    v-model="currentRowDP"
                    @change.native="clickDevProp(scope.row)"
                    >&nbsp;
                  </el-radio>
                </template>
              </el-table-column>
              <el-table-column
                prop="i_id"
                label="设备id"
                header-align="center"
                align="center"
                :width="flexColumnWidth('i_id', this.devProp)"
              >
              </el-table-column>
              <el-table-column
                prop="dp_name"
                label="属性名称"
                header-align="center"
                align="center"
                :width="flexColumnWidth('dp_name', this.devProp)"
              >
              </el-table-column>
              <el-table-column
                prop="dp_state"
                label="属性状态"
                header-align="center"
                align="center"
                :width="flexColumnWidth('dp_state', this.devProp)"
              >
              </el-table-column>
              <el-table-column
                prop="dp_date"
                label="更新时间"
                header-align="center"
                align="center"
                :width="flexColumnWidth('dp_date', this.devProp)"
              >
              </el-table-column>
              <el-table-column
                prop="p_id"
                label="模型id"
                header-align="center"
                align="center"
                :width="flexColumnWidth('p_id', this.devProp)"
              >
              </el-table-column>
              <el-table-column
                fixed="right"
                label="操作"
                prop="operation"
                width="150"
                align="center"
              >
                <template slot-scope="props">
                  <el-button
                    @click="editDevProp(props.row)"
                    size="mini"
                    style="font-size: 12px; width: 55px"
                    align="center"
                    type="primary"
                    >编辑</el-button
                  >
                  <el-button
                    @click="delDevProp(props.row)"
                    size="mini"
                    type="danger"
                    style="font-size: 12px; width: 55px"
                    >删除</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
          </el-row>
          <el-row>
            <el-table
              :data="bindprop"
              stripe
              height="230px"
              ref="bindTable"
              highlight-current-row
              border
              style="width: 100%; margin-top: 10px; margin-bottom: 5px"
            >
              <el-table-column
                prop="dp_id"
                label="设备属性id"
                header-align="center"
                align="center"
                :width="flexColumnWidth('dp_id', this.bindprop)"
              >
              </el-table-column>
              <el-table-column
                prop="me_id"
                label="消息id"
                header-align="center"
                align="center"
                :width="flexColumnWidth('me_id', this.bindprop)"
              >
              </el-table-column>
              <el-table-column
                prop="prop_id"
                label="模型属性id"
                header-align="center"
                align="center"
                :width="flexColumnWidth('prop_id', this.bindprop)"
              >
              </el-table-column>
              <el-table-column
                prop="prop_name"
                label="属性名称"
                header-align="center"
                align="center"
                :width="flexColumnWidth('prop_name', this.bindprop)"
              >
              </el-table-column>
              <el-table-column
                prop="prop_enum_map"
                label="模型映射"
                header-align="center"
                align="center"
                :width="flexColumnWidth('prop_name', this.bindprop)"
              >
              </el-table-column>
              <el-table-column
                prop="dppp_operflag"
                label="操作标识"
                header-align="center"
                align="center"
                :width="flexColumnWidth('dppp_operflag', this.bindprop)"
              >
              </el-table-column>
              <el-table-column
                fixed="right"
                label="操作"
                prop="operation"
                width="100"
                align="center"
              >
                <template slot-scope="props">
                  <el-button
                    @click="delDppp(props.row)"
                    size="mini"
                    type="danger"
                    style="font-size: 12px"
                    >删除</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
          </el-row>
        </el-col>
      </el-row>
    </el-card>
    <!--start 设备模型弹框 -->
    <el-dialog :visible.sync="devModelVisible">
      <template slot="title">
        <div style="font-size: 18px">设备模型选择</div>
      </template>
      <el-form ref="form" class="form-inline">
        <el-row>
          <el-col :xs="24" :sm="24" :md="12" :lg="16" :xl="6">
            <el-form-item label="模型名称" :label-width="formLabelWidth">
              <el-input v-model="devModel.p_cname"></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="6">
            <el-form-item>
              <el-button @click="searchProduct" size="medium">查找</el-button>
              <el-button @click="confirmProduct" type="primary" size="medium"
                >确定</el-button
              >
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-table
            v-fit-columns
            :data="modelP"
            ref="singTable"
            highlight-current-row
            border
            style="width: 100%; margin-top: 10px; margin-bottom: 5px"
          >
            <el-table-column label="选择" width="50">
              <template slot-scope="scope">
                <el-radio
                  :label="scope.$index"
                  v-model="currentRowP"
                  @change.native="getCurrentRowP(scope.row)"
                  >&nbsp;
                </el-radio>
              </template>
            </el-table-column>
            <el-table-column prop="pId" label="设备模型id"> </el-table-column>
            <el-table-column prop="pCname" label="模型名称" width="280">
            </el-table-column>
            <el-table-column prop="pEname" label="英文名称" width="280">
            </el-table-column>
            <el-table-column prop="pDataformat" label="数据格式">
            </el-table-column>
            <el-table-column prop="pDesc" label="模型描述" width="80">
            </el-table-column>
            <el-table-column prop="pType" label="模型类型" width="80">
            </el-table-column>
            <el-table-column prop="pProtocol" label="传输协议" width="280">
            </el-table-column>
            <el-table-column prop="ctrlDevice" label="控制类型" width="280">
            </el-table-column>
          </el-table>
          <el-pagination
            background
            @size-change="pageSizeChangeP"
            @current-change="pageCurrentChangeP"
            :current-page="pageformp.pageNum"
            :page-sizes="pageformp.pageSizes"
            :page-size="pageformp.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="pageformp.total"
            style="margin-bottom: 15px"
          >
          </el-pagination>
        </el-row>
      </el-form>
    </el-dialog>
    <!--end 设备模型弹框 -->
    <!--start 设备类型弹框-->
    <el-dialog :visible.sync="devTypeVisible">
      <template slot="title">
        <div style="font-size: 18px">设备类型选择</div>
      </template>
      <el-form ref="form" class="form-inline">
        <el-row>
          <el-col :xs="24" :sm="24" :md="12" :lg="16" :xl="6">
            <el-form-item label="类型名称" :label-width="formLabelWidth">
              <el-input v-model="devType.cname"></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="6">
            <el-form-item>
              <el-button @click="searchType" size="medium">查找</el-button>
              <el-button @click="confirmType" type="primary" size="medium"
                >确定</el-button
              >
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-table
            v-fit-columns
            :data="Type"
            ref="singTable"
            highlight-current-row
            border
            style="width: 100%; margin-top: 10px; margin-bottom: 5px"
          >
            <el-table-column label="选择" width="50">
              <template slot-scope="scope">
                <el-radio
                  :label="scope.$index"
                  v-model="currentRow"
                  @change.native="getCurrentRowT(scope.row)"
                  >&nbsp;
                </el-radio>
              </template>
            </el-table-column>
            <el-table-column prop="cid" label="类型编码" width="80">
            </el-table-column>
            <el-table-column prop="cname" label="中文名称"> </el-table-column>
            <el-table-column prop="ename" label="英文名称"> </el-table-column>
            <el-table-column prop="fid" label="父级编码" width="80">
            </el-table-column>
            <el-table-column prop="cseries" label="级数" width="50">
            </el-table-column>
          </el-table>
          <el-pagination
            background
            @size-change="pageSizeChangeT"
            @current-change="pageCurrentChangeT"
            :current-page="pageformt.pageNum"
            :page-sizes="pageformt.pageSizes"
            :page-size="pageformt.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="pageformt.total"
            style="margin-bottom: 15px"
          >
          </el-pagination>
        </el-row>
      </el-form>
    </el-dialog>
    <!--end 设备类型弹框 -->
    <!--start 主机弹框-->
    <el-dialog :visible.sync="devVisible">
      <template slot="title">
        <div style="font-size: 18px">主设备选择</div>
      </template>
      <el-form ref="form" class="form-inline">
        <el-row>
          <el-col :xs="24" :sm="24" :md="12" :lg="16" :xl="6">
            <el-form-item label="设备名称" :label-width="formLabelWidth">
              <el-input v-model="devCondition.name"></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="6">
            <el-form-item>
              <el-button @click="searchDevh" size="medium">查找</el-button>
              <el-button @click="confirmDevh" type="primary" size="medium"
                >确定</el-button
              >
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-table
            v-fit-columns
            :data="Devs"
            ref="singTable"
            highlight-current-row
            border
            style="width: 100%; margin-top: 10px; margin-bottom: 5px"
          >
            <el-table-column label="选择" width="50">
              <template slot-scope="scope">
                <el-radio
                  :label="scope.$index"
                  v-model="currentRowT"
                  @change.native="getCurrentRowD(scope.row)"
                  >&nbsp;
                </el-radio>
              </template>
            </el-table-column>
            <el-table-column prop="id" label="设备id"> </el-table-column>
            <el-table-column prop="sn" label="设备sn" width="280">
            </el-table-column>
            <el-table-column prop="token" label="访问标识"> </el-table-column>
            <el-table-column prop="name" label="设备名称" width="80">
            </el-table-column>
          </el-table>
          <el-pagination
            background
            @size-change="pageSizeChangeD"
            @current-change="pageCurrentChangeD"
            :current-page="pageformd.pageNum"
            :page-sizes="pageformd.pageSizes"
            :page-size="pageformd.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="pageformd.total"
            style="margin-bottom: 15px"
          >
          </el-pagination>
        </el-row>
      </el-form>
    </el-dialog>
    <!--end 主机型弹框 -->
    <!--start 设备属性录入弹框-->
    <el-dialog :visible.sync="devPropInputVisible">
      <template slot="title">
        <div style="font-size: 18px">设备属性</div>
      </template>
      <el-form
        ref="devPropInputform"
        class="form-inline"
        style="border-radius: 4px"
      >
        <el-row gutter="20" style="margin-bottom: 10px">
          <el-col :xs="14" :sm="14" :md="14" :lg="14" :xl="14"> </el-col>
        </el-row>
        <el-row :gutter="20" style="width: 100%">
          <el-col :xs="14" :sm="14" :md="14" :lg="14" :xl="14">
            <el-form-item label="设备属性" :label-width="formLabelWidth">
              <el-input
                v-model="devPropInput.dp_name"
                style="width: 100%"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="10" :sm="10" :md="10" :lg="10" :xl="10">
            <el-form-item label="设备状态" :label-width="formLabelWidth">
              <el-select
                v-model="devPropInput.dp_state"
                placeholder="请选择"
                style="width: 100%"
              >
                <el-option
                  v-for="item in dp_state"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="10" :offset="14">
            <el-button
              @click="saveDevProp"
              type="primary"
              size="medium"
              style="float: right"
              >确定</el-button
            >
          </el-col>
        </el-row>
      </el-form>
    </el-dialog>
    <!--end 设备属性录入弹框 -->
    <!-- start设备特殊属性：摄像头 -->
    <el-dialog :visible.sync="dialogVisible">
      <template slot="title">
        <div style="font-size: 18px">摄像头及特殊属性录入</div>
      </template>
      <el-form ref="form" class="form-inline">
        <el-row>
          <el-col :xs="8" :sm="8" :md="8" :lg="8" :xl="8">
            <el-form-item label="属性key" :label-width="LabelWidth">
              <el-input v-model="propertyForm.pkey"></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="8" :sm="8" :md="8" :lg="8" :xl="8">
            <el-form-item label="属性值" :label-width="LabelWidth">
              <el-input v-model="propertyForm.pvalue"></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="8" :sm="8" :md="8" :lg="8" :xl="8">
            <el-form-item>
              <el-button @click="addProperties" size="medium">添加</el-button>
              <el-button @click="pptconfirm" type="primary" size="medium"
                >保存</el-button
              >
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col>
            <el-form-item>
              <el-table :data="propertyForm.ppties" style="width: 100%">
                <el-table-column fixed prop="No" label="序号">
                </el-table-column>
                <el-table-column prop="pkey" label="属性key"> </el-table-column>
                <el-table-column prop="pvalue" label="属性值">
                </el-table-column>
                <el-table-column fixed="right" label="操作">
                  <template slot-scope="scope">
                    <el-button
                      @click.native.prevent="
                        deleteRow(scope.$index, propertyForm.ppties)
                      "
                      type="text"
                      size="small"
                      >移除
                    </el-button>
                  </template>
                </el-table-column>
              </el-table>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-dialog>
  </el-container>
</template>
<script>
export default {
  components: {},
  name: "deviceReg",
  data() {
    return {
      propertyForm: {
        pkey: "",
        pvalue: "",
        ppties: [],
      },
      //公共变量
      clickDevFlag: false, //点击选中某行标志：设备属性使用
      devPropB: true, //设备属性新增按钮
      modelPropB: true, //模型属性与设备属性关联添加按钮
      bindLand: true, //编到这了，让它跳转相应
      resetDev: true,
      dp_state: [
        {
          value: 0,
          label: "关",
        },
        {
          value: 1,
          label: "开",
        },
      ],
      highlightRows: new Set(),
      LabelWidth: "80px",
      formLabelWidth: "120px",
      modify_title: "管控区域录入",
      placeholder: {},
      currentRowP: 0,
      currentRowT: 0,
      currentRow: 0,
      currentRowDP: 1,
      //区域信息分页
      ctrlId: 0,
      devPropflag: 0, //设备属性新增与修改标识，0-为新增，1-为修改
      devPropInput: {
        //存入数据库的设备模型属性
        dp_id: -1,
        i_id: -1,
        p_id: -1,
        dp_name: "",
        dp_state: "",
      },
      modelProp: [], //模型属性
      devProp: [], // //从数据库中取出的设备模型属性
      bindprop: [],
      //设备模型
      pageformp: {
        total: 20,
        pages: 5,
        pageNum: 1,
        pageSizes: [2, 5, 10, 15, 20],
        pageSize: 5,
        navigateFirstPage: 1,
        navigateLastPage: 3,
        lastPage: false,
        firstPage: false,
        hasNextPage: true,
        hasPreviousPage: true,
      },
      //设备类型
      pageformt: {
        total: 20,
        pages: 5,
        pageNum: 1,
        pageSizes: [2, 5, 10, 15, 20],
        pageSize: 5,
        navigateFirstPage: 1,
        navigateLastPage: 3,
        lastPage: false,
        firstPage: false,
        hasNextPage: true,
        hasPreviousPage: true,
      },
      //设备
      pageformd: {
        total: 20,
        pages: 5,
        pageNum: 1,
        pageSizes: [2, 5, 10, 15, 20],
        pageSize: 5,
        navigateFirstPage: 1,
        navigateLastPage: 3,
        lastPage: false,
        firstPage: false,
        hasNextPage: true,
        hasPreviousPage: true,
      },
      devModel: {
        pageNum: "1",
        pageSize: "5",
        p_id: "",
        p_cname: "",
        p_dataformat: "",
        p_ename: "",
        p_type: "",
        p_protocol: "",
        ctrl_device: "",
      },
      devType: {
        pageNum: 1,
        pageSize: 5,
        cid: "",
        ename: "",
        cname: "",
        fid: "",
        cseries: -1,
      },
      //设备查询条件
      devCondition: {
        pageNum: 1,
        pageSize: 5,
        id: -1,
        sn: "",
        token: "",
        name: "",
        type: "",
        longitude: "",
        latitude: "",
        region: "", //暂时不用
        mperiod: "",
        admin: "",
        online: -1,
        datelow: "",
        dateup: "",
        state: "",
        hostflag: "1",
        host: "",
        product: -1,
      },
      Devs: [],
      DeviceInfo: {
        sn: "",
        token: "",
        name: "",
        type: "",
        product: "",
      },
      modelP: [],
      Type: [],
      period: [
        {
          value: "一周",
          disabled: false,
        },
        {
          value: "一月",
          disabled: false,
        },
        {
          value: "一年",
          disabled: false,
        },
        {
          value: "两年",
          disabled: false,
        },
      ],
      hostFlag: [
        {
          key: "是",
          value: 1,
          disabled: false,
        },
        {
          key: "否",
          value: 0,
          disabled: false,
        },
      ],
      host: [],
      modelPropC: {
        //未绑定设备的模型控制属性查询条件
        prop_id: -1,
        prop_product: -1,
        prop_type1: 4,
        prop_type2: 0,
      },
      devPropC: {
        //设备属性查询条件
        pageNum: 1,
        pageSize: 100,
        i_id: -1,
        p_id: -1,
        dp_id: -1,
      },
      deviceBaseInfo: {
        id: -1, //返回值
        sn: "",
        token: "",
        name: "",
        type: "",
        longitude: "",
        latitude: "",
        region: "", //暂时不用
        mperiod: "一年",
        host: "0",
        hostflag: "",
        product: "",
        state: "",
        online: -1,
        date: "",
        admin: "",
        properties: {},
        i_properties: "",
      },
      dppp: [], //模型属性与设备属性关系存储
      dpppC: {
        //模型属性与设备属性关系查询
        pageNum: 1,
        pageSize: 100,
        dp_id: -1,
        prop_id: -1,
      },
      modelTp: [],
      modelTpid: [],
      deviceInfoRules: {
        sn: [{ required: false, message: "请输入设备序列号", trigger: "blur" }],
        name: [{ required: true, message: "请输入设备名称", trigger: "blur" }],
        token: [
          { required: false, message: "请输入访问标识", trigger: "blur" },
        ],
        product: [
          { required: false, message: "请选择设备模型", trigger: "blur" },
        ],
        type: [{ required: false, message: "请选择设备模型", trigger: "blur" }],
        admin: [
          { required: false, message: "请选择设备模型", trigger: "blur" },
        ],
        mperiod: [
          { required: false, message: "请选择设备模型", trigger: "blur" },
        ],
        hostFlag: [
          { required: false, message: "请选择设备模型", trigger: "blur" },
        ],
        host: [{ required: false, message: "请选择设备模型", trigger: "blur" }],
      },
      devModelVisible: false,
      devTypeVisible: false,
      devVisible: false,
      devPropInputVisible: false,
      dialogVisible: false,
    };
  },
  methods: {
    getPProperty() {
      //得到模型属性
      this.modelPropC.prop_product = this.devModel.p_id;
      this.modelPropC.i_id = this.deviceBaseInfo.id;
      this.$api.apiHitIotRun.getCtlProperties(this.modelPropC).then((res) => {
        console.log(res);
        if (res.data.code === "1") {
          this.modelProp = res.data.data.propertiesList;
        }
      });
    },
    delDppp(row) {
      this.dpppC.dppp_id = row.dppp_id;
      this.dpppC.dp_id = -1;
      let dpid = row.dp_id;
      this.$api.apiHitIotRun.delDevPppp(this.dpppC).then((res) => {
        console.log(res);
        if (res.data.code === "1") {
          this.$message({
            message: "设备属性与模型属性关系信息删除成功！",
            type: "success",
          });
          this.dpppC.dp_id = dpid;
          this.dpppC.dppp_id = -1;
          this.$api.apiHitIotRun.getDevPppp(this.dpppC).then((res) => {
            console.log(res);
            if (res.data.code === "1") {
              this.bindprop = res.data.data;
            } else {
              this.$message({
                message: "设备属性与模型属性关系信息查询失败！",
                type: "warning",
              });
            }
          });
          this.getPProperty();
        } else {
          this.$message({
            message: "设备属性与模型属性关系信息查询失败！",
            type: "warning",
          });
        }
      });
    },
    delDevProp(row) {
      this.devPropInput.i_id = this.deviceBaseInfo.id;
      this.devPropInput.dp_id = row.dp_id;
      this.$api.apiHitIotRun.deleteDevProp(this.devPropInput).then((res) => {
        if (res.data.code === "1") {
          this.$message({
            message: "设备属性信息删除成功！",
            type: "success",
          });
          this.devPropInput.p_id = -1;
          this.devPropInput.dp_name = "";
          this.devPropC.i_id = this.devPropInput.i_id;
          this.$api.apiHitIotRun.getDevProp(this.devPropC).then((res) => {
            console.log(res);
            if (res.data.code === "1") {
              this.devProp = res.data.data;
              this.currentRowDP = 1;
            } else {
              this.$message({
                message: "请先保存设备信息！",
                type: "warning",
              });
            }
          });
          this.dpppC.dp_id = this.devPropInput.dp_id;
          this.dpppC.dppp_id = -1;
          this.$api.apiHitIotRun.getDevPppp(this.dpppC).then((res) => {
            console.log(res);
            if (res.data.code === "1") {
              this.bindprop = res.data.data;
            } else {
              this.$message({
                message: "设备属性与模型属性关系信息查询失败！",
                type: "warning",
              });
            }
          });
          this.getPProperty();
        } else {
          this.$message({
            message: "设备属性信息查询失败！",
            type: "warning",
          });
        }
      });
    },
    handleSelectionChange(row) {
      //暂时没有用
      // this.clickDevProp(row);
    },
    tableRowClassName({ row, rowIndex }) {
      row.row_index = rowIndex;
    },
    clickDevProp(row, even, column) {
      row.clickDevFlag = !row.clickDevFlag;
      this.$refs.devPropTable.toggleRowSelection(row, row.clickDevFlag);
      this.currentRowDP = row.row_index;
      this.dpppC.dp_id = row.dp_id;
      //this.deviceBaseInfo.id = row.i_id; //这个一会删除，因为设备录入不会有不同设备的
      this.dpppC.dppp_id = -1;
      this.$api.apiHitIotRun.getDevPppp(this.dpppC).then((res) => {
        console.log(res);
        if (res.data.code === "1") {
          this.bindprop = res.data.data;
        } else {
          this.$message({
            message: "设备属性与模型属性关系信息查询失败！",
            type: "warning",
          });
        }
      });
    },
    editDevProp(devPropRow) {
      this.devPropflag = 1;
      this.devPropInput.dp_id = devPropRow.dp_id;
      this.devPropInput.dp_name = devPropRow.dp_name;
      this.devPropInput.dp_state = devPropRow.dp_state;
      this.devPropInputVisible = true;
    },
    saveDevProp() {
      //批量提交关联数据      //获得设备id      //devPropInput//录入设备属性的数据结构
      //devProp//从数据库中取得设备属性的数据结构
      //this.deviceBaseInfo.id = 1; //这个在保存后，取得，此处为暂时设置
      if (this.deviceBaseInfo.id == -1) {
        this.$message({
          message: "请先保存设备信息！",
          type: "warning",
        });
        return;
      }
      if (
        this.deviceBaseInfo.product == "" ||
        this.deviceBaseInfo.product == null
      ) {
        this.$message({
          message: "请先保存设备信息！",
          type: "warning",
        });
        return;
      }
      this.devPropInput.i_id = this.deviceBaseInfo.id;
      this.devPropInput.p_id = this.deviceBaseInfo.product; ////获模型id
      console.log(this.devPropInput);
      if (this.devPropflag == 1) {
        this.$api.apiHitIotRun.updateDevProp(this.devPropInput).then((res) => {
          console.log(res);
          if (res.data.code === "1") {
            this.dpppC.dp_id = res.data.data.dp_id;
            this.$message({
              message: "保存设备属性信息成功！",
              type: "success",
            });
            //this.devPropInput.i_id = -1;
            this.devPropInput.p_id = -1;
            this.devPropInput.dp_name = "";
            this.devPropC.i_id = this.devPropInput.i_id;
            this.$api.apiHitIotRun.getDevProp(this.devPropC).then((res) => {
              console.log(res);
              if (res.data.code === "1") {
                this.devProp = res.data.data;
                let i = 0;
                let ind = 0;
                this.devProp.forEach((item) => {
                  if (item.dp_id != this.dpppC.dp_id) i++;
                  else ind = i;
                });
                this.currentRowDP = ind;
                this.modelPropB = false;
              } else {
                this.$message({
                  message: "请先保存设备信息！",
                  type: "warning",
                });
              }
            });
          } else {
            this.$message({
              message: "设备属性信息查询失败！",
              type: "warning",
            });
          }
        });
      } else {
        this.$api.apiHitIotRun.saveDevPropN(this.devPropInput).then((res) => {
          console.log(res);
          if (res.data.code === "1") {
            this.dpppC.dp_id = res.data.data.dp_id;
            this.$message({
              message: "保存设备属性信息成功！",
              type: "success",
            });
            //this.devPropInput.i_id = -1;
            this.devPropInput.p_id = -1;
            this.devPropInput.dp_name = "";
            this.devPropC.i_id = this.devPropInput.i_id;
            this.$api.apiHitIotRun.getDevProp(this.devPropC).then((res) => {
              console.log(res);
              if (res.data.code === "1") {
                this.devProp = res.data.data;
                let i = 0;
                let ind = 0;
                this.devProp.forEach((item) => {
                  if (item.dp_id != this.dpppC.dp_id) i++;
                  else ind = i;
                });
                this.currentRowDP = ind; //最好是能滚动到那一行
                this.modelPropB = false;
                this.dpppC.dppp_id = -1;
                this.$api.apiHitIotRun.getDevPppp(this.dpppC).then((res) => {
                  console.log(res);
                  if (res.data.code === "1") {
                    this.bindprop = res.data.data;
                  } else {
                    this.$message({
                      message: "设备属性与模型属性关系信息查询失败！",
                      type: "warning",
                    });
                  }
                });
              } else {
                this.$message({
                  message: "请先保存设备信息！",
                  type: "warning",
                });
              }
            });
          } else {
            this.$message({
              message: "设备属性信息查询失败！",
              type: "warning",
            });
          }
        });
      }
      this.devPropInputVisible = false; //隐藏输入窗口
      this.modelPropB = false; //可使用模型属性按钮
    },
    selectionLineChangeHandle(selection, row) {
      this.modelTp = selection;
      this.modelTpid = selection.map((item) => item.id);
    },
    addpm() {
      //添加模型属性与设备属性关系
      //this.devPropB = false;
      console.log(this.modelTp);
      if (this.dpppC.dp_id == -1 || this.devProp.length == 0) {
        this.$message({
          message: "请先增加设备属性！",
          type: "warning",
        });
      }
      let flag;
      this.dppp = []; //去掉重复
      for (let i = 0; i < this.modelTp.length; i++) {
        flag = 1;
        this.bindprop.forEach((item) => {
          if (item.prop_id == this.modelTp[i].prop_id) {
            flag = 0;
          }
        });
        if (flag) {
          let dp1 = {
            dp_id: this.dpppC.dp_id,
            me_id: this.modelTp[i].prop_mid,
            prop_id: this.modelTp[i].prop_id,
            prop_name: this.modelTp[i].prop_name,
            prop_enum_map: this.modelTp[i].prop_enum_map,
            dppp_operflag: this.modelTp[i].prop_type,
          };
          console.log("dfdfd01:" + dp1.me_id + "；dp_id:" + dp1.dp_id);
          this.dppp.push(dp1);
        }
      }
      if (this.dppp.length > 0) {
        //
        this.$api.apiHitIotRun.saveDevPppp(this.dppp).then((res) => {
          console.log(res);
          if (res.data.code === "1") {
            this.$message({
              message: "保存设备属性与模型属性关系信息成功！",
              type: "success",
            });
            this.dpppC.dppp_id = -1;
            this.$api.apiHitIotRun.getDevPppp(this.dpppC).then((res) => {
              console.log(res);
              if (res.data.code === "1") {
                this.bindprop = res.data.data;
              } else {
                this.$message({
                  message: "设备属性与模型属性关系信息查询失败！",
                  type: "warning",
                });
              }
            });
            this.getPProperty();
          } else {
            this.$message({
              message: "设备属性与模型属性关系信息保存失败！",
              type: "warning",
            });
          }
        });
      }
      console.log(this.dppp);
    },
    confirmProduct() {
      if (this.deviceBaseInfo.product != this.devModel.p_id) {
        if (this.devProp.length > 0) {
          this.$message({
            message: "请先删除设备属性，再变换模型！",
            type: "warning",
          });
          return;
        }
      }
      //有用
      this.deviceBaseInfo.product = this.devModel.p_id;
      //属性查询条件
      this.modelPropC.prop_product = this.devModel.p_id;
      this.modelPropC.i_id = this.deviceBaseInfo.id;
      this.$api.apiHitIotRun.getCtlProperties(this.modelPropC).then((res) => {
        console.log(res);
        if (res.data.code === "1") {
          this.modelProp = res.data.data.propertiesList;
        }
      });
      this.devModelVisible = false;
    },
    searchProduct() {
      //查找设备模型
      this.devModel.p_id = "";
      console.log(this.devModel);
      this.$api.apiHitIotLocation
        .getModelP(
          this.pageformp.pageSize,
          this.pageformp.pageNum,
          this.devModel
        )
        .then((res) => {
          console.log(res);
          if (res.data.code === "1") {
            let dataTmp = res.data;
            this.modelP = dataTmp.data.productList;
            this.devModel.p_id = this.modelP[0].pId;
            this.pageformp.total = dataTmp.data.totalCount;
            this.pageformp.pages = dataTmp.data.totalPages;
          } else {
            this.$message({
              message: "查询失败！",
              type: "info",
            });
          }
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
    searchType() {
      //查找设备类型
      this.devType.pageNum = this.pageformt.pageNum;
      this.devType.pageSize = this.pageformt.pageSize;
      this.devType.cid = "";
      this.$api.apiHitIotLocation
        .getNationalS(this.devType)
        .then((res) => {
          console.log(res);
          if (res.data.code === "1") {
            this.Type = res.data.data;
            this.devType.cid = this.Type[0].cid;
            this.pageformt.total = res.data.total;
            this.pageformt.pages = res.data.pages;
          } else {
            this.$message({
              message: "查询失败！",
              type: "info",
            });
          }
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
    confirmType() {
      this.deviceBaseInfo.type = this.devType.cid;
      this.devTypeVisible = false;
    },
    searchDevh() {
      //查找设备
      this.devCondition.pageNum = this.pageformd.pageNum;
      this.devCondition.pageSize = this.pageformd.pageSize;
      this.devCondition.id = -1;
      this.$api.apiHitIotLocation
        .getHost(this.devCondition)
        .then((res) => {
          console.log(res);
          if (res.data.code === "1") {
            let dataTmp = res.data;
            this.Devs = dataTmp.data;
            this.devCondition.id = this.Devs[0].id;
            this.devCondition.name = this.Devs[0].name;
            this.pageformd.pages = dataTmp.pages;
            this.pageformd.total = dataTmp.total;
          } else {
            this.$message({
              message: "查询失败！",
              type: "info",
            });
          }
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
    confirmDevh() {
      this.deviceBaseInfo.host =
        this.devCondition.name + "||" + this.devCondition.id;
      this.devVisible = false;
    },
    save() {
      this.deviceBaseInfo.host = this.deviceBaseInfo.host.split("||")[1];
      //在这里进行最终校验和提交表单，表单数据在this.deviceBaseInfo中
      console.log(this.deviceBaseInfo);
      this.$api.apiHitIotLocation
        .saveDev(this.deviceBaseInfo)
        .then((res) => {
          console.log(res);
          if (res.data.code === "1") {
            let dataTmp = res.data.data.token.split("#");
            console.log(dataTmp);
            this.deviceBaseInfo.id = dataTmp[1];
            this.deviceBaseInfo.token = dataTmp[0];
            this.$message({
              message: "保存成功！",
              type: "success",
            });
            this.devPropB = false; //设备属性新增按钮
            this.bindLand = false;
            this.resetDev = false;
          } else {
            this.$message({
              message: "查询失败！",
              type: "info",
            });
          }
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
    resetForm(deviceBaseInfo) {
      //在这里实现表单重置功能
      this.$refs[deviceBaseInfo].resetFields();
      this.deviceBaseInfo.i_properties = "";
      this.propertyForm.ppties = "";
    },

    getCurrentRow(row) {
      //设备选择
      console.log(row);
      this.ctrlId = row.ctl_id;
    },
    getCurrentRowP(row) {
      console.log(row);
      this.devModel.p_id = row.pId;
    },
    getCurrentRowT(row) {
      console.log(row); //可以给查询条件赋值 devType
      this.devType.cid = row.cid;
    },
    getCurrentRowD(row) {
      console.log(row); //可以给查询条件赋值
      this.devCondition.name = row.name;
      this.devCondition.id = row.id;
    },
    pageSizeChangeT(pageSize) {
      //设备类型当每页的数量/尺寸改变后，重新向后台发送信息  searchType// 每页条数切换调用   landsearch()
      this.pageformt.pageSize = pageSize;
      this.searchType();
    },
    pageCurrentChangeT(currentPage) {
      //设备类型当前页面切换, //页码切换 调用landsearch()
      this.pageformt.pageNum = currentPage;
      this.searchType();
    },
    pageSizeChangeP(pageSize) {
      //主机当每页的数量/尺寸改变后，重新向后台发送信息  searchType// 每页条数切换调用   landsearch()
      this.pageformp.pageSize = pageSize;
      this.searchProduct();
    },
    pageCurrentChangeP(currentPage) {
      //主机当前页面切换, //页码切换 调用landsearch()
      this.pageformp.pageNum = currentPage;
      this.searchProduct();
    },
    pageSizeChangeD(pageSize) {
      //主机当每页的数量/尺寸改变后，重新向后台发送信息  searchType// 每页条数切换调用   landsearch()
      this.pageformd.pageSize = pageSize;
      this.searchDevh();
    },
    pageCurrentChangeD(currentPage) {
      //主机当前页面切换, //页码切换 调用landsearch()
      this.pageformd.pageNum = currentPage;
      this.searchDevh();
    },
    // 自适应表格列宽
    flexColumnWidth(str, tableData, flag = "max") {
      // str为该列的字段名(传字符串);tableData为该表格的数据源(传变量);
      // flag为可选值，可不传该参数,传参时可选'max'或'equal',默认为'max'
      // flag为'max'则设置列宽适配该列中最长的内容,flag为'equal'则设置列宽适配该列中第一行内容的长度。
      let columnContent = "";
      if (
        !tableData ||
        !tableData.length ||
        tableData.length === 0 ||
        tableData === undefined
      ) {
        return;
      }
      if (!str || !str.length || str.length === 0 || str === undefined) {
        return;
      }
      if (flag === "equal") {
        // 获取该列中第一个不为空的数据(内容)
        for (let i = 0; i < tableData.length; i++) {
          if (tableData[i][str].length > 0) {
            columnContent = tableData[i][str];
            break;
          }
        }
      } else {
        // 获取该列中最长的数据(内容)
        let index = 0;
        for (let i = 0; i < tableData.length; i++) {
          if (tableData[i][str] === null) {
            return;
          }
          const now_temp = tableData[i][str];
          const max_temp = tableData[index][str];
          if (now_temp.length >= max_temp.length) {
            index = i;
          }
        }
        columnContent = tableData[index][str].toString();
      }
      // 以下分配的单位长度可根据实际需求进行调整
      let flexWidth = 0;
      for (const char of columnContent) {
        if ((char >= "A" && char <= "Z") || (char >= "a" && char <= "z")) {
          // 如果是英文字符，为字符分配8个单位宽度
          flexWidth += 8;
        } else if (char >= "\u4e00" && char <= "\u9fa5") {
          // 如果是中文字符，为字符分配18个单位宽度
          flexWidth += 18;
        } else {
          // 其他种类字符，为字符分配15个单位宽度
          flexWidth += 15;
        }
      }
      if (flexWidth < 120) {
        // 设置最小宽度
        flexWidth = 120;
      }
      return flexWidth;
    },
    inputDevProp: function () {
      this.devPropInputVisible = true;
      this.devPropflag = 0; //设置标志位为新增
    },
    addProperties() {
      this.propertyForm.ppties.push({
        No: this.propertyForm.ppties.length, // + 1,
        pkey: this.propertyForm.pkey,
        pvalue: this.propertyForm.pvalue,
      });
      this.propertyForm.pkey = "";
      this.propertyForm.pvalue = "";
    },
    deleteRow(index, rows) {
      rows.splice(index, 1);
      this.propertyForm.ppties.remove(index);
    },
    pptconfirm() {
      let loc = '{"';
      let count = 0;
      for (let vulue of this.propertyForm.ppties) {
        loc += vulue.pkey + '":"' + vulue.pvalue + '","';
        count++;
      }
      if (count > 0) {
        this.deviceBaseInfo.i_properties = loc + 'iscamera":"1"}'; //loc.substr(0, loc.length - 2) + "}";
      }
      this.dialogVisible = false;
    },
  },
};
</script>
<style scoped>
.el-card {
  width: 60%;
  margin-left: 10%;
}
.el-input {
  width: 80%;
}
.display {
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  width: 95%;
  margin: 10px auto;
  border-radius: 5px;
}

.el-collapse {
  border-bottom: unset;
}
.search_condition {
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  width: 95%;
  margin: 5px auto;
  border-radius: 5px;
  text-align: left;
}
.el-table__header col[name="gutter"] {
  display: table-cell !important;
}

.el-table >>> .highlight {
  background: #ecf5ff;
}

.el-collapse-item >>> .el-collapse-item__header {
  justify-content: center;
  font-size: 18px;
}
.search_condition
  >>> .el-collapse-item
  .el-collapse-item__header
  .el-collapse-item__arrow {
  margin: 0;
}
</style>
