<template>
  <div>
    <el-card class="box-card">
      <div slot="header" class="clear-fix">
        <span>设备查询</span>
      </div>
      <el-form label-position="right" :model="searchKeys">
        <el-row>
          <el-col :span="12">
            <span>设备基本信息</span>
          </el-col>
          <el-col :span="8">
            <el-button-group style="float: right">
              <el-button
                style="margin-right: 10px; margin-bottom: 10px"
                @click="search"
                >查询</el-button
              >
              <el-button
                style="margin-right: 10px; margin-bottom: 10px"
                @click="resetForm(searchKeys)"
                >重置</el-button
              >
            </el-button-group>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="设备序列号" prop="sn">
              <el-input
                type="text"
                class="input-medium"
                v-model="searchKeys.sn"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="设备名称" prop="name">
              <el-input
                class="input-medium"
                v-model="searchKeys.name"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="设备类型" prop="type">
              <el-select v-model="searchKeys.type">
                <el-option></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="设备模型" prop="model">
              <el-select v-model="searchKeys.model">
                <el-option></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="访问标识" prop="visitFlag">
              <el-input
                class="input-small"
                v-model="searchKeys.visitFlag"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="工作状态" prop="status">
              <el-select v-model="searchKeys.status">
                <el-option
                  v-for="item in status"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                  :disabled="item.disabled"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="在线" prop="online">
              <el-input
                class="input-small"
                v-model="searchKeys.online"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="负责人" prop="admin">
              <el-input
                class="input-small"
                v-model="searchKeys.admin"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="维护周期" prop="period">
              <el-select v-model="searchKeys.period">
                <el-option
                  v-for="item in period"
                  :key="item.value"
                  :label="item.value"
                  :value="item.value"
                  :disabled="item.disabled"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="纬度" prop="lat">
              <el-input v-model="searchKeys.lat"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="经度" prop="lng">
              <el-input v-model="searchKeys.lng"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="选择行政区">
              <v-distpicker
                :province="searchKeys.province"
                :city="searchKeys.city"
                :area="searchKeys.area"
              ></v-distpicker>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="街道">
              <el-input v-model="searchKeys.street"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-card>
  </div>
</template>
<script>
import VDistpicker from "v-distpicker";
export default {
  components: {
    VDistpicker,
  },
  name: "deviceSearch",
  data() {
    return {
      status: [
        {
          value: "0",
          label: "未注册",
          disabled: false,
        },
        {
          value: "1",
          label: "已注册",
          disabled: false,
        },
        {
          value: "2",
          label: "异常",
          disabled: false,
        },
        {
          value: "3",
          label: "报废",
          disabled: false,
        },
        {
          value: "4",
          label: "已注销",
          disabled: false,
        },
      ],
      period: [
        {
          value: "一周",
          disabled: false,
        },
        {
          value: "一月",
          disabled: false,
        },
        {
          value: "一年",
          disabled: false,
        },
        {
          value: "两年",
          disabled: false,
        },
      ],
      searchKeys: {
        sn: "",
        name: "",
        type: "",
        model: "",
        visitFlag: "",
        status: "",
        online: "",
        admin: "",
        period: "",
        lng: "",
        lat: "",
        province: "",
        city: "",
        area: "",
        street: "",
      },
    };
  },
  methods: {
    search() {
      //在这里进行最终校验和提交表单，表单数据在this.searchKeys中
    },
    resetForm(searchKeys) {
      //在这里实现表单重置功能
      const emptySearchKeys = {
        sn: "",
        name: "",
        type: "",
        model: "",
        visitFlag: "",
        status: "",
        online: "",
        admin: "",
        period: "",
        lng: "",
        lat: "",
        province: "",
        city: "",
        area: "",
        street: "",
      };
      this.searchKeys = emptySearchKeys;
    },
  },
};
</script>
<style scoped>
.el-card {
  width: 60%;
  margin-left: 20%;
}
.el-input {
  width: 80%;
}
.el-input {
  width: 80%;
}
</style>
