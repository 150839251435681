<template>
  <el-container class="container">
    <el-header class="header">
      <div>
        <el-link
          @click="backClassification"
          style="font-size: 16px; font-weight: bold"
          type="info"
          :underline="false"
          >产品</el-link
        >
        <el-divider direction="vertical"></el-divider>
        <span>{{ service.name }}</span>
      </div>
    </el-header>
    <el-main>
      <el-scrollbar
        class="asideScrollbar"
        :wrap-style="[{ 'overflow-x': 'hidden' }]"
      >
        <div style="margin: 0 15px 0">
          <!--          这里套一层margin是为了使el-scrollbar往右边偏一点，不至于紧贴着元素-->
          <div class="desc">
            <div>
              <span style="font-weight: bold; font-size: 20px">{{
                service.name
              }}</span>
              <span style="margin-left: 10px; color: #909399">ID：</span>
              <span style="color: #909399">{{ service.id }}</span>
              <el-divider direction="vertical"></el-divider>
              <span style="margin-left: 10px; color: #909399"
                >注册设备数：</span
              >
              <span style="color: #909399"> {{ total }} </span>
            </div>
            <el-divider></el-divider>
            <div class="description">
              <el-descriptions
                class="margin-top"
                :column="3"
                :label-style="{ 'font-size': '16px', color: '#909399' }"
                :content-style="{ 'font-size': '16px' }"
              >
                <el-descriptions-item label="产品名称">{{
                  service.name
                }}</el-descriptions-item>
                <el-descriptions-item label="产品id">{{
                  service.id
                }}</el-descriptions-item>
                <el-descriptions-item label="协议类型">
                  {{ service.protocol }}
                </el-descriptions-item>
                <el-descriptions-item label="数据格式">{{
                  service.format
                }}</el-descriptions-item>
                <el-descriptions-item label="业务描述">{{
                  service.desc
                }}</el-descriptions-item>
              </el-descriptions>
            </div>
          </div>
          <div class="def">
            <el-tabs v-model="activeName">
              <el-tab-pane label="模型定义" name="first">
                <div class="model-def">
                  <modelAttribute :product-id="service.id"></modelAttribute>
                  <el-divider></el-divider>
                  <modelCommand :product-id="service.id"></modelCommand>
                </div>
              </el-tab-pane>
              <el-tab-pane label="消息解编码定义" name="second"
                ><messageCoding :product-id="service.id"></messageCoding
              ></el-tab-pane>

              <el-tab-pane label="消息-属性绑定关系定义" name="third">
                <MessageParamBinding
                  :product-id="service.id"
                ></MessageParamBinding>
              </el-tab-pane>
            </el-tabs>
          </div>
        </div>
      </el-scrollbar>
    </el-main>
  </el-container>
</template>

<script>
import modelAttribute from "@/components/hitlotClassification/modelAttribute";
import modelCommand from "@/components/hitlotClassification/modelCommand";
import messageCoding from "@/components/hitlotClassification/messageCoding";
import MessageParamBinding from "../../components/hitlotClassification/MessageParamBinding";
export default {
  name: "serviceDefine",
  data() {
    return {
      name: this.$route.params.service,
      total: 5,
      service: {
        name: "智慧农业熵情站",
        id: "1",
        format: "二进制码流",
        protocol: "HTTP",
        desc: "智慧农业熵情站，好厉害！",
      },
      activeName: "first",
    };
  },
  created() {
    this.service.id = this.$route.params.service;
    this.loadData();
  },
  methods: {
    backClassification() {
      this.$router.push("/hitIotClassification/serviceClassification");
    },
    loadData() {
      const res = {};
      // let param = {
      //   productId: this.$route.params.service,
      // };
      const url =
        "http://39.104.14.150:8081/hit/iot/v1/product/queryProductInfo?productId=" +
        this.service.id;
      this.$http.post(url).then((res) => {
        if (res.data.code === "0") {
          this.service.name = res.data.data.cname;
          this.service.protocol = res.data.data.protocol;
          this.service.desc = res.data.data.desc;
          if (res.data.data.dataformat === "0") {
            this.service.format = "二进制码流";
          } else {
            this.service.format = "json字符串";
          }
          this.total = res.data.data.deviceNum;
        }
      });
    },
  },
  components: {
    MessageParamBinding,
    modelAttribute,
    modelCommand,
    messageCoding,
  },
  beforeRouteUpdate(to) {},
};
</script>

<style scoped>
.container {
  width: 100%;
  height: 100%;
}
.header {
  width: calc(100% - 30px);
  margin: 15px 15px 0;
}
.desc {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
  padding: 12px;
  border-radius: 5px;
  margin-bottom: 15px;
}
.description {
  padding: 12px;
}
.def {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
  padding: 12px;
  border-radius: 5px;
  margin-bottom: 15px;
}

/deep/.el-tabs__item {
  font-size: 18px;
}
/*deep强制渲染*/

.asideScrollbar {
  height: 100%;
}
</style>
