<template>
  <div>
    <!--    选择行政区   -->
    <div class="header">
      <el-row>
        <el-col :span="12">
          <v-distpicker
            :province="select.province"
            :city="select.city"
            :area="select.area"
            @selected="onSelected"
          >
          </v-distpicker>
        </el-col>
        <el-col :span="6"
          ><el-input
            v-model="select.cun"
            placeholder="可输入村落或街道"
          ></el-input
        ></el-col>
        <el-col :span="2" style="margin-right: 20px">
          <el-button @click="selectcun" v-show="ctladdable" type="primary"
            >检索</el-button
          ></el-col
        >
        <el-col :span="3">
          <el-button @click="addPolygon" v-show="ctladdable" type="primary"
            >添加地块</el-button
          >
        </el-col>
      </el-row>

      <!--      <el-button @click="add" v-show="subctlable">提交地块</el-button>-->
      <el-button @click="addMarker" v-if="!isShow">添加标记点</el-button>
    </div>
    <!--    百度地图api-->
    <el-card>
      <baidu-map
        class="map"
        ak="hvIiKrCHzmMD40ZgvIvPxzX9k6q8cXik"
        :center="center"
        :zoom="zoom"
        map-type="BMAP_HYBRID_MAP"
        @moving="syncCenterAndZoom"
        @moveend="syncCenterAndZoom"
        @zoomend="syncCenterAndZoom"
        @ready="handler"
        :double-click-zoom="false"
        :scroll-wheel-zoom="true"
      >
        <bm-navigation anchor="BMAP_ANCHOR_TOP_RIGHT"></bm-navigation>
        <bm-scale anchor="BMAP_ANCHOR_TOP_RIGHT"></bm-scale>
        <!--        位置跳转-->
        <bm-local-search
          :keyword="keyword"
          :location="location"
          :select-first-result="true"
          :panel="false"
          :auto-viewport="true"
          @markersset="removeMarker"
          @searchcomplete="searchcomplete"
        ></bm-local-search>
        <bm-boundary-ex :name="name" :strokeWeight="2" strokeColor="blue">
          <bm-polygon-ex
            :i-region="name"
            :polygon-data="polygonData"
            @drawover="callbakAdd"
            @modifyover="callbakModify"
            @delete="callbakDelete"
            @modifying="callbakModifying"
            @adddevover="callbakAddDev(arguments)"
            ref="polygonex"
          >
          </bm-polygon-ex>
        </bm-boundary-ex>
      </baidu-map>
    </el-card>

    <el-dialog title="填写地块信息" :visible.sync="dialogVisible1">
      <el-form :model="form1">
        <el-form-item label="省市区" label-width="120px">
          <el-input
            v-model="form1.ctl_address"
            auto-complete="off"
            readonly
          ></el-input>
        </el-form-item>
        <el-form-item label="街道" label-width="120px">
          <el-input v-model="road" auto-complete="off"></el-input>
        </el-form-item>
        <el-form-item label="地块名称" label-width="120px">
          <el-input v-model="form1.ctl_name" auto-complete="off"></el-input>
        </el-form-item>
        <el-form-item label="地块面积" label-width="120px">
          <el-input v-model="form1.ctl_area" auto-complete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="cancelsubmit">取 消</el-button>
        <el-button @click="submitctl">完 成</el-button>
      </div>
    </el-dialog>
    <el-dialog title="修改地块信息" :visible.sync="dialogVisible2">
      <el-form :model="form1">
        <el-form-item label="地块详细位置" label-width="120px">
          <el-input
            v-model="form1.ctl_address"
            auto-complete="off"
            readonly
          ></el-input>
        </el-form-item>
        <el-form-item label="地块名称" label-width="120px">
          <el-input v-model="form1.ctl_name" auto-complete="off"></el-input>
        </el-form-item>
        <el-form-item label="地块面积" label-width="120px">
          <el-input v-model="form1.ctl_area" auto-complete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="cancelupdate">取 消</el-button>
        <el-button @click="updatectl">完 成</el-button>
      </div>
    </el-dialog>
    <el-dialog title="添加设备" :visible.sync="dialogVisible3">
      <div class="tablehead">
        <div>当前选择设备:{{ dev_name }}</div>
      </div>
      <el-table
        :data="deviceRegisted"
        highlight-current-row
        @row-click="handle"
        ref="devtable"
      >
        <el-table-column label="设备名称" prop="i_name"></el-table-column>
        <el-table-column label="设备号" prop="i_sn"></el-table-column>
        <!--        <el-table-column label="负责人" prop="admin"></el-table-column>-->
        <el-table-column label="当前状态" prop="i_state"> </el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="unbinddevqueryinfo.pageNum"
        :page-sizes="[5, 10]"
        :page-size="unbinddevqueryinfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="unbinddevtotal"
      >
      </el-pagination>
      <div slot="footer">
        <el-button @click="canceldev">取消</el-button>
        <el-button @click="submitDev">完 成</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import BaiduMap from "vue-baidu-map/components/map/Map.vue";
import BmLocalSearch from "vue-baidu-map/components/search/LocalSearch.vue";
import VDistpicker from "v-distpicker";
import BmScale from "vue-baidu-map/components/controls/Scale.vue";
import BmNavigation from "vue-baidu-map/components/controls/Navigation.vue";
import BmBoundaryEx from "../../components/custom-baidumap/BoundaryEx";
import BmPolygonEx from "../../components/custom-baidumap/PolygonEx.vue";
var BMapLib = require("bmaplib").BMapLib;
export default {
  name: "locationTest",
  components: {
    BaiduMap,
    VDistpicker,
    BmScale,
    BmNavigation,
    BmBoundaryEx,
    BmLocalSearch,
    BmPolygonEx, //自定义多边形
  },
  data() {
    return {
      map: "", //地图实例
      num: 0,
      select: { province: "山东省", city: "威海市", area: "环翠区", cun: "" },
      form: {
        address: "哈工大（威海）",
      },
      deviceRegisted: [],
      DeviceSelected: "",
      AreaSelected: "",
      dialogVisible: false,
      center: {
        lng: 122.130539,
        lat: 37.508969,
      },
      farmlandloc: "山东省威海市环翠区",
      farmlandID: "",
      farmlandName: "",
      deviceNum: 0,
      zoom: 10,
      polygonPath: [], //临时变量，地块的坐标集合
      location: "山东省威海市环翠区",
      keyword: "环翠区",
      greenhouses: [],
      position: { lng: 0, lat: 0 },
      points: [],
      pointPosition: {},
      positionCenter: {},
      clickPoint: {},
      infoWindow: {
        show: true,
        contents: "这里展示信息内容：",
      },
      dialogVisible1: false,
      dialogVisible2: false,
      dialogVisible3: false,
      form1: {
        //add,modify
        ctl_name: "",
        ctl_address: "",
        ctl_area: 0,
        ctl_image: "abcdef",
        ctl_local: "",
      },
      road: "",
      devform: {},
      dev_name: "",
      choosedev: {},
      isShow: true,
      addedPosition: { lng: 0, lat: 0 },
      isSame: true,
      infoWindow01: { show: false },
      name: "山东省威海市环翠区", //带边界的区域
      polygonData: [],
      originpolygonData: [],
      ctladdable: true, //是否可以添加多边形（上一个完全结束才行）
      subctlable: false, //画完地块才可以提交
      Bmap: "",
      unbinddevqueryinfo: {
        ctl_id: 0,
        datelow: "",
        dateup: "",
        i_id: "-1",
        i_latitude: "",
        i_longitude: "",
        i_name: "",
        i_online: "",
        i_region: "",
        i_sn: "",
        i_state: "",
        i_token: "",
        i_type: "",
        pageNum: 1,
        pageSize: 5,
      },
      unbinddevtotal: 0,
      queryInfo: {
        pageNum: 1,
        pageSize: 100,
        address: "",
        local: "",
        name: "",
        alow: "",
        aup: "",
        ctl_id: -1,
      },
      mapStyle: {
        // styleJson: StyleJson,
        styleJson: [
          {
            featureType: "poilabel",
            elementType: "labels",
            stylers: {
              visibility: "off",
            },
          },
          {
            featureType: "poilabel",
            elementType: "labels.icon",
            stylers: {
              visibility: "off",
            },
          },
        ],
      },
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    searchcomplete(results) {
      console.log(results);
    },
    selectcun() {
      console.log(this.select);
      if (this.select.cun === "") this.keyword = this.select.area;
      else this.keyword = this.name + this.select.cun;
      console.log(this.keyword);
      this.fetchData();
    },
    handleSizeChange(val) {
      this.unbinddevqueryinfo.pageSize = val;
      this.getUnbinddevices();
    },
    handleCurrentChange(val) {
      this.unbinddevqueryinfo.pageNum = val;
      this.getUnbinddevices();
    },
    getUnbinddevices() {
      this.$api.apiHitIotLocation
        .getUnBindDev(this.unbinddevqueryinfo)
        .then((res) => {
          console.log(res.data);
          this.unbinddevtotal = res.data.total;
          this.deviceRegisted = res.data.data;
          this.$nextTick(() => {
            console.log(this.$refs["devtable"]);
            this.$refs["devtable"].setCurrentRow(this.deviceRegisted[0]);
            this.handle(this.deviceRegisted[0]);
          });
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
    submitctl() {
      // this.$axios({
      //   method: "post",
      //   url: "http://122.9.150.203:6701/hitLocCtrlSaveb",
      //   data: this.form1,
      //   config: {
      //     emulateJSON: false,
      //   },
      // });
      this.form1.ctl_address = this.form1.ctl_address + "-" + this.road;
      this.$api.apiHitIotLocation
        .savePolygon(this.form1)
        .then((res) => {
          console.log(res.data);
          this.dialogVisible1 = false;
          this.$message.success("添加成功");
          this.ctladdable = true;
          this.fetchData();
        })
        .catch((err) => {
          console.log(err.response);
          this.dialogVisible1 = false;
          this.$message.error("添加失败");
        });
    },
    cancelsubmit() {
      this.dialogVisible1 = false;
      this.polygonData = JSON.parse(JSON.stringify(this.originpolygonData));
    },
    updatectl() {
      // this.$axios({
      //   method: "post",
      //   url: "http://122.9.150.203:6701/hitLocCtrlUpdate",
      //   data: this.form1,
      //   config: {
      //     emulateJson: false,
      //   },
      // })
      this.$api.apiHitIotLocation
        .updatePolygon(this.form1)
        .then((res) => {
          console.log(res.data);
          this.dialogVisible2 = false;
          this.$message.success("修改成功");
          this.fetchData();
        })
        .catch((err) => {
          console.log(err.response);
          this.dialogVisible2 = false;
          this.$message.error("修改失败");
        });
    },
    cancelupdate() {
      this.dialogVisible2 = false;
      this.polygonData = JSON.parse(JSON.stringify(this.originpolygonData));
    },
    addPolygon() {
      console.log("addpolygon");
      // this.addable = true;
      this.$refs.polygonex.drawPolygon();
      this.ctladdable = false;
    },
    callbakAdd(e) {
      console.log("%c map callbakAdd", "color: green;");
      console.log(e);
      // this.newPolygon = e;
      this.subctlable = true;
      // var ply = new BaiduMap.Polygon(e.path);
      // var area = this.getPolygonArea(e.path);
      var area = BMapLib.GeoUtils.getPolygonArea(e.path);
      console.log("area:  ", area);
      this.form1.ctl_area = area.toFixed(2);
      let path = e.path;
      this.form1.ctl_local = "{";
      path.forEach((value, index) => {
        console.log(value);
        console.log(index);
        this.form1.ctl_local +=
          index +
          ":[" +
          value.lng.toString() +
          "," +
          value.lat.toString() +
          "],";
      });
      this.form1.ctl_local = this.form1.ctl_local.substring(
        0,
        this.form1.ctl_local.length - 1
      );
      console.log(this.form1.ctl_local);
      this.form1.ctl_local += "}";
      this.form1.ctl_name = "";
      this.dialogVisible1 = true;
      this.form1.ctl_address =
        this.select.province + "-" + this.select.city + "-" + this.select.area;
    },
    callbakModifying(e) {
      console.log("modifying", e);
    },
    callbakModify(e) {
      console.log("%c map callbakModify", "color: green;");
      console.log(e);
      // var area = this.getPolygonArea(e.path);
      var area = BMapLib.GeoUtils.getPolygonArea(e.path);
      this.form1.ctl_area = area.toFixed(2);
      let path = e.path;
      this.form1.ctl_local = "{";
      path.forEach((value, index) => {
        console.log(value);
        console.log(index);
        this.form1.ctl_local +=
          index +
          ":[" +
          value.lng.toString() +
          "," +
          value.lat.toString() +
          "],";
      });
      this.form1.ctl_local = this.form1.ctl_local.substring(
        0,
        this.form1.ctl_local.length - 1
      );
      this.form1.ctl_local += "}";
      // this.form1.ctl_address =
      //   this.select.province + "-" + this.select.city + "-" + this.select.area;
      this.form1.ctl_address = e.data.ctl_address;
      this.form1.ctl_name = e.data.ctl_name;
      this.form1.ctl_id = e.data.ctl_id;
      this.dialogVisible2 = true;
    },
    callbakDelete(e) {
      console.log("%c map callbakDelete", "color: green;");
      console.log(e);
    },
    callbakAddDev(arguements) {
      console.log("newmarker", arguements);
      this.devform.ctl_id = arguements[0];
      let point = arguements[1];
      this.devform.i_latitude = point.lat;
      this.devform.i_longitude = point.lng;
      this.devform.region = this.name;
      this.dialogVisible3 = true;
      this.getUnbinddevices();
      // this.$axios
      //   .get(
      //     "http://122.9.150.203:8081/hit/iot/v1/device/listUninstalleddevice"
      //   )
      // this.$api.apiHitIotLocation
      //   .getUnBindDev()
      //   .then((res) => {
      //     console.log(res.data);
      //     this.deviceRegisted = res.data.data;
      //     this.$nextTick(() => {
      //       console.log(this.$refs["devtable"]);
      //       this.$refs["devtable"].setCurrentRow(this.deviceRegisted[0]);
      //       this.handle(this.deviceRegisted[0]);
      //     });
      //   })
      //   .catch((err) => {
      //     console.log(err.response);
      //   });
    },
    handle(row) {
      this.choosedev = row; //传送给地块的新设备的信息
      this.dev_name = row.i_name;
      this.devform.i_id = row.i_id;
    },
    submitDev() {
      // this.$axios({
      //   method: "post",
      //   url: "http://10.245.150.82:6701/hitLandBindDev",
      //   params: this.devform,
      // })
      let str = this.devform.ctl_id + "#" + this.devform.i_id;
      this.$api.apiHitIotLocation
        .installDev(this.devform)
        .then((res) => {
          console.log(res.data);
          this.dialogVisible3 = false;
          if (res.data.code === -1) this.$message.error("绑定失败");
          else this.$message.success("绑定成功");
          this.$refs.polygonex.finishaddnewDev(this.choosedev);
          this.fetchData();
        })
        .catch((err) => {
          console.log(err.response);
          this.dialogVisible3 = false;
          this.$message.error("绑定失败");
        });
    },
    canceldev() {
      this.dialogVisible3 = false;
      // let allOverlay = this.map.getOverlays();
      // console.log({ allOverlay });
      // this.map.removeOverlay(allOverlay[allOverlay.length - 1]);
      // this.$nextTick(() => {
      //   console.log(this.map.getOverlays());
      // });
      this.$refs.polygonex.canceladdnewDev();
    },
    onSelected(e) {
      this.location = e.city.value + e.area.value;
      this.name = e.province.value + e.city.value + e.area.value;
      this.keyword = e.area.value;
      this.select.province = e.province.value;
      this.select.area = e.area.value;
      this.select.city = e.city.value;
      console.log(this.location);
      // this.fetchData();
      // this.selectcun();
    },
    AreaOptionClicked() {
      this.location = this.location + this.form.address;
      this.keyword = this.location;
      // this.zoom = 5;
      this.dialogVisible = true;
      this.isShow = true;
    },
    DeviceOptionClicked() {
      this.keyword = this.location;
      this.position = this.polygonPath[0];
      // this.zoom = 5;
    },
    syncCenterAndZoom(e) {
      const { lng, lat } = e.target.getCenter();
      this.center.lng = lng;
      this.center.lat = lat;
      this.positionCenter.lng = lng;
      this.positionCenter.lat = lat;
      this.zoom = e.target.getZoom();
    },
    updatePolygonPath(e) {
      this.polygonPath = e.target.getPath();
    },
    add() {
      this.dialogVisible1 = true;
      this.form1.ctl_address =
        this.select.province + "-" + this.select.city + "-" + this.select.area;
    },
    addMarker() {
      this.dialogVisible2 = true;
      this.points.push(this.position);
      this.deviceRegisted.splice(
        this.deviceRegisted.indexOf(this.DeviceSelected),
        1
      );
      this.position = this.polygonPath[0];
      console.log("len:");
      console.log(this.deviceRegisted.length);
      if (this.deviceRegisted.length === 2) {
        this.isShow = true;
      }
    },
    getClickInfo(e) {
      console.log("here1");
      console.log("here2");
      this.clickPoint = { lng: e.point.lng, lat: e.point.lat };
      console.log(this.clickPoint);
      this.position = this.clickPoint;
    },
    areaClicked(greenHouse) {
      console.log(greenHouse);
    },
    handler({ BMap, map }) {
      console.log("handler");
      this.map = map;
      console.log(map);
      this.BMap = BMap;
    },
    removeMarker(pois) {
      // console.log(pois);
      let allOverlay = this.map.getOverlays();
      this.map.removeOverlay(allOverlay[allOverlay.length - 1]);
    },
    fetchData() {
      this.queryInfo.address =
        this.select.province + "-" + this.select.city + "-" + this.select.area;
      console.log(this.queryInfo);
      this.$api.apiHitIotLocation
        .getPolygon(this.queryInfo)
        .then((res) => {
          console.log(res.data);
          this.polygonData = [];
          let { iotland } = res.data;
          iotland.forEach((item, index) => {
            //let rege = /(?<=\[)[0-9+.,]+/g;   //zxd0204
            let rege = new RegExp("(?<=\\[)[0-9+.,]+", "g");
            let result = item.ctl_local.match(rege);
            let path = [];
            result.forEach((item, index) => {
              let str = item.split(",");
              path.push({ lng: str[0], lat: str[1] });
            });
            this.polygonData.push({ path: path, ...item });
          });
          console.log(this.polygonData);
          this.originpolygonData = JSON.parse(JSON.stringify(this.polygonData));
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
  },
};
</script>

<style>
.map {
  width: 100%;
  height: calc(100vh - 250px);
}

.anchorBL {
  display: none;
}
path {
  fill-opacity: 0.25;
}
.header {
  display: flex;
  flex-flow: nowrap;
}
.header button {
  margin-left: 20px;
}
.BMap_bubble_content {
  color: white;
}
.BMap_bubble_title {
  color: black;
}
</style>
<style lang="less">
.distpicker-address-wrapper {
  width: max-content;
}
.BMap_bottom {
  display: none;
}
.BMap_pop {
  > div,
  > img:nth-child(10) {
    display: none;
    overflow: unset;
  }
  > div:nth-child(9) {
    display: block;
    overflow: unset;
    width: 340px !important;
  }
  > div:nth-child(8) {
    /*display: block;*/
  }
  .BMap_top {
    display: none;
  }
  .BMap_center {
    background: transparent;
    border: none;
    position: sticky !important;
    height: 100%;
  }
}
.BMap_bubble_content {
  background: rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  padding: 20px;
  .info-window {
    padding-right: 8px;
    width: 100%;
    max-height: 280px;
    overflow: auto;
    .el-divider {
      background: #ccccccbf;
    }
    .address {
      color: #fff;
    }
  }
  //自定义滚动条样式
  .info-window::-webkit-scrollbar {
    width: 6px;
    height: 1px;
  }
  .info-window::-webkit-scrollbar-thumb {
    border-radius: 6px;
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    background: #535353;
  }
  .info-window::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    background: #ededed;
  }
}
</style>
