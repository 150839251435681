<template>
  <el-container class="container" direction="vertical">
    <el-card class="search_condition">
      <el-collapse accordion v-model="activeNames" style="margin-bottom: 1px">
        <el-collapse-item name="1">
          <template slot="title">
            <div style="font-size: 16px">管控区域查询条件</div>
          </template>
          <el-form
            :label-width="formLabelWidth"
            :inline="false"
            style="margin-bottom: 0px; height: 0px"
          >
            <el-row>
              <el-col :span="6" style="float: right; margin-bottom: 2px">
                <el-button
                  @click="landsearch"
                  type="primary"
                  style="margin-right: 10px"
                  >查询</el-button
                >
                <el-button @click="reset(basicAreaInfo)">重置</el-button>
              </el-col>
            </el-row>
            <el-row>
              <el-col :xs="4" :sm="6" :md="8" :lg="14" :xl="8">
                <el-form-item label="选择行政区">
                  <v-distpicker
                    :province="basicAreaInfo.province"
                    :city="basicAreaInfo.city"
                    :area="basicAreaInfo.area"
                    @province="onChangeProvince"
                    @city="onChangeCity"
                    @area="onChangeArea"
                  ></v-distpicker>
                </el-form-item>
              </el-col>
              <el-col :xs="4" :sm="6" :md="8" :lg="10" :xl="8">
                <el-form-item label="街道">
                  <el-input v-model="basicAreaInfo.street"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :xs="4" :sm="6" :md="8" :lg="14" :xl="8">
                <el-form-item label="区域名称">
                  <el-input v-model="basicAreaInfo.areaName"></el-input>
                </el-form-item>
              </el-col>
              <el-col :xs="4" :sm="6" :md="8" :lg="10" :xl="8">
                <el-form-item label="面积">
                  <el-input v-model="basicAreaInfo.landArea"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </el-collapse-item>
      </el-collapse>
      <el-table
        v-fit-columns
        :data="areaTable"
        ref="singTable"
        highlight-current-row
        height="250"
        border
        style="width: 100%; margin-top: 10px; margin-bottom: 5px"
      >
        <el-table-column label="选择" width="50">
          <template slot-scope="scope">
            <el-radio
              :label="scope.$index"
              v-model="currentRow"
              @change.native="getCurrentRow(scope.row)"
              >&nbsp;
            </el-radio>
          </template>
        </el-table-column>
        <el-table-column prop="ctl_id" label="区域id"> </el-table-column>
        <el-table-column prop="ctl_address" label="行政区域"> </el-table-column>
        <el-table-column prop="ctl_name" label="区域名称" width="80">
        </el-table-column>
        <el-table-column prop="ctl_area" label="地块面积" width="80">
        </el-table-column>
        <el-table-column prop="ctl_local" label="地块坐标" width="280">
        </el-table-column>
        <!--        <el-table-column-->
        <!--          prop="ctl_cameratoken"-->
        <!--          label="视频访问许可"-->
        <!--          width="280"-->
        <!--        >-->
        <!--        </el-table-column>-->
        <!--        <el-table-column prop="ctl_cameraurl" label="直播地址" width="280">-->
        <!--        </el-table-column>-->
        <el-table-column
          fixed="right"
          label="操作"
          prop="operation"
          width="180"
          align="center"
        >
          <template slot-scope="props">
            <el-button
              size="mini"
              @click="
                $router.push({
                  path: 'CtrLandModify',
                  query: props.row,
                })
              "
              >编辑
            </el-button>
            <el-button
              size="mini"
              type="danger"
              @click="handleDelete(props.row)"
              >移除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        @size-change="pageSizeChangel"
        @current-change="pageCurrentChangel"
        :current-page="pageforml.pageNum"
        :page-sizes="pageforml.pageSizes"
        :page-size="pageforml.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pageforml.total"
        style="margin-bottom: 15px"
      >
      </el-pagination>
    </el-card>
    <el-card class="devices">
      <el-row style="margin-top: 30px">
        <el-col :span="8">
          <span>设备信息</span>
        </el-col>
        <el-col :span="16" style="justify-content: left">
          <el-button @click="bindDeviceSearchb" type="primary"
            >绑定设备查询</el-button
          >
          <el-tooltip
            effect="dark"
            :disabled="!unbindBtn"
            content="请先进行绑定设备信息查询..."
            placement="top"
          >
            <el-button @click="unDevbindCtrl" :disabled="unbindBtn"
              >解除绑定</el-button
            >
          </el-tooltip>
          <el-button @click="unbindDeviceSearchb" type="primary"
            >未绑定设备查询</el-button
          >
          <el-tooltip
            effect="dark"
            :disabled="!bindBtn"
            content="请先进行未绑定设备信息查询..."
            placement="top"
          >
            <el-button @click="devBindCtrl" :disabled="bindBtn">绑定</el-button>
          </el-tooltip>
        </el-col>
      </el-row>
      <el-row style="margin: 10px">
        <el-table
          v-fit-columns
          :row-key="getRowKeys"
          :data="deviceTableData"
          style="width: 100%"
          border
          highlight-current-row
          height="300"
          :row-class-name="tableRowClassName"
          ref="multipleTable"
          @selection-change="handleSelectionChange"
        >
          <el-table-column
            fixed
            label="选择"
            type="selection"
            :reserve-selection="true"
          ></el-table-column>
          <el-table-column label="id" prop="i_id"></el-table-column>
          <el-table-column label="序列号" prop="i_sn"></el-table-column>
          <el-table-column label="访问标识" prop="i_token"></el-table-column>
          <el-table-column label="名称" prop="i_name"></el-table-column>
          <el-table-column label="类型" prop="i_type"></el-table-column>
          <el-table-column label="经度" prop="i_longitude"></el-table-column>
          <el-table-column label="纬度" prop="i_latitude"></el-table-column>
          <el-table-column label="所在区域" prop="i_region"></el-table-column>
          <el-table-column label="在线标识" prop="i_online"></el-table-column>
          <el-table-column label="当前状态" prop="i_state"></el-table-column>
          <el-table-column label="安装日期" prop="i_date"></el-table-column>
        </el-table>
        <el-pagination
          background
          @size-change="pageSizeChanged"
          @current-change="pageCurrentChanged"
          :current-page="pageformd.pageNum"
          :page-sizes="pageformd.pageSizes"
          :page-size="pageformd.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="pageformd.total"
          style="margin-bottom: 15px"
        >
        </el-pagination>
      </el-row>
    </el-card>
  </el-container>
</template>

<script>
import VDistpicker from "v-distpicker";
export default {
  components: {
    VDistpicker,
  },
  name: "bindDevice",
  data() {
    return {
      //公共变量
      activeNames: ["1"],
      highlightRows: new Set(),
      LabelWidth: "60px",
      formLabelWidth: "120px",
      modify_title: "管控区域录入",
      placeholder: {},
      currentRow: 0,
      //按钮使能
      bindBtn: true,
      unbindBtn: true,
      //区域信息分页
      ctrlId: 0,
      pageforml: {
        total: 20,
        pages: 5,
        pageNum: 1,
        pageSizes: [2, 5, 10, 15, 20],
        pageSize: 5,
        navigateFirstPage: 1,
        navigateLastPage: 3,
        lastPage: false,
        firstPage: false,
        hasNextPage: true,
        hasPreviousPage: true,
      },
      //设备信息分页
      pageformd: {
        total: 20,
        pages: 5,
        pageNum: 1,
        pageSizes: [1, 2, 5, 10, 15, 20],
        pageSize: 5,
        navigateFirstPage: 1,
        navigateLastPage: 3,
        lastPage: false,
        firstPage: false,
        hasNextPage: true,
        hasPreviousPage: true,
      },
      //传递变量--区域查询条件
      basicAreaInfo: {
        devId: "",
        province: "山东省",
        city: "威海市",
        area: "",
        street: "",
        areaName: "",
        landArea: "",
      },
      areaTable: [
        {
          ctl_id: -1,
          ctl_address: "请查询...",
          ctl_name: "",
          ctl_area: "",
          ctl_local: "",
        },
      ],
      //设备查询
      UnbindDev: {
        pageNum: 1,
        pageSize: 5,
        i_id: "-1",
        i_sn: "",
        i_token: "",
        i_name: "",
        i_type: "",
        i_longitude: "",
        i_latitude: "",
        i_region: "",
        i_online: "", //依据需求设置默认为0-不在线; 1-在线,不代表正常，与i_state都为1，则为健康。当i_state=2时为在线不正常。
        i_state: "",
        datelow: "",
        dateup: "",
        ctl_id: "",
      },
      deviceTableData: [
        {
          i_id: -1,
          i_sn: "请查询",
          i_token: "",
          i_name: "",
          i_type: "",
          i_latitude: "",
          i_longitude: "",
          i_region: "",
          i_online: "",
          i_state: "",
          i_date: "",
        },
      ],
      devs: "", //[],
      itL: {
        ctl_address: "",
        ctl_area: -1,
        ctl_id: -1,
        ctl_image: "",
        ctl_local: "",
        ctl_name: "",
      },
      bindflag: 0,
    };
  },
  activated() {
    if (window.name === "") {
      console.log("首次被加载！");
      window.name = "地块管理";
    } else this.landsearch();
    // if(window.performance.navigation.type ===1){
    //   this.landsearch();
    // }
  },
  methods: {
    getRowKeys(row) {
      return row.i_id;
    },
    onChangeProvince(data) {
      this.basicAreaInfo.province = data.value;
    },
    onChangeCity(data) {
      this.basicAreaInfo.city = data.value;
    },
    onChangeArea(data) {
      this.basicAreaInfo.area = data.value;
    },
    landsearch: function () {
      var pageNum = this.pageforml.pageNum;
      var pageSize = this.pageforml.pageSize;
      var ctl_id = -1;
      var address = "";
      if (
        this.basicAreaInfo.province !== "省" &&
        this.basicAreaInfo.province.length !== 0
      )
        address += this.basicAreaInfo.province;
      if (
        this.basicAreaInfo.city !== "市" &&
        this.basicAreaInfo.city.length !== 0
      )
        address += "-" + this.basicAreaInfo.city;
      if (
        this.basicAreaInfo.area !== "区" &&
        this.basicAreaInfo.area.length !== 0
      )
        address += "-" + this.basicAreaInfo.area;
      if (this.basicAreaInfo.street !== "")
        address += "-" + this.basicAreaInfo.street;

      var local = "";
      var name = this.basicAreaInfo.areaName;
      var salw = "0";
      var aup = this.basicAreaInfo.landArea;
      this.$api.apiHitIotLocation
        .getPolygonc(pageNum, pageSize, ctl_id, address, local, name, salw, aup)
        .then((res) => {
          if (res.data.code == 1) {
            this.areaTable = res.data.iotland;
            this.pageforml.total = res.data.total;
            this.ctrlId = this.areaTable[0].ctl_id;
            console.log(this.ctrlId);
          } else {
            this.$message({
              message: "查询失败，请联管理员",
              type: "info",
            });
          }
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
    reset(basicAreaInfo) {
      const emptyBasicAreaInfo = {
        province: "",
        city: "",
        area: "",
        street: "",
        areaName: "",
        name: "",
        landArea: "",
      };
      this.basicAreaInfo = emptyBasicAreaInfo;
    },
    getCurrentRow(row) {
      //得到主表的地块id
      this.ctrlId = row.ctl_id;
      //查询绑定设备
      this.bindDeviceSearchb();
    },
    //区域分页
    pageSizeChangel(pageSize) {
      //区域当每页的数量/尺寸改变后，重新向后台发送信息  // 每页条数切换调用   landsearch()
      this.pageforml.pageSize = pageSize;
      this.landsearch();
    },
    pageCurrentChangel(currentPage) {
      //区域当前页面切换, //页码切换 调用landsearch()
      this.pageforml.pageNum = currentPage;
      this.landsearch();
    },
    //未绑定设备查询--按钮
    unbindDeviceSearchb: function () {
      this.$refs.multipleTable.clearSelection(); //重置，清除表中上次的选择
      this.devs = ""; //重置，清除变量中的选择--设备id
      this.bindflag = 0; //置表标位，表明现在是进行未绑定设备操作
      this.bindBtn = false;
      this.unbindBtn = true;
      this.pageformd.pageNum = 1;
      this.unbindDeviceSearch(); //查询未绑定设备
    },
    //未绑定设备查询--函数
    unbindDeviceSearch: function () {
      this.error = null;
      this.UnbindDev.ctl_id = this.ctrlId;
      this.UnbindDev.pageNum = this.pageformd.pageNum;
      this.UnbindDev.pageSize = this.pageformd.pageSize;
      this.$api.apiHitIotLocation
        .getUnBindDev(this.UnbindDev)
        .then((res) => {
          console.log(res);
          if (res.data.code === 1) {
            this.deviceTableData = res.data.data;
            this.pageformd.total = res.data.total;
          } else {
            this.$message({
              message: "查询失败，请与管理员联系！",
              type: "info",
            });
          }
        })
        .catch((error) => {
          console.log(error);
          this.error = error.toString();
        });
    },
    //绑定设备查询--按钮
    bindDeviceSearchb: function () {
      this.$refs.multipleTable.clearSelection(); //重置，清除设备表中选择
      this.devs = ""; //重置，清除变量中选择
      this.bindflag = 1; //设置标志位，表明进入已绑定设备操作
      this.unbindBtn = false;
      this.bindBtn = true;
      this.pageforml.pageNum = 1;
      this.bindDeviceSearch(); //搜索已绑定设备
    },
    //绑定设备查询--函数
    bindDeviceSearch: function () {
      this.error = null;
      this.UnbindDev.ctl_id = this.ctrlId;
      this.UnbindDev.pageNum = this.pageformd.pageNum;
      this.UnbindDev.pageSize = this.pageformd.pageSize;
      console.log(this.UnbindDev);
      this.$api.apiHitIotLocation
        .getBindDevs(this.UnbindDev)
        .then((res) => {
          console.log(res);
          if (res.data.code === 1) {
            this.deviceTableData = res.data.data;
            this.pageformd.total = res.data.total;
          }
        })
        .catch((error) => {
          console.log(error);
          this.error = error.toString();
        });
    },
    //设备每页显示记录尺寸改变--每页条数切换，不改变原有选择项
    pageSizeChanged(pageSize) {
      this.pageformd.pageSize = pageSize;
      if (this.bindflag === 1) {
        this.bindDeviceSearch();
      } else {
        this.unbindDeviceSearch();
      }
    },
    pageCurrentChanged(currentPage) {
      //页码切换
      console.log("currentPage:" + currentPage);
      this.pageformd.pageNum = currentPage;
      if (this.bindflag === 1) {
        this.bindDeviceSearch();
      } else {
        this.unbindDeviceSearch();
      }
      console.log(this.devs);
    },
    //绑定
    devBindCtrl: function () {
      let dd = this.devs.split("#");
      if (dd.length <= 1) {
        //即使为空，长度也是1
        this.$message({
          message: "未有设备被选定，无法解绑定地块！",
          type: "info",
        });
        return;
      } else {
        if (this.ctrlId === null || this.ctrlId === 0 || this.ctrlId === "") {
          this.$message({
            message: "未有地块被选定，无法绑定设备！",
            type: "info",
          });
          return;
        }
        let devs = this.ctrlId + this.devs;
        console.log("devs:" + devs);
        this.$api.apiHitIotLocation
          .installDevs(devs)
          .then((res) => {
            console.log(res);
            if (res.data.code === 1) {
              this.$message({
                message: "绑定成功",
                type: "success",
              });
              this.unbindDeviceSearchb(); //再查一遍未绑定设备，以便继续进行查询
            } else {
              this.$message({
                message: "绑定失败",
                type: "info",
              });
            }
          })
          .catch((err) => {
            console.log(err.response);
          });
      }
    },
    //解绑
    unDevbindCtrl: function () {
      let dd = this.devs.split("#");
      if (dd.length <= 1) {
        this.$message({
          message: "未有设备被选定，无法解绑！",
          type: "info",
        });
        return;
      } else {
        if (this.ctrlId === null || this.ctrlId === 0 || this.ctrlId === "") {
          this.$message({
            message: "未有地块被选定，无法绑定设备！",
            type: "info",
          });
          return;
        }
        let devs = this.ctrlId + this.devs;
        console.log("devs:" + devs);
        if (devs === this.ctrlId) {
          this.$message({
            message: "没有设备被选择，无法解除绑定！",
            type: "info",
          });
          return;
        }
        this.$api.apiHitIotLocation
          .uninstallDevs(devs)
          .then((res) => {
            if (res.data.code == 1) {
              this.$message({
                message: "绑定解除成功",
                type: "success",
              });
              this.bindDeviceSearchb(); //解除绑定后，要再查一遍
            } else {
              this.$message({
                message: "绑定解除失败，后台异常",
                type: "info",
              });
            }
          })
          .catch((err) => {
            console.log(err.response);
          });
      }
    },
    tableRowClassName({ row, rowIndex }) {
      if (this.highlightRows.has(rowIndex)) {
        return "highlight";
      }
      return "";
    },
    handleSelectionChange: function (val) {
      // val是一个列表，包含所有的选择项
      var i = 0;
      this.devs = "";
      for (; i < val.length; i++) {
        this.devs += "#" + val[i].i_id;
      }
      console.log("devs:" + this.devs);
    },
    handleDelete: function (row) {
      this.itL.ctl_id = row.ctl_id;
      console.log(this.itL);
      this.$confirm("此操作将永久移除该单位, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$api.apiHitIotLocation
            .deletePolygon(this.itL)
            .then((res) => {
              if (res.data.code === 1) {
                this.landsearch();
                this.$message({
                  type: "success",
                  message: "移除成功!" + row.ctl_id,
                });
              } else if (res.data.code === 0) {
                this.$message({
                  type: "sucess",
                  message:
                    "删除失败，编号：" +
                    row.ctl_id +
                    "有绑定设备，请先解绑再删除！",
                });
              } else {
                this.$message({
                  type: "info",
                  message: "删除失败，请与管理员联系" + row.ctl_id,
                });
              }
            })
            .catch((err) => {
              console.log(err.response);
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消移除操作哈" + row.ctl_id,
          });
        });
    },
  },
};
</script>

<style scoped>
.el-input {
  width: 80%;
}
.el-button {
  margin-left: 10px;
}

.search_condition {
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  width: 95%;
  margin: 10px;
  border-radius: 5px;
  text-align: left;
}
.devices {
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  width: 95%;
  margin: 10px;
  border-radius: 5px;
  text-align: left;
}
.form {
  width: 80%;
}
.el-collapse {
  border-bottom: unset;
}

/*.el-table__header col[name="gutter"] {*/
/*  display: table-cell !important;*/
/*}*/

.el-table >>> .highlight {
  background: #ecf5ff;
}

.el-collapse-item >>> .el-collapse-item__header {
  justify-content: center;
  font-size: 18px;
  height: 35px;
}
>>> .el-collapse-item .el-collapse-item__header .el-collapse-item__arrow,
.search_condition {
  margin: 0;
}
</style>
