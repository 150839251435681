import serviceList from "./serviceList";
import environment from "./environmentConfig";

let apiSplicing = (env) => {
  let serviceTemp = JSON.parse(JSON.stringify(serviceList[env]));
  let serviceUrl = {};
  let separator = "/"; //分割符
  let colon = ":"; //冒号
  for (let i in serviceTemp) {
    if (serviceTemp[i].url[serviceTemp[i].url.length - 1] === "/") {
      //如果url最后一位是/
      serviceUrl[i] = serviceTemp[i].url.slice(0, -1);
    } else {
      serviceUrl[i] = serviceTemp[i].url;
    }
    if (serviceTemp[i].port) {
      //有端口
      serviceUrl[i] = serviceUrl[i] + colon + serviceTemp[i].port;
    } else {
      //无端口
      serviceUrl[i] = serviceUrl[i] + ""; //eslint
    }
    if (serviceTemp[i].suffix && serviceTemp[i].suffix[0] === "/") {
      //有后缀且有分隔符
      serviceUrl[i] = serviceUrl[i] + serviceTemp[i].suffix;
    } else if (serviceTemp[i].suffix && serviceTemp[i].suffix[0] !== "/") {
      //有后缀且无分隔符
      serviceUrl[i] = serviceUrl[i] + separator + serviceTemp[i].suffix;
    }
    serviceUrl[i] = serviceUrl[i] + separator; //加上最后的/
  }
  return serviceUrl;
};

const services = {
  dev: apiSplicing("dev"),
  test: apiSplicing("test"),
  production: apiSplicing("production"),
};

const baseUrls = services[environment];
export default baseUrls;
