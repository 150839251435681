<template>
  <el-container class="container" direction="vertical">
    <el-card class="display_add">
      <el-collapse accordion v-model="activeNames">
        <el-collapse-item name="1">
          <template slot="title">
            <div style="font-size: 16px">管控区域维护</div>
          </template>
          <el-form
            :label-width="formLabelWidth"
            :inline="false"
            class="demo-from-inline"
          >
            <el-row>
              <el-col>
                <el-button-group
                  style="float: right; margin-bottom: 10px; margin-right: 60px"
                >
                  <el-button
                    style="margin-right: 10px"
                    type="primary"
                    @click="save"
                    >保存</el-button
                  >
                  <el-button style="margin-right: 10px" @click="goBack"
                    >返回</el-button
                  >
                </el-button-group>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :xs="6" :sm="6" :md="6" :lg="6" :xl="6">
                <el-form-item label="区域/地块id">
                  <el-input
                    v-model="basicAreaInfo.ctlId"
                    :onlyread="true"
                    style="width: 100%"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :xs="18" :sm="18" :md="18" :lg="18" :xl="18">
                <el-form-item label="选择行政区">
                  <v-distpicker
                    :province="basicAreaInfo.province"
                    :city="basicAreaInfo.city"
                    :area="basicAreaInfo.area"
                    @province="onChangeProvince"
                    @city="onChangeCity"
                    @area="onChangeArea"
                    style="width: 100%"
                  ></v-distpicker>
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                <el-form-item label="街道">
                  <el-input
                    v-model="basicAreaInfo.street"
                    style="width: 91%"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
                <el-form-item label="区域名称">
                  <el-input v-model="basicAreaInfo.areaName"></el-input>
                </el-form-item>
              </el-col>
              <el-col :xs="6" :sm="6" :md="6" :lg="6" :xl="6">
                <el-form-item label="地块面积">
                  <el-input
                    style="width: 75%"
                    v-model="basicAreaInfo.landArea"
                    autocomplete="off"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :xs="6" :sm="6" :md="6" :lg="6" :xl="6">
                <el-form-item
                  label="面积单位"
                  prop="unit"
                  label-width="LabelWidth"
                >
                  <el-select
                    v-model="basicAreaInfo.unit"
                    placeholder="请选择"
                    style="width: 40%"
                  >
                    <el-option
                      v-for="item in unit"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col>
                <el-form-item label="地块坐标">
                  <el-input
                    v-model="basicAreaInfo.local"
                    :readonly="true"
                  ></el-input>
                  <el-button @click="dialogVisible = true">坐标录入</el-button>
                </el-form-item>
              </el-col>
              <el-col :xs="14" :sm="12" :md="14" :lg="14" :xl="14">
                <el-form-item label="访问许可">
                  <el-input
                    style="width: 100%"
                    v-model="basicAreaInfo.cameratoken"
                    autocomplete="off"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :xs="10" :sm="12" :md="10" :lg="10" :xl="10">
                <el-form-item label="直播地址">
                  <el-input
                    style="width: 78%"
                    v-model="basicAreaInfo.cameraurl"
                    autocomplete="off"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </el-collapse-item>
      </el-collapse>
    </el-card>
    <el-dialog :visible.sync="dialogVisible">
      <template slot="title">
        <div style="font-size: 18px">地块坐标录入</div>
      </template>
      <el-form ref="form" class="form-inline">
        <el-row>
          <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="6">
            <el-form-item label="经度" :label-width="LabelWidth">
              <el-input v-model="coordsForm.lng"></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="6">
            <el-form-item label="纬度" :label-width="LabelWidth">
              <el-input v-model="coordsForm.lat"></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="6">
            <el-form-item>
              <el-button @click="addCoords" size="medium">添加</el-button>
              <el-button @click="coordinate" type="primary" size="medium"
                >保存</el-button
              >
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col>
            <el-form-item>
              <el-table :data="coordsForm.coords" style="width: 100%">
                <el-table-column fixed prop="No" label="序号">
                </el-table-column>
                <el-table-column prop="lng" label="经度"> </el-table-column>
                <el-table-column prop="lat" label="纬度"> </el-table-column>
                <el-table-column fixed="right" label="操作">
                  <template slot-scope="scope">
                    <el-button
                      @click.native.prevent="
                        deleteRow(scope.$index, coordsForm.coords)
                      "
                      type="text"
                      size="small"
                      >移除
                    </el-button>
                  </template>
                </el-table-column>
              </el-table>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-dialog>
  </el-container>
</template>

<script>
import VDistpicker from "v-distpicker";
import BaiduMap from "vue-baidu-map/components/map/Map.vue";
import BmLocalSearch from "vue-baidu-map/components/search/LocalSearch.vue";

export default {
  components: {
    // BaiduMap,
    VDistpicker,
    // BmLocalSearch,
  },
  name: "CtrLandModify",
  data() {
    return {
      //公共变量
      activeNames: ["1"],
      highlightRows: new Set(),
      LabelWidth: "60px",
      formLabelWidth: "120px",
      modify_title: "管控区域录入",
      placeholder: {},
      //相关数据
      unit: [
        {
          value: "亩",
          label: "亩",
        },
        {
          value: "平方米",
          label: "平方米",
        },
        {
          value: "平方公里",
          label: "平方公里",
        },
        {
          value: "平方里",
          label: "平方里",
        },
      ],
      basicAreaInfo: {
        ctlId: "",
        province: "",
        city: "",
        area: "",
        street: "",
        areaName: "",
        local: "",
        landArea: "",
        unit: "",
        cameratoken: "",
        cameraurl: "",
      },
      coordsForm: {
        lng: "",
        lat: "",
        coords: [],
      },
      iotLand: {
        ctl_id: -1,
        ctl_address: "",
        ctl_local: "",
        ctl_name: "",
        ctl_area: "",
        ctl_image: "",
        ctl_cameratoken: "",
        ctl_cameraurl: "",
      },
      bmmapParams: {
        //这里是百度的信息，基本没有用
        zoom: 10,
        center: {
          lng: 122.130539,
          lat: 37.508969,
        },
      },
      dialogVisible: false,
    };
  },
  created() {
    this.getParams();
    this.modifyCoords();
  },
  methods: {
    tableRowClassName({ row, rowIndex }) {
      if (this.highlightRows.has(rowIndex)) {
        return "highlight";
      }
      return "";
    },
    modifyCoords: function () {
      //this.dialogVisible = true;
      if (this.basicAreaInfo.local != null) {
        console.log(this.basicAreaInfo.local);
        let t1 = this.basicAreaInfo.local.replace("{", "");
        t1 = t1.replace("}", "");
        t1 = t1.replace(/\]/g, "");
        t1 = t1.replace(/\[/g, "");
        console.log(t1);
        let t2 = t1.split(",");
        for (let i = 0; i < t2.length; i++) {
          console.log(t2[i]);
          if (t2[i] != null && t2[i] != "") {
            let t3 = t2[i++].split(":");
            this.coordsForm.coords.push({
              No: t3[0],
              lng: t3[1],
              lat: t2[i],
            });
          }
        }
      }
    },
    coordinate() {
      let loc = "{";
      var count = 0;
      for (let vulue of this.coordsForm.coords) {
        loc += vulue.No + ":[" + vulue.lng + "," + vulue.lat + "],";
        count++;
      }
      if (count < 3) {
        this.$message({
          message: "坐标数量不够三个，需要三个坐标值才能围成地块。请继续录入",
          type: "info",
        });
        return;
      }
      this.basicAreaInfo.local = loc.substr(0, loc.length - 1) + "}";
      this.dialogVisible = false;
    },
    handleSelectionChange() {},
    onChangeProvince(data) {
      this.basicAreaInfo.province = data.value;
    },
    onChangeCity(data) {
      this.basicAreaInfo.city = data.value;
    },
    onChangeArea(data) {
      this.basicAreaInfo.area = data.value;
    },
    getParams() {
      // 取到路由带过来的参数
      let land = this.$route.query; // 将数据放在当前组件的数据内
      console.log(land);
      let info = land.ctl_address.split("-");
      this.basicAreaInfo.province = info[0];
      this.basicAreaInfo.city = info[1];
      this.basicAreaInfo.area = info[2];
      this.basicAreaInfo.street = info[3];
      this.basicAreaInfo.ctlId = land.ctl_id;
      this.basicAreaInfo.areaName = land.ctl_name;
      this.basicAreaInfo.local = land.ctl_local;
      this.basicAreaInfo.landArea = land.ctl_area;
      this.basicAreaInfo.cameratoken = land.ctl_cameratoken;
      this.basicAreaInfo.cameraurl = land.ctl_cameraurl;
    },
    save: function () {
      //更新地块信息，下面的内容需要更换
      this.iotLand.ctl_address =
        this.basicAreaInfo.province +
        "-" +
        this.basicAreaInfo.city +
        "-" +
        this.basicAreaInfo.area +
        "-" +
        this.basicAreaInfo.street;
      this.iotLand.ctl_area = this.basicAreaInfo.landArea; // + this.basicAreaInfo.unit;
      this.iotLand.ctl_name = this.basicAreaInfo.areaName;
      this.iotLand.ctl_local = this.basicAreaInfo.local;
      this.iotLand.ctl_id = this.basicAreaInfo.ctlId;
      this.iotLand.ctl_cameratoken = this.basicAreaInfo.cameratoken;
      this.iotLand.ctl_cameraurl = this.basicAreaInfo.cameraurl;
      console.log(this.basicAreaInfo.province);
      console.log(this.iotLand);
      //提交到后台
      if (this.iotLand.ctl_address == "---") {
        this.$alert("地址不能为空");
        return;
      } else {
        this.$api.apiHitIotLocation
          .updatePolygon(this.iotLand)
          .then((res) => {
            if (res.data.code == 1) {
              this.$message({
                message: "存储成功！",
                type: "success",
              });
            } else {
              this.$message({
                message: "存储失败！后台异常！",
                type: "info",
              });
            }
          })
          .catch((err) => {
            console.log(err.response);
          });
      }
    },
    goBack() {
      //关闭子页面
      this.$router.go(-1); // 返回
      //this.$store.dispatch("tagsView/delView",this.$route);
      // this.$store.state.tagsView.visitedViews.splice(
      //   this.$store.state.tagsView.visitedViews.findIndex(
      //     (item) => item.path === this.$route.path
      //   ),
      //   1
      // );
      // this.$router.push(
      //   this.$store.state.tagsView.visitedViews[
      //     this.$store.state.tagsView.visitedViews.length - 1
      //   ].path
      // );
      this.$tabs.close();
    },
    addCoords() {
      this.coordsForm.coords.push({
        No: this.coordsForm.coords.length, // + 1,
        lng: this.coordsForm.lng,
        lat: this.coordsForm.lat,
      });
      this.coordsForm.lng = "";
      this.coordsForm.lat = "";
    },
    deleteRow(index, rows) {
      rows.splice(index, 1);
      this.coordsForm.coords.remove(index);
    },
  },
  watch: {
    $route: "getParams",
  },
};
</script>

<style scoped>
.el-input {
  width: 80%;
}

.el-button {
  margin-left: 10px;
}

.display_add {
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  width: 95%;
  margin: 20px auto;
  border-radius: 5px;
  text-align: left;
}

.form {
  width: 80%;
}

.el-collapse {
  border-bottom: unset;
}
.el-table__header col[name="gutter"] {
  display: table-cell !important;
}

.el-table >>> .highlight {
  background: #ecf5ff;
}

.el-collapse-item >>> .el-collapse-item__header {
  justify-content: center;
  font-size: 18px;
}
.display_add
  >>> .el-collapse-item
  .el-collapse-item__header
  .el-collapse-item__arrow {
  margin: 0;
}
</style>
