//设备运行管理
import runTest from "../../views/hitIotRun/runTest";

const routerHitIotRun = [
  {
    path: "runTest",
    component: runTest,
    meta: {
      title: "设备运行管理",
    },
  },
];
export default routerHitIotRun;
