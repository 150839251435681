<template>
  <div>
    <div class="table-header">
      <div style="font-size: 16px; font-weight: bold; color: #909399">字段</div>
      <el-button round @click="addField" size="mini" type="primary" plain
        >添加</el-button
      >
    </div>
    <div class="table">
      <el-table
        :data="tableList"
        style="width: 100%"
        :header-row-style="{
          color: '#303133',
          'font-weight': 'bold',
        }"
        :header-cell-style="{ background: '#eef1f6', padding: '5px' }"
        :cell-style="{ padding: '5px' }"
      >
        <!--      header-cell-style里把表头高度调小一点-->
        <!--      cell-style里把普通表格高度调小一点-->
        <el-table-column prop="range" label="偏移值" min-width="16%">
          <template slot-scope="scope">
            {{ scope.row.range.from }}-{{ scope.row.range.to }}
          </template>
        </el-table-column>
        <el-table-column prop="name" label="名称" min-width="16%">
        </el-table-column>
        <el-table-column prop="len" label="长度" min-width="16%">
        </el-table-column>
        <el-table-column prop="default" label="默认值" min-width="16%">
        </el-table-column>
        <el-table-column prop="model" label="模型映射" min-width="16%">
        </el-table-column>
        <el-table-column label="操作" min-width="16%">
          <template slot-scope="scope">
            <el-button
              @click="modiField(scope.row)"
              type="text"
              style="font-size: 16px"
              >修改</el-button
            >
            <el-divider direction="vertical"></el-divider>
            <el-button
              @click="delField(scope.row)"
              type="text"
              style="font-size: 16px"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <br />
    <div class="paginationClass">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[5, 10, 20]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <el-dialog
      title="添加字段"
      :visible.sync="dialogAddField"
      width="50%"
      :close-on-click-modal="false"
      @close="resetAdd"
      v-dialogDrag
      append-to-body
    >
      <!--          当要在对话框中再打开一次对话框时，会发现新打开的对话框是暗的，这时候要在标签里加入append-to-body就可以了-->
      <el-form
        :model="form"
        :rules="rules"
        ref="addForm"
        label-width="100px"
        class="demo-ruleForm"
        status-icon
      >
        <el-form-item label="地址域" prop="checked">
          <el-checkbox v-model="form.checked">是否标记为地址域</el-checkbox>
        </el-form-item>
        <el-form-item label="字段名称" prop="name">
          <el-input
            :disabled="addressFieldExist"
            v-model="form.name"
            type="text"
          ></el-input>
        </el-form-item>
        <el-form-item label="描述" prop="desc">
          <el-input type="textarea" v-model="form.desc"></el-input>
        </el-form-item>
        <el-form-item label="偏移值" prop="range">
          <el-input
            disabled
            :placeholder="form.range.from + '-' + form.range.to"
          ></el-input>
        </el-form-item>
        <el-form-item label="长度" prop="len">
          <el-tooltip
            class="item"
            effect="dark"
            content="输入值为字节长度。"
            placement="right"
          >
            <el-input v-model.number="form.len"></el-input>
          </el-tooltip>
        </el-form-item>
        <el-form-item label="默认值" prop="default">
          <el-tooltip
            class="item"
            effect="dark"
            content="该字段在码流中的默认值。"
            placement="right"
          >
            <el-input v-model="form.default"></el-input>
          </el-tooltip>
        </el-form-item>
        <el-form-item label="映射模型" prop="model">
          <el-select v-model="form.model" placeholder="请选择">
            <el-option
              v-for="item in modelOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item style="text-align: right">
          <!--          <el-button type="primary" @click="submitForm('ruleForm')">立即创建</el-button>-->
          <!--          <el-button @click="resetForm('ruleForm')">重置</el-button>-->
          <el-button @click="resetAddForm()">取 消</el-button>
          <el-button type="primary" @click="submitAddForm()">确 定</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

    <el-dialog
      title="修改字段"
      :visible.sync="dialogModiField"
      width="50%"
      :close-on-click-modal="false"
      @close="resetModi"
      v-dialogDrag
      append-to-body
    >
      <!--          当要在对话框中再打开一次对话框时，会发现新打开的对话框是暗的，这时候要在标签里加入append-to-body就可以了-->
      <el-form
        :model="form"
        :rules="rules"
        ref="modiForm"
        label-width="100px"
        class="demo-ruleForm"
        status-icon
      >
        <el-form-item label="地址域" prop="checked">
          <el-checkbox v-model="form.checked">是否标记为地址域</el-checkbox>
        </el-form-item>
        <el-form-item label="字段名称" prop="name">
          <el-input
            :disabled="addressFieldExist"
            v-model="form.name"
            type="text"
          ></el-input>
        </el-form-item>
        <el-form-item label="描述" prop="desc">
          <el-input type="textarea" v-model="form.desc"></el-input>
        </el-form-item>
        <el-form-item label="偏移值" prop="range">
          <el-input
            disabled
            :placeholder="form.range.from + '-' + form.range.to"
          ></el-input>
        </el-form-item>
        <el-form-item label="长度" prop="len">
          <el-tooltip
            class="item"
            effect="dark"
            content="输入值为字节长度。"
            placement="right"
          >
            <el-input v-model.number="form.len"></el-input>
          </el-tooltip>
        </el-form-item>
        <el-form-item label="默认值" prop="default">
          <el-tooltip
            class="item"
            effect="dark"
            content="该字段在码流中的默认值。"
            placement="right"
          >
            <el-input v-model="form.default"></el-input>
          </el-tooltip>
        </el-form-item>
        <el-form-item label="映射模型" prop="model">
          <el-select v-model="form.model" placeholder="请选择">
            <el-option
              v-for="item in modelOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item style="text-align: right">
          <!--          <el-button type="primary" @click="submitForm('ruleForm')">立即创建</el-button>-->
          <!--          <el-button @click="resetForm('ruleForm')">重置</el-button>-->
          <el-button @click="resetModiForm()">取 消</el-button>
          <el-button type="primary" @click="submitModiForm()">确 定</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "message",
  data() {
    let checkNum = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请输入字节长度"));
      }
      setTimeout(() => {
        if (!Number.isInteger(value)) {
          callback(new Error("请输入整数值"));
        } else if (value <= 0) {
          callback(new Error("字节长度不能小于0"));
        } else {
          callback();
        }
      }, 1000);
    };
    // let checkDefault = (rule, value, callback) => {
    //   if (!value) {
    //     return callback(new Error("请输入默认值"));
    //   }
    //   setTimeout(() => {
    //     const patt =
    //     if (!Number.isInteger(value)) {
    //       callback(new Error("请输入整数值"));
    //     } else if (value <= 0) {
    //       callback(new Error("字节长度不能小于0"));
    //     } else {
    //       callback();
    //     }
    //   }, 1000);
    // };
    // 改
    return {
      modelOptions: [
        {
          label: "属性1",
          value: "属性1",
        },
        {
          label: "属性2",
          value: "属性2",
        },
      ],
      // 后端根据是上保数据还是下发命令，返回可供映射的模型
      total: 0,
      currentPage: 1,
      pageSize: 10,
      tableData: [],
      tableList: [],
      dialogAddField: false,
      flag: true,
      addressFieldExist: false,
      from: 0,
      to: 0,
      form: {
        checked: "",
        name: "",
        desc: "",
        range: {
          from: "",
          to: "",
        },
        len: "",
        default: "",
        model: "",
      },
      rules: {
        name: [{ required: true, message: "请输入字段名称", trigger: "blur" }],
        len: [{ required: true, validator: checkNum, trigger: "blur" }],
        default: [{ required: true, message: "请输入默认值", trigger: "blur" }],
        model: [
          { required: true, message: "请选择映射模型", trigger: "change" },
        ],
      },
      index: "",
      // 记录要修改的字段的下标
      dialogModiField: false,
    };
  },
  methods: {
    deepClone: function deepClone(target) {
      // 定义一个变量
      let result;
      // 如果当前需要深拷贝的是一个对象的话
      if (typeof target === "object") {
        // 如果是一个数组的话
        if (Array.isArray(target)) {
          result = []; // 将result赋值为一个数组，并且执行遍历
          for (let i in target) {
            // 递归克隆数组中的每一项
            result.push(deepClone(target[i]));
          }
          // 判断如果当前的值是null的话；直接赋值为null
        } else if (target === null) {
          result = null;
          // 判断如果当前的值是一个RegExp对象的话，直接赋值
        } else if (target.constructor === RegExp) {
          result = target;
        } else {
          // 否则是普通对象，直接for in循环，递归赋值对象的所有值
          result = {};
          for (let i in target) {
            result[i] = deepClone(target[i]);
          }
        }
        // 如果不是对象的话，就是基本数据类型，那么直接赋值
      } else {
        result = target;
      }
      // 返回最终结果
      return result;
    },
    getArrayIndex(arr, obj) {
      let i = arr.length;
      while (i--) {
        if (arr[i].name === obj.name) {
          return i;
        }
      }
      return -1;
    },
    // 根据name相同获取数组下标的函数，搭配delField使用
    handleSizeChange(pageSize) {
      // 每页条数切换
      this.pageSize = pageSize;
      this.handleCurrentChange(this.currentPage);
    },
    handleCurrentChange(currentPage) {
      //页码切换
      this.currentPage = currentPage;
      this.currentChangePage(this.tableData, currentPage);
    },
    //分页方法（重点）
    currentChangePage(list, currentPage) {
      let from = (currentPage - 1) * this.pageSize;
      let to = currentPage * this.pageSize;
      this.tableList = [];
      for (; from < to; from++) {
        if (list[from]) {
          this.tableList.push(list[from]);
        }
      }
    },
    addField() {
      if (this.tableData.length === 0) {
        this.from = this.to = 0;
      } else {
        this.from = this.to =
          this.tableData[this.tableData.length - 1].range.to;
      }
      this.form.range.from = this.from;
      this.form.range.to = this.to;
      this.dialogAddField = true;
    },
    modiField(row) {
      this.index = this.getArrayIndex(this.tableData, row);
      this.from = this.tableData[this.index].range.from;
      this.to = this.tableData[this.index].range.to;
      // 不能this.form = this.deepClone(row);这么写，这样会使this.$refs["form"].resetFields()失效
      this.form = this.deepClone(row);
      this.dialogModiField = true;
    },
    delField(row) {
      this.$confirm("此操作将永久删除该参数, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        const index = this.getArrayIndex(this.tableData, row);
        let i = index;
        this.from = this.to = this.tableData[index].range.from;
        this.tableData.splice(index, 1);
        for (; i < this.tableData.length; ++i) {
          this.tableData[i].range.from = this.to;
          this.from =
            this.to =
            this.tableData[i].range.to =
              this.to + this.tableData[i].len;
        }
        this.currentChangePage(this.tableData, this.currentPage);
        this.total = this.tableData.length;
        this.$message({
          type: "success",
          message: "删除成功!",
        });
      });
    },
    resetAddForm() {
      this.dialogAddField = false;
    },
    resetModiForm() {
      this.dialogModiField = false;
    },
    resetAdd() {
      this.$refs["addForm"].resetFields();
    },
    resetModi() {
      this.form = {
        checked: "",
        name: "",
        desc: "",
        range: {
          from: "",
          to: "",
        },
        len: "",
        default: "",
        model: "",
      };
      // this.$refs["modiForm"].resetFields(); 该写法应该是基于表单输入，通过置空输入来置空form（通过v-model）
    },
    submitAddForm() {
      this.flag = true;
      let i;
      for (i = 0; i < this.tableData.length; ++i) {
        if (this.tableData[i].name === this.form.name) {
          this.resetAddForm();
          this.$message({
            type: "error",
            message: "同名字段已经存在!",
          });
          this.flag = false;
          break;
        }
      }
      if (this.flag) {
        this.$refs["addForm"].validate((valid) => {
          if (valid) {
            this.tableData.push(this.deepClone(this.form));
            this.$emit("sync-field", this.tableData);
            this.to = this.from = this.form.range.to;
            this.resetAddForm();
            this.currentChangePage(this.tableData, this.currentPage);
            this.total = this.tableData.length;
            this.$message({
              type: "success",
              message: "创建成功!",
            });
          } else {
            return false;
          }
        });
      }
    },
    submitModiForm() {
      this.flag = true;
      let i;
      for (i = 0; i < this.tableData.length; ++i) {
        if (
          this.tableData[i].name === this.form.name &&
          this.form.name !== this.tableData[this.index].name
        ) {
          this.resetModiForm();
          this.$message({
            type: "error",
            message: "同名字段已经存在!",
          });
          this.flag = false;
          break;
        }
      }
      if (this.flag) {
        this.$refs["modiForm"].validate((valid) => {
          if (valid) {
            this.tableData[this.index] = this.deepClone(this.form);
            let i = this.index + 1;
            for (; i < this.tableData.length; ++i) {
              this.tableData[i].range.from = this.to;
              this.from =
                this.to =
                this.tableData[i].range.to =
                  this.to + this.tableData[i].len;
            }
            // 修改偏移值后重新设置后面字段的偏移值
            this.$emit("sync-field", this.tableData);
            this.resetModiForm();
            this.currentChangePage(this.tableData, this.currentPage);
            this.total = this.tableData.length;
            this.$message({
              type: "success",
              message: "创建成功!",
            });
          } else {
            return false;
          }
        });
      }
    },
  },
  watch: {
    "form.len": {
      handler(newLen, oldLen) {
        if (Number.isInteger(this.form.len) && this.form.len > 0) {
          this.to = this.from + this.form.len;
          this.form.range.to = this.to;
        } else {
          this.to = this.from;
          this.form.range.to = this.to;
          // 也要兼顾len为0的情况
        }
      },
    },
    "form.checked": {
      handler(newLen, oldLen) {
        if (this.form.checked === true) {
          this.form.name = "messageId";
          this.addressFieldExist = true;
        } else {
          this.form.name = "";
          this.addressFieldExist = false;
        }
      },
    },
  },
};
</script>

<style scoped>
.item {
  margin-bottom: 18px;
}
.table {
  padding: 10px;
}
.table-header {
  width: calc(100% - 20px);
  display: flex;
  justify-content: space-between;
  margin: 0 10px 10px 10px;
}
</style>
