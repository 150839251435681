//设备分类管理
import axiosInstanceMethods from "@/config/request";

const apiHitIotModels = {
  //模型主体信息新增
  modelAdd: (data) =>
    axiosInstanceMethods()["iotPlatform"].request({
      method: "post",
      url: "/product/createProducts",
      data: {
        pDesc: data.desc,
        p_cname: data.nameC,
        p_dataformat: data.dataFormat,
        p_ename: data.nameE,
        p_protocol: data.protocolType,
        p_type: data.modelType,
        ctrl_device: data.ctrlDevice,
      },
    }),
  //模型主体信息修改 这里也需要修改  2023.02.14
  modelModify: (data, model_id) =>
    axiosInstanceMethods()["iotPlatform"].request({
      method: "post",
      url: "/product/modifyProduct",
      data: {
        pDesc: data.desc,
        p_cname: data.nameC,
        p_dataformat: data.dataFormat,
        p_ename: data.nameE,
        p_protocol: data.protocolType,
        p_type: data.modelType,
        model_id: data.id,
        ctrl_device: data.ctrlDevice,
      },
    }),
  //模型发布
  modelRelease: (data) =>
    axiosInstanceMethods()["iotPlatform"].request({
      method: "post",
      url: "/product/modelOnlineCommand",
      data: data,
    }),
  //编码解码规则新增
  codeRuleAdd: (data, model_id) =>
    axiosInstanceMethods()["iotPlatform"].request({
      method: "post",
      url: "/product/creatMessageCodingRule",
      data: {
        me_c_name: data.nameC,
        me_e_name: data.nameE,
        me_pid: model_id,
        //"me_resp_to": 5,
        me_tag: data.messageId,
        me_tag_end: data.end,
        me_tag_start: data.start,
        me_type: data.response === "否" ? 0 : 1,
      },
    }),
  //编码解码规则修改
  codeRuleModify: (data, model_id) => {
    return axiosInstanceMethods()["iotPlatform"].request({
      method: "post",
      url: "/product/ModifyMessage",
      data: {
        me_c_name: data.nameC,
        me_e_name: data.nameE,
        me_pid: model_id,
        //"me_resp_to": 5,
        me_tag: data.messageId,
        me_tag_end: data.end,
        me_tag_start: data.start,
        me_type: data.response === "否" ? 0 : 1,
        me_id: data.message_id,
      },
    });
  },
  //编码解码规则删除
  codeRuleDel: (data) =>
    axiosInstanceMethods()["iotPlatform"].request({
      method: "post",
      url: "/product/deleteMessage",
      data: {
        message_id: data,
      },
    }),
  //绑定提交——二进制数据流(JSON暂时没写)
  //保存按钮和绑定提交都会调用这个接口，区别在于保存会对属性进行重新暂存，绑定提交就是生成绑定规则并上线，对应save_flag字段
  attributeBindingSubmit: (props, model_id, message_id, save_flag) =>
    axiosInstanceMethods()["iotPlatform"].request({
      method: "post",
      url: "/product/modelPS",
      data: {
        mid: message_id,
        product: model_id,
        //prop列表在js中重新定义
        prop: props,
        save_flag: save_flag,
      },
    }),

  //查询已有的模型id
  modelIdListQuery: (data) =>
    axiosInstanceMethods()["iotPlatform"].request({
      method: "get",
      url: "",
      params: data,
    }),
  //根据用户输入的模型主体信息来查询已有的一个或一组模型
  modelQuery: (data, pageSize, currentPage) =>
    axiosInstanceMethods()["iotPlatform"].request({
      method: "post",
      url: "/product/queryProducts",
      params: {
        pageSize: pageSize,
        pageNum: currentPage,
      },
      data: {
        p_cname: data.nameC,
        p_dataformat: data.dataFormat,
        p_ename: data.nameE,
        p_id: data.id,
        p_protocol: data.protocolType,
        p_type: data.modelType,
        ctrl_device: data.ctrlDevice,
      },
    }),
  //模型删除
  modelDelete: (data) =>
    axiosInstanceMethods()["iotPlatform"].request({
      method: "post",
      url: "/product/modelDelete",
      data: {
        model_id: data,
      },
    }),
  //模型废除
  modelAbolish: (data) =>
    axiosInstanceMethods()["iotPlatform"].request({
      method: "post",
      url: "",
      data: data,
    }),
  //模型粘贴——即一次性录入一堆数据，区别于正常录入时的一条一条地录入
  modelDataPaste: (data) =>
    axiosInstanceMethods()["iotPlatform"].request({
      method: "post",
      url: "",
      data: data,
    }),
  //模型修改数据请求
  //data：模型id字符串类型
  onModelModifyDataQuery: (data) =>
    axiosInstanceMethods()["iotPlatform"].request({
      method: "post",
      url: "/product/queryProducts",
      params: {
        pageSize: 1,
        pageNum: 1,
      },
      //请求体中携带模型id
      data: {
        p_id: data,
      },
    }),
  //data：模型id字符串类型
  onMessageModifyDataQuery: (data) =>
    axiosInstanceMethods()["iotPlatform"].request({
      method: "post",
      url: "/product/queryMessageInfo",
      params: {
        pageSize: 0,
        pageNum: 0,
        productId: data,
      },
    }),
  ListProductProperties:(data) =>
    axiosInstanceMethods()["iotPlatform"].request({
      method: "post",
      url: "/product/queryMessageInfo",
      params: {
        pageSize: 0,
        pageNum: 0,
        productId: data,
      },
    }),
};
export default apiHitIotModels;
