//监控视频
import axiosInstanceMethods from "../../config/request";

const apiVideo = {
  getAccessToken: (data, tokenurl) =>
    axiosInstanceMethods()["monitorEZVIZ"].request({
      method: "post",
      url: tokenurl, //"/lapp/token/get",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: data,
    }),
};
export default apiVideo;
