<template>
  <el-card style="margin: 50px 100px" body-style="{padding:20px}">
    <div slot="header">
      <span style="margin-right: 36px">设备详情</span>
      <el-tag v-if="deviceInfo.ins_online" type="success">正常运行</el-tag>
      <el-tag v-else-if="deviceInfo.ins_online" type="info">离线</el-tag>
      <el-tag v-else type="warning">异常</el-tag>
      <el-button style="float: right; padding: 3px 0" type="text"
        >设备控制</el-button
      >
    </div>
    <el-row>
      <el-col :span="11">
        <el-divider>设备基本信息</el-divider>
        <el-card>
          <el-form class="info" :model="deviceInfo" label-position="top" inline>
            <el-form-item label="设备名称">
              <el-input v-model="deviceInfo.i_c_name" readonly></el-input>
            </el-form-item>
            <el-form-item label="设备序列号">
              <el-input v-model="deviceInfo.ci_sn" readonly></el-input>
            </el-form-item>
            <el-form-item label="设备型号">
              <el-input v-model="deviceInfo.int_longitude" readonly></el-input>
            </el-form-item>
            <el-form-item label="设备类型">
              <el-input v-model="deviceInfo.int_longitude" readonly></el-input>
            </el-form-item>
            <el-form-item label="设备安装时间">
              <el-input v-model="deviceInfo.ins_date" readonly></el-input>
            </el-form-item>
            <el-form-item label="维护周期">
              <el-input v-model="deviceInfo.ins_mperiod" readonly></el-input>
            </el-form-item>
            <el-form-item label="负责人">
              <el-input v-model="deviceInfo.ins_admin" readonly></el-input>
            </el-form-item>
            <!--          <el-form-item label="工作状态">-->
            <!--          </el-form-item>-->
            <el-form-item label="设备所在区域">
              <el-input
                v-model="deviceInfo.ins_region"
                readonly
                v-if="hasInstalled === true"
              >
                <el-button
                  slot="append"
                  icon="el-icon-map-location"
                ></el-button>
              </el-input>
              <el-tag v-if="hasInstalled === false">设备未安装</el-tag>
            </el-form-item>
          </el-form>
        </el-card>

        <!--选择展示操作指令下达框,目前是写死的，最好是能根据orderform中的指令规则动态展示多条指令-->
        <el-divider v-if="hasOrder === true">操作指令下达</el-divider>
        <el-card v-if="hasOrder === true">
          <el-card>
            <el-form :model="orderForm[0]" ref="orderForm" label-width="100px">
              <el-row>
                <el-col :span="5">
                  <el-form-item label="指令名称" prop="name">
                    <!--<el-input v-model="orderForm.ordername" readonly ></el-input>-->
                    <el-tag>开关指令</el-tag>
                  </el-form-item>
                </el-col>
                <!--这里动态加载orderForm.transmitParams中的指令为什么不行呢，根本没有-->
                <!--原因是1.标签中配置=后面如果是对象引用，要使用:label
                         2.之前没有指定orderForm中数组的下标，所以注意看控制台的报错信息，Invalid prop: type check failed for prop "model". Expected Object, got Array
                         就是说你想取到的是一个对象，其实说的是上面你:model的问题，表示对象引用你却给了一个数组进去，我将orderForm改为orderForm[0]就不报错了，后面也是一样的问题
                         orderForm.transmitParams是娶不到东西的，因为Form是一个数组，你需要指定具体是哪一个元素，或者前面套一层v-for
                -->
                <!--<el-col
                  v-for="(data, index) in orderForm[0].transmitParams"
                  :key="index"
                >
                  <el-form-item :label="data.param" prop="name">
                    <el-select v-model="orderReqBody.properties.switchNum">
                      <el-option :label="data.区阀1" value="15" />
                    </el-select>
                  </el-form-item>
                </el-col>-->
                <el-row>
                  <el-col :span="8">
                    <el-form-item label="switchNum" prop="name">
                      <el-select v-model="orderReqBody.properties.switchNum">
                        <el-option label="水泵" value="5" />
                        <el-option label="肥泵" value="6" />
                        <el-option label="搅拌泵1" value="7" />
                        <el-option label="搅拌泵2" value="8" />
                        <el-option label="搅拌泵3" value="9" />
                        <el-option label="搅拌泵4" value="10" />
                        <el-option label="肥阀1" value="11" />
                        <el-option label="肥阀2" value="12" />
                        <el-option label="肥阀3" value="13" />
                        <el-option label="肥阀4" value="14" />
                        <el-option label="区阀1" value="15" />
                        <el-option label="区阀2" value="16" />
                        <el-option label="区阀3" value="17" />
                        <el-option label="区阀4" value="18" />
                        <el-option label="区阀5" value="19" />
                        <el-option label="区阀6" value="20" />
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item label="switch" prop="name">
                      <el-select v-model="orderReqBody.properties.switch">
                        <el-option label="ON" value="01" />
                        <el-option label="OFF" value="00" />
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-button
                    style="float: right"
                    type="primary"
                    @click="sendCmd()"
                    >发送</el-button
                  >
                </el-row>
              </el-row>
            </el-form>
          </el-card>
        </el-card>

        <el-divider>最新监测数据</el-divider>
        <el-row style="margin-text-outline: 100px">
          <el-col v-for="(data, index) of lastestData" :key="index" :span="8">
            <el-card>
              <div slot="header">
                <span style="margin-right: 20px">{{ data.p_c_name }}</span>
                <el-tag v-if="data.sta_abnormal === '0'" type="success"
                  >正常</el-tag
                >
                <el-tag v-else type="warning">异常</el-tag>
                <el-button
                  style="float: right; padding: 3px 0"
                  type="text"
                  @click="loadData()"
                  >刷新</el-button
                >
              </div>
              <div style="text-align: center">
                <span
                  style="font-size: 36px; color: #9588e3; margin: 20px 20px"
                  >{{ data.sta_value }}</span
                ><span>{{ data.sta_unit }}</span>
                <p></p>
                <time class="time">{{ data.sta_time }}</time>
              </div>
            </el-card>
            .
          </el-col>
        </el-row>
      </el-col>
      <el-col :span="11" :offset="2">
        <el-divider>历史观测数据</el-divider>
        <el-row>
          <el-table
            :data="records"
            border
            highlight-current-row
            :row-class-name="tableRowClassName"
            :default-sort="{ prop: 'sta_time', order: 'descending' }"
            size="mini"
            :header-cell-style="{ 'font-size': '14px', 'font-weight': 'bold' }"
          >
            <el-table-column
              label="名称"
              fixed
              prop="p_c_name"
            ></el-table-column>
            <!--  <el-table-column
                                label="测量值"
                                fixed
                                prop="sta_value"
                        ></el-table-column>
                        <el-table-column label="单位" prop="p_e_unit"></el-table-column>
                        <el-table-column label="含义" prop="p_desc"></el-table-column>-->
            <el-table-column
              label="测量时间"
              prop="sta_time"
              sortable
            ></el-table-column>
          </el-table>
          <!-- 分页 -->
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :page-sizes="[10, 15, 20]"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
          ></el-pagination>
          <el-divider>日志及告警信息</el-divider>
          <el-card class="infoCard">
            <ul style="color: #7abef1; font-size: 20px">
              <li class="left-li">日志信息</li>
              <li class="right-li">时间</li>
            </ul>
            <vue-seamless-scroll
              :data="warnings"
              class="seamless-warp"
              :class-option="classOption"
            >
              <ul
                v-for="(item, index) in warnings"
                :key="index"
                style="color: #f6b18c"
              >
                <li class="left-li" v-text="item.logInfo"></li>
                <li class="right-li" v-text="item.eventTime"></li>
              </ul>
            </vue-seamless-scroll>
          </el-card>
        </el-row>
      </el-col>
    </el-row>
  </el-card>
</template>

<script>
import vueSeamlessScroll from "vue-seamless-scroll";
export default {
  name: "rtDeviceDetail",
  components: {
    vueSeamlessScroll,
  },
  computed: {
    classOption() {
      return {
        step: 0.2, // 数值越大速度滚动越快
        limitMoveNum: 2, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
      };
    },
  },

  created() {
    const { loadData } = this;
    const { loadStatistics } = this;
    const { loadLogs } = this;
    console.log(this.warnings);
    //此项可以打印，但是没有params，估计是跳转的时候没有获取到数据所以params是空的
    console.log(this.$router.currentRoute);
    console.log("_____________________________________");
    console.log(this.$router.currentRoute.params.id);
    if (this.$router.currentRoute.params.id) {
      console.log("已经将params.id存入到sessionstorage中");
      sessionStorage.setItem("ins_id", this.$router.currentRoute.params.id);
    }

    loadData();
    loadStatistics();
    loadLogs();
    setInterval(loadData, 10000);
    setInterval(loadStatistics, 200000);
    setInterval(loadLogs, 100000);
  },
  data() {
    return {
      total: 0,
      currentPage:1,
      pageSize: 10,
      // 请求数据
      queryInfo: {
        query: "",
        pageNum: 1,
        pageSize: 10,
      },
      hasOrder: false,
      hasInstalled: true,
      deviceInfo: {},
      records: [],
      warnings: [
        /*{
          logInfo: "danger warning",
          eventTime: "2021/11/10",
        },
        {
          logInfo: "操作成功",
          eventTime: "2021/11/10",
        },
        {
          logInfo: "操作成功",
          eventTime: "2021/11/10",

        },*/
      ],
      lastestData: [],
      //指令规则列表
      orderForm: [
        {
          ordername: "开关指令",
          transmitParams: [
            {
              param: "switchNum",
              enummap: {
                区阀1: "15",
                区阀2: "16",
                区阀3: "17",
                区阀4: "18",
              },
              desc: "开关名称",
            },
            {
              param: "switchNum",
              enummap: {
                ON: "01",
                OFF: "00",
              },
              desc: "开关操作",
            },
          ],
        },
      ],
      //发送指令的请求体
      orderReqBody: {
        deviceId: "1",
        messageId: "5",
        properties: {
          switch: "",
          switchNum: "",
        },
      },
    };
  },
  methods: {
    async loadData() {
      const id = sessionStorage.getItem("ins_id");
      // const { data: res } = await this.$http.get(  //2023.1.5 张小东修改
      //   "http://122.9.150.203:8081/iotweb/device/?sta_install_id=" + id
      // );
      const { data: res } = await this.$api.apiHitIotRun.deviceMonitorInfo(id);
      //给hasOrder赋值，表明此设备有无指令,先不修改后台api，直接判断设备id是否为1
      if (id === "1") {
        this.hasOrder = true;
      }
      //给hasInstallled赋值，表名此设备是否已经安装
      if (res.data.deviceInfo.ins_region === null) {
        this.hasInstalled = false;
      }
      this.deviceInfo = res.data.deviceInfo;
      this.lastestData = res.data.latestData;
      //注意此方法将warning覆盖，并且属性名差太多直接给弄undefined了
      //this.warnings = res.data.warnings;
      // console.log(res)
    },
    async loadStatistics() {
      const id = sessionStorage.getItem("ins_id");
      //this.queryInfo.sta_install_id = id;
      //let params = this.queryInfo;
      // const { data: res } = await this.$http.get(
      //   "http://122.9.150.203:8081/iotweb/statistics",
      //   {
      //     params: params,
      //   }
      // );
      console.log("query:"+this.queryInfo);
      const { data: res } = await this.$api.apiHitIotRun.deviceStatistics(this.queryInfo.query,this.queryInfo.pageNum,this.queryInfo.pageSize,id);
      // console.log("res:"+res.data);
      this.records = [];
      let that = this;
      res.data.forEach(function (item) {
        let temp = {
          p_c_name: item.staValue,
          //p_e_unit: item.sta_parameter.p_e_unit,
          //p_desc: item.sta_parameter.p_desc,
          //sta_value: item.sta_value,
          sta_time: item.staTime,
        };
        that.records.push(temp);
      });
      console.log("res.total:"+this.total);
      this.total = res.total;
    },
    loadLogs: async function () {
      const id = sessionStorage.getItem("ins_id");
      this.$api.apiHitIotRun.listLogsAndWarnings(1, 10, id).then((res) => {
        //置空warnings
        this.warnings = [];
        for (let i = 0; i < res.data.data.length; i++) {
          this.warnings.push({
            logInfo: res.data.data[i].loginfo,
            eventTime: res.data.data[i].eventtime,
          });
        }
      });

      // this.$http  //2023.1.5 张小东修改，这里需要修改，目前是写死的内容，如页号和页长等
      //   .get(
      //     "http://122.9.150.203:8081/command/listLogsAndWarnings?pageNum=1&pageSize=5&deviceId=" +
      //       id
      //   )
      //   .then((res) => {
      //     //置空warnings
      //     this.warnings = [];
      //     for (let i = 0; i < res.data.data.length; i++) {
      //       this.warnings.push({
      //         logInfo: res.data.data[i].loginfo,
      //         eventTime: res.data.data[i].eventtime,
      //       });
      //     }
      //   });

      /*console.log(this.warnings);
      this.warnings.push({
        logInfo: "danger warning",
        eventTime: "2021/11/10",
      });
      this.warnings.push({
        logInfo: "danger warning",
        eventTime: "2021/11/10",
      });
      this.warnings.push({
        logInfo: "danger warning",
        eventTime: "2021/11/10",
      });*/
    },
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize;
      this.loadStatistics();
    },
    // 监听pageNum改变的事件
    handleCurrentChange(newPage) {
      this.queryInfo.pageNum = newPage;
      this.loadStatistics();
    },
    tableRowClassName({ row }) {
      if (row.abnormal) {
        return "warning-row";
      }
    },
    //向后台发送操作指令 这个指令现在基本不能使用，设备id是写死的，控制器也是写死的，等后面再修改，先把下面的绝对IP改为相对的
    sendCmd: function () {
      let msg = {
        //这些后面都要修改
        deviceId: this.orderReqBody.deviceId,
        messageId: this.orderReqBody.messageId,
        propname: this.orderReqBody.properties.switch,
        flag: this.orderReqBody.properties.switchNum,
      };
      this.$api.apiHitIotRun.sendDevCmd(msg).then((res) => {
        console.log("sendCmd-res:"+res.data.code);
        if (res.data.code === "1") {
          this.$message({
            message: "指令发送成功！",
            type: "success",
          });
          this.loadData();
        } else {
          this.$message({
            message: "指令发送失败！",
            type: "info",
          });
        }
      });
      // this.$http
      //   .post(
      //     "http://122.9.150.203:8081/command/sendcommand",
      //     this.orderReqBody
      //   )
      //   .then((res) => {
      //     if (res.data.code === "0") {
      //       console.log("----------执行成功---------");
      //       this.$message({
      //         type: "success",
      //         message: "指令下达成功!",
      //       });
      //       this.loadData();
      //     } else {
      //       this.$message({
      //         type: "error",
      //         message: res.data.msg,
      //       });
      //     }
      //   });
    },
  },
};
</script>

<style lang="less">
.el-table .warning-row {
  background: #fce3b6;
}

.time {
  font-size: 13px;
  color: #999;
}

.seamless-warp {
  width: 100%;
  height: calc(100% - 16px);
  overflow: hidden;
}

.infoCard {
  background: #666666;

  ul {
    list-style: none;
    padding-left: 0px;
    margin-bottom: 20px;
  }

  li {
    line-height: 30px;
    width: 33.3%;
    text-align: center;
    float: left;
  }
  .left-li {
    width: 70%;
  }
  .right-li {
    width: 30%;
  }
}
</style>
