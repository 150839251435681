<template>
  <div>
    <div class="header">
      <div style="font-size: 18px; font-weight: bold">
        <el-divider direction="vertical"></el-divider>命令
      </div>
      <el-button round @click="addCmd">添加命令</el-button>
    </div>
    <el-table
      :data="cmdList"
      style="width: 100%"
      :header-row-style="{
        color: '#303133',
        'font-size': '16px',
        'font-weight': 'bold',
      }"
      :row-style="{ 'font-size': '16px' }"
      :header-cell-style="{ background: '#eef1f6', padding: '5px' }"
      :cell-style="{ padding: '5px' }"
    >
      <el-table-column prop="name" label="命令名称" min-width="25%">
      </el-table-column>
      <el-table-column prop="transmit" label="下发参数" min-width="25%">
        <template slot-scope="scope">{{ transmit_names(scope.row) }}</template>
      </el-table-column>
      <el-table-column prop="response" label="响应参数" min-width="25%">
        <template slot-scope="scope">{{ response_names(scope.row) }}</template>
      </el-table-column>
      <el-table-column label="操作" min-width="25%">
        <template slot-scope="scope">
          <el-button
            @click="modiCmd(scope.row)"
            type="text"
            style="font-size: 16px"
            >修改</el-button
          >
          <el-divider direction="vertical"></el-divider>
          <el-button
            @click="delCmd(scope.row)"
            type="text"
            style="font-size: 16px"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <br />
    <div class="paginationClass">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[5, 10, 20]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>

    <el-dialog
      title="添加命令"
      :visible.sync="dialogAddCmd"
      width="70%"
      :close-on-click-modal="false"
      @close="resetadd"
      v-dialogDrag
    >
      <el-form
        :model="form"
        :rules="rules"
        ref="form"
        label-width="100px"
        class="demo-ruleForm"
        status-icon
      >
        <el-form-item label="命令名称" prop="name">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="下发参数">
          <cmdAddParams
            param-tpye="下发参数"
            ref="transmitParam"
            @transmit-add-param="transmitAddParam"
          ></cmdAddParams
        ></el-form-item>
        <el-form-item label="响应参数">
          <cmdAddParams
            param-tpye="响应参数"
            ref="responseParam"
            @response-add-param="responseAddParam"
          ></cmdAddParams>
        </el-form-item>
        <el-form-item style="text-align: right">
          <!--          <el-button type="primary" @click="submitForm('ruleForm')">立即创建</el-button>-->
          <!--          <el-button @click="resetForm('ruleForm')">重置</el-button>-->
          <el-button @click="resetAddForm()">取 消</el-button>
          <el-button type="primary" @click="submitAddForm()">确 定</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <!--    虽然修改对话框与添加对话框几乎一模一样，但是表单提交函数的逻辑却不一样，这意味着共用一个组件需要考虑不同场景下一些参数的传入，-->
    <!--    这使得逻辑变的有点复杂，不利于代码的阅读和维护，所以底下还是写了一个几乎一样的dialog。-->
    <el-dialog
      title="修改命令"
      :visible.sync="dialogModiCmd"
      width="70%"
      :close-on-click-modal="false"
      @close="resetmodi"
      v-dialogDrag
    >
      <el-form
        :model="modiForm"
        :rules="rules"
        ref="form"
        label-width="100px"
        class="demo-ruleForm"
        status-icon
      >
        <el-form-item label="命令名称" prop="name">
          <el-input v-model="modiForm.name"></el-input>
        </el-form-item>
        <el-form-item label="下发参数">
          <cmdModiParams
            param-tpye="下发参数"
            :product-id="productId"
            :cmd-id="cmdData[index].id"
            @transmit-modi-param="transModiParam"
          ></cmdModiParams>
        </el-form-item>
        <el-form-item label="响应参数">
          <cmdModiParams
            param-tpye="响应参数"
            :product-id="productId"
            :cmd-id="cmdData[index].id"
            @response-modi-param="responseModiParam"
          ></cmdModiParams>
        </el-form-item>
        <el-form-item style="text-align: right">
          <!--          <el-button type="primary" @click="submitForm('ruleForm')">立即创建</el-button>-->
          <!--          <el-button @click="resetForm('ruleForm')">重置</el-button>-->
          <el-button @click="resetModiForm()">取 消</el-button>
          <el-button type="primary" @click="submitModiForm()">确 定</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import cmdAddParams from "@/components/hitlotClassification/modelCommand/cmdAddParams";
import cmdModiParams from "@/components/hitlotClassification/modelCommand/cmdModiParams";
export default {
  name: "modelCommand",
  props: {
    productId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      flag: true,
      total: 0,
      currentPage: 1,
      pageSize: 10,
      cmdData: [
        {
          id: "1",
          name: "温度",
          transmit: ["switch", "switchNum"],
          response: ["response1", "response2"],
        },
      ],
      cmdList: [],
      dialogAddCmd: false,
      dialogModiCmd: false,
      index: 0,
      // index用来定位需要修改的命令下标
      form: {
        name: "",
        transmit: [],
        response: [],
      },
      modiForm: {
        name: "",
        transmit: [],
        response: [],
      },
      rules: {
        name: [{ required: true, message: "请输入属性名称", trigger: "blur" }],
      },
    };
  },
  computed: {
    transmit_names() {
      return function (row) {
        let t_list = [];
        let i;
        for (i = 0; i < row.transmit.length; ++i) {
          t_list.push(row.transmit[i]);
        }
        return t_list.join(",");
      };
    },
    response_names() {
      return function (row) {
        let t_list = [];
        let i;
        for (i = 0; i < row.response.length; ++i) {
          t_list.push(row.response[i]);
        }
        return t_list.join(",");
      };
    },
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData() {
      const res = {};
      let param = {
        produtId: this.produtId,
      };
      this.$http
        .post(
          "http://39.104.14.150:8081/hit/iot/v1/product/ListProductOrders" +
            "?produtId=" +
            param.produtId
        )
        .then((res) => {
          console.log(res.data.data.length);

          this.cmdData = [];

          //此处只插入了一条命令的相关信息，应该设置一个循环，将返回的所有命令的相关信息都插入到cmdData中
          //使用一个for循环，将
          for (var i = 0; i < res.data.data.length; i++) {
            this.cmdData.push({
              id: res.data.data[i].id,
              name: res.data.data[i].name,
              transmit: res.data.data[i].transmit,
              response: res.data.data[i].response,
            });
          }

          this.currentChangePage(this.cmdData, this.currentPage);
          this.total = this.cmdData.length;
          console.log({
            id: res.data.data.id,
            name: res.data.data.name,
            transmit: res.data.data.transmit,
            response: res.data.data.response,
          });
        });
      // 返回的cmdData要求
    },
    // 定义一个深拷贝函数  接收目标target参数
    deepClone: function deepClone(target) {
      // 定义一个变量
      let result;
      // 如果当前需要深拷贝的是一个对象的话
      if (typeof target === "object") {
        // 如果是一个数组的话
        if (Array.isArray(target)) {
          result = []; // 将result赋值为一个数组，并且执行遍历
          for (let i in target) {
            // 递归克隆数组中的每一项
            result.push(deepClone(target[i]));
          }
          // 判断如果当前的值是null的话；直接赋值为null
        } else if (target === null) {
          result = null;
          // 判断如果当前的值是一个RegExp对象的话，直接赋值
        } else if (target.constructor === RegExp) {
          result = target;
        } else {
          // 否则是普通对象，直接for in循环，递归赋值对象的所有值
          result = {};
          for (let i in target) {
            result[i] = deepClone(target[i]);
          }
        }
        // 如果不是对象的话，就是基本数据类型，那么直接赋值
      } else {
        result = target;
      }
      // 返回最终结果
      return result;
    },
    // var newData=JSON.parse(JSON.stringify(data)); 使用JSON解析函数也可以实现
    getArrayIndex(arr, obj) {
      let i = arr.length;
      while (i--) {
        if (arr[i].id === obj.id) {
          return i;
        }
      }
      return -1;
    },
    // 根据id相同获取数组下标的函数，搭配deleteService使用
    addCmd() {
      this.form = {
        name: "",
        transmit: [],
        response: [],
      };
      this.dialogAddCmd = true;
    },
    modiCmd(row) {
      this.index = this.getArrayIndex(this.cmdData, row);
      // this.form = this.deepClone(this.cmdData[this.index]);
      this.modiForm.name = row.name;
      this.dialogModiCmd = true;
    },
    delCmd(row) {
      this.$confirm("此操作将永久删除该属性, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        // 调用接口
        const res = {};
        let param = {
          productId: this.productId,
          id: this.cmdData[this.index].id,
        };
        this.$http.post("", param).then((res) => {
          if (res.data.code === "0") {
            this.loadData();
            this.$message({
              type: "success",
              message: "删除成功!",
            });
          }
        });
        // 调用接口结束
        // const index = this.getArrayIndex(this.cmdData, row);
        // this.cmdData.splice(index, 1);
        // this.currentChangePage(this.cmdData, this.currentPage);
        // this.total = this.cmdData.length;
        // this.$message({
        //   type: "success",
        //   message: "删除成功!",
        // });
      });
    },
    handleSizeChange(pageSize) {
      // 每页条数切换
      this.pageSize = pageSize;
      this.handleCurrentChange(this.currentPage);
    },
    handleCurrentChange(currentPage) {
      //页码切换
      this.currentPage = currentPage;
      this.currentChangePage(this.cmdData, currentPage);
    },
    //分页方法（重点）
    currentChangePage(list, currentPage) {
      let from = (currentPage - 1) * this.pageSize;
      let to = currentPage * this.pageSize;
      this.cmdList = [];
      for (; from < to; from++) {
        if (list[from]) {
          this.cmdList.push(list[from]);
        }
      }
    },
    submitAddForm() {
      this.flag = true;
      let i;
      for (i = 0; i < this.cmdData.length; ++i) {
        if (this.cmdData[i].name === this.form.name) {
          this.reset();
          this.$message({
            type: "error",
            message: "同名命令已经存在!",
          });
          this.flag = false;
          break;
        }
      }
      if (this.form.transmit.length === 0 || this.form.response.length === 0) {
        this.$message({
          type: "error",
          message: "请完成下发参数和响应参数的定义！",
        });
        this.flag = false;
      }
      if (this.flag) {
        this.$refs["form"].validate((valid) => {
          if (valid) {
            // 调用接口
            const res = {};
            let param = {
              productId: this.productId,
              paramdata: this.deepClone(this.form),
            };
            this.$http
              .post(
                "http://39.104.14.150:8081/hit/iot/v1/product/ListProductOrders",
                param
              )
              .then((res) => {
                if (res.data.code === "0") {
                  this.loadData();
                  this.$message({
                    type: "success",
                    message: "创建成功!",
                  });
                }
              });
            this.dialogAddCmd = false;
            // 调用接口结束
            // this.cmdData.push(this.deepClone(this.form));
            // this.dialogAddCmd = false;
            // this.currentChangePage(this.cmdData, this.currentPage);
            // this.total = this.cmdData.length;
            // this.$message({
            //   type: "success",
            //   message: "创建成功!",
            // });
          } else {
            return false;
          }
        });
      }
    },
    submitModiForm() {
      this.flag = true;
      let i;
      for (i = 0; i < this.cmdData.length; ++i) {
        if (
          this.cmdData[i].name === this.modiForm.name &&
          this.modiForm.name !== this.cmdData[this.index].name
        ) {
          this.reset();
          this.$message({
            type: "error",
            message: "同名命令已经存在!",
          });
          this.flag = false;
          break;
        }
      }
      this.$refs["form"].validate((valid) => {
        if (valid) {
          // 调用接口
          // 只改name，参数信息在子组件的接口里改
          const res = {};

          let param = {
            productId: this.productId,
            id: this.cmdData[this.index].id,
            paramdata: this.deepClone(this.modiForm),
          };
          console.log(param);
          this.$http.post("", param).then((res) => {
            if (res.data.code === "0") {
              this.loadData();
              this.$message({
                type: "success",
                message: "修改成功!",
              });
            }
          });
          // 调用接口结束
          this.dialogModiCmd = false;
          // this.cmdData[this.index] = this.deepClone(this.form);
          // // 考虑到javascript的数组、对象也有深拷贝的问题存在
          // this.dialogModiCmd = false;
          // this.currentChangePage(this.cmdData, this.currentPage);
          // this.total = this.cmdData.length;
          // this.$message({
          //   type: "success",
          //   message: "修改成功!",
          // });
        } else {
          return false;
        }
      });
    },
    resetAddForm() {
      this.dialogAddCmd = false;
    },
    resetModiForm() {
      this.dialogModiCmd = false;
    },
    resetadd() {
      this.$refs["form"].resetFields();
      this.$refs["transmitParam"].tableData = [];
      this.$refs["transmitParam"].currentChangePage(
        this.$refs["transmitParam"].tableData,
        this.$refs["transmitParam"].currentPage
      );
      this.$refs["transmitParam"].total =
        this.$refs["transmitParam"].tableData.length;

      this.$refs["responseParam"].tableData = [];
      this.$refs["responseParam"].currentChangePage(
        this.$refs["responseParam"].tableData,
        this.$refs["responseParam"].currentPage
      );
      this.$refs["responseParam"].total =
        this.$refs["responseParam"].tableData.length;
      // 把子组件的tableData也重置
    },
    resetmodi() {
      this.modiForm = {
        name: "",
        transmit: [],
        response: [],
      };
    },
    // transmitAddParam函数用于处理子组件下发参数tableData增加时同步到父组件的cmdData.transmit
    transmitAddParam(data) {
      this.form.transmit = this.deepClone(data);
    },
    responseAddParam(data) {
      this.form.response = this.deepClone(data);
    },
    transModiParam(data) {
      this.modiForm.transmit = this.deepClone(data);
    },
    responseModiParam(data) {
      this.modiForm.response = this.deepClone(data);
    },
  },
  components: {
    cmdAddParams,
    cmdModiParams,
  },
  mounted() {
    this.currentChangePage(this.cmdData, this.currentPage);
    this.total = this.cmdData.length;
  },
};
</script>

<style scoped>
.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
</style>
