<template>
  <p>baseTest.vue</p>
</template>

<script>
export default {
  name: "baseTest",
};
</script>

<style scoped></style>
