//位置信息管理
import axiosInstanceMethods from "../../config/request";

const apiHitIotLocation = {
  //获取地块列表
  getPolygon: (data) =>
    axiosInstanceMethods()["iotLocation"].request({
      method: "post",
      url: "hitLocCtrlLfind",
      params: data,
    }),
  //未绑定某设备的地块查询
  getLandsUnbindDev: (data) =>
    axiosInstanceMethods()["iotLocation"].request({
      method: "post",
      url: "hitCtrlsUnbindDevfind",
      data: data,
    }),
  getPolygonc: (pageNum, pageSize, ctl_id, address, local, name, salw, aup) =>
    axiosInstanceMethods()["iotLocation"].request({
      method: "post",
      url: "/hitLocCtrlLfind",
      params: {
        pageNum: pageNum,
        pageSize: pageSize,
        ctl_id: ctl_id,
        address: address,
        local: local,
        name: name,
        alow: salw,
        aup: aup,
      },
    }),
  //查询未绑定某设备的地块--未绑定在某块地的设备
  getPolygoncByDevid: (pageNum, pageSize, devid) =>
    axiosInstanceMethods()["iotLocation"].request({
      method: "post",
      url: "/hitLocfindLand",
      params: {
        pageNum: pageNum,
        pageSize: pageSize,
        devid: devid,
      },
    }),
  //新增地块
  savePolygon: (data) =>
    axiosInstanceMethods()["iotLocation"].request({
      method: "post",
      url: "/hitLocCtrlSaveb",
      data: data,
      config: {
        emulateJSON: false,
      },
    }),
  //更新地块
  updatePolygon: (data) =>
    axiosInstanceMethods()["iotLocation"].request({
      method: "post",
      url: "/hitLocCtrlUpdate",
      data: data,
      config: {
        emulateJSON: false,
      },
    }),
  //删除地块
  deletePolygon: (data) =>
    axiosInstanceMethods()["iotLocation"].request({
      method: "post",
      url: "hitLocCtrlDel",
      data: data,
    }),
  //获取安装设备列表
  getinstalledDev: (data) =>
    axiosInstanceMethods()["iotLocation"].request({
      method: "post",
      url: "hitLandBindDevFind",
      data: data,
      config: {
        emulateJson: false,
      },
    }),
  //获取未安装设备列表,改为未在这块地上安装的设备
  getUninstallDev: () =>
    axiosInstanceMethods()["iotPlatform"].request({
      method: "get",
      url: "/device/listUninstalleddevice",
    }),
  //获取设备国标分类列表
  getNationalS: (data) =>
    axiosInstanceMethods()["iotPlatform"].request({
      method: "post",
      url: "/deviceM/searchNationalS",
      data: data,
    }),

  //获取设备模型列表
  getModelP: (pageSize, pageNum, data) =>
    axiosInstanceMethods()["iotPlatform"].request({
      method: "post",
      url: "/product/queryProducts",
      params: {
        pageSize: pageSize,
        pageNum: pageNum,
      },
      data: { data },
    }),
  //注册设备
  saveDev: (data) =>
    axiosInstanceMethods()["iotPlatform"].request({
      method: "post",
      url: "/device/adddevicebasicinfo",
      data: data,
    }),
  //更新设备
  updateDevice: (data) =>
    axiosInstanceMethods()["iotPlatform"].request({
      method: "post",
      url: "/deviceM/modifydevice",
      data: data,
    }),
  //删除设备
  delDevice: (data) =>
    axiosInstanceMethods()["iotPlatform"].request({
      method: "post",
      url: "/deviceM/deldevice",
      params: {
        id: data,
      },
    }),
  //获取设备列表
  getHost: (data) =>
    axiosInstanceMethods()["iotPlatform"].request({
      method: "post",
      url: "/deviceM/listDeviceByCondition",
      data: data,
    }),
  //获取未绑定设备列表-2  zxd 2022.03.10
  getUnBindDev: (data) =>
    axiosInstanceMethods()["iotLocation"].request({
      method: "post",
      url: "/hitLandUnbindDevFind",
      data: data,
    }),
  //获取未绑定设备列表-2  zxd 2022.03.10
  getBindDevs: (data) =>
    axiosInstanceMethods()["iotLocation"].request({
      method: "post",
      url: "/hitLandBindDevFindc",
      data: data,
    }),
  //多设备与一个地块绑定（安装多设备）zxd 2022.03.10
  installDevs: (data) =>
    axiosInstanceMethods()["iotLocation"].request({
      method: "post",
      url: "/hitLandBindDevs",
      params: { data: data },
      config: {
        emulateJson: false,
      },
    }),
  //一个设备与多个地块绑定（安装一个设备到多个地块）zxd 2022.06.03
  installDevOnLands: (data) =>
    axiosInstanceMethods()["iotLocation"].request({
      method: "post",
      url: "/hitLandsBindDev",
      params: { data: data },
      config: {
        emulateJson: false,
      },
    }),
  //一个设备与多个地块绑定（安装一个设备到多个地块）zxd 2022.06.03
  uninstallDevOnLands: (data) =>
    axiosInstanceMethods()["iotLocation"].request({
      method: "post",
      url: "/hitunBindDevToLands",
      params: { data: data },
      config: {
        emulateJson: false,
      },
    }),
  //多设备与一个地块绑定（安装多设备）zxd 2022.03.10
  uninstallDevs: (data) =>
    axiosInstanceMethods()["iotLocation"].request({
      method: "post",
      url: "/hitunLandBindDevs",
      params: { data: data },
      config: {
        emulateJson: false,
      },
    }),
  //设备与地块绑定（安装设备）
  installDev: (data) =>
    axiosInstanceMethods()["iotLocation"].request({
      method: "post",
      url: "hitLandBindDev",
      params: data,
    }),
  //更新设备安装位置
  updateDev: (data) =>
    axiosInstanceMethods()["iotLocation"].request({
      method: "post",
      url: "hitLandUpdateDev",
      data: data,
    }),
  //取消安装设备
  removeinstallDev: (data) =>
    axiosInstanceMethods()["iotLocation"].request({
      method: "post",
      url: "hitunLandBindDev",
      params: data,
    }),
  //获取地块监控列表
  getMonitorP: (data) =>
    axiosInstanceMethods()["iotPlatform"].request({
      method: "post",
      url: "/datastatistic/hitLocShadowDatafind",
      data: data,
    }),
  //获取地块监控列表
  getMonitorSP: (ctlId) =>
    axiosInstanceMethods()["iotPlatform"].request({
      method: "post",
      url: "/datastatistic/hitLocShadowDataAccurateFind",
      params: { ctl_id: ctlId },
    }),
};
export default apiHitIotLocation;
