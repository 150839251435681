<template>
  <el-container class="container">
    <el-header class="header">
      <div style="font-weight: bold; font-size: 20px">
        <span>
          产品(此为练习页面，模型录入与查询有分别的页面来承担：/hitIotClassification/productEntry和/hitIotClassification/productQuery)
        </span>
        <span style="margin-left: 10px" @click="func_show = !func_show">
          <el-link type="primary" :underline="false"
            ><i class="el-icon-view"></i>功能介绍</el-link
          >
        </span>
      </div>
      <div>
        <el-button @click="dialogServiceVisible = true" type="primary" round>创建产品</el-button>
      </div>
    </el-header>
    <el-main>
      <el-scrollbar
        class="asideScrollbar"
        :wrap-style="[{ 'overflow-x': 'hidden' }]"
      >
        <div style="margin: 0 15px 0">
          <!--          这里套一层margin是为了使el-scrollbar往右边偏一点，不至于紧贴着元素-->
          <div class="explain" v-show="func_show">
            <div style="font-size: 16px; font-weight: bold">产品说明</div>
            <p style="color: #909399">
              在物联网平台中，某一类具有相同能力或特征的设备的抽象合集被称为一种产品。
              每类产品都有对应的业务模型，您可以定义模型的命令（设备接收解析规则）和属性（设备上传解析规则）等信息，根据产品的接入协议、数据格式等可能还需要您定义其他相关的内容。
              创建好的产品可以在注册设备时进行产品绑定。
            </p>
          </div>
          <div class="service">
            <div>
              <el-table
                :data="tableList"
                style="width: 100%"
                :header-row-style="{
                  color: '#303133',
                  'font-size': '16px',
                  'font-weight': 'bold',
                }"
                :row-style="{ 'font-size': '16px' }"
                :header-cell-style="{ background: '#eef1f6' }"
              >
                <el-table-column prop="name" label="产品名称" min-width="20%">
                </el-table-column>
                <el-table-column prop="id" label="产品id" min-width="20%">
                </el-table-column>
                <el-table-column
                  prop="format"
                  label="数据格式"
                  min-width="20%"
                  :formatter="formatFormat"
                >
                </el-table-column>
                <el-table-column
                  prop="protocol"
                  label="协议类型"
                  min-width="20%"
                >
                </el-table-column>
                <el-table-column label="操作" min-width="20%">
                  <template slot-scope="scope">
                    <el-button
                      @click="checkService(scope.row)"
                      type="text"
                      style="font-size: 16px"
                      >查看</el-button
                    >
                    <el-divider direction="vertical"></el-divider>
                    <el-button
                      @click="deleteService(scope.row)"
                      type="text"
                      style="font-size: 16px"
                      >删除</el-button
                    >
                  </template>
                </el-table-column>
              </el-table>
              <br />
              <div class="paginationClass">
                <el-pagination
                  @size-change="handleSizeChange"
                  @current-change="handleCurrentChange"
                  :current-page="currentPage"
                  :page-sizes="[5, 10, 20]"
                  :page-size="pageSize"
                  layout="total, sizes, prev, pager, next, jumper"
                  :total="total"
                >
                </el-pagination>
              </div>
            </div>
          </div>
        </div>
      </el-scrollbar>
    </el-main>
    <el-dialog
      title="创建业务"
      :visible.sync="dialogServiceVisible"
      width="50%"
      :close-on-click-modal="false"
      @close="reset"
      v-dialogDrag
    >
      <el-form
        :model="createForm"
        :rules="rules"
        ref="createForm"
        label-width="100px"
        class="demo-ruleForm"
        status-icon
      >
        <el-form-item label="产品名称" prop="name">
          <el-input v-model="createForm.name"></el-input>
        </el-form-item>

        <el-form-item label="英文名称" prop="name">
          <el-input v-model="createForm.ename"></el-input>
        </el-form-item>

        <el-form-item label="协议类型" prop="protocol">
          <el-select v-model="createForm.protocol" placeholder="请选择协议类型">
            <el-option label="TCP" value="TCP"></el-option>
            <el-option label="HTTP/HTTP2" value="HTTP/HTTP2"></el-option>
            <el-option label="HTTPS" value="HTTPS"></el-option>
            <el-option label="MQTT" value="MQTT"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="数据格式" prop="format">
          <el-select v-model="createForm.format" placeholder="数据格式">
            <el-option label="二进制码流" value="0"></el-option>
            <el-option label="json字符串" value="1"></el-option>
          </el-select>
          <el-tooltip
            style="margin-left: 10px"
            class="item"
            effect="dark"
            placement="right"
          >
            <div slot="content">
              <div>数据格式为二进制码流时，该业务需要进行编解码插件开发。</div>
              <br />
              <div>数据格式为JSON时，不需要进行编解码插件开发。</div>
            </div>
            <i class="el-icon-info"></i>
          </el-tooltip>
        </el-form-item>
        <el-form-item label="业务描述" prop="desc">
          <el-input type="textarea" v-model="createForm.desc"></el-input>
        </el-form-item>
        <el-form-item style="text-align: right">
          <!--          <el-button type="primary" @click="submitForm('ruleForm')">立即创建</el-button>-->
          <!--          <el-button @click="resetForm('ruleForm')">重置</el-button>-->
          <el-button @click="resetForm()">取 消</el-button>
          <el-button type="primary" @click="submitForm()">确 定</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </el-container>
</template>

<script>
export default {
  name: "serviceClassification",

  created() {
    const { loadData } = this;
    this.loadData();
    //设置刷新间隔时间
    setInterval(loadData, 20000);
  },
  data() {
    return {
      flag: true,
      // 辅助判断是否存在同名业务
      total: 0,
      currentPage: 1,
      pageSize: 10,
      func_show: true,
      tableData: [
        {
          name: "智慧农业熵情站",
          id: "1",
          format: "二进制码流",
          protocol: "HTTP",
        },
      ],
      tableList: [],
      dialogServiceVisible: false,
      // dialogServiceVisible用于控制创建业务按钮对应的对话窗
      createForm: {
        name: "",
        protocol: "",
        format: "",
        desc: "",
        ename: "",
      },
      rules: {
        name: [{ required: true, message: "请输入业务名称", trigger: "blur" }],
        protocol: [
          { required: true, message: "请选择协议类型", trigger: "change" },
        ],
        format: [
          { required: true, message: "请选择数据类型", trigger: "change" },
        ],
      },
    };
  },
  methods: {
    //<el-table/>根据一列值取值返回对应的需要展示的值
    formatFormat(row, column) {
      if (row.format === "0") {
        return "二进制码流";
      } else if (row.format === "1") {
        return "json字符串";
      } else {
        return "错误";
      }
    },

    //给产品展示列表tableData赋值
    loadData: function () {
      this.$api.apiHitIotClassification
        .listAllProducts(this.pageSize, this.currentPage)
        .then((res) => {
          console.log(res);
          this.tableData = [];
          let that = this;
          res.data.data.productList.forEach(function (item) {
            let temp = {
              name: item.pCname,
              protocol: item.pProtocol,
              format: item.pDataformat,
              id: item.pId,
            };
            that.tableData.push(temp);
          });
          that.currentChangePage(that.tableData, that.currentPage);
          that.total = res.data.data.totalCount;
        });
      // .catch((err) => {
      //   console.log(err.response);
      // });
      // const { data: res } = this.$http
      //   .get("http://122.9.150.203:8081/hit/iot/v1/product/listAllProducts")
      //   .then((res) => {
      //     this.tableData = [];
      //     let that = this;
      //     res.data.data.forEach(function (item) {
      //       let temp = {
      //         name: item.pCname,
      //         protocol: item.pProtocol,
      //         format: item.pDataformat,
      //         id: item.pId,
      //       };
      //       that.tableData.push(temp);
      //     });
      //     that.currentChangePage(that.tableData, that.currentPage);
      //     that.total = that.tableData.length;
      //   });
    },

    getArrayIndex(arr, obj) {
      let i = arr.length;
      while (i--) {
        if (arr[i].id === obj.id) {
          return i;
        }
      }
      return -1;
    },
    // 根据id相同获取数组下标的函数，搭配deleteService使用
    checkService(row) {
      this.$router.push("/hitIotClassification/serviceDefine/" + row.id);
    },
    deleteService(row) {
      this.$confirm("此操作将永久删除该业务, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        const res = {};

        let param = {
          productId: row.id,
        };
        this.$http.post("", param).then((res) => {
          if (res.data.code === "0") {
            this.loadData();
            this.$message({
              type: "success",
              message: "删除成功!",
            });
          }
        });
        //   const index = this.getArrayIndex(this.tableData, row);
        //   this.tableData.splice(index, 1);
        //   this.currentChangePage(this.tableData, this.currentPage);
        //   this.total = this.tableData.length;
        //   this.$message({
        //     type: "success",
        //     message: "删除成功!",
        //   });
      });
    },
    handleSizeChange(pageSize) {
      // 每页条数切换
      this.pageSize = pageSize;
      this.handleCurrentChange(this.currentPage);
    },
    handleCurrentChange(currentPage) {
      //页码切换
      this.currentPage = currentPage;
      this.currentChangePage(this.tableData, currentPage);
    },
    //分页方法（重点）
    currentChangePage(list, currentPage) {
      let from = (currentPage - 1) * this.pageSize;
      let to = currentPage * this.pageSize;
      this.tableList = [];
      for (; from < to; from++) {
        if (list[from]) {
          this.tableList.push(list[from]);
        }
      }
    },
    submitForm() {
      this.flag = true;
      let i;
      for (i = 0; i < this.tableData.length; ++i) {
        if (this.tableData[i].name === this.createForm.name) {
          this.$refs["createForm"].resetFields();
          this.$message({
            type: "error",
            message: "同名业务已经存在!",
          });
          this.flag = false;
          break;
        }
      }
      if (this.flag) {
        this.$refs["createForm"].validate((valid) => {
          if (valid) {
            /*this.tableData.push({
              name: this.createForm.name,
              id: Math.round(Math.random() * 800 + 200).toString(),
              format: this.createForm.format,
              protocol: this.createForm.protocol,
            });*/
            // 考虑到javascript的数组、对象也有深拷贝的问题存在
            this.dialogServiceVisible = false;
            this.currentChangePage(this.tableData, this.currentPage);
            this.total = this.tableData.length;

            const res = {};

            let param = {
              pProtocol: this.createForm.protocol,
              pDataformat: this.createForm.format,
              pEname: this.createForm.ename,
              pCname: this.createForm.name,
              pDesc: this.createForm.desc,
            };
            this.$http
              .post(
                "http://39.104.14.150:8081/hit/iot/v1/product/CreateProducts",
                param
              )
              .then((res) => {
                // console.log("res=>", res);
                // console.log(res);
                // console.log(res.data.code);
                if (res.data.code === "0") {
                  this.loadData();
                  this.$message({
                    type: "success",
                    message: "创建成功!",
                  });
                }
              });
          } else {
            return false;
          }
        });
      }
    },
    resetForm() {
      this.dialogServiceVisible = false;
    },
    reset() {
      this.$refs["createForm"].resetFields();
    },
  },
  mounted() {
    this.currentChangePage(this.tableData, this.currentPage);
    this.total = this.tableData.length;
  },
};
</script>

<style scoped>
.container {
  width: 100%;
  height: 100%;
}
.header {
  width: calc(100% - 30px);
  margin: 15px 15px 0;
  display: flex;
  justify-content: space-between;
}
.explain {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
  padding: 12px;
  border-radius: 5px;
  margin-bottom: 15px;
}
.service {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
  padding: 12px;
  border-radius: 5px;
}
.asideScrollbar {
  height: 100%;
}
</style>
