<template>
  <el-container class="container" direction="vertical">
    <el-card class="display_add">
      <el-collapse accordion v-model="activeNames">
        <el-collapse-item name="1">
          <template slot="title">
            <div style="font-size: 16px">管控区域维护</div>
          </template>
          <el-form
            :label-width="formLabelWidth"
            :inline="false"
            class="demo-from-inline"
          >
            <el-row>
              <el-col>
                <el-button-group
                  style="float: right; margin-bottom: 10px; margin-right: 60px"
                >
                  <el-button
                    style="margin-right: 10px"
                    type="primary"
                    @click="save"
                    >保存</el-button
                  >
                  <el-button style="margin-right: 10px" @click="reset"
                    >重置</el-button
                  >
                </el-button-group>
              </el-col>
            </el-row>
            <el-row :gutter="10">
              <el-col :xs="14" :sm="14" :md="14" :lg="12" :xl="12">
                <el-form-item label="选择行政区">
                  <v-distpicker
                    :province="basicAreaInfo.province"
                    :city="basicAreaInfo.city"
                    :area="basicAreaInfo.area"
                    style="width: 100%"
                    @province="onChangeProvince"
                    @city="onChangeCity"
                    @area="onChangeArea"
                  ></v-distpicker>
                </el-form-item>
              </el-col>
              <el-col :xs="10" :sm="10" :md="10" :lg="12" :xl="12">
                <el-form-item label="街道">
                  <el-input
                    v-model="basicAreaInfo.street"
                    style="width: 92%"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="10">
              <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="14">
                <el-form-item label="区域名称">
                  <el-input
                    v-model="basicAreaInfo.areaName"
                    style="width: 100%"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :xs="8" :sm="6" :md="6" :lg="6" :xl="6">
                <el-form-item label="地块面积">
                  <el-input
                    style="width: 100%"
                    v-model="basicAreaInfo.landArea"
                    autocomplete="off"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :xs="4" :sm="6" :md="6" :lg="6" :xl="4">
                <el-form-item
                  label="面积单位"
                  prop="unit"
                  label-width="LabelWidth"
                >
                  <el-select
                    v-model="basicAreaInfo.unit"
                    placeholder="请选择"
                    style="width: 60%"
                  >
                    <el-option
                      v-for="item in unit"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col>
                <el-form-item label="地块坐标">
                  <el-input
                    v-model="basicAreaInfo.local"
                    :readonly="true"
                    style="width: 85%"
                  ></el-input>
                  <el-button @click="dialogVisible = true">坐标录入</el-button>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </el-collapse-item>
      </el-collapse>
    </el-card>
    <el-card class="display">
      <el-row gutter="2">
        <el-col :span="8">
          <span>设备信息</span>
        </el-col>
        <el-col :span="3">
          <el-button @click="findbindDeviceb" type="primary"
            >绑定设备查询</el-button
          >
        </el-col>
        <el-col :span="2">
          <el-tooltip
            effect="dark"
            :disabled="!unbindBtn"
            content="请先进行绑定设备信息查询..."
            placement="top"
          >
            <div>
              <el-button @click="unDevbindCtl" :disabled="unbindBtn"
                >解除绑定</el-button
              >
            </div>
          </el-tooltip>
        </el-col>
        <el-col :span="3">
          <el-button @click="findUnbindDeviceb" type="primary"
            >未绑定设备查询</el-button
          >
        </el-col>
        <el-col :span="2">
          <el-tooltip
            effect="dark"
            :disabled="!bindBtn"
            content="请先进行未绑定设备信息查询..."
            placement="top"
          >
            <div>
              <el-button @click="DevbindCtl" :disabled="bindBtn"
                >绑定</el-button
              >
            </div>
          </el-tooltip>
        </el-col>
      </el-row>
      <el-row style="margin: 10px">
        <el-table
          v-fit-columns
          :row-key="getRowKeys"
          :data="deviceTableData"
          style="width: 100%"
          border
          highlight-current-row
          height="350"
          :row-class-name="tableRowClassName"
          ref="multipleTable"
          @selection-change="handleSelectionChange"
        >
          <el-table-column
            fixed
            label="选择"
            type="selection"
            :reserve-selection="true"
          ></el-table-column>
          <el-table-column label="id" prop="i_id"></el-table-column>
          <el-table-column label="序列号" prop="i_sn"></el-table-column>
          <el-table-column label="访问标识" prop="i_token"></el-table-column>
          <el-table-column label="名称" prop="i_name"></el-table-column>
          <el-table-column label="类型" prop="i_type"></el-table-column>
          <el-table-column label="经度" prop="i_longitude"></el-table-column>
          <el-table-column label="纬度" prop="i_latitude"></el-table-column>
          <el-table-column label="所在区域" prop="i_region"></el-table-column>
          <el-table-column label="在线标识" prop="i_online"></el-table-column>
          <el-table-column label="当前状态" prop="i_state"></el-table-column>
          <el-table-column label="安装日期" prop="i_date"></el-table-column>
        </el-table>
        <el-pagination
          background
          @size-change="pageSizeChanged"
          @current-change="pageCurrentChanged"
          :current-page="pageformd.pageNum"
          :page-sizes="pageformd.pageSizes"
          :page-size="pageformd.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="pageformd.total"
          style="margin-bottom: 15px"
        >
        </el-pagination>
      </el-row>
    </el-card>
    <el-dialog :visible.sync="dialogVisible">
      <template slot="title">
        <div style="font-size: 18px">地块坐标录入</div>
      </template>
      <el-form ref="form" class="form-inline">
        <el-row>
          <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="6">
            <el-form-item label="经度" :label-width="LabelWidth">
              <el-input v-model="coordsForm.lng"></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="6">
            <el-form-item label="纬度" :label-width="LabelWidth">
              <el-input v-model="coordsForm.lat"></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="6">
            <el-form-item>
              <el-button @click="addCoords" size="medium">添加</el-button>
              <el-button @click="coordinate" type="primary" size="medium"
                >保存</el-button
              >
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col>
            <el-form-item>
              <el-table :data="coordsForm.coords" style="width: 100%">
                <el-table-column fixed prop="No" label="序号">
                </el-table-column>
                <el-table-column prop="lng" label="经度"> </el-table-column>
                <el-table-column prop="lat" label="纬度"> </el-table-column>
                <el-table-column fixed="right" label="操作">
                  <template slot-scope="scope">
                    <el-button
                      @click.native.prevent="
                        deleteRow(scope.$index, coordsForm.coords)
                      "
                      type="text"
                      size="small"
                      >移除
                    </el-button>
                  </template>
                </el-table-column>
              </el-table>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-dialog>
  </el-container>
</template>

<script>
import VDistpicker from "v-distpicker";
import BaiduMap from "vue-baidu-map/components/map/Map.vue";
import BmLocalSearch from "vue-baidu-map/components/search/LocalSearch.vue";

export default {
  components: {
    // BaiduMap,
    VDistpicker,
    // BmLocalSearch,
  },
  name: "controlArea",
  data() {
    return {
      //公共变量
      activeNames: ["1"],
      highlightRows: new Set(),
      LabelWidth: "60px",
      formLabelWidth: "120px",
      modify_title: "管控区域录入",
      placeholder: {},
      bindflag: 0, //区分绑定与未绑定查询的标志0--未绑定操作
      //按钮不使能
      bindBtn: true,
      unbindBtn: true,
      //相关数据
      unit: [
        {
          value: "亩",
          label: "亩",
        },
        {
          value: "平方米",
          label: "平方米",
        },
        {
          value: "平方公里",
          label: "平方公里",
        },
        {
          value: "平方里",
          label: "平方里",
        },
      ],
      basicAreaInfo: {
        devId: "",
        province: "",
        city: "",
        area: "",
        street: "",
        areaName: "",
        local: "",
        landArea: "",
        unit: "",
      },
      coordsForm: {
        lng: "",
        lat: "",
        coords: [],
      },
      devs: "", //完成跨页选中
      iotLand: {
        ctl_address: "",
        ctl_local: "",
        ctl_name: "",
        ctl_area: "",
        ctl_image: "",
        ctl_cameratoken: "",
        ctl_cameraurl: "",
      },
      UnbindDev: {
        pageNum: 1,
        pageSize: 5,
        i_id: "-1",
        i_sn: "",
        i_token: "",
        i_name: "",
        i_type: "",
        i_longitude: "",
        i_latitude: "",
        i_region: "",
        i_online: "", //依据需求设置默认为0-不在线; 1-在线,不代表正常，与i_state都为1，则为健康。当i_state=2时为在线不正常。
        i_state: "",
        datelow: "",
        dateup: "",
        ctl_id: "",
      },
      deviceTableData: [],
      deviceInfo: [],
      bmmapParams: {
        //这里是百度的信息，基本没有用
        zoom: 10,
        center: {
          lng: 122.130539,
          lat: 37.508969,
        },
      },
      //设备信息分页
      pageformd: {
        total: 20,
        pages: 5,
        pageNum: 1,
        pageSizes: [1, 2, 5, 10, 15, 20],
        pageSize: 5,
        navigateFirstPage: 1,
        navigateLastPage: 3,
        lastPage: false,
        firstPage: false,
        hasNextPage: true,
        hasPreviousPage: true,
      },
      dialogVisible: false,
    };
  },
  methods: {
    tableRowClassName({ row, rowIndex }) {
      if (this.highlightRows.has(rowIndex)) {
        return "highlight";
      }
      return "";
    },
    getRowKeys(row) {
      return row.i_id;
    },
    coordinate() {
      let loc = "{";
      let count = 0;
      for (let vulue of this.coordsForm.coords) {
        loc += vulue.No + ":[" + vulue.lng + "," + vulue.lat + "],";
        count++;
      }
      if (count < 3) {
        this.$message({
          message: "坐标数量不够三个，需要三个坐标值才能围成地块。请继续录入",
          type: "info",
        });
        return;
      }
      this.basicAreaInfo.local = loc.substr(0, loc.length - 1) + "}";
      this.dialogVisible = false;
    },
    DevbindCtl: function () {
      if (this.basicAreaInfo.devId == null || this.basicAreaInfo.devId == "") {
        this.$message({
          message: "未录入区域信息，无法绑定设备，请先录入区域信息并保存",
          type: "failure",
        });
        return;
      }
      let devs = this.basicAreaInfo.devId + this.devs;
      let dd = this.devs.split("#");
      if (dd.length <= 1) {
        this.$message({
          message: "未选中设备，不能绑定！",
          type: "warning",
        });
        return;
      }
      this.$api.apiHitIotLocation
        .installDevs(devs)
        .then((res) => {
          console.log(res);
          if (res.data.code == 1) {
            this.$message({
              message: "存储成功",
              type: "success",
            });
            this.findUnbindDeviceb(); //刷新
          } else {
            this.$message({
              message: "存储失败",
              type: "info",
            });
          }
        })
        .catch((err) => {
          console.log(err.response);
        });
      console.log(devs);
    },
    unDevbindCtl: function () {
      if (this.basicAreaInfo.devId == null || this.basicAreaInfo.devId == "") {
        this.$message({
          message: "未录入区域信息，无法绑定设备，请先录入区域信息并保存",
          type: "failure",
        });
        return;
      }

      let devs = this.basicAreaInfo.devId + this.devs;
      let dd = this.devs.split("#");
      if (dd.length <= 1) {
        this.$message({
          message: "未选择设备，不能提交",
          type: "info",
        });
        return;
      }
      this.$api.apiHitIotLocation
        .uninstallDevs(devs)
        .then((res) => {
          if (res.data.code == 1) {
            this.$message({
              message: "绑定解除成功",
              type: "success",
            });
            this.findbindDeviceb(); //再查已绑定信息
          } else {
            this.$message({
              message: "绑定解除失败，后台异常",
              type: "info",
            });
          }
        })
        .catch((err) => {
          console.log(err.response);
        });
      console.log(devs);
    },
    handleSelectionChange(val) {
      // val是一个列表，包含所有的选择项
      var i = 0;
      this.devs = "";
      for (; i < val.length; i++) {
        this.devs += "#" + val[i].i_id;
      }
    },
    onChangeProvince(data) {
      this.basicAreaInfo.province = data.value;
    },
    onChangeCity(data) {
      this.basicAreaInfo.city = data.value;
    },
    onChangeArea(data) {
      this.basicAreaInfo.area = data.value;
    },
    save: function () {
      //在这里实现后端接口   //1.获取数据并重新组合
      this.iotLand.ctl_address =
        this.basicAreaInfo.province +
        "-" +
        this.basicAreaInfo.city +
        "-" +
        this.basicAreaInfo.area +
        "-" +
        this.basicAreaInfo.street;
      this.iotLand.ctl_area = this.basicAreaInfo.landArea; // + this.basicAreaInfo.unit;
      this.iotLand.ctl_name = this.basicAreaInfo.areaName;
      this.iotLand.ctl_local = this.basicAreaInfo.local;
      this.iotLand.ctl_cameratoken = this.basicAreaInfo.cameratoken;
      this.iotLand.ctl_cameraurl = this.basicAreaInfo.cameraurl;
      console.log(this.basicAreaInfo.province);
      console.log(this.iotLand);
      //提交到后台
      if (this.iotLand.ctl_address == "---") {
        this.$alert("地址不能为空");
        return;
      } else {
        this.$api.apiHitIotLocation
          .savePolygon(this.iotLand)
          .then((res) => {
            if (res.data.code == 1) {
              this.basicAreaInfo.devId = res.data.devid; //地块编号
              this.$message({
                message: "存储成功！",
                type: "success",
              });
            } else {
              this.$message({
                message: "存储失败！后台异常！",
                type: "info",
              });
            }
          })
          .catch((err) => {
            console.log(err.response);
          });
      }
    },
    reset() {
      const emptyBasicAreaInfo = {
        province: "",
        city: "",
        area: "",
        street: "",
        areaName: "",
        name: "",
        local: "",
        landArea: "",
        unit: "",
      };
      const emptyCoordsForm = {
        lng: "",
        lat: "",
        coords: [],
      };
      this.basicAreaInfo = emptyBasicAreaInfo;
      this.coordsForm = emptyCoordsForm;
    },
    addCoords() {
      this.coordsForm.coords.push({
        No: this.coordsForm.coords.length, // + 1,
        lng: this.coordsForm.lng,
        lat: this.coordsForm.lat,
      });
      this.coordsForm.lng = "";
      this.coordsForm.lat = "";
    },
    deleteRow(index, rows) {
      rows.splice(index, 1);
      this.coordsForm.coords.remove(index);
    },
    //未绑定设备查询--按钮
    findUnbindDeviceb: function () {
      this.$refs.multipleTable.clearSelection(); //重置，清除表中上次的选择
      this.devs = ""; //重置，清除变量中的选择--设备id
      this.bindflag = 0; //置表标位，表明现在是进行未绑定设备操作
      this.bindBtn = false;
      this.unbindBtn = true;
      this.findUnbindDevice(); //查询未绑定设备
    },
    findUnbindDevice() {
      this.error = null;
      this.loading = true;
      this.UnbindDev.ctl_id = this.basicAreaInfo.devId; //地块编号
      this.error = null;
      this.UnbindDev.pageNum = this.pageformd.pageNum;
      this.UnbindDev.pageSize = this.pageformd.pageSize;
      console.log(this.UnbindDev);
      this.$api.apiHitIotLocation
        .getUnBindDev(this.UnbindDev)
        .then((res) => {
          this.loading = false;
          console.log(res);
          this.deviceTableData = res.data.data;
          this.pageformd.total = res.data.total;
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
          this.error = error.toString();
        });
      console.log("fae");
    },
    pageSizeChanged(pageSize) {
      //设备每页显示记录尺寸改变     // 每页条数切换
      this.pageformd.pageSize = pageSize;
      if (this.bindflag === 1) {
        this.findbindDevice();
      } else {
        this.findUnbindDevice();
      }
    },
    pageCurrentChanged(currentPage) {
      //页码切换
      this.pageformd.pageNum = currentPage;
      if (this.bindflag === 1) {
        this.findbindDevice();
      } else {
        this.findUnbindDevice();
      }
      console.log(this.devs);
    },
    //未绑定设备查询--按钮
    findbindDeviceb: function () {
      this.$refs.multipleTable.clearSelection(); //重置，清除表中上次的选择
      this.devs = ""; //重置，清除变量中的选择--设备id
      this.bindflag = 1; //置表标位，表明现在是进行未绑定设备操作
      this.bindBtn = true;
      this.unbindBtn = false;
      this.findbindDevice(); //查询未绑定设备
    },
    findbindDevice() {
      this.error = null;
      this.loading = true;
      this.UnbindDev.ctl_id = this.basicAreaInfo.devId;
      this.UnbindDev.pageNum = this.pageformd.pageNum;
      this.UnbindDev.pageSize = this.pageformd.pageSize;
      console.log(this.UnbindDev);
      this.$api.apiHitIotLocation
        .getBindDevs(this.UnbindDev)
        .then((res) => {
          this.loading = false;
          console.log(res);
          this.deviceTableData = res.data.data;
          this.pageformd.total = res.data.total;
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
          this.error = error.toString();
        });
      console.log("fae");
    },
  },
};
</script>

<style scoped>
.el-input {
  width: 80%;
}

.el-button {
  margin-left: 10px;
}

.display {
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  width: 95%;
  margin: 20px auto;
  border-radius: 5px;
}

.display_add {
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  width: 95%;
  margin: 20px auto;
  border-radius: 5px;
  text-align: left;
}

.form {
  width: 80%;
}

.el-collapse {
  border-bottom: unset;
}
.el-table__header col[name="gutter"] {
  display: table-cell !important;
}

.el-table >>> .highlight {
  background: #ecf5ff;
}

.el-collapse-item >>> .el-collapse-item__header {
  justify-content: center;
  font-size: 18px;
}
.display_add
  >>> .el-collapse-item
  .el-collapse-item__header
  .el-collapse-item__arrow {
  margin: 0;
}
</style>
