import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    modelCopyData: {
      modelMainBaseInfo: {},
      codeDataList: [],
      attributeDataList: [],
      accessToCopy: false,
    },
    //一边录入一边查询怎么办？标记正在录入的模型id
    modelInputtingId: null,
  },
  mutations: {
    modelDataCopy(state, payload) {
      if (
        payload.modelMainBaseInfo === {} &&
        payload.codeRulesDataList.length === 0
      ) {
        state.modelCopyData = {
          modelMainBaseInfo: {},
          codeDataList: [],
          attributeDataList: [],
          accessToCopy: false,
        };
      } else {
        state.modelCopyData = {
          modelMainBaseInfo: payload.modelMainBaseInfo,
          codeDataList: payload.codeDataList,
          attributeDataList: payload.attributeDataList,
          accessToCopy: true,
        };
      }
    },
    modelInputtingIdSet(state, id) {
      state.modelInputtingId = id;
      console.log(id);
    },
    modelInputtingIdSetNull(state) {
      state.modelInputtingId = null;
    },
  },
  actions: {},
  modules: {},
});
