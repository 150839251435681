import Vue from "vue";
import VueRouter from "vue-router";
import axios from "axios";
import ElementUI from "element-ui";
import Vuetify from "vuetify";
import vuetify from "@/plugins/vuetify";
import API from "./api/api.js";
import router from "./router";
import qs from "qs";
import App from "./App.vue";
import store from "./store";
import RouterTab from "vue-router-tab";
import "vue-router-tab/dist/lib/vue-router-tab.css";
import "element-ui/lib/theme-chalk/index.css";
import "./style/overallConfig.css";
import "./utils/directives";
import baseUrls from "./config/baseUrlList";
import Plugin from "v-fit-columns";
import * as echarts from "echarts";
console.log(baseUrls);
import axiosInstanceMethods from "./config/request";
console.log("2", axiosInstanceMethods());
import getAxiosInstances from "./config/axiosInstance";
console.log("1", getAxiosInstances());
Vue.use(VueRouter);
Vue.use(RouterTab);
Vue.use(ElementUI);
Vue.use(Plugin);
Vue.config.productionTip = false;
Vue.prototype.$axios = axios;
// Vue.prototype.$http = axios;
Vue.prototype.$api = API;
//Vue.forceUpdate()
Vue.prototype.$echarts = echarts;
// 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

//配置请求的根路径，下面的这个访问方式是多余的，增加了一个访问漏洞，它绕过了前面的设置
axios.defaults.baseURL = "http://127.0.0.1:8000/api/";
Vue.prototype.$http = axios;
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
