<template>
  <el-aside class="asideBar" style="width: auto">
    <el-scrollbar
      class="asideScrollbar"
      :wrap-style="[{ 'overflow-x': 'hidden' }]"
    >
      <el-row class="asideRow">
        <el-col class="asideCol">
          <el-menu
            default-active="1"
            class="asideMenu el-menu-vertical"
            background-color="#304156"
            text-color="#bbb"
            active-text-color="#fff"
            :collapse="isCollapse"
          >
            <!--伸缩按钮-->
            <el-menu-item
              class="asideMenuItem"
              @click="isCollapse = !isCollapse"
            >
              <i class="el-icon-s-unfold" v-if="isCollapse"></i>
              <i class="el-icon-s-fold" v-else></i>
              <span slot="title" class="menuTitle" v-if="isCollapse">
                展开侧边栏
              </span>
              <span slot="title" class="menuTitle" v-else>收起侧边栏</span>
            </el-menu-item>
            <!--设备分类管理-->
            <el-submenu index="1">
              <template slot="title">
                <i class="el-icon-s-grid"></i>
                <span class="menuTitle" slot="title">设备分类管理</span>
              </template>
              <el-menu-item
                class="asideMenuItem"
                index="1-1"
                @click="
                  $router.push({
                    path: '/hitIotClassification/nationalTable',
                  })
                "
              >
                国标分类
              </el-menu-item>
<!--              <el-menu-item-->   <!-- 这个页面是以前定义的，用来定义模型，包括两个三个页 serviceClassification.vue和serviceDefine.vue（其引入了modelAttribute.vue,messageCoding.vue,MessageParamBinding.vue,modelCommand.vue(包括cmdModiParams.vue)），其中有一些错误，包括固定IP -->
<!--                class="asideMenuItem"-->
<!--                index="1-2"-->
<!--                @click="-->
<!--                  $router.push('/hitIotClassification/serviceClassification')-->
<!--                "-->
<!--              >-->
<!--                业务分类-->
<!--              </el-menu-item>-->
              <el-menu-item
                class="asideMenuItem"
                index="1-3"
                @click="$router.push('/hitIotClassification/productEntry')"
              >
                设备模型录入
              </el-menu-item>
              <el-menu-item
                class="asideMenuItem"
                index="1-4"
                @click="$router.push('/hitIotClassification/productQuery')"
              >
                设备模型查询与修改
              </el-menu-item>
            </el-submenu>
            <!--位置信息管理-->
            <el-submenu index="2">
              <template slot="title">
                <i class="el-icon-location"></i>
                <span class="menuTitle" slot="title">位置信息管理</span>
              </template>
              <el-menu-item
                class="asideMenuItem"
                index="2-1"
                @click="$router.push('/hitIotLocation/locationTest')"
              >
                地理位置信息
              </el-menu-item>
              <el-menu-item
                class="asideMenuItem"
                index="2-2"
                @click="$router.push('/hitIotLocation/controlArea')"
              >
                管控区域维护
              </el-menu-item>
              <el-menu-item
                class="asideMenuItem"
                index="2-3"
                @click="$router.push('/hitIotLocation/bindDevice')"
              >
                设备与区域绑定
              </el-menu-item>
              <el-menu-item
                class="asideMenuItem"
                index="2-4"
                @click="$router.push('/hitIotLocation/landMonitor')"
              >
                监测信息与设备控制
              </el-menu-item>
            </el-submenu>
            <!--设备基础信息维护-->
            <el-submenu index="3">
              <template slot="title">
                <i class="el-icon-s-order"></i>
                <span class="menuTitle" slot="title">设备基础信息维护</span>
              </template>
              <el-menu-item
                class="asideMenuItem"
                index="3-1"
                @click="$router.push('/hitIotBase/deviceReg')"
              >
                设备注册
              </el-menu-item>
              <el-menu-item
                class="asideMenuItem"
                index="3-2"
                @click="$router.push('/hitIotBase/deviceInfo')"
              >
                设备参数信息维护
              </el-menu-item>
              <el-menu-item
                class="asideMenuItem"
                index="3-5"
                @click="$router.push('/hitIotBase/deviceDel')"
              >
                设备信息维护
              </el-menu-item>
            </el-submenu>
            <!--健康检查参数维护-->
            <el-submenu index="4">
              <template slot="title">
                <i class="el-icon-s-help"></i>
                <span class="menuTitle" slot="title">健康检查参数维护</span>
              </template>
              <el-menu-item
                class="asideMenuItem"
                index="4-1"
                @click="$router.push('/hitIotHC/hcTest')"
              >
                健康检查维护
              </el-menu-item>
              <el-menu-item
                class="asideMenuItem"
                index="4-2"
                @click="$router.push('/hitIotHC/hcTest')"
              >
                异常日志及报警
              </el-menu-item>
            </el-submenu>
            <!--设备运行管理-->
            <el-submenu index="5">
              <template slot="title">
                <i class="el-icon-s-data"></i>
                <span class="menuTitle" slot="title">设备运行管理</span>
              </template>
              <el-menu-item
                class="asideMenuItem"
                index="5-2"
                @click="$router.push('/hitIotRun/runTest')"
              >
                运行服务管理
              </el-menu-item>
              <el-menu-item
                class="asideMenuItem"
                index="5-3"
                @click="$router.push('/hitIotRun/runTest')"
              >
                运行状态统计
              </el-menu-item>
            </el-submenu>
          </el-menu>
        </el-col>
      </el-row>
    </el-scrollbar>
  </el-aside>
</template>

<script>
export default {
  name: "customAside",
  data() {
    return {
      isCollapse: false,
    };
  },
};
</script>

<style scoped>
.asideBar {
  background-color: var(--themeBgColor);
  height: calc(100vh - 61px);
}
.asideScrollbar {
  height: 100%;
}
.asideMenu {
  border: 0;
}
.asideMenuItem {
  font-size: 20px;
}
.menuTitle {
  font-size: 20px;
}
.el-menu-vertical:not(.el-menu--collapse) {
  width: 260px;
}
</style>
