<template>
  <div style="margin: 10px">
    <div class="header">
      <el-button round @click="dialogAddItem = true"
        >添加{{ paramTpye }}</el-button
      >
    </div>
    <el-table
      :data="tableList"
      style="width: 100%"
      :header-row-style="{
        color: '#303133',
        'font-weight': 'bold',
      }"
      :header-cell-style="{ background: '#eef1f6', padding: '0' }"
      :cell-style="{ padding: '0' }"
    >
      <!--      header-cell-style里把表头高度调小一点-->
      <!--      cell-style里把普通表格高度调小一点-->
      <el-table-column prop="name" label="参数名称" min-width="25%">
      </el-table-column>
      <el-table-column prop="format" label="数据类型" min-width="25%">
      </el-table-column>
      <el-table-column prop="desc" label="描述" min-width="25%">
      </el-table-column>
      <el-table-column label="操作" min-width="25%">
        <template slot-scope="scope">
          <el-button
            @click="modiParam(scope.row)"
            type="text"
            style="font-size: 16px"
            >修改</el-button
          >
          <el-divider direction="vertical"></el-divider>
          <el-button
            @click="delParam(scope.row)"
            type="text"
            style="font-size: 16px"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <br />
    <div class="paginationClass">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[5, 10, 20]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>

    <el-dialog
      title="添加参数"
      :visible.sync="dialogAddItem"
      width="50%"
      :close-on-click-modal="false"
      @close="reset"
      v-dialogDrag
      append-to-body
    >
      <!--      当要在对话框中再打开一次对话框时，会发现新打开的对话框是暗的，这时候要在标签里加入append-to-body就可以了-->
      <el-form
        :model="form"
        :rules="rules"
        ref="form"
        label-width="100px"
        class="demo-ruleForm"
        status-icon
      >
        <el-form-item label="参数名称" prop="name">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="参数描述" prop="desc">
          <el-input type="textarea" v-model="form.desc"></el-input>
        </el-form-item>
        <el-form-item label="数据类型" prop="format">
          <el-select v-model="form.format" placeholder="请选择数据类型">
            <el-option label="int(整型)" value="int(整型)"></el-option>
            <el-option label="decimal(小数)" value="decimal(小数)"></el-option>
            <el-option
              label="string(字符串)"
              value="string(字符串)"
            ></el-option>
            <el-option
              label="dateTime(日期时间)"
              value="dateTime(日期时间)"
            ></el-option>
            <el-option
              label="jsonObject(JSON结构体)"
              value="jsonObject(JSON结构体)"
            ></el-option>
            <el-option
              label="stringList(数组)"
              value="stringList(数组)"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="取值范围"
          prop="range"
          v-if="form.format !== 'string(字符串)' && form.format !== ''"
        >
          <el-tooltip
            class="item"
            effect="dark"
            content="数字之间使用-分隔"
            placement="top"
          >
            <el-input v-model="form.range" placeholder="如：1-15"></el-input>
          </el-tooltip>
        </el-form-item>
        <el-form-item
          label="字符串枚举映射规则"
          prop="enummap"
          v-if="form.format === 'string(字符串)'"
          label-width="160px"
        >
          <el-tooltip
            class="item"
            effect="dark"
            content="字符串-数值用英文逗号分割"
            placement="top"
          >
            <el-input
              v-model="form.enummap"
              placeholder="如：区阀1-15,区阀2-16,区阀3-17,区阀4-18"
            ></el-input>
          </el-tooltip>
        </el-form-item>
        <el-form-item style="text-align: right">
          <!--          <el-button type="primary" @click="submitForm('ruleForm')">立即创建</el-button>-->
          <!--          <el-button @click="resetForm('ruleForm')">重置</el-button>-->
          <el-button @click="resetForm()">取 消</el-button>
          <el-button type="primary" @click="submitAddForm()">确 定</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <!--    虽然修改对话框与添加对话框几乎一模一样，但是表单提交函数的逻辑却不一样，这意味着共用一个组件需要考虑不同场景下一些参数的传入，-->
    <!--    这使得逻辑变的有点复杂，不利于代码的阅读和维护，所以底下还是写了一个几乎一样的dialog。-->
    <el-dialog
      title="修改参数"
      :visible.sync="dialogModiItem"
      width="50%"
      :close-on-click-modal="false"
      @close="reset"
      v-dialogDrag
      append-to-body
    >
      <el-form
        :model="form"
        :rules="rules"
        ref="form"
        label-width="100px"
        class="demo-ruleForm"
        status-icon
      >
        <el-form-item label="参数名称" prop="name">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="参数描述" prop="desc">
          <el-input type="textarea" v-model="form.desc"></el-input>
        </el-form-item>
        <el-form-item label="数据类型" prop="format">
          <el-select v-model="form.format" placeholder="请选择数据类型">
            <el-option label="int(整型)" value="int(整型)"></el-option>
            <el-option label="decimal(小数)" value="decimal(小数)"></el-option>
            <el-option
              label="string(字符串)"
              value="string(字符串)"
            ></el-option>
            <el-option
              label="dateTime(日期时间)"
              value="dateTime(日期时间)"
            ></el-option>
            <el-option
              label="jsonObject(JSON结构体)"
              value="jsonObject(JSON结构体)"
            ></el-option>
            <el-option
              label="stringList(数组)"
              value="stringList(数组)"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="取值范围"
          prop="range"
          v-if="form.format !== 'string(字符串)' && form.format !== ''"
        >
          <el-tooltip
            class="item"
            effect="dark"
            content="数字之间使用-分隔"
            placement="top"
          >
            <el-input v-model="form.range" placeholder="如：1-15"></el-input>
          </el-tooltip>
        </el-form-item>
        <el-form-item
          label="字符串枚举映射规则"
          prop="enummap"
          v-if="form.format === 'string(字符串)'"
          label-width="160px"
        >
          <el-tooltip
            class="item"
            effect="dark"
            content="字符串-数值用英文逗号分割"
            placement="top"
          >
            <el-input
              v-model="form.enummap"
              placeholder="如：区阀1-15,区阀2-16,区阀3-17,区阀4-18"
            ></el-input>
          </el-tooltip>
        </el-form-item>
        <el-form-item style="text-align: right">
          <!--          <el-button type="primary" @click="submitForm('ruleForm')">立即创建</el-button>-->
          <!--          <el-button @click="resetForm('ruleForm')">重置</el-button>-->
          <el-button @click="resetForm()">取 消</el-button>
          <el-button type="primary" @click="submitModiForm()">确 定</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "cmdAddParams",
  props: {
    paramTpye: {
      type: String,
      default: "",
    },
  },
  data() {
    // 这里因为设置了1秒的延迟，所以提交和修改相较于业务添加那块会有1秒的延迟，给人一种慢了的感觉，想要提高体验，可以减小延迟时间
    return {
      flag: true,
      total: 0,
      currentPage: 1,
      pageSize: 10,
      tableData: [],
      tableList: [],
      dialogAddItem: false,
      dialogModiItem: false,
      index: "",
      // index用来定位需要修改的属性下标
      form: {
        name: "",
        desc: "",
        format: "",
        range: "",
        enummap: "",
      },
      rules: {
        name: [{ required: true, message: "请输入属性名称", trigger: "blur" }],
        format: [
          { required: true, message: "请选择参数类型", trigger: "change" },
        ],
        enummap: [
          {
            required: true,
            message: "字符串枚举映射不能为空",
            trigger: "blur",
          },
        ],
        range: [
          { required: true, message: "取值范围不能为空", trugger: "blur" },
        ],
      },
    };
  },
  methods: {
    deepClone: function deepClone(target) {
      // 定义一个变量
      let result;
      // 如果当前需要深拷贝的是一个对象的话
      if (typeof target === "object") {
        // 如果是一个数组的话
        if (Array.isArray(target)) {
          result = []; // 将result赋值为一个数组，并且执行遍历
          for (let i in target) {
            // 递归克隆数组中的每一项
            result.push(deepClone(target[i]));
          }
          // 判断如果当前的值是null的话；直接赋值为null
        } else if (target === null) {
          result = null;
          // 判断如果当前的值是一个RegExp对象的话，直接赋值
        } else if (target.constructor === RegExp) {
          result = target;
        } else {
          // 否则是普通对象，直接for in循环，递归赋值对象的所有值
          result = {};
          for (let i in target) {
            result[i] = deepClone(target[i]);
          }
        }
        // 如果不是对象的话，就是基本数据类型，那么直接赋值
      } else {
        result = target;
      }
      // 返回最终结果
      return result;
    },
    getArrayIndex(arr, obj) {
      let i = arr.length;
      while (i--) {
        if (arr[i].name === obj.name) {
          return i;
        }
      }
      //添加模块这里还不能生成id，所以用name来作为唯一标识进行修改操作
      return -1;
    },
    modiParam(row) {
      this.index = this.getArrayIndex(this.tableData, row);
      this.dialogModiItem = true;
    },
    delParam(row) {
      this.$confirm("此操作将永久删除该参数, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        const index = this.getArrayIndex(this.tableData, row);
        this.tableData.splice(index, 1);
        if (this.paramTpye === "下发参数") {
          this.$emit("transmit-add-param", this.tableData);
        } else if (this.paramTpye === "响应参数") {
          this.$emit("response-add-param", this.tableData);
        }
        // 删除完了也要跟父组件同步
        this.currentChangePage(this.tableData, this.currentPage);
        this.total = this.tableData.length;
        this.$message({
          type: "success",
          message: "删除成功!",
        });
      });
    },
    handleSizeChange(pageSize) {
      // 每页条数切换
      this.pageSize = pageSize;
      this.handleCurrentChange(this.currentPage);
    },
    handleCurrentChange(currentPage) {
      //页码切换
      this.currentPage = currentPage;
      this.currentChangePage(this.tableData, currentPage);
    },
    //分页方法（重点）
    currentChangePage(list, currentPage) {
      let from = (currentPage - 1) * this.pageSize;
      let to = currentPage * this.pageSize;
      this.tableList = [];
      for (; from < to; from++) {
        if (list[from]) {
          this.tableList.push(list[from]);
        }
      }
    },
    submitAddForm() {
      this.flag = true;
      let i;
      for (i = 0; i < this.tableData.length; ++i) {
        if (this.tableData[i].name === this.form.name) {
          this.$refs["form"].resetFields();
          this.$message({
            type: "error",
            message: "同名参数已经存在!",
          });
          this.flag = false;
          break;
        }
      }
      if (this.flag) {
        this.$refs["form"].validate((valid) => {
          if (valid) {
            if (this.form.format !== "string(字符串)") {
              this.form.enummap = "";
            } else {
              this.form.range = "";
            }
            // 防止用户两个都填了
            this.tableData.push(this.deepClone(this.form));
            if (this.paramTpye === "下发参数") {
              this.$emit("transmit-add-param", this.tableData);
            } else if (this.paramTpye === "响应参数") {
              this.$emit("response-add-param", this.tableData);
            }
            // 根据paramType向父组件传tablData
            // 注意，transmit-add-param不要写成transmitAddParam，脚手架应该还不支持这种写法
            this.dialogAddItem = false;
            this.currentChangePage(this.tableData, this.currentPage);
            this.total = this.tableData.length;
            this.$message({
              type: "success",
              message: "添加成功!",
            });
          } else {
            return false;
          }
        });
      }
    },
    submitModiForm() {
      this.flag = true;
      let i;
      for (i = 0; i < this.tableData.length; ++i) {
        if (
          this.tableData[i].name === this.form.name &&
          this.form.name !== this.tableData[this.index].name
        ) {
          this.$refs["form"].resetFields();
          this.$message({
            type: "error",
            message: "同名属性已经存在!",
          });
          this.flag = false;
          break;
        }
      }
      // 修改函数也要检查是否有同名属性（除修改前的自己以外）
      this.$refs["form"].validate((valid) => {
        if (valid) {
          if (this.form.format !== "string(字符串)") {
            this.form.enummap = "";
          } else {
            this.form.range = "";
          }
          // 防止用户两个都填了
          this.tableData[this.index] = this.deepClone(this.form);
          // 考虑到javascript语法中也存在的深拷贝问题
          if (this.paramTpye === "下发参数") {
            this.$emit("transmit-add-param", this.tableData);
          } else if (this.paramTpye === "响应参数") {
            this.$emit("response-add-param", this.tableData);
          }
          // 修改完了也要跟父组件同步
          this.dialogModiItem = false;
          this.currentChangePage(this.tableData, this.currentPage);
          this.total = this.tableData.length;
          this.$message({
            type: "success",
            message: "修改成功!",
          });
        } else {
          return false;
        }
      });
    },
    resetForm() {
      this.dialogAddItem = false;
      this.dialogModiItem = false;
    },
    reset() {
      this.$refs["form"].resetFields();
    },
  },
};
</script>

<style scoped>
.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
</style>
