//设备基础信息维护
import baseTest from "../../views/hitIotBase/baseTest";
import deviceReg from "@/views/hitIotBase/deviceReg";
import deviceDel from "@/views/hitIotBase/deviceDel";
import deviceInfo from "@/views/hitIotBase/deviceInfo";
import rtDeviceDetail from "../../views/hitIotBase/rtDeviceDetail";
import deviceSearch from "@/views/hitIotBase/deviceSearch";
import DeviceModify from "../../views/hitIotBase/DeviceModify";
import devicebindReg from "../../views/hitIotBase/devicebindReg";

const routerHitIotBase = [
  {
    path: "baseTest",
    component: baseTest,
    meta: {
      title: "设备基础信息管理测试",
    },
  },
  {
    path: "deviceReg",
    component: deviceReg,
    meta: {
      title: "设备注册",
    },
  },
  {
    path: "deviceDel",
    component: deviceDel,
    meta: {
      title: "设备信息维护",
    },
  },
  {
    path: "deviceInfo",
    component: deviceInfo,
    meta: {
      title: "设备参数信息维护",
    },
  },
  {
    path: "rtDeviceDetail",
    component: (resolve) =>
      require(["../../views/hitIotBase/rtDeviceDetail"], resolve),
    name: "rtDeviceDetail",
    meta: {
      title: "实时设备状态展示",
      keepAlive: false,
    },
  },
  // {
  //   path: "deviceSearch",
  //   component: deviceSearch,
  //   meta: {
  //     title: "设备查询",
  //   },
  // },
  {
    path: "DeviceModify",
    component: DeviceModify,
    meta: {
      title: "设备信息修改",
    },
  },
  {
    path: "devicebindReg",
    component: devicebindReg,
    meta: {
      title: "设备与地块绑定",
    },
  },
];
export default routerHitIotBase;
